.au-row{
  border: 1px solid rgb(54, 140, 255);
  padding: 1rem;
  margin-bottom: 1rem;
}

.au-button {
  margin-top: 1rem;
  margin-right: 1rem;
}

.au-title {
  margin: 1rem 0;
}

.au-img{
  max-width: 90%;
}