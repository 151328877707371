.table-component {
  padding: 0 !important;
}

table {
  width: 100%;
  border-collapse: separate !important;
  border-spacing: 0;
  border: solid #368cff 1px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  overflow: hidden;
}

thead {
  background-color: #100F0F;
  padding: 1rem;
}

th {
  padding: 0.5rem;
}

td {
  padding: 0.5rem;
  border-bottom: 1px solid #368cff !important;
}

td img {
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
}

.expertise-areas-ul{
  padding: 0;
}

.expertise-areas-li {
  list-style-position: inside;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-column-title{
  margin: 0;
}

.table-expand-button {
  cursor: pointer;
  background: transparent;
  border: 0;
  font-size: 3rem;
  color: #368cff;
}

.table-expand-button svg {
  transition: 0.2s;
}

.table-expand-button .active {
  transform: rotate(180deg);
}

.flag-img{
  border-radius: 0;
  width: 5rem;
  height: 3rem;
  background-color: #FFFFFF;
}

.table-socials{
  font-size: 1.8rem;
  color: #FFFFFF;
  transition: 0.2s;
}

.table-socials:not(:last-of-type){
  margin-right: 1rem;
}

.table-socials:hover {
  color: #368cff;
}

.bio-p {
  white-space: pre-wrap;
  padding-right: 1rem;
}

.td-no-border {
  border: 0 !important;
}

.links-in-table {
  display: flex;
  margin: 2rem 0 !important;
  align-items: center;
}

.links-in-table button:not(:last-of-type) {
  margin-right: 1rem;
  margin-left: 1rem;
}

.image-in-table {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-cropper-img {
  object-fit: cover;
  border-radius: 50%;
  height: 10rem;
  width: 10rem;
}