section:nth-child(even){
  background-color: #100F0F;
  padding: 50px 0;
}

section:not(:first-child){
  padding: 50px 0;
}

.padding-0 {
  padding: 0;
}

.padding-0-imp {
  padding: 0 !important;
}

.app-container {
  position: relative;
  min-height: 100vh;
  padding-bottom: 18rem;
  overflow-x: hidden;
}

.bg {
  background-color:hsla(88,0%,0%,1);
  background-image:
  radial-gradient(at 86% 84%, hsla(237,84%,62%,0.2) 0px, transparent 50%),
  radial-gradient(at 59% 82%, hsla(240,75%,60%,0.2) 0px, transparent 50%),
  radial-gradient(at 39% 30%, hsla(212,85%,77%,0.05) 0px, transparent 50%),
  radial-gradient(at 32% 9%, hsla(208,99%,75%,0.06) 0px, transparent 50%),
  radial-gradient(at 96% 10%, hsla(189,63%,61%,0.1) 0px, transparent 50%),
  radial-gradient(at 4% 3%, hsla(239,70%,77%,0.15) 0px, transparent 50%),
  radial-gradient(at 50% 70%, hsla(233,73%,73%,0.2) 0px, transparent 50%);
  background-size: cover;
}