.po-title {
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
}

.po-p {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 3rem;
}

.po-button-col{
  display: flex;
  justify-content: center;
}

.po-button {
  background-color: #100F0F;
  border: 1px solid #368cff;
  border-radius: 0.5rem;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Basier circle",-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1;
  padding: 1rem 1.6rem;
  text-align: center;
  text-decoration: none #222222 solid;
  text-decoration-thickness: auto;
  transition: all .15s cubic-bezier(.4, 0, .2, 1);
  box-shadow: 0px 1px 2px rgba(166, 175, 195, 0.25);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-bottom: 10px;
}

.po-button:hover {
  background: rgb(0,109,255);
  background: radial-gradient(circle, rgba(0,109,255,0.4490838571756828) 0%, rgba(0,6,36,0.6255544454109769) 100%);
  color: #fff;
  transition: 1s;
}

@media (min-width: 768px) {
  .po-button {
    font-size: 1.125rem;
    padding: 1rem 2rem;
  }
}