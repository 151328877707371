.filter-container {
  background-color: #100F0F;
  padding: 1rem;
  margin-top: 1rem;
  border: 1px solid #368cff;
  border-radius: 5px;
  margin-bottom: 3rem;
}

.filter-col {
  color: #000000;
}

.filter-name-input{
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px solid rgba(red, green, blue, 0.3);
  background-color: rgba(red, green, blue, 0.3);
  padding: 0 10px;
  outline: #368cff;
}

.filter-name-input:focus-visible{
  box-shadow: 0 0 0px 1px #368cff;
  border: 1px solid #368cff;
}
