@keyframes antFadeIn {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes antFadeOut {
    0% {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @keyframes antMoveDownIn {
    0% {
      transform: translateY(100%);
      transform-origin: 0 0;
      opacity: 0;
    }
    to {
      transform: translateY(0);
      transform-origin: 0 0;
      opacity: 1;
    }
  }
  @keyframes antMoveDownOut {
    0% {
      transform: translateY(0);
      transform-origin: 0 0;
      opacity: 1;
    }
    to {
      transform: translateY(100%);
      transform-origin: 0 0;
      opacity: 0;
    }
  }
  @keyframes antMoveLeftIn {
    0% {
      transform: translateX(-100%);
      transform-origin: 0 0;
      opacity: 0;
    }
    to {
      transform: translateX(0);
      transform-origin: 0 0;
      opacity: 1;
    }
  }
  @keyframes antMoveLeftOut {
    0% {
      transform: translateX(0);
      transform-origin: 0 0;
      opacity: 1;
    }
    to {
      transform: translateX(-100%);
      transform-origin: 0 0;
      opacity: 0;
    }
  }
  @keyframes antMoveRightIn {
    0% {
      transform: translateX(100%);
      transform-origin: 0 0;
      opacity: 0;
    }
    to {
      transform: translateX(0);
      transform-origin: 0 0;
      opacity: 1;
    }
  }
  @keyframes antMoveRightOut {
    0% {
      transform: translateX(0);
      transform-origin: 0 0;
      opacity: 1;
    }
    to {
      transform: translateX(100%);
      transform-origin: 0 0;
      opacity: 0;
    }
  }
  @keyframes antMoveUpIn {
    0% {
      transform: translateY(-100%);
      transform-origin: 0 0;
      opacity: 0;
    }
    to {
      transform: translateY(0);
      transform-origin: 0 0;
      opacity: 1;
    }
  }
  @keyframes antMoveUpOut {
    0% {
      transform: translateY(0);
      transform-origin: 0 0;
      opacity: 1;
    }
    to {
      transform: translateY(-100%);
      transform-origin: 0 0;
      opacity: 0;
    }
  }
  @keyframes loadingCircle {
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes waveEffect {
    to {
      box-shadow: 0 0 0 #2caad9;
      box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
    }
  }
  @keyframes fadeEffect {
    to {
      opacity: 0;
    }
  }
  @keyframes antSlideUpIn {
    0% {
      transform: scaleY(0.8);
      transform-origin: 0 0;
      opacity: 0;
    }
    to {
      transform: scaleY(1);
      transform-origin: 0 0;
      opacity: 1;
    }
  }
  @keyframes antSlideUpOut {
    0% {
      transform: scaleY(1);
      transform-origin: 0 0;
      opacity: 1;
    }
    to {
      transform: scaleY(0.8);
      transform-origin: 0 0;
      opacity: 0;
    }
  }
  @keyframes antSlideDownIn {
    0% {
      transform: scaleY(0.8);
      transform-origin: 100% 100%;
      opacity: 0;
    }
    to {
      transform: scaleY(1);
      transform-origin: 100% 100%;
      opacity: 1;
    }
  }
  @keyframes antSlideDownOut {
    0% {
      transform: scaleY(1);
      transform-origin: 100% 100%;
      opacity: 1;
    }
    to {
      transform: scaleY(0.8);
      transform-origin: 100% 100%;
      opacity: 0;
    }
  }
  @keyframes antSlideLeftIn {
    0% {
      transform: scaleX(0.8);
      transform-origin: 0 0;
      opacity: 0;
    }
    to {
      transform: scaleX(1);
      transform-origin: 0 0;
      opacity: 1;
    }
  }
  @keyframes antSlideLeftOut {
    0% {
      transform: scaleX(1);
      transform-origin: 0 0;
      opacity: 1;
    }
    to {
      transform: scaleX(0.8);
      transform-origin: 0 0;
      opacity: 0;
    }
  }
  @keyframes antSlideRightIn {
    0% {
      transform: scaleX(0.8);
      transform-origin: 100% 0;
      opacity: 0;
    }
    to {
      transform: scaleX(1);
      transform-origin: 100% 0;
      opacity: 1;
    }
  }
  @keyframes antSlideRightOut {
    0% {
      transform: scaleX(1);
      transform-origin: 100% 0;
      opacity: 1;
    }
    to {
      transform: scaleX(0.8);
      transform-origin: 100% 0;
      opacity: 0;
    }
  }
  @keyframes antZoomIn {
    0% {
      transform: scale(0.2);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes antZoomOut {
    0% {
      transform: scale(1);
    }
    to {
      transform: scale(0.2);
      opacity: 0;
    }
  }
  @keyframes antZoomBigIn {
    0% {
      transform: scale(0.8);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes antZoomBigOut {
    0% {
      transform: scale(1);
    }
    to {
      transform: scale(0.8);
      opacity: 0;
    }
  }
  @keyframes antZoomUpIn {
    0% {
      transform: scale(0.8);
      transform-origin: 50% 0;
      opacity: 0;
    }
    to {
      transform: scale(1);
      transform-origin: 50% 0;
    }
  }
  @keyframes antZoomUpOut {
    0% {
      transform: scale(1);
      transform-origin: 50% 0;
    }
    to {
      transform: scale(0.8);
      transform-origin: 50% 0;
      opacity: 0;
    }
  }
  @keyframes antZoomLeftIn {
    0% {
      transform: scale(0.8);
      transform-origin: 0 50%;
      opacity: 0;
    }
    to {
      transform: scale(1);
      transform-origin: 0 50%;
    }
  }
  @keyframes antZoomLeftOut {
    0% {
      transform: scale(1);
      transform-origin: 0 50%;
    }
    to {
      transform: scale(0.8);
      transform-origin: 0 50%;
      opacity: 0;
    }
  }
  @keyframes antZoomRightIn {
    0% {
      transform: scale(0.8);
      transform-origin: 100% 50%;
      opacity: 0;
    }
    to {
      transform: scale(1);
      transform-origin: 100% 50%;
    }
  }
  @keyframes antZoomRightOut {
    0% {
      transform: scale(1);
      transform-origin: 100% 50%;
    }
    to {
      transform: scale(0.8);
      transform-origin: 100% 50%;
      opacity: 0;
    }
  }
  @keyframes antZoomDownIn {
    0% {
      transform: scale(0.8);
      transform-origin: 50% 100%;
      opacity: 0;
    }
    to {
      transform: scale(1);
      transform-origin: 50% 100%;
    }
  }
  @keyframes antZoomDownOut {
    0% {
      transform: scale(1);
      transform-origin: 50% 100%;
    }
    to {
      transform: scale(0.8);
      transform-origin: 50% 100%;
      opacity: 0;
    }
  }
  @keyframes antStatusProcessing {
    0% {
      transform: scale(0.8);
      opacity: 0.5;
    }
    to {
      transform: scale(2.4);
      opacity: 0;
    }
  }
  @keyframes antZoomBadgeIn {
    0% {
      transform: scale(0) translate(50%, -50%);
      opacity: 0;
    }
    to {
      transform: scale(1) translate(50%, -50%);
    }
  }
  @keyframes antZoomBadgeOut {
    0% {
      transform: scale(1) translate(50%, -50%);
    }
    to {
      transform: scale(0) translate(50%, -50%);
      opacity: 0;
    }
  }
  @keyframes antZoomBadgeInRtl {
    0% {
      transform: scale(0) translate(-50%, -50%);
      opacity: 0;
    }
    to {
      transform: scale(1) translate(-50%, -50%);
    }
  }
  @keyframes antZoomBadgeOutRtl {
    0% {
      transform: scale(1) translate(-50%, -50%);
    }
    to {
      transform: scale(0) translate(-50%, -50%);
      opacity: 0;
    }
  }
  @keyframes card-loading {
    0%,
    to {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
  }
  @keyframes antCheckboxEffect {
    0% {
      transform: scale(1);
      opacity: 0.5;
    }
    to {
      transform: scale(1.6);
      opacity: 0;
    }
  }
  @keyframes antdDrawerFadeIn {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes antShowHelpIn {
    0% {
      transform: translateY(-5px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes antShowHelpOut {
    to {
      transform: translateY(-5px);
      opacity: 0;
    }
  }
  @keyframes diffZoomIn1 {
    0% {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  @keyframes diffZoomIn2 {
    0% {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  @keyframes diffZoomIn3 {
    0% {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  @keyframes MessageMoveOut {
    0% {
      max-height: 150px;
      padding: 8px;
      opacity: 1;
    }
    to {
      max-height: 0;
      padding: 0;
      opacity: 0;
    }
  }
  @keyframes NotificationFadeIn {
    0% {
      left: 384px;
      opacity: 0;
    }
    to {
      left: 0;
      opacity: 1;
    }
  }
  @keyframes NotificationLeftFadeIn {
    0% {
      right: 384px;
      opacity: 0;
    }
    to {
      right: 0;
      opacity: 1;
    }
  }
  @keyframes NotificationFadeOut {
    0% {
      max-height: 150px;
      margin-bottom: 16px;
      padding-top: 16px;
      padding-bottom: 16px;
      opacity: 1;
    }
    to {
      max-height: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      opacity: 0;
    }
  }
  @keyframes ant-progress-active {
    0% {
      width: 0;
      opacity: 0.1;
    }
    20% {
      width: 0;
      opacity: 0.5;
    }
    to {
      width: 100%;
      opacity: 0;
    }
  }
  @keyframes antRadioEffect {
    0% {
      transform: scale(1);
      opacity: 0.5;
    }
    to {
      transform: scale(1.6);
      opacity: 0;
    }
  }
  @keyframes ant-skeleton-loading {
    0% {
      background-position: 100% 50%;
    }
    to {
      background-position: 0 50%;
    }
  }
  @keyframes ant-skeleton-loading-rtl {
    0% {
      background-position: 0 50%;
    }
    to {
      background-position: 100% 50%;
    }
  }
  @keyframes antSpinMove {
    to {
      opacity: 1;
    }
  }
  @keyframes antRotate {
    to {
      transform: rotate(405deg);
    }
  }
  @keyframes antRotateRtl {
    to {
      transform: rotate(-405deg);
    }
  }
  @keyframes uploadAnimateIn {
    0% {
      height: 0;
      margin: 0;
      padding: 0;
      opacity: 0;
    }
  }
  @keyframes uploadAnimateOut {
    to {
      height: 0;
      margin: 0;
      padding: 0;
      opacity: 0;
    }
  }
  @keyframes uploadAnimateInlineIn {
    0% {
      width: 0;
      height: 0;
      margin: 0;
      padding: 0;
      opacity: 0;
    }
  }
  @keyframes uploadAnimateInlineOut {
    to {
      width: 0;
      height: 0;
      margin: 0;
      padding: 0;
      opacity: 0;
    }
  }
  .ant-image-preview,
  .ant-modal {
    pointer-events: none;
  }
  .ant-image-preview.zoom-appear,
  .ant-image-preview.zoom-enter,
  .ant-modal.zoom-appear,
  .ant-modal.zoom-enter {
    transform: none;
    opacity: 0;
    animation-duration: 0.3s;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-image-preview-mask,
  .ant-modal-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    filter: alpha(opacity=50);
  }
  .ant-image-preview-mask-hidden,
  .ant-modal-mask-hidden,
  template {
    display: none;
  }
  .ant-image-preview-wrap,
  .ant-modal-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    outline: 0;
    -webkit-overflow-scrolling: touch;
  }
  [class*="ant-"] input::-ms-clear,
  [class*="ant-"] input::-ms-reveal,
  [class*="ant-"]::-ms-clear,
  [class^="ant-"] input::-ms-clear,
  [class^="ant-"] input::-ms-reveal,
  [class^="ant-"]::-ms-clear,
  input::-ms-clear,
  input::-ms-reveal {
    display: none;
  }
  *,
  ::after,
  ::before,
  [class*="ant-"],
  [class*="ant-"] *,
  [class*="ant-"] ::after,
  [class*="ant-"] ::before,
  [class^="ant-"],
  [class^="ant-"] *,
  [class^="ant-"] ::after,
  [class^="ant-"] ::before {
    box-sizing: border-box;
  }
  body,
  html {
    width: 100%;
    height: 100%;
  }
  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
    --antd-wave-shadow-color: #2caad9;
    --scroll-bar: 0;
  }
  @-ms-viewport {
    width: device-width;
  }
  body {
    margin: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-variant: tabular-nums;
    line-height: 1.5715;
    background-color: #212121;
    font-feature-settings: "tnum";
  }
  [tabindex="-1"]:focus {
    outline: 0 !important;
  }
  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0.5em;
    color: rgba(255, 255, 255, 0.85);
    font-weight: 500;
  }
  dl,
  ol,
  p,
  ul {
    margin-top: 0;
    margin-bottom: 1em;
  }
  abbr[data-original-title],
  abbr[title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    border-bottom: 0;
    cursor: help;
  }
  address {
    margin-bottom: 1em;
    font-style: normal;
    line-height: inherit;
  }
  input[type="number"],
  input[type="password"],
  input[type="text"],
  textarea {
    -webkit-appearance: none;
  }
  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-bottom: 0;
  }
  dt {
    font-weight: 500;
  }
  dd {
    margin-bottom: 0.5em;
    margin-left: 0;
  }
  blockquote,
  figure {
    margin: 0 0 1em;
  }
  dfn {
    font-style: italic;
  }
  b,
  strong {
    font-weight: bolder;
  }
  small {
    font-size: 80%;
  }
  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }
  sub {
    bottom: -0.25em;
  }
  sup {
    top: -0.5em;
  }
  a {
    color: #2caad9;
    text-decoration: none;
    background-color: transparent;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;
  }
  a:hover {
    color: #287a99;
  }
  a:active {
    color: #4eb3d2;
  }
  a:active,
  a:hover {
    text-decoration: none;
    outline: 0;
  }
  a:focus {
    text-decoration: none;
    outline: 0;
  }
  a[disabled] {
    color: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
    pointer-events: none;
  }
  code,
  kbd,
  pre,
  samp {
    font-size: 1em;
    font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
  }
  pre {
    margin-top: 0;
    margin-bottom: 1em;
    overflow: auto;
  }
  img {
    vertical-align: middle;
    border-style: none;
  }
  svg:not(:root) {
    overflow: hidden;
  }
  [role="button"],
  a,
  area,
  button,
  input:not([type="range"]),
  label,
  select,
  summary,
  textarea {
    touch-action: manipulation;
  }
  table {
    border-collapse: collapse;
  }
  caption {
    padding-top: 0.75em;
    padding-bottom: 0.3em;
    color: rgba(255, 255, 255, 0.45);
    text-align: left;
    caption-side: bottom;
  }
  th {
    text-align: inherit;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }
  button,
  input {
    overflow: visible;
  }
  button,
  select {
    text-transform: none;
  }
  [type="reset"],
  [type="submit"],
  button,
  html [type="button"] {
    -webkit-appearance: button;
  }
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner,
  button::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }
  input[type="checkbox"],
  input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }
  input[type="date"],
  input[type="datetime-local"],
  input[type="month"],
  input[type="time"] {
    -webkit-appearance: listbox;
  }
  textarea {
    overflow: auto;
    resize: vertical;
  }
  fieldset {
    min-width: 0;
    margin: 0;
    padding: 0;
    border: 0;
  }
  legend {
    display: block;
    width: 100%;
    max-width: 100%;
    margin-bottom: 0.5em;
    padding: 0;
    color: inherit;
    font-size: 1.5em;
    line-height: inherit;
    white-space: normal;
  }
  progress {
    vertical-align: baseline;
  }
  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }
  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
  }
  [type="search"]::-webkit-search-cancel-button,
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }
  .anticon svg,
  output {
    display: inline-block;
  }
  summary {
    display: list-item;
  }
  [hidden] {
    display: none !important;
  }
  mark {
    padding: 0.2em;
    background-color: #36311c;
  }
  ::selection {
    color: #fff;
    background: #2caad9;
  }
  .clearfix::after,
  .clearfix::before {
    display: table;
    content: "";
  }
  .clearfix::after {
    clear: both;
  }
  .anticon {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .anticon > * {
    line-height: 1;
  }
  .anticon::before {
    display: none;
  }
  .anticon .anticon-icon {
    display: block;
  }
  .anticon[tabindex] {
    cursor: pointer;
  }
  .anticon-spin,
  .anticon-spin::before {
    display: inline-block;
    animation: loadingCircle 1s infinite linear;
  }
  .fade-appear,
  .fade-enter,
  .fade-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .fade-appear.fade-appear-active,
  .fade-enter.fade-enter-active {
    animation-name: antFadeIn;
    animation-play-state: running;
  }
  .fade-leave.fade-leave-active {
    animation-name: antFadeOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .fade-appear,
  .fade-enter {
    opacity: 0;
  }
  .fade-appear,
  .fade-enter,
  .fade-leave {
    animation-timing-function: linear;
  }
  .move-up-appear,
  .move-up-enter,
  .move-up-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .move-up-appear.move-up-appear-active,
  .move-up-enter.move-up-enter-active {
    animation-name: antMoveUpIn;
    animation-play-state: running;
  }
  .move-up-leave.move-up-leave-active {
    animation-name: antMoveUpOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .move-up-appear,
  .move-up-enter {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .move-up-leave {
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  }
  .move-down-appear,
  .move-down-enter,
  .move-down-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .move-down-appear.move-down-appear-active,
  .move-down-enter.move-down-enter-active {
    animation-name: antMoveDownIn;
    animation-play-state: running;
  }
  .move-down-leave.move-down-leave-active {
    animation-name: antMoveDownOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .move-down-appear,
  .move-down-enter {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .move-down-leave {
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  }
  .move-left-appear,
  .move-left-enter,
  .move-left-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .move-left-appear.move-left-appear-active,
  .move-left-enter.move-left-enter-active {
    animation-name: antMoveLeftIn;
    animation-play-state: running;
  }
  .move-left-leave.move-left-leave-active {
    animation-name: antMoveLeftOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .move-left-appear,
  .move-left-enter {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .move-left-leave {
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  }
  .move-right-appear,
  .move-right-enter,
  .move-right-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .move-right-appear.move-right-appear-active,
  .move-right-enter.move-right-enter-active {
    animation-name: antMoveRightIn;
    animation-play-state: running;
  }
  .move-right-leave.move-right-leave-active {
    animation-name: antMoveRightOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .move-right-appear,
  .move-right-enter {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .move-right-leave {
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  }
  [ant-click-animating-without-extra-node="true"],
  [ant-click-animating="true"] {
    position: relative;
  }
  .ant-click-animating-node,
  [ant-click-animating-without-extra-node="true"]::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    border-radius: inherit;
    box-shadow: 0 0 0 0 #2caad9;
    box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
    opacity: 0.2;
    animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    content: "";
    pointer-events: none;
  }
  .slide-up-appear,
  .slide-up-enter,
  .slide-up-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .slide-up-appear.slide-up-appear-active,
  .slide-up-enter.slide-up-enter-active {
    animation-name: antSlideUpIn;
    animation-play-state: running;
  }
  .slide-up-leave.slide-up-leave-active {
    animation-name: antSlideUpOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .slide-up-appear,
  .slide-up-enter {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .slide-up-leave {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  .slide-down-appear,
  .slide-down-enter,
  .slide-down-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .slide-down-appear.slide-down-appear-active,
  .slide-down-enter.slide-down-enter-active {
    animation-name: antSlideDownIn;
    animation-play-state: running;
  }
  .slide-down-leave.slide-down-leave-active {
    animation-name: antSlideDownOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .slide-down-appear,
  .slide-down-enter {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .slide-down-leave {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  .slide-left-appear,
  .slide-left-enter,
  .slide-left-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .slide-left-appear.slide-left-appear-active,
  .slide-left-enter.slide-left-enter-active {
    animation-name: antSlideLeftIn;
    animation-play-state: running;
  }
  .slide-left-leave.slide-left-leave-active {
    animation-name: antSlideLeftOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .slide-left-appear,
  .slide-left-enter {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .slide-left-leave {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  .slide-right-appear,
  .slide-right-enter,
  .slide-right-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .slide-right-appear.slide-right-appear-active,
  .slide-right-enter.slide-right-enter-active {
    animation-name: antSlideRightIn;
    animation-play-state: running;
  }
  .slide-right-leave.slide-right-leave-active {
    animation-name: antSlideRightOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .slide-right-appear,
  .slide-right-enter {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .slide-right-leave {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  .zoom-appear,
  .zoom-enter,
  .zoom-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .zoom-appear.zoom-appear-active,
  .zoom-enter.zoom-enter-active {
    animation-name: antZoomIn;
    animation-play-state: running;
  }
  .zoom-leave.zoom-leave-active {
    animation-name: antZoomOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .zoom-appear,
  .zoom-enter {
    transform: scale(0);
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .zoom-appear-prepare,
  .zoom-enter-prepare {
    transform: none;
  }
  .zoom-leave {
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .zoom-big-appear,
  .zoom-big-enter,
  .zoom-big-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .zoom-big-appear.zoom-big-appear-active,
  .zoom-big-enter.zoom-big-enter-active {
    animation-name: antZoomBigIn;
    animation-play-state: running;
  }
  .zoom-big-leave.zoom-big-leave-active {
    animation-name: antZoomBigOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .zoom-big-appear,
  .zoom-big-enter {
    transform: scale(0);
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .zoom-big-appear-prepare,
  .zoom-big-enter-prepare {
    transform: none;
  }
  .zoom-big-leave {
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .zoom-big-fast-appear,
  .zoom-big-fast-enter,
  .zoom-big-fast-leave {
    animation-duration: 0.1s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .zoom-big-fast-appear.zoom-big-fast-appear-active,
  .zoom-big-fast-enter.zoom-big-fast-enter-active {
    animation-name: antZoomBigIn;
    animation-play-state: running;
  }
  .zoom-big-fast-leave.zoom-big-fast-leave-active {
    animation-name: antZoomBigOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .zoom-big-fast-appear,
  .zoom-big-fast-enter {
    transform: scale(0);
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .zoom-big-fast-appear-prepare,
  .zoom-big-fast-enter-prepare {
    transform: none;
  }
  .zoom-big-fast-leave {
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .zoom-up-appear,
  .zoom-up-enter,
  .zoom-up-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .zoom-up-appear.zoom-up-appear-active,
  .zoom-up-enter.zoom-up-enter-active {
    animation-name: antZoomUpIn;
    animation-play-state: running;
  }
  .zoom-up-leave.zoom-up-leave-active {
    animation-name: antZoomUpOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .zoom-up-appear,
  .zoom-up-enter {
    transform: scale(0);
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .zoom-up-appear-prepare,
  .zoom-up-enter-prepare {
    transform: none;
  }
  .zoom-up-leave {
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .zoom-down-appear,
  .zoom-down-enter,
  .zoom-down-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .zoom-down-appear.zoom-down-appear-active,
  .zoom-down-enter.zoom-down-enter-active {
    animation-name: antZoomDownIn;
    animation-play-state: running;
  }
  .zoom-down-leave.zoom-down-leave-active {
    animation-name: antZoomDownOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .zoom-down-appear,
  .zoom-down-enter {
    transform: scale(0);
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .zoom-down-appear-prepare,
  .zoom-down-enter-prepare {
    transform: none;
  }
  .zoom-down-leave {
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .zoom-left-appear,
  .zoom-left-enter,
  .zoom-left-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .zoom-left-appear.zoom-left-appear-active,
  .zoom-left-enter.zoom-left-enter-active {
    animation-name: antZoomLeftIn;
    animation-play-state: running;
  }
  .zoom-left-leave.zoom-left-leave-active {
    animation-name: antZoomLeftOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .zoom-left-appear,
  .zoom-left-enter {
    transform: scale(0);
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .zoom-left-appear-prepare,
  .zoom-left-enter-prepare {
    transform: none;
  }
  .zoom-left-leave {
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .zoom-right-appear,
  .zoom-right-enter,
  .zoom-right-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .zoom-right-appear.zoom-right-appear-active,
  .zoom-right-enter.zoom-right-enter-active {
    animation-name: antZoomRightIn;
    animation-play-state: running;
  }
  .zoom-right-leave.zoom-right-leave-active {
    animation-name: antZoomRightOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .zoom-right-appear,
  .zoom-right-enter {
    transform: scale(0);
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .zoom-right-appear-prepare,
  .zoom-right-enter-prepare {
    transform: none;
  }
  .zoom-right-leave {
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ant-motion-collapse-legacy {
    overflow: hidden;
  }
  .ant-motion-collapse,
  .ant-motion-collapse-legacy-active {
    transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  }
  .ant-motion-collapse {
    overflow: hidden;
  }
  .ant-affix {
    position: fixed;
    z-index: 10;
  }
  .ant-alert {
    box-sizing: border-box;
    margin: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    padding: 8px 15px 8px 37px;
    word-wrap: break-word;
    border-radius: 4px;
  }
  .ant-alert.ant-alert-no-icon {
    padding: 8px 15px;
  }
  .ant-alert.ant-alert-no-icon .ant-alert-close-icon {
    top: 12.0005px;
  }
  .ant-alert.ant-alert-closable {
    padding-right: 30px;
  }
  .ant-alert-icon {
    position: absolute;
    top: 12.0005px;
    left: 16px;
  }
  .ant-alert-description {
    display: none;
    font-size: 14px;
    line-height: 22px;
  }
  .ant-alert-success {
    background-color: #212e1d;
    border: 1px solid #30521f;
  }
  .ant-alert-success .ant-alert-icon {
    color: #4bac1b;
  }
  .ant-alert-info {
    background-color: #1e2b31;
    border: 1px solid #244a58;
  }
  .ant-alert-info .ant-alert-icon {
    color: #2caad9;
  }
  .ant-alert-warning {
    background-color: #362c1c;
    border: 1px solid #624b1d;
  }
  .ant-alert-warning .ant-alert-icon {
    color: #d99816;
  }
  .ant-alert-error {
    background-color: #351d20;
    border: 1px solid #612125;
  }
  .ant-alert-error .ant-alert-icon {
    color: #ab2229;
  }
  .ant-alert-error .ant-alert-description > pre {
    margin: 0;
    padding: 0;
  }
  .ant-alert-close-icon {
    position: absolute;
    top: 12.0005px;
    right: 16px;
    padding: 0;
    overflow: hidden;
    font-size: 12px;
    line-height: 12px;
    background-color: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
  }
  .ant-alert-close-icon .anticon-close,
  .ant-alert-close-text {
    color: rgba(255, 255, 255, 0.45);
    transition: color 0.3s;
  }
  .ant-alert-close-icon .anticon-close:hover,
  .ant-alert-close-text:hover {
    color: rgba(255, 255, 255, 0.75);
  }
  .ant-alert-with-description {
    position: relative;
    padding: 15px 15px 15px 63px;
    color: rgba(255, 255, 255, 0.85);
    line-height: 1.5715;
    border-radius: 4px;
  }
  .ant-alert-with-description.ant-alert-no-icon {
    padding: 15px;
  }
  .ant-alert-with-description .ant-alert-icon {
    position: absolute;
    top: 15px;
    left: 24px;
    font-size: 24px;
  }
  .ant-alert-with-description .ant-alert-close-icon {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 14px;
    cursor: pointer;
  }
  .ant-alert-with-description .ant-alert-message {
    display: block;
    margin-bottom: 4px;
    color: rgba(255, 255, 255, 0.85);
    font-size: 16px;
  }
  .ant-alert-message {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-alert-with-description .ant-alert-description,
  .ant-btn.ant-btn-loading::before,
  .ant-carousel .slick-slide img {
    display: block;
  }
  .ant-alert.ant-alert-motion-leave {
    overflow: hidden;
    opacity: 1;
    transition: max-height 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
      padding-top 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
      margin-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ant-alert.ant-alert-motion-leave-active {
    max-height: 0;
    margin-bottom: 0 !important;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
  .ant-alert-banner {
    margin-bottom: 0;
    border: 0;
    border-radius: 0;
  }
  .ant-alert.ant-alert-rtl {
    padding: 8px 37px 8px 15px;
    direction: rtl;
  }
  .ant-alert-rtl.ant-alert.ant-alert-no-icon {
    padding: 8px 15px;
  }
  .ant-alert.ant-alert-rtl.ant-alert.ant-alert-closable {
    padding-right: 37px;
    padding-left: 30px;
  }
  .ant-alert.ant-alert-rtl.ant-alert.ant-alert-no-icon.ant-alert-closable {
    padding-right: 15px;
    padding-left: 30px;
  }
  .ant-alert-rtl .ant-alert-icon {
    right: 16px;
    left: auto;
  }
  .ant-alert-rtl .ant-alert-close-icon {
    right: auto;
    left: 16px;
  }
  .ant-alert.ant-alert-rtl.ant-alert-with-description,
  .ant-alert.ant-alert-rtl.ant-alert-with-description.ant-alert-closable {
    padding: 15px 63px 15px 15px;
  }
  .ant-alert.ant-alert-rtl.ant-alert-with-description.ant-alert-no-icon {
    padding: 15px;
  }
  .ant-alert-rtl.ant-alert-with-description .ant-alert-icon {
    right: 24px;
    left: auto;
  }
  .ant-alert-rtl.ant-alert-with-description .ant-alert-close-icon {
    right: auto;
    left: 16px;
  }
  .ant-anchor {
    box-sizing: border-box;
    margin: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    padding: 0 0 0 2px;
  }
  .ant-anchor-wrapper {
    margin-left: -4px;
    padding-left: 4px;
    overflow: auto;
    background-color: #212121;
  }
  .ant-anchor-ink {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
  .ant-anchor-ink::before {
    position: relative;
    display: block;
    width: 2px;
    height: 100%;
    margin: 0 auto;
    background-color: #424242;
    content: " ";
  }
  .ant-anchor-ink-ball {
    position: absolute;
    left: 50%;
    display: none;
    width: 8px;
    height: 8px;
    background-color: #212121;
    border: 2px solid #2caad9;
    border-radius: 8px;
    transform: translateX(-50%);
    transition: top 0.3s ease-in-out;
  }
  .ant-anchor-ink-ball.visible {
    display: inline-block;
  }
  .ant-anchor.fixed .ant-anchor-ink .ant-anchor-ink-ball {
    display: none;
  }
  .ant-anchor-link {
    padding: 7px 0 7px 16px;
    line-height: 1.143;
  }
  .ant-anchor-link-title {
    position: relative;
    display: block;
    margin-bottom: 6px;
    overflow: hidden;
    color: rgba(255, 255, 255, 0.85);
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all 0.3s;
  }
  .ant-anchor-link-title:only-child {
    margin-bottom: 0;
  }
  .ant-anchor-link-active > .ant-anchor-link-title {
    color: #2caad9;
  }
  .ant-anchor-link .ant-anchor-link {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .ant-anchor-rtl {
    direction: rtl;
  }
  .ant-anchor-rtl.ant-anchor-wrapper {
    margin-right: -4px;
    margin-left: 0;
    padding-right: 4px;
    padding-left: 0;
  }
  .ant-anchor-rtl .ant-anchor-ink {
    right: 0;
    left: auto;
  }
  .ant-anchor-rtl .ant-anchor-ink-ball {
    right: 50%;
    left: 0;
    transform: translateX(50%);
  }
  .ant-anchor-rtl .ant-anchor-link {
    padding: 7px 16px 7px 0;
  }
  .ant-avatar,
  .ant-select-auto-complete {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
  }
  .ant-select-auto-complete .ant-select-clear {
    right: 13px;
  }
  .ant-avatar {
    position: relative;
    display: inline-block;
    overflow: hidden;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    background: rgba(255, 255, 255, 0.3);
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 50%;
  }
  .ant-avatar-image {
    background: 0 0;
  }
  .ant-avatar-string {
    position: absolute;
    left: 50%;
    transform-origin: 0 center;
  }
  .ant-avatar.ant-avatar-icon {
    font-size: 18px;
  }
  .ant-avatar-lg.ant-avatar-icon > .anticon,
  .ant-avatar-sm.ant-avatar-icon > .anticon,
  .ant-avatar.ant-avatar-icon > .anticon,
  .ant-card-loading-content p {
    margin: 0;
  }
  .ant-avatar-lg {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
  }
  .ant-avatar-lg-string {
    position: absolute;
    left: 50%;
    transform-origin: 0 center;
  }
  .ant-avatar-lg.ant-avatar-icon {
    font-size: 24px;
  }
  .ant-avatar-sm {
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 50%;
  }
  .ant-avatar-sm-string {
    position: absolute;
    left: 50%;
    transform-origin: 0 center;
  }
  .ant-avatar-sm.ant-avatar-icon {
    font-size: 14px;
  }
  .ant-avatar-square {
    border-radius: 4px;
  }
  .ant-avatar > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .ant-avatar-group {
    display: inline-flex;
  }
  .ant-avatar-group .ant-avatar {
    border: 1px solid #fff;
  }
  .ant-avatar-group .ant-avatar:not(:first-child) {
    margin-left: -8px;
  }
  .ant-avatar-group-popover .ant-avatar + .ant-avatar {
    margin-left: 3px;
  }
  .ant-avatar-group-rtl .ant-avatar:not(:first-child) {
    margin-right: -8px;
    margin-left: 0;
  }
  .ant-avatar-group-popover.ant-popover-rtl .ant-avatar + .ant-avatar {
    margin-right: 3px;
    margin-left: 0;
  }
  .ant-back-top {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: fixed;
    right: 100px;
    bottom: 50px;
    z-index: 10;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  .ant-back-top:empty {
    display: none;
  }
  .ant-back-top-rtl {
    right: auto;
    left: 100px;
    direction: rtl;
  }
  .ant-back-top-content {
    width: 40px;
    height: 40px;
    overflow: hidden;
    color: #fff;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.45);
    border-radius: 20px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-back-top-content:hover {
    background-color: rgba(255, 255, 255, 0.85);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-back-top-icon {
    font-size: 24px;
    line-height: 40px;
  }
  @media screen and (max-width: 768px) {
    .ant-back-top {
      right: 60px;
    }
  }
  @media screen and (max-width: 480px) {
    .ant-back-top {
      right: 20px;
    }
  }
  .ant-badge {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    color: unset;
    line-height: 1;
  }
  .ant-badge-count {
    z-index: auto;
    min-width: 20px;
    height: 20px;
    padding: 0 6px;
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    text-align: center;
    background: #ab2229;
    border-radius: 10px;
    box-shadow: 0 0 0 1px #212121;
  }
  .ant-badge-count a,
  .ant-badge-count a:hover {
    color: #fff;
  }
  .ant-badge-count-sm {
    min-width: 14px;
    height: 14px;
    padding: 0;
    font-size: 12px;
    line-height: 14px;
    border-radius: 7px;
  }
  .ant-badge-multiple-words {
    padding: 0 8px;
  }
  .ant-badge-dot {
    z-index: auto;
    width: 6px;
    height: 6px;
    background: #ab2229;
    border-radius: 100%;
    box-shadow: 0 0 0 1px #212121;
  }
  .ant-badge .ant-scroll-number-custom-component,
  .ant-badge-count,
  .ant-badge-dot {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    transform-origin: 100% 0;
  }
  .ant-badge-status {
    line-height: inherit;
    vertical-align: baseline;
  }
  .ant-badge-status-dot {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 6px;
    height: 6px;
    vertical-align: middle;
    border-radius: 50%;
  }
  .ant-badge-status-success {
    background-color: #4bac1b;
  }
  .ant-badge-status-processing {
    position: relative;
    background-color: #197fde;
  }
  .ant-badge-status-processing::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #197fde;
    border-radius: 50%;
    animation: antStatusProcessing 1.2s infinite ease-in-out;
    content: "";
  }
  .ant-badge-status-default {
    background-color: #d9d9d9;
  }
  .ant-badge-status-error {
    background-color: #ab2229;
  }
  .ant-badge-status-warning {
    background-color: #d99816;
  }
  .ant-badge-status-magenta,
  .ant-badge-status-pink {
    background: #cd2d84;
  }
  .ant-badge-status-red {
    background: #d5222b;
  }
  .ant-badge-status-volcano {
    background: #d94c1d;
  }
  .ant-badge-status-orange {
    background: #d97c18;
  }
  .ant-badge-status-yellow {
    background: #d9bf16;
  }
  .ant-badge-status-gold {
    background: #d99816;
  }
  .ant-badge-status-cyan {
    background: #15aaaa;
  }
  .ant-badge-status-lime {
    background: #8dbd13;
  }
  .ant-badge-status-green {
    background: #4bac1b;
  }
  .ant-badge-status-blue {
    background: #197fde;
  }
  .ant-badge-status-geekblue {
    background: #2d4ccd;
  }
  .ant-badge-status-purple {
    background: #662cb7;
  }
  .ant-badge-status-text {
    margin-left: 8px;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
  }
  .ant-badge-zoom-appear,
  .ant-badge-zoom-enter,
  .ant-badge-zoom-leave {
    animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    animation-fill-mode: both;
  }
  .ant-badge-zoom-leave {
    animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  }
  .ant-badge-not-a-wrapper:not(.ant-badge-status) {
    vertical-align: middle;
  }
  .ant-badge-not-a-wrapper .ant-scroll-number {
    position: relative;
    top: auto;
    display: block;
  }
  .ant-scroll-number {
    overflow: hidden;
  }
  .ant-scroll-number-only {
    display: inline-block;
    height: 20px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-scroll-number-only > p.ant-scroll-number-only-unit {
    height: 20px;
    margin: 0;
  }
  .ant-scroll-number-symbol {
    vertical-align: top;
  }
  .ant-ribbon-wrapper {
    position: relative;
  }
  .ant-ribbon {
    box-sizing: border-box;
    margin: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    position: absolute;
    top: 8px;
    height: 22px;
    padding: 0 8px;
    color: #fff;
    line-height: 22px;
    white-space: nowrap;
    background-color: #2caad9;
    border-radius: 4px;
  }
  .ant-ribbon-corner {
    position: absolute;
    top: 100%;
    width: 8px;
    height: 8px;
    color: #2caad9;
    border: 4px solid;
    transform: scaleY(0.75);
    transform-origin: top;
  }
  .ant-ribbon-corner::after {
    position: absolute;
    top: -4px;
    left: -4px;
    width: inherit;
    height: inherit;
    color: rgba(0, 0, 0, 0.25);
    border: inherit;
    content: "";
  }
  .ant-ribbon-color-magenta,
  .ant-ribbon-color-pink {
    background-color: #cd2d84;
  }
  .ant-ribbon-color-magenta .ant-ribbon-corner,
  .ant-ribbon-color-pink .ant-ribbon-corner {
    color: #cd2d84;
  }
  .ant-ribbon-color-red {
    background-color: #d5222b;
  }
  .ant-ribbon-color-red .ant-ribbon-corner {
    color: #d5222b;
  }
  .ant-ribbon-color-volcano {
    background-color: #d94c1d;
  }
  .ant-ribbon-color-volcano .ant-ribbon-corner {
    color: #d94c1d;
  }
  .ant-ribbon-color-orange {
    background-color: #d97c18;
  }
  .ant-ribbon-color-orange .ant-ribbon-corner {
    color: #d97c18;
  }
  .ant-ribbon-color-yellow {
    background-color: #d9bf16;
  }
  .ant-ribbon-color-yellow .ant-ribbon-corner {
    color: #d9bf16;
  }
  .ant-ribbon-color-gold {
    background-color: #d99816;
  }
  .ant-ribbon-color-gold .ant-ribbon-corner {
    color: #d99816;
  }
  .ant-ribbon-color-cyan {
    background-color: #15aaaa;
  }
  .ant-ribbon-color-cyan .ant-ribbon-corner {
    color: #15aaaa;
  }
  .ant-ribbon-color-lime {
    background-color: #8dbd13;
  }
  .ant-ribbon-color-lime .ant-ribbon-corner {
    color: #8dbd13;
  }
  .ant-ribbon-color-green {
    background-color: #4bac1b;
  }
  .ant-ribbon-color-green .ant-ribbon-corner {
    color: #4bac1b;
  }
  .ant-ribbon-color-blue {
    background-color: #197fde;
  }
  .ant-ribbon-color-blue .ant-ribbon-corner {
    color: #197fde;
  }
  .ant-ribbon-color-geekblue {
    background-color: #2d4ccd;
  }
  .ant-ribbon-color-geekblue .ant-ribbon-corner {
    color: #2d4ccd;
  }
  .ant-ribbon-color-purple {
    background-color: #662cb7;
  }
  .ant-ribbon-color-purple .ant-ribbon-corner {
    color: #662cb7;
  }
  .ant-ribbon.ant-ribbon-placement-end {
    right: -8px;
    border-bottom-right-radius: 0;
  }
  .ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner {
    right: 0;
  }
  .ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner,
  .ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner::after {
    border-color: currentColor transparent transparent currentColor;
  }
  .ant-ribbon.ant-ribbon-placement-start {
    left: -8px;
    border-bottom-left-radius: 0;
  }
  .ant-ribbon.ant-ribbon-placement-start .ant-ribbon-corner {
    left: 0;
  }
  .ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner::after,
  .ant-ribbon.ant-ribbon-placement-start .ant-ribbon-corner,
  .ant-ribbon.ant-ribbon-placement-start .ant-ribbon-corner::after {
    border-color: currentColor currentColor transparent transparent;
  }
  .ant-badge-rtl,
  .ant-ribbon-rtl {
    direction: rtl;
  }
  .ant-badge-rtl .ant-badge .ant-scroll-number-custom-component,
  .ant-badge-rtl .ant-badge-count,
  .ant-badge-rtl .ant-badge-dot {
    right: auto;
    left: 0;
    direction: ltr;
    transform: translate(-50%, -50%);
    transform-origin: 0 0;
  }
  .ant-badge-rtl.ant-badge .ant-scroll-number-custom-component {
    right: auto;
    left: 0;
    transform: translate(-50%, -50%);
    transform-origin: 0 0;
  }
  .ant-badge-rtl .ant-badge-status-text {
    margin-right: 8px;
    margin-left: 0;
  }
  .ant-badge-rtl .ant-badge-zoom-appear,
  .ant-badge-rtl .ant-badge-zoom-enter {
    animation-name: antZoomBadgeInRtl;
  }
  .ant-badge-rtl .ant-badge-zoom-leave {
    animation-name: antZoomBadgeOutRtl;
  }
  .ant-badge-not-a-wrapper .ant-badge-count {
    transform: none;
  }
  .ant-ribbon-rtl.ant-ribbon-placement-end {
    right: unset;
    left: -8px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner {
    right: unset;
    left: 0;
    border-color: currentColor currentColor transparent transparent;
  }
  .ant-ribbon-rtl.ant-ribbon-placement-start {
    right: -8px;
    left: unset;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 4px;
  }
  .ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner {
    right: 0;
    left: unset;
  }
  .ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner,
  .ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner::after {
    border-color: currentColor transparent transparent currentColor;
  }
  .ant-breadcrumb {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    color: rgba(255, 255, 255, 0.45);
    font-size: 14px;
  }
  .ant-breadcrumb .anticon {
    font-size: 14px;
  }
  .ant-breadcrumb a {
    color: rgba(255, 255, 255, 0.45);
    transition: color 0.3s;
  }
  .ant-breadcrumb a:hover {
    color: #287a99;
  }
  .ant-breadcrumb > span:last-child,
  .ant-breadcrumb > span:last-child a {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-breadcrumb > span:last-child .ant-breadcrumb-separator {
    display: none;
  }
  .ant-breadcrumb-separator {
    margin: 0 8px;
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-breadcrumb-link > .anticon + a,
  .ant-breadcrumb-link > .anticon + span,
  .ant-breadcrumb-overlay-link > .anticon {
    margin-left: 4px;
  }
  .ant-breadcrumb-rtl {
    direction: rtl;
  }
  .ant-breadcrumb-rtl::before {
    display: table;
    content: "";
  }
  .ant-breadcrumb-rtl::after {
    display: table;
    clear: both;
    content: "";
  }
  .ant-breadcrumb-rtl > span {
    float: right;
  }
  .ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + a,
  .ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + span,
  .ant-breadcrumb-rtl .ant-breadcrumb-overlay-link > .anticon {
    margin-right: 4px;
    margin-left: 0;
  }
  .ant-btn {
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: 32px;
    padding: 4px 15px;
    font-size: 14px;
    border-radius: 4px;
    color: rgba(255, 255, 255, 0.85);
    background: 0 0;
    border-color: #6f6c6c;
  }
  .ant-btn > .anticon {
    line-height: 1;
  }
  .ant-btn,
  .ant-btn:active,
  .ant-btn:focus {
    outline: 0;
  }
  .ant-btn:not([disabled]):hover {
    text-decoration: none;
  }
  .ant-btn:not([disabled]):active {
    outline: 0;
    box-shadow: none;
  }
  .ant-btn[disabled] {
    cursor: not-allowed;
  }
  .ant-btn[disabled] > * {
    pointer-events: none;
  }
  .ant-btn-lg,
  .ant-btn-sm {
    height: 40px;
    padding: 6.4px 15px;
    font-size: 16px;
    border-radius: 4px;
  }
  .ant-btn-sm {
    height: 24px;
    padding: 0 7px;
    font-size: 14px;
  }
  .ant-btn > a:only-child {
    color: currentColor;
  }
  .ant-btn > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn:focus,
  .ant-btn:hover {
    color: #287a99;
    border-color: #287a99;
  }
  .ant-btn:focus > a:only-child,
  .ant-btn:hover > a:only-child {
    color: currentColor;
  }
  .ant-btn:focus > a:only-child::after,
  .ant-btn:hover > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn:active {
    color: #4eb3d2;
    background: 0 0;
    border-color: #4eb3d2;
  }
  .ant-btn-primary > a:only-child,
  .ant-btn:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-primary > a:only-child::after,
  .ant-btn:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn[disabled],
  .ant-btn[disabled]:active,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:hover {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
    border-color: #6f6c6c;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-primary:focus > a:only-child,
  .ant-btn-primary:hover > a:only-child,
  .ant-btn[disabled]:active > a:only-child,
  .ant-btn[disabled]:focus > a:only-child,
  .ant-btn[disabled]:hover > a:only-child,
  .ant-btn[disabled] > a:only-child {
    color: currentColor;
  }
  .ant-btn-primary:focus > a:only-child::after,
  .ant-btn-primary:hover > a:only-child::after,
  .ant-btn[disabled]:active > a:only-child::after,
  .ant-btn[disabled]:focus > a:only-child::after,
  .ant-btn[disabled]:hover > a:only-child::after,
  .ant-btn[disabled] > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn:active,
  .ant-btn:focus,
  .ant-btn:hover {
    text-decoration: none;
    background: 0 0;
  }
  .ant-btn > span,
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
    display: inline-block;
  }
  .ant-btn-primary {
    color: #fff;
    background: #2caad9;
    border-color: #2caad9;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }
  .ant-btn-primary:focus,
  .ant-btn-primary:hover {
    color: #fff;
    background: #1b85b3;
    border-color: #1b85b3;
  }
  .ant-btn-primary:active {
    color: #fff;
    background: #53c3e6;
    border-color: #53c3e6;
  }
  .ant-btn-ghost > a:only-child,
  .ant-btn-primary:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-ghost > a:only-child::after,
  .ant-btn-primary:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:active,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:hover {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
    border-color: #6f6c6c;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-ghost:focus > a:only-child,
  .ant-btn-ghost:hover > a:only-child,
  .ant-btn-primary[disabled]:active > a:only-child,
  .ant-btn-primary[disabled]:focus > a:only-child,
  .ant-btn-primary[disabled]:hover > a:only-child,
  .ant-btn-primary[disabled] > a:only-child {
    color: currentColor;
  }
  .ant-btn-ghost:focus > a:only-child::after,
  .ant-btn-ghost:hover > a:only-child::after,
  .ant-btn-primary[disabled]:active > a:only-child::after,
  .ant-btn-primary[disabled]:focus > a:only-child::after,
  .ant-btn-primary[disabled]:hover > a:only-child::after,
  .ant-btn-primary[disabled] > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
    border-right-color: #287a99;
    border-left-color: #287a99;
  }
  .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
    border-color: #6f6c6c;
  }
  .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
    border-right-color: #287a99;
  }
  .ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
    border-right-color: #6f6c6c;
  }
  .ant-btn-group .ant-btn-primary + .ant-btn-primary,
  .ant-btn-group .ant-btn-primary:last-child:not(:first-child) {
    border-left-color: #287a99;
  }
  .ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled],
  .ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled] {
    border-left-color: #6f6c6c;
  }
  .ant-btn-ghost {
    color: rgba(255, 255, 255, 0.85);
    background: 0 0;
    border-color: #6f6c6c;
  }
  .ant-btn-ghost:focus,
  .ant-btn-ghost:hover {
    color: #287a99;
    background: 0 0;
    border-color: #287a99;
  }
  .ant-btn-ghost:active {
    color: #4eb3d2;
    background: 0 0;
    border-color: #4eb3d2;
  }
  .ant-btn-dashed > a:only-child,
  .ant-btn-ghost:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dashed > a:only-child::after,
  .ant-btn-ghost:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-ghost[disabled],
  .ant-btn-ghost[disabled]:active,
  .ant-btn-ghost[disabled]:focus,
  .ant-btn-ghost[disabled]:hover {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
    border-color: #6f6c6c;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-dashed:focus > a:only-child,
  .ant-btn-dashed:hover > a:only-child,
  .ant-btn-ghost[disabled]:active > a:only-child,
  .ant-btn-ghost[disabled]:focus > a:only-child,
  .ant-btn-ghost[disabled]:hover > a:only-child,
  .ant-btn-ghost[disabled] > a:only-child {
    color: currentColor;
  }
  .ant-btn-dashed:focus > a:only-child::after,
  .ant-btn-dashed:hover > a:only-child::after,
  .ant-btn-ghost[disabled]:active > a:only-child::after,
  .ant-btn-ghost[disabled]:focus > a:only-child::after,
  .ant-btn-ghost[disabled]:hover > a:only-child::after,
  .ant-btn-ghost[disabled] > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-dashed {
    color: rgba(255, 255, 255, 0.85);
    background: 0 0;
    border-color: #6f6c6c;
    border-style: dashed;
  }
  .ant-btn-dashed:focus,
  .ant-btn-dashed:hover {
    color: #287a99;
    background: 0 0;
    border-color: #287a99;
  }
  .ant-btn-dashed:active {
    color: #4eb3d2;
    background: 0 0;
    border-color: #4eb3d2;
  }
  .ant-btn-danger > a:only-child,
  .ant-btn-dashed:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-danger > a:only-child::after,
  .ant-btn-dashed:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-dashed[disabled],
  .ant-btn-dashed[disabled]:active,
  .ant-btn-dashed[disabled]:focus,
  .ant-btn-dashed[disabled]:hover {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
    border-color: #6f6c6c;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-danger:focus > a:only-child,
  .ant-btn-danger:hover > a:only-child,
  .ant-btn-dashed[disabled]:active > a:only-child,
  .ant-btn-dashed[disabled]:focus > a:only-child,
  .ant-btn-dashed[disabled]:hover > a:only-child,
  .ant-btn-dashed[disabled] > a:only-child {
    color: currentColor;
  }
  .ant-btn-danger:focus > a:only-child::after,
  .ant-btn-danger:hover > a:only-child::after,
  .ant-btn-dashed[disabled]:active > a:only-child::after,
  .ant-btn-dashed[disabled]:focus > a:only-child::after,
  .ant-btn-dashed[disabled]:hover > a:only-child::after,
  .ant-btn-dashed[disabled] > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-danger {
    color: #fff;
    background: #ab2229;
    border-color: #ab2229;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }
  .ant-btn-danger:focus,
  .ant-btn-danger:hover {
    color: #fff;
    background: #85141d;
    border-color: #85141d;
  }
  .ant-btn-danger:active {
    color: #fff;
    background: #b84244;
    border-color: #b84244;
  }
  .ant-btn-danger:active > a:only-child,
  .ant-btn-link > a:only-child {
    color: currentColor;
  }
  .ant-btn-danger:active > a:only-child::after,
  .ant-btn-link > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-danger[disabled],
  .ant-btn-danger[disabled]:active,
  .ant-btn-danger[disabled]:focus,
  .ant-btn-danger[disabled]:hover {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
    border-color: #6f6c6c;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-danger[disabled]:active > a:only-child,
  .ant-btn-danger[disabled]:focus > a:only-child,
  .ant-btn-danger[disabled]:hover > a:only-child,
  .ant-btn-danger[disabled] > a:only-child,
  .ant-btn-link:focus > a:only-child,
  .ant-btn-link:hover > a:only-child {
    color: currentColor;
  }
  .ant-btn-danger[disabled]:active > a:only-child::after,
  .ant-btn-danger[disabled]:focus > a:only-child::after,
  .ant-btn-danger[disabled]:hover > a:only-child::after,
  .ant-btn-danger[disabled] > a:only-child::after,
  .ant-btn-link:focus > a:only-child::after,
  .ant-btn-link:hover > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-link {
    color: #2caad9;
    background: 0 0;
    border-color: transparent;
    box-shadow: none;
  }
  .ant-btn-link:focus,
  .ant-btn-link:hover {
    color: #287a99;
    background: 0 0;
  }
  .ant-btn-link:active {
    color: #4eb3d2;
    background: 0 0;
    border-color: #4eb3d2;
  }
  .ant-btn-link:active > a:only-child,
  .ant-btn-text > a:only-child {
    color: currentColor;
  }
  .ant-btn-link:active > a:only-child::after,
  .ant-btn-text > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-link[disabled],
  .ant-btn-link[disabled]:active,
  .ant-btn-link[disabled]:focus,
  .ant-btn-link[disabled]:hover {
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-btn-link[disabled]:active > a:only-child,
  .ant-btn-link[disabled]:focus > a:only-child,
  .ant-btn-link[disabled]:hover > a:only-child,
  .ant-btn-link[disabled] > a:only-child {
    color: currentColor;
  }
  .ant-btn-link[disabled]:active > a:only-child::after,
  .ant-btn-link[disabled]:focus > a:only-child::after,
  .ant-btn-link[disabled]:hover > a:only-child::after,
  .ant-btn-link[disabled] > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-link:hover {
    background: 0 0;
  }
  .ant-btn-link:active,
  .ant-btn-link:focus,
  .ant-btn-link:hover {
    border-color: transparent;
  }
  .ant-btn-link[disabled],
  .ant-btn-link[disabled]:active,
  .ant-btn-link[disabled]:focus,
  .ant-btn-link[disabled]:hover {
    color: rgba(255, 255, 255, 0.3);
    background: 0 0;
    border-color: transparent;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-text {
    color: rgba(255, 255, 255, 0.85);
    background: 0 0;
    border-color: transparent;
    box-shadow: none;
  }
  .ant-btn-text:focus,
  .ant-btn-text:hover {
    color: #287a99;
    background: 0 0;
  }
  .ant-btn-text:focus > a:only-child,
  .ant-btn-text:hover > a:only-child {
    color: currentColor;
  }
  .ant-btn-text:focus > a:only-child::after,
  .ant-btn-text:hover > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-text:active {
    color: #4eb3d2;
    background: 0 0;
  }
  .ant-btn-dangerous > a:only-child,
  .ant-btn-text:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous > a:only-child::after,
  .ant-btn-text:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-text[disabled],
  .ant-btn-text[disabled]:active,
  .ant-btn-text[disabled]:focus,
  .ant-btn-text[disabled]:hover {
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-btn-text[disabled]:active > a:only-child,
  .ant-btn-text[disabled]:focus > a:only-child,
  .ant-btn-text[disabled]:hover > a:only-child,
  .ant-btn-text[disabled] > a:only-child {
    color: currentColor;
  }
  .ant-btn-text[disabled]:active > a:only-child::after,
  .ant-btn-text[disabled]:focus > a:only-child::after,
  .ant-btn-text[disabled]:hover > a:only-child::after,
  .ant-btn-text[disabled] > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-text:focus,
  .ant-btn-text:hover {
    color: rgba(255, 255, 255, 0.85);
    background: rgba(255, 255, 255, 0.03);
    border-color: transparent;
  }
  .ant-btn-text:active {
    color: rgba(255, 255, 255, 0.85);
    background: rgba(255, 255, 255, 0.04);
    border-color: transparent;
  }
  .ant-btn-text[disabled],
  .ant-btn-text[disabled]:active,
  .ant-btn-text[disabled]:focus,
  .ant-btn-text[disabled]:hover {
    color: rgba(255, 255, 255, 0.3);
    background: 0 0;
    border-color: transparent;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-dangerous {
    color: #ab2229;
    background: 0 0;
    border-color: #ab2229;
  }
  .ant-btn-dangerous:focus,
  .ant-btn-dangerous:hover {
    color: #85141d;
    background: 0 0;
    border-color: #85141d;
  }
  .ant-btn-dangerous:focus > a:only-child,
  .ant-btn-dangerous:hover > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous:focus > a:only-child::after,
  .ant-btn-dangerous:hover > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-dangerous:active {
    color: #b84244;
    background: 0 0;
    border-color: #b84244;
  }
  .ant-btn-dangerous:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-dangerous[disabled],
  .ant-btn-dangerous[disabled]:active,
  .ant-btn-dangerous[disabled]:focus,
  .ant-btn-dangerous[disabled]:hover {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
    border-color: #6f6c6c;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-dangerous.ant-btn-primary:focus > a:only-child,
  .ant-btn-dangerous.ant-btn-primary:hover > a:only-child,
  .ant-btn-dangerous[disabled]:active > a:only-child,
  .ant-btn-dangerous[disabled]:focus > a:only-child,
  .ant-btn-dangerous[disabled]:hover > a:only-child,
  .ant-btn-dangerous[disabled] > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-primary:focus > a:only-child::after,
  .ant-btn-dangerous.ant-btn-primary:hover > a:only-child::after,
  .ant-btn-dangerous[disabled]:active > a:only-child::after,
  .ant-btn-dangerous[disabled]:focus > a:only-child::after,
  .ant-btn-dangerous[disabled]:hover > a:only-child::after,
  .ant-btn-dangerous[disabled] > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-dangerous.ant-btn-primary {
    color: #fff;
    background: #ab2229;
    border-color: #ab2229;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }
  .ant-btn-dangerous.ant-btn-link > a:only-child,
  .ant-btn-dangerous.ant-btn-primary:active > a:only-child,
  .ant-btn-dangerous.ant-btn-primary > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-link > a:only-child::after,
  .ant-btn-dangerous.ant-btn-primary:active > a:only-child::after,
  .ant-btn-dangerous.ant-btn-primary > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-dangerous.ant-btn-primary:focus,
  .ant-btn-dangerous.ant-btn-primary:hover {
    color: #fff;
    background: #85141d;
    border-color: #85141d;
  }
  .ant-btn-dangerous.ant-btn-primary:active {
    color: #fff;
    background: #b84244;
    border-color: #b84244;
  }
  .ant-btn-dangerous.ant-btn-primary[disabled],
  .ant-btn-dangerous.ant-btn-primary[disabled]:active,
  .ant-btn-dangerous.ant-btn-primary[disabled]:focus,
  .ant-btn-dangerous.ant-btn-primary[disabled]:hover {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
    border-color: #6f6c6c;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child,
  .ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child,
  .ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child::after,
  .ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child::after,
  .ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child::after,
  .ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-dangerous.ant-btn-link,
  .ant-btn-dangerous.ant-btn-text {
    color: #ab2229;
    background: 0 0;
    border-color: transparent;
    box-shadow: none;
  }
  .ant-btn-dangerous.ant-btn-link[disabled],
  .ant-btn-dangerous.ant-btn-link[disabled]:active,
  .ant-btn-dangerous.ant-btn-link[disabled]:focus,
  .ant-btn-dangerous.ant-btn-link[disabled]:hover {
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-btn-dangerous.ant-btn-link:focus > a:only-child,
  .ant-btn-dangerous.ant-btn-link:hover > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-link:focus > a:only-child::after,
  .ant-btn-dangerous.ant-btn-link:hover > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-dangerous.ant-btn-link:focus,
  .ant-btn-dangerous.ant-btn-link:hover {
    color: #85141d;
    background: 0 0;
    border-color: transparent;
  }
  .ant-btn-dangerous.ant-btn-link:active {
    color: #b84244;
    background: 0 0;
    border-color: transparent;
  }
  .ant-btn-dangerous.ant-btn-link:active > a:only-child,
  .ant-btn-dangerous.ant-btn-text > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-link:active > a:only-child::after,
  .ant-btn-dangerous.ant-btn-text > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-dangerous.ant-btn-link[disabled],
  .ant-btn-dangerous.ant-btn-link[disabled]:active,
  .ant-btn-dangerous.ant-btn-link[disabled]:focus,
  .ant-btn-dangerous.ant-btn-link[disabled]:hover {
    color: rgba(255, 255, 255, 0.3);
    background: 0 0;
    border-color: transparent;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child,
  .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
  .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after,
  .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
  .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
  .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-dangerous.ant-btn-text:focus,
  .ant-btn-dangerous.ant-btn-text:hover {
    background: 0 0;
  }
  .ant-btn-dangerous.ant-btn-text:active {
    background: 0 0;
  }
  .ant-btn-dangerous.ant-btn-text[disabled],
  .ant-btn-dangerous.ant-btn-text[disabled]:active,
  .ant-btn-dangerous.ant-btn-text[disabled]:focus,
  .ant-btn-dangerous.ant-btn-text[disabled]:hover {
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-btn-dangerous.ant-btn-text:focus > a:only-child,
  .ant-btn-dangerous.ant-btn-text:hover > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-text:focus > a:only-child::after,
  .ant-btn-dangerous.ant-btn-text:hover > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-dangerous.ant-btn-text:focus,
  .ant-btn-dangerous.ant-btn-text:hover {
    color: #85141d;
    background: rgba(255, 255, 255, 0.03);
    border-color: transparent;
  }
  .ant-btn-dangerous.ant-btn-text:active {
    color: #b84244;
    background: rgba(255, 255, 255, 0.04);
    border-color: transparent;
  }
  .ant-btn-background-ghost.ant-btn-primary > a:only-child,
  .ant-btn-dangerous.ant-btn-text:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-primary > a:only-child::after,
  .ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-dangerous.ant-btn-text[disabled],
  .ant-btn-dangerous.ant-btn-text[disabled]:active,
  .ant-btn-dangerous.ant-btn-text[disabled]:focus,
  .ant-btn-dangerous.ant-btn-text[disabled]:hover {
    color: rgba(255, 255, 255, 0.3);
    background: 0 0;
    border-color: transparent;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child,
  .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
  .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
  .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after,
  .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
  .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
  .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-icon-only {
    width: 32px;
    height: 32px;
    padding: 2.4px 0;
    font-size: 16px;
    border-radius: 4px;
    vertical-align: -1px;
  }
  .ant-btn-icon-only > * {
    font-size: 16px;
  }
  .ant-btn-icon-only.ant-btn-lg {
    width: 40px;
    height: 40px;
    padding: 4.9px 0;
    font-size: 18px;
    border-radius: 4px;
  }
  .ant-btn-icon-only.ant-btn-lg > * {
    font-size: 18px;
  }
  .ant-btn-icon-only.ant-btn-sm {
    width: 24px;
    height: 24px;
    padding: 0;
    font-size: 14px;
    border-radius: 4px;
  }
  .ant-btn-icon-only.ant-btn-sm > * {
    font-size: 14px;
  }
  .ant-btn-round {
    height: 32px;
    padding: 4px 16px;
    font-size: 14px;
    border-radius: 32px;
  }
  .ant-btn-round.ant-btn-lg {
    height: 40px;
    padding: 6.4px 20px;
    font-size: 16px;
    border-radius: 40px;
  }
  .ant-btn-round.ant-btn-sm {
    height: 24px;
    padding: 0 12px;
    font-size: 14px;
    border-radius: 24px;
  }
  .ant-btn-round.ant-btn-icon-only {
    width: auto;
  }
  .ant-btn-circle,
  .ant-btn-circle-outline {
    min-width: 32px;
    padding-right: 0;
    padding-left: 0;
    text-align: center;
    border-radius: 50%;
  }
  .ant-btn-circle-outline.ant-btn-lg,
  .ant-btn-circle.ant-btn-lg {
    min-width: 40px;
    border-radius: 50%;
  }
  .ant-btn-circle-outline.ant-btn-sm,
  .ant-btn-circle.ant-btn-sm {
    min-width: 24px;
    border-radius: 50%;
  }
  .ant-btn::before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 1;
    display: none;
    background: #212121;
    border-radius: inherit;
    opacity: 0.35;
    transition: opacity 0.2s;
    content: "";
    pointer-events: none;
  }
  .ant-btn .anticon {
    transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-btn .anticon.anticon-minus > svg,
  .ant-btn .anticon.anticon-plus > svg {
    shape-rendering: optimizeSpeed;
  }
  .ant-btn.ant-btn-loading {
    position: relative;
  }
  .ant-btn.ant-btn-loading:not([disabled]) {
    pointer-events: none;
  }
  .ant-btn > .ant-btn-loading-icon {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-btn > .ant-btn-loading-icon .anticon {
    padding-right: 8px;
  }
  .ant-btn-group {
    display: inline-flex;
  }
  .ant-btn-group,
  .ant-btn-group > .ant-btn,
  .ant-btn-group > span > .ant-btn {
    position: relative;
  }
  .ant-btn-group > .ant-btn:active,
  .ant-btn-group > .ant-btn:focus,
  .ant-btn-group > .ant-btn:hover,
  .ant-btn-group > span > .ant-btn:active,
  .ant-btn-group > span > .ant-btn:focus,
  .ant-btn-group > span > .ant-btn:hover {
    z-index: 2;
  }
  .ant-btn-group > .ant-btn[disabled],
  .ant-btn-group > span > .ant-btn[disabled] {
    z-index: 0;
  }
  .ant-btn-group .ant-btn-icon-only {
    font-size: 14px;
  }
  .ant-btn-group-lg > .ant-btn,
  .ant-btn-group-lg > span > .ant-btn {
    height: 40px;
    padding: 6.4px 15px;
    font-size: 16px;
    border-radius: 0;
  }
  .ant-btn-group-lg .ant-btn.ant-btn-icon-only {
    width: 40px;
    height: 40px;
    padding-right: 0;
    padding-left: 0;
  }
  .ant-btn-group-sm > .ant-btn,
  .ant-btn-group-sm > span > .ant-btn {
    height: 24px;
    padding: 0 7px;
    font-size: 14px;
    border-radius: 0;
  }
  .ant-btn-group-sm > .ant-btn > .anticon,
  .ant-btn-group-sm > span > .ant-btn > .anticon,
  .ant-form label {
    font-size: 14px;
  }
  .ant-btn-group-sm .ant-btn.ant-btn-icon-only {
    width: 24px;
    height: 24px;
    padding-right: 0;
    padding-left: 0;
  }
  .ant-btn + .ant-btn-group,
  .ant-btn-group .ant-btn + .ant-btn,
  .ant-btn-group .ant-btn + span,
  .ant-btn-group span + .ant-btn,
  .ant-btn-group + .ant-btn,
  .ant-btn-group + .ant-btn-group,
  .ant-btn-group > span + span {
    margin-left: -1px;
  }
  .ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
    border-left-color: transparent;
  }
  .ant-btn-group .ant-btn {
    border-radius: 0;
  }
  .ant-btn-group > .ant-btn:first-child,
  .ant-btn-group > span:first-child > .ant-btn {
    margin-left: 0;
  }
  .ant-btn-group-sm > .ant-btn:only-child,
  .ant-btn-group-sm > span:only-child > .ant-btn,
  .ant-btn-group > .ant-btn:only-child,
  .ant-btn-group > span:only-child > .ant-btn {
    border-radius: 4px;
  }
  .ant-btn-group > .ant-btn:first-child:not(:last-child),
  .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .ant-btn-group > .ant-btn:last-child:not(:first-child),
  .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
  .ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
  .ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .ant-btn-group > .ant-btn-group {
    float: left;
  }
  .ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
    border-radius: 0;
  }
  .ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
    padding-right: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
    padding-left: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ant-btn-group-rtl.ant-btn + .ant-btn-group,
  .ant-btn-group-rtl.ant-btn-group .ant-btn + .ant-btn,
  .ant-btn-group-rtl.ant-btn-group .ant-btn + span,
  .ant-btn-group-rtl.ant-btn-group span + .ant-btn,
  .ant-btn-group-rtl.ant-btn-group + .ant-btn,
  .ant-btn-group-rtl.ant-btn-group + .ant-btn-group,
  .ant-btn-group-rtl.ant-btn-group > span + span,
  .ant-btn-rtl.ant-btn + .ant-btn-group,
  .ant-btn-rtl.ant-btn-group .ant-btn + .ant-btn,
  .ant-btn-rtl.ant-btn-group .ant-btn + span,
  .ant-btn-rtl.ant-btn-group span + .ant-btn,
  .ant-btn-rtl.ant-btn-group + .ant-btn,
  .ant-btn-rtl.ant-btn-group + .ant-btn-group,
  .ant-btn-rtl.ant-btn-group > span + span {
    margin-right: -1px;
    margin-left: auto;
  }
  .ant-btn-group.ant-btn-group-rtl {
    direction: rtl;
  }
  .ant-btn-group-rtl.ant-btn-group > .ant-btn:first-child:not(:last-child),
  .ant-btn-group-rtl.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .ant-btn-group-rtl.ant-btn-group > .ant-btn:last-child:not(:first-child),
  .ant-btn-group-rtl.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
    border-top-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 4px;
  }
  .ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
  .ant-btn-group-rtl.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
  .ant-btn-group-rtl.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
    border-top-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 4px;
  }
  .ant-btn:active > span,
  .ant-btn:focus > span {
    position: relative;
  }
  .ant-btn > .anticon + span,
  .ant-btn > span + .anticon {
    margin-left: 8px;
  }
  .ant-btn-background-ghost {
    color: rgba(255, 255, 255, 0.85);
    background: 0 0 !important;
    border-color: rgba(255, 255, 255, 0.25);
  }
  .ant-btn-background-ghost.ant-btn-primary {
    color: #2caad9;
    background: 0 0;
    border-color: #2caad9;
    text-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-primary:focus,
  .ant-btn-background-ghost.ant-btn-primary:hover {
    color: #1b85b3;
    background: 0 0;
    border-color: #1b85b3;
  }
  .ant-btn-background-ghost.ant-btn-primary:active {
    color: #53c3e6;
    background: 0 0;
    border-color: #53c3e6;
  }
  .ant-btn-background-ghost.ant-btn-danger > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-danger > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-background-ghost.ant-btn-primary[disabled],
  .ant-btn-background-ghost.ant-btn-primary[disabled]:active,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:hover {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
    border-color: #6f6c6c;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-background-ghost.ant-btn-danger,
  .ant-btn-background-ghost.ant-btn-dangerous {
    color: #ab2229;
    background: 0 0;
    border-color: #ab2229;
    text-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-danger:focus,
  .ant-btn-background-ghost.ant-btn-danger:hover {
    color: #85141d;
    background: 0 0;
    border-color: #85141d;
  }
  .ant-btn-background-ghost.ant-btn-danger:active {
    color: #b84244;
    background: 0 0;
    border-color: #b84244;
  }
  .ant-btn-background-ghost.ant-btn-danger:active > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-dangerous > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-background-ghost.ant-btn-danger[disabled],
  .ant-btn-background-ghost.ant-btn-danger[disabled]:active,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:hover {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
    border-color: #6f6c6c;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-background-ghost.ant-btn-dangerous:focus,
  .ant-btn-background-ghost.ant-btn-dangerous:hover {
    color: #85141d;
    background: 0 0;
    border-color: #85141d;
  }
  .ant-btn-background-ghost.ant-btn-dangerous:active {
    color: #b84244;
    background: 0 0;
    border-color: #b84244;
  }
  .ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-background-ghost.ant-btn-dangerous[disabled],
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
    border-color: #6f6c6c;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
    color: #ab2229;
    background: 0 0;
    border-color: transparent;
    text-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover {
    color: #85141d;
    background: 0 0;
    border-color: transparent;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
    color: #b84244;
    background: 0 0;
    border-color: transparent;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
    border-color: #6f6c6c;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
  .ant-btn-two-chinese-chars::first-letter {
    letter-spacing: 0.34em;
  }
  .ant-btn-two-chinese-chars > :not(.anticon) {
    margin-right: -0.34em;
    letter-spacing: 0.34em;
  }
  .ant-btn-block {
    width: 100%;
  }
  .ant-btn:empty {
    display: inline-block;
    width: 0;
    visibility: hidden;
    content: "\a0";
  }
  a.ant-btn {
    padding-top: 0.1px;
    line-height: 30px;
  }
  a.ant-btn-lg {
    line-height: 38px;
  }
  a.ant-btn-sm {
    line-height: 22px;
  }
  .ant-btn-rtl {
    direction: rtl;
  }
  .ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary,
  .ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child) {
    border-right-color: #287a99;
    border-left-color: #6f6c6c;
  }
  .ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled],
  .ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled] {
    border-right-color: #6f6c6c;
    border-left-color: #287a99;
  }
  .ant-btn-rtl.ant-btn > .ant-btn-loading-icon .anticon,
  .ant-comment-rtl .ant-comment-content-author > a,
  .ant-comment-rtl .ant-comment-content-author > span {
    padding-right: 0;
    padding-left: 8px;
  }
  .ant-btn > .ant-btn-loading-icon:only-child .anticon {
    padding-right: 0;
    padding-left: 0;
  }
  .ant-btn-rtl.ant-btn > .anticon + span,
  .ant-btn-rtl.ant-btn > span + .anticon {
    margin-right: 8px;
    margin-left: 0;
  }
  .ant-picker-calendar {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    background: #212121;
  }
  .ant-picker-calendar-header {
    display: flex;
    justify-content: flex-end;
    padding: 12px 0;
  }
  .ant-picker-calendar-header .ant-picker-calendar-year-select {
    min-width: 80px;
  }
  .ant-picker-calendar-header .ant-picker-calendar-month-select {
    min-width: 70px;
    margin-left: 8px;
  }
  .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    margin-left: 8px;
  }
  .ant-picker-calendar .ant-picker-panel {
    background: #212121;
    border: 0;
    border-top: 1px solid #424242;
    border-radius: 0;
  }
  .ant-picker-calendar .ant-picker-panel .ant-picker-date-panel,
  .ant-picker-calendar .ant-picker-panel .ant-picker-month-panel {
    width: auto;
  }
  .ant-picker-calendar .ant-picker-panel .ant-picker-body {
    padding: 8px 0;
  }
  .ant-picker-calendar .ant-picker-panel .ant-picker-content {
    width: 100%;
  }
  .ant-picker-calendar-mini {
    border-radius: 4px;
  }
  .ant-picker-calendar-mini .ant-picker-calendar-header {
    padding-right: 8px;
    padding-left: 8px;
  }
  .ant-picker-calendar-mini .ant-picker-panel {
    border-radius: 0 0 4px 4px;
  }
  .ant-picker-calendar-mini .ant-picker-content {
    height: 256px;
  }
  .ant-picker-calendar-mini .ant-picker-content th {
    height: auto;
    padding: 0;
    line-height: 18px;
  }
  .ant-picker-calendar-full .ant-picker-panel {
    display: block;
    width: 100%;
    text-align: right;
    background: #212121;
    border: 0;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-body td {
    padding: 0;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
    height: auto;
    padding: 0 12px 5px 0;
    line-height: 18px;
  }
  .ant-carousel .slick-slide.slick-loading img,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell .ant-picker-calendar-date-today::before,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell::before {
    display: none;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:hover .ant-picker-calendar-date {
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
    background: #1e2b31;
  }
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected
    .ant-picker-calendar-date
    .ant-picker-calendar-date-value,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected
    .ant-picker-calendar-date-today
    .ant-picker-calendar-date-value,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected:hover
    .ant-picker-calendar-date
    .ant-picker-calendar-date-value,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected:hover
    .ant-picker-calendar-date-today
    .ant-picker-calendar-date-value {
    color: #2caad9;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    display: block;
    width: auto;
    height: auto;
    margin: 0 4px;
    padding: 4px 8px 0;
    border: 0;
    border-top: 2px solid #424242;
    border-radius: 0;
    transition: background 0.3s;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
    line-height: 24px;
    transition: color 0.3s;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
    position: static;
    width: auto;
    height: 86px;
    overflow-y: auto;
    color: rgba(255, 255, 255, 0.85);
    line-height: 1.5715;
    text-align: left;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
    border-color: #2caad9;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
  .ant-picker-header > button:hover {
    color: rgba(255, 255, 255, 0.85);
  }
  @media only screen and (max-width: 480px) {
    .ant-picker-calendar-header {
      display: block;
    }
    .ant-picker-calendar-header .ant-picker-calendar-year-select {
      width: 50%;
    }
    .ant-picker-calendar-header .ant-picker-calendar-month-select {
      width: calc(50% - 8px);
    }
    .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
      width: 100%;
      margin-top: 8px;
      margin-left: 0;
    }
    .ant-picker-calendar-header .ant-picker-calendar-mode-switch > label {
      width: 50%;
      text-align: center;
    }
  }
  .ant-picker-calendar-rtl {
    direction: rtl;
  }
  .ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-mode-switch,
  .ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-month-select {
    margin-right: 8px;
    margin-left: 0;
  }
  .ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel {
    text-align: left;
  }
  .ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
    padding: 0 0 5px 12px;
  }
  .ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
    text-align: right;
  }
  .ant-card {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    background: #212121;
    border-radius: 4px;
  }
  .ant-card-rtl {
    direction: rtl;
  }
  .ant-card-hoverable {
    cursor: pointer;
    transition: box-shadow 0.3s, border-color 0.3s;
  }
  .ant-card-hoverable:hover {
    border-color: transparent;
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.64), 0 3px 6px 0 rgba(0, 0, 0, 0.48), 0 5px 12px 4px rgba(0, 0, 0, 0.36);
  }
  .ant-card-bordered {
    border: 1px solid #424242;
  }
  .ant-card-head {
    min-height: 48px;
    margin-bottom: -1px;
    padding: 0 24px;
    color: rgba(255, 255, 255, 0.85);
    font-weight: 500;
    font-size: 16px;
    background: 0 0;
    border-bottom: 1px solid #424242;
    border-radius: 4px 4px 0 0;
  }
  .ant-card-head::before {
    display: table;
    content: "";
  }
  .ant-card-head::after {
    clear: both;
  }
  .ant-card-head-wrapper {
    display: flex;
    align-items: center;
  }
  .ant-card-head-title {
    display: inline-block;
    flex: 1;
    padding: 16px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ant-card-extra,
  .ant-card-head .ant-tabs {
    color: rgba(255, 255, 255, 0.85);
    font-weight: 400;
    font-size: 14px;
  }
  .ant-card-head .ant-tabs {
    clear: both;
    margin-bottom: -17px;
  }
  .ant-card-head .ant-tabs-bar {
    border-bottom: 1px solid #424242;
  }
  .ant-card-extra {
    float: right;
    margin-left: auto;
    padding: 16px 0;
  }
  .ant-card-rtl .ant-card-extra {
    margin-right: auto;
    margin-left: 0;
  }
  .ant-card-body {
    padding: 24px;
  }
  .ant-card-body::after,
  .ant-card-body::before,
  .ant-card-head::after {
    display: table;
    content: "";
  }
  .ant-card-body::after {
    clear: both;
  }
  .ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
    margin: -1px 0 0 -1px;
    padding: 0;
  }
  .ant-card-grid {
    float: left;
    width: 33.33%;
    padding: 24px;
    border: 0;
    border-radius: 0;
    box-shadow: 1px 0 0 0 #424242, 0 1px 0 0 #424242, 1px 1px 0 0 #424242, 1px 0 0 0 #424242 inset,
      0 1px 0 0 #424242 inset;
    transition: all 0.3s;
  }
  .ant-card-rtl .ant-card-actions > li,
  .ant-card-rtl .ant-card-grid {
    float: right;
  }
  .ant-card-grid-hoverable:hover {
    position: relative;
    z-index: 1;
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.64), 0 3px 6px 0 rgba(0, 0, 0, 0.48), 0 5px 12px 4px rgba(0, 0, 0, 0.36);
  }
  .ant-card-contain-tabs > .ant-card-head .ant-card-head-title {
    min-height: 32px;
    padding-bottom: 0;
  }
  .ant-card-contain-tabs > .ant-card-head .ant-card-extra {
    padding-bottom: 0;
  }
  .ant-card-bordered .ant-card-cover {
    margin-right: -1px;
    margin-left: -1px;
    transform: translateY(-1px);
  }
  .ant-card-cover > * {
    display: block;
    width: 100%;
  }
  .ant-card-cover img {
    border-radius: 4px 4px 0 0;
  }
  .ant-card-actions {
    margin: 0;
    padding: 0;
    list-style: none;
    background: #212121;
    border-top: 1px solid #424242;
  }
  .ant-card-actions::before {
    display: table;
    content: "";
  }
  .ant-card-actions::after {
    display: table;
    clear: both;
    content: "";
  }
  .ant-card-actions > li {
    float: left;
    margin: 12px 0;
    color: rgba(255, 255, 255, 0.45);
    text-align: center;
  }
  .ant-card-actions > li > span {
    position: relative;
    display: block;
    min-width: 32px;
    font-size: 14px;
    line-height: 1.5715;
    cursor: pointer;
  }
  .ant-card-actions > li > span:hover {
    color: #2caad9;
    transition: color 0.3s;
  }
  .ant-card-actions > li > span a:not(.ant-btn),
  .ant-card-actions > li > span > .anticon {
    display: inline-block;
    width: 100%;
    color: rgba(255, 255, 255, 0.45);
    line-height: 22px;
    transition: color 0.3s;
  }
  .ant-card-actions > li > span a:not(.ant-btn):hover,
  .ant-card-actions > li > span > .anticon:hover {
    color: #2caad9;
  }
  .ant-card-actions > li > span > .anticon {
    font-size: 16px;
    line-height: 22px;
  }
  .ant-card-actions > li:not(:last-child) {
    border-right: 1px solid #424242;
  }
  .ant-card-type-inner .ant-card-head {
    padding: 0 24px;
    background: rgba(255, 255, 255, 0.04);
  }
  .ant-card-type-inner .ant-card-head-title {
    padding: 12px 0;
    font-size: 14px;
  }
  .ant-card-type-inner .ant-card-body {
    padding: 16px 24px;
  }
  .ant-card-type-inner .ant-card-extra {
    padding: 13.5px 0;
  }
  .ant-card-meta {
    margin: -4px 0;
  }
  .ant-card-meta::after,
  .ant-card-meta::before,
  .ant-carousel .slick-track::after,
  .ant-carousel .slick-track::before {
    display: table;
    content: "";
  }
  .ant-card-meta::after {
    clear: both;
  }
  .ant-card-meta-avatar {
    float: left;
    padding-right: 16px;
  }
  .ant-card-rtl .ant-card-meta-avatar {
    float: right;
    padding-right: 0;
    padding-left: 16px;
  }
  .ant-card-meta-detail {
    overflow: hidden;
  }
  .ant-card-meta-detail > div:not(:last-child) {
    margin-bottom: 8px;
  }
  .ant-card-meta-title {
    overflow: hidden;
    color: rgba(255, 255, 255, 0.85);
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ant-card-meta-description {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-card-loading {
    overflow: hidden;
  }
  .ant-card-loading .ant-card-body {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-card-loading-block {
    height: 14px;
    margin: 4px 0;
    background: linear-gradient(90deg, rgba(66, 66, 66, 0.2), rgba(66, 66, 66, 0.4), rgba(66, 66, 66, 0.2));
    background-size: 600% 600%;
    border-radius: 4px;
    animation: card-loading 1.4s ease infinite;
  }
  .ant-card-small > .ant-card-head {
    min-height: 36px;
    padding: 0 12px;
    font-size: 14px;
  }
  .ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
    padding: 8px 0;
  }
  .ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra,
  .ant-carousel {
    padding: 8px 0;
    font-size: 14px;
  }
  .ant-card-small > .ant-card-body {
    padding: 12px;
  }
  .ant-carousel {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
  }
  .ant-carousel .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  .ant-carousel .slick-list {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  .ant-carousel .slick-list:focus {
    outline: 0;
  }
  .ant-carousel .slick-list.dragging {
    cursor: pointer;
  }
  .ant-carousel .slick-list .slick-slide,
  .ant-carousel .slick-slide.dragging img {
    pointer-events: none;
  }
  .ant-carousel .slick-list .slick-slide.slick-active {
    pointer-events: auto;
  }
  .ant-carousel .slick-list .slick-slide.slick-active input.ant-checkbox-input,
  .ant-carousel .slick-list .slick-slide.slick-active input.ant-radio-input {
    visibility: visible;
  }
  .ant-carousel .slick-list .slick-slide > div > div {
    vertical-align: bottom;
  }
  .ant-carousel .slick-slider .slick-list,
  .ant-carousel .slick-slider .slick-track {
    transform: translate3d(0, 0, 0);
  }
  .ant-carousel .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
  }
  .ant-carousel .slick-track::after {
    clear: both;
  }
  .ant-carousel .slick-list .slick-slide input.ant-checkbox-input,
  .ant-carousel .slick-list .slick-slide input.ant-radio-input,
  .slick-loading .ant-carousel .slick-track {
    visibility: hidden;
  }
  .ant-carousel .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }
  .ant-carousel .slick-initialized .slick-slide {
    display: block;
  }
  .ant-carousel .slick-loading .slick-slide {
    visibility: hidden;
  }
  .ant-carousel .slick-vertical .slick-slide {
    display: block;
    height: auto;
  }
  .ant-carousel .slick-arrow.slick-hidden {
    display: none;
  }
  .ant-carousel .slick-next,
  .ant-carousel .slick-prev {
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    padding: 0;
    color: transparent;
    font-size: 0;
    line-height: 0;
    background: 0 0;
    border: 0;
    outline: 0;
    cursor: pointer;
  }
  .ant-carousel .slick-next:focus,
  .ant-carousel .slick-next:hover,
  .ant-carousel .slick-prev:focus,
  .ant-carousel .slick-prev:hover {
    color: transparent;
    background: 0 0;
    outline: 0;
  }
  .ant-carousel .slick-dots li.slick-active:focus,
  .ant-carousel .slick-dots li.slick-active:hover,
  .ant-carousel .slick-next:focus::before,
  .ant-carousel .slick-next:hover::before,
  .ant-carousel .slick-prev:focus::before,
  .ant-carousel .slick-prev:hover::before {
    opacity: 1;
  }
  .ant-carousel .slick-next.slick-disabled::before,
  .ant-carousel .slick-prev.slick-disabled::before {
    opacity: 0.25;
  }
  .ant-carousel .slick-prev {
    left: -25px;
  }
  .ant-carousel .slick-prev::before {
    content: "←";
  }
  .ant-carousel .slick-next {
    right: -25px;
  }
  .ant-carousel .slick-next::before,
  .ant-carousel-rtl .ant-carousel .slick-prev::before {
    content: "→";
  }
  .ant-carousel .slick-dots {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: flex !important;
    justify-content: center;
    margin-right: 15%;
    margin-left: 15%;
    padding-left: 0;
    list-style: none;
  }
  .ant-carousel .slick-dots-bottom {
    bottom: 12px;
  }
  .ant-carousel .slick-dots-top {
    top: 12px;
  }
  .ant-carousel .slick-dots li {
    position: relative;
    display: inline-block;
    flex: 0 1 auto;
    box-sizing: content-box;
    width: 16px;
    height: 3px;
    margin: 0 3px;
    padding: 0;
    text-align: center;
    text-indent: -999px;
    vertical-align: top;
    transition: all 0.5s;
  }
  .ant-carousel .slick-dots li button {
    display: block;
    width: 100%;
    height: 3px;
    padding: 0;
    color: transparent;
    font-size: 0;
    background: #212121;
    border: 0;
    border-radius: 1px;
    outline: 0;
    cursor: pointer;
    opacity: 0.3;
    transition: all 0.5s;
  }
  .ant-carousel .slick-dots li button:focus,
  .ant-carousel .slick-dots li button:hover {
    opacity: 0.75;
  }
  .ant-carousel .slick-dots li.slick-active {
    width: 24px;
  }
  .ant-carousel .slick-dots li.slick-active button {
    background: #212121;
    opacity: 1;
  }
  .ant-carousel-vertical .slick-dots {
    top: 50%;
    bottom: auto;
    flex-direction: column;
    width: 3px;
    height: auto;
    margin: 0;
    transform: translateY(-50%);
  }
  .ant-carousel-vertical .slick-dots-left {
    right: auto;
    left: 12px;
  }
  .ant-carousel-vertical .slick-dots-right {
    right: 12px;
    left: auto;
  }
  .ant-carousel-vertical .slick-dots li {
    width: 3px;
    height: 16px;
    margin: 4px 2px;
    vertical-align: baseline;
  }
  .ant-carousel-vertical .slick-dots li button {
    width: 3px;
    height: 16px;
  }
  .ant-carousel-vertical .slick-dots li.slick-active,
  .ant-carousel-vertical .slick-dots li.slick-active button {
    width: 3px;
    height: 24px;
  }
  .ant-carousel-rtl {
    direction: rtl;
  }
  .ant-carousel-rtl .ant-carousel .slick-track {
    right: 0;
    left: auto;
  }
  .ant-carousel-rtl .ant-carousel .slick-prev {
    right: -25px;
    left: auto;
  }
  .ant-carousel-rtl .ant-carousel .slick-next {
    right: auto;
    left: -25px;
  }
  .ant-carousel-rtl .ant-carousel .slick-next::before {
    content: "←";
  }
  .ant-carousel-rtl.ant-carousel .slick-dots {
    flex-direction: row-reverse;
  }
  .ant-carousel-rtl.ant-carousel-vertical .slick-dots {
    flex-direction: column;
  }
  .ant-cascader,
  .ant-cascader-picker {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
  }
  .ant-cascader-input.ant-input {
    position: static;
    width: 100%;
    padding-right: 24px;
    background-color: transparent !important;
    cursor: pointer;
  }
  .ant-cascader-picker-show-search .ant-cascader-input.ant-input {
    position: relative;
  }
  .ant-cascader-picker {
    position: relative;
    display: inline-block;
    background-color: transparent;
    border-radius: 4px;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;
  }
  .ant-cascader-picker-with-value .ant-cascader-picker-label {
    color: transparent;
  }
  .ant-cascader-picker-disabled {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
    cursor: not-allowed;
  }
  .ant-cascader-picker-disabled .ant-cascader-input {
    cursor: not-allowed;
  }
  .ant-cascader-picker:focus .ant-cascader-input {
    border-color: #2caad9;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(44, 170, 217, 0.2);
  }
  .ant-input-rtl .ant-cascader-picker:focus .ant-cascader-input {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ant-cascader-picker-borderless .ant-cascader-input {
    border-color: transparent !important;
    box-shadow: none !important;
  }
  .ant-cascader-picker-show-search.ant-cascader-picker-focused {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-cascader-picker-label {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 20px;
    margin-top: -10px;
    padding: 0 20px 0 12px;
    overflow: hidden;
    line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ant-cascader-picker-arrow,
  .ant-cascader-picker-clear {
    position: absolute;
    top: 50%;
    right: 12px;
    width: 12px;
    height: 12px;
    margin-top: -6px;
    color: rgba(255, 255, 255, 0.3);
    font-size: 12px;
    line-height: 12px;
  }
  .ant-cascader-picker-clear {
    z-index: 2;
    background: #212121;
    cursor: pointer;
    opacity: 0;
    transition: color 0.3s ease, opacity 0.15s ease;
  }
  .ant-cascader-picker-clear:hover {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-cascader-picker:hover .ant-cascader-picker-clear {
    opacity: 1;
  }
  .ant-cascader-picker-arrow {
    z-index: 1;
  }
  .ant-cascader-picker-label:hover
    + .ant-cascader-input:not(.ant-cascader-picker-disabled .ant-cascader-picker-label:hover + .ant-cascader-input) {
    border-color: #287a99;
    border-right-width: 1px !important;
  }
  .ant-input-rtl
    .ant-cascader-picker-label:hover
    + .ant-cascader-input:not(.ant-cascader-picker-disabled .ant-cascader-picker-label:hover + .ant-cascader-input) {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ant-cascader-picker-small .ant-cascader-picker-arrow,
  .ant-cascader-picker-small .ant-cascader-picker-clear {
    right: 8px;
  }
  .ant-cascader-menus {
    position: absolute;
    z-index: 1050;
    font-size: 14px;
    white-space: nowrap;
    background: #212121;
    border-radius: 4px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
  }
  .ant-cascader-menus ol,
  .ant-cascader-menus ul {
    margin: 0;
    list-style: none;
  }
  .ant-cascader-menus-empty,
  .ant-cascader-menus-hidden,
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow::before {
    display: none;
  }
  .ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-bottomLeft,
  .ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-bottomLeft {
    animation-name: antSlideUpIn;
  }
  .ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-topLeft,
  .ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-topLeft {
    animation-name: antSlideDownIn;
  }
  .ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-bottomLeft {
    animation-name: antSlideUpOut;
  }
  .ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-topLeft {
    animation-name: antSlideDownOut;
  }
  .ant-cascader-menu {
    display: inline-block;
    min-width: 111px;
    height: 180px;
    margin: 0;
    padding: 4px 0;
    overflow: auto;
    vertical-align: top;
    list-style: none;
    border-right: 1px solid #424242;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .ant-cascader-menu:first-child {
    border-radius: 4px 0 0 4px;
  }
  .ant-cascader-menu:last-child {
    margin-right: -1px;
    border-right-color: transparent;
    border-radius: 0 4px 4px 0;
  }
  .ant-cascader-menu:only-child {
    border-radius: 4px;
  }
  .ant-cascader-menu-item {
    padding: 5px 12px;
    overflow: hidden;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    transition: all 0.3s;
  }
  .ant-cascader-menu-item:hover,
  .ant-picker-week-panel-row:hover td {
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-cascader-menu-item-disabled,
  .ant-checkbox-disabled + span {
    color: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
  }
  .ant-cascader-menu-item-disabled:hover {
    background: 0 0;
  }
  .ant-cascader-menu-empty .ant-cascader-menu-item {
    color: rgba(255, 255, 255, 0.3);
    cursor: default;
    pointer-events: none;
  }
  .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
  .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
    font-weight: 600;
    background-color: #1e2b31;
  }
  .ant-cascader-menu-item-expand {
    position: relative;
    padding-right: 24px;
  }
  .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
  .ant-cascader-menu-item-loading-icon {
    display: inline-block;
    font-size: 10px;
    position: absolute;
    right: 12px;
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-cascader-menu-item-disabled.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
  .ant-cascader-menu-item-disabled.ant-cascader-menu-item-loading-icon {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-cascader-menu-item .ant-cascader-menu-item-keyword {
    color: #ab2229;
  }
  .ant-cascader-picker-rtl .ant-cascader-input.ant-input {
    padding-right: 11px;
    padding-left: 24px;
    text-align: right;
  }
  .ant-cascader-picker-rtl {
    direction: rtl;
  }
  .ant-cascader-picker-rtl .ant-cascader-picker-label {
    padding: 0 12px 0 20px;
    text-align: right;
  }
  .ant-cascader-picker-rtl .ant-cascader-picker-arrow,
  .ant-cascader-picker-rtl .ant-cascader-picker-clear {
    right: auto;
    left: 12px;
  }
  .ant-cascader-picker-rtl.ant-cascader-picker-small .ant-cascader-picker-arrow,
  .ant-cascader-picker-rtl.ant-cascader-picker-small .ant-cascader-picker-clear {
    right: auto;
    left: 8px;
  }
  .ant-cascader-menu-rtl .ant-cascader-menu {
    direction: rtl;
    border-right: none;
    border-left: 1px solid #424242;
  }
  .ant-cascader-menu-rtl .ant-cascader-menu:first-child {
    border-radius: 0 4px 4px 0;
  }
  .ant-cascader-menu-rtl .ant-cascader-menu:last-child {
    margin-right: 0;
    margin-left: -1px;
    border-left-color: transparent;
    border-radius: 4px 0 0 4px;
  }
  .ant-cascader-menu-rtl .ant-cascader-menu:only-child {
    border-radius: 4px;
  }
  .ant-cascader-menu-rtl .ant-cascader-menu-item-expand {
    padding-right: 12px;
    padding-left: 24px;
  }
  .ant-cascader-menu-rtl .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
  .ant-cascader-menu-rtl .ant-cascader-menu-item-loading-icon {
    right: auto;
    left: 12px;
  }
  .ant-cascader-menu-rtl .ant-cascader-menu-item-loading-icon {
    transform: scaleY(-1);
  }
  .ant-checkbox {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    top: -0.09em;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    vertical-align: middle;
    outline: 0;
    cursor: pointer;
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #2caad9;
  }
  .ant-checkbox-checked::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #2caad9;
    border-radius: 4px;
    visibility: hidden;
    animation: antCheckboxEffect 0.36s ease-in-out;
    animation-fill-mode: backwards;
    content: "";
  }
  .ant-checkbox-wrapper:hover .ant-checkbox::after,
  .ant-checkbox:hover::after {
    visibility: visible;
  }
  .ant-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    direction: ltr;
    background-color: transparent;
    border: 1px solid #6f6c6c;
    border-radius: 4px;
    border-collapse: separate;
    transition: all 0.3s;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after,
  .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    content: " ";
  }
  .ant-checkbox-inner::after {
    top: 50%;
    left: 22%;
    width: 5.71428571px;
    height: 9.14285714px;
    transform: rotate(45deg) scale(0) translate(-50%, -50%);
    opacity: 0;
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  }
  .ant-checkbox-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2caad9;
    border-color: #2caad9;
  }
  .ant-checkbox-disabled,
  .ant-checkbox-disabled .ant-checkbox-input {
    cursor: not-allowed;
  }
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: rgba(255, 255, 255, 0.3);
    animation-name: none;
  }
  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #6f6c6c !important;
  }
  .ant-checkbox-disabled .ant-checkbox-inner::after {
    border-color: rgba(255, 255, 255, 0.08);
    border-collapse: separate;
    animation-name: none;
  }
  .ant-checkbox-disabled:hover::after,
  .ant-checkbox-wrapper:hover .ant-checkbox-disabled::after {
    visibility: hidden;
  }
  .ant-checkbox-group,
  .ant-checkbox-wrapper {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    display: inline-block;
  }
  .ant-checkbox-wrapper {
    line-height: unset;
    cursor: pointer;
  }
  .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
    cursor: not-allowed;
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 8px;
  }
  .ant-checkbox + span {
    padding-right: 8px;
    padding-left: 8px;
  }
  .ant-checkbox-group {
    line-height: 1.5715;
  }
  .ant-checkbox-group-item {
    display: inline-block;
    margin-right: 8px;
  }
  .ant-checkbox-group-item:last-child {
    margin-right: 0;
  }
  .ant-checkbox-group-item + .ant-checkbox-group-item {
    margin-left: 0;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: transparent;
    border-color: #6f6c6c;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    background-color: #2caad9;
    border: 0;
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    content: " ";
  }
  .ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
    background-color: rgba(255, 255, 255, 0.3);
    border-color: rgba(255, 255, 255, 0.3);
  }
  .ant-checkbox-rtl {
    direction: rtl;
  }
  .ant-checkbox-group-rtl .ant-checkbox-group-item {
    margin-right: 0;
    margin-left: 8px;
  }
  .ant-checkbox-group-rtl .ant-checkbox-group-item:last-child {
    margin-left: 0 !important;
  }
  .ant-checkbox-group-rtl .ant-checkbox-group-item + .ant-checkbox-group-item {
    margin-left: 8px;
  }
  .ant-collapse {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    background-color: rgba(255, 255, 255, 0.04);
    border: 1px solid #6f6c6c;
    border-bottom: 0;
    border-radius: 4px;
  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid #6f6c6c;
  }
  .ant-collapse > .ant-collapse-item:last-child,
  .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 0 0 4px 4px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    position: relative;
    padding: 12px 16px 12px 40px;
    color: rgba(255, 255, 255, 0.85);
    line-height: 1.5715;
    cursor: pointer;
    transition: all 0.3s;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header::before {
    display: table;
    content: "";
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header::after {
    display: table;
    clear: both;
    content: "";
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    left: 16px;
    display: inline-block;
    font-size: 12px;
    transform: translateY(-50%);
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow > * {
    line-height: 1;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow
    .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow-icon {
    display: block;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
    transition: transform 0.24s;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
    float: right;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header:focus,
  .ant-rate-star > div:focus {
    outline: 0;
  }
  .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
    padding-left: 12px;
  }
  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding: 12px 40px 12px 16px;
  }
  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    right: 16px;
    left: auto;
  }
  .ant-collapse-anim-active {
    transition: height 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .ant-collapse-content {
    overflow: hidden;
    color: rgba(255, 255, 255, 0.85);
    background-color: #212121;
    border-top: 1px solid #6f6c6c;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px;
  }
  .ant-collapse-content-inactive {
    display: none;
  }
  .ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: 0 0 4px 4px;
  }
  .ant-collapse-borderless {
    background-color: rgba(255, 255, 255, 0.04);
    border: 0;
  }
  .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: 1px solid #6f6c6c;
  }
  .ant-collapse-borderless > .ant-collapse-item:last-child,
  .ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
    border-radius: 0;
  }
  .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content,
  .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
    background-color: transparent;
    border-top: 0;
  }
  .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 4px;
  }
  .ant-collapse-ghost {
    background-color: transparent;
    border: 0;
  }
  .ant-collapse-ghost > .ant-collapse-item {
    border-bottom: 0;
  }
  .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
  .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow,
  .ant-picker-header button {
    color: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
  }
  .ant-collapse-rtl {
    direction: rtl;
  }
  .ant-collapse-rtl .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 12px 40px 12px 16px;
  }
  .ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
    transform: rotate(180deg);
  }
  .ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
    float: left;
  }
  .ant-collapse-rtl.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
    padding-right: 12px;
    padding-left: 0;
  }
  .ant-comment {
    position: relative;
    background-color: transparent;
  }
  .ant-comment-inner {
    display: flex;
    padding: 16px 0;
  }
  .ant-comment-avatar {
    position: relative;
    flex-shrink: 0;
    margin-right: 12px;
    cursor: pointer;
  }
  .ant-comment-avatar img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  .ant-comment-content {
    position: relative;
    flex: 1 1 auto;
    min-width: 1px;
    font-size: 14px;
    word-wrap: break-word;
  }
  .ant-comment-content-author {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 4px;
    font-size: 14px;
  }
  .ant-comment-content-author > a,
  .ant-comment-content-author > span {
    padding-right: 8px;
    font-size: 12px;
    line-height: 18px;
  }
  .ant-comment-actions > li > span,
  .ant-comment-content-author-name {
    color: rgba(255, 255, 255, 0.45);
    font-size: 14px;
    transition: color 0.3s;
  }
  .ant-comment-actions > li,
  .ant-comment-content-author-name > *,
  .ant-comment-content-author-name > :hover {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-comment-content-author-time {
    color: rgba(255, 255, 255, 0.3);
    white-space: nowrap;
    cursor: auto;
  }
  .ant-comment-content-detail p {
    margin-bottom: inherit;
    white-space: pre-wrap;
  }
  .ant-comment-actions {
    margin-top: 12px;
    margin-bottom: inherit;
    padding-left: 0;
  }
  .ant-comment-actions > li {
    display: inline-block;
  }
  .ant-comment-actions > li > span {
    margin-right: 10px;
    font-size: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-comment-actions > li > span:hover {
    color: rgba(255, 255, 255, 0.65);
  }
  .ant-comment-nested {
    margin-left: 44px;
  }
  .ant-comment-rtl,
  .ant-picker-input > input-rtl {
    direction: rtl;
  }
  .ant-comment-rtl .ant-comment-avatar {
    margin-right: 0;
    margin-left: 12px;
  }
  .ant-comment-rtl .ant-comment-actions {
    padding-right: 0;
  }
  .ant-comment-rtl .ant-comment-actions > li > span {
    margin-right: 0;
    margin-left: 10px;
  }
  .ant-comment-rtl .ant-comment-nested {
    margin-right: 44px;
    margin-left: 0;
  }
  .ant-picker-panel {
    display: inline-flex;
    flex-direction: column;
    text-align: center;
    background: #212121;
    border: 1px solid #424242;
    border-radius: 4px;
    outline: 0;
  }
  .ant-picker-panel-focused {
    border-color: #2caad9;
  }
  .ant-picker-date-panel,
  .ant-picker-decade-panel,
  .ant-picker-month-panel,
  .ant-picker-quarter-panel,
  .ant-picker-week-panel,
  .ant-picker-year-panel {
    display: flex;
    flex-direction: column;
    width: 280px;
  }
  .ant-picker-time-panel {
    display: flex;
    flex-direction: column;
  }
  .ant-picker-header {
    display: flex;
    padding: 0 8px;
    color: rgba(255, 255, 255, 0.85);
    border-bottom: 1px solid #424242;
  }
  .ant-picker-header > * {
    flex: none;
  }
  .ant-picker-header button {
    padding: 0;
    line-height: 40px;
    background: 0 0;
    border: 0;
    cursor: pointer;
    transition: color 0.3s;
  }
  .ant-picker-header > button {
    min-width: 1.6em;
    font-size: 14px;
  }
  .ant-picker-header-view {
    flex: auto;
    font-weight: 500;
    line-height: 40px;
  }
  .ant-picker-header-view button {
    color: inherit;
    font-weight: inherit;
  }
  .ant-picker-header-view button:not(:first-child) {
    margin-left: 8px;
  }
  .ant-picker-header-view button:hover {
    color: #2caad9;
  }
  .ant-picker-next-icon,
  .ant-picker-prev-icon,
  .ant-picker-super-next-icon,
  .ant-picker-super-prev-icon {
    position: relative;
    display: inline-block;
    width: 7px;
    height: 7px;
  }
  .ant-picker-next-icon::before,
  .ant-picker-prev-icon::before,
  .ant-picker-super-next-icon::after,
  .ant-picker-super-next-icon::before,
  .ant-picker-super-prev-icon::after,
  .ant-picker-super-prev-icon::before {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 7px;
    height: 7px;
    border: 0 solid currentColor;
    border-width: 1.5px 0 0 1.5px;
    content: "";
  }
  .ant-picker-super-next-icon::after,
  .ant-picker-super-prev-icon::after {
    top: 4px;
    left: 4px;
  }
  .ant-picker-prev-icon,
  .ant-picker-super-prev-icon {
    transform: rotate(-45deg);
  }
  .ant-picker-next-icon,
  .ant-picker-super-next-icon {
    transform: rotate(135deg);
  }
  .ant-picker-content {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
  }
  .ant-picker-content td,
  .ant-picker-content th {
    position: relative;
    min-width: 24px;
    font-weight: 400;
  }
  .ant-picker-content th {
    height: 30px;
    line-height: 30px;
  }
  .ant-picker-cell {
    padding: 3px 0;
    color: rgba(255, 255, 255, 0.3);
    cursor: pointer;
  }
  .ant-dropdown-menu-item > .anticon + span > a:hover,
  .ant-dropdown-menu-item > a:hover,
  .ant-dropdown-menu-submenu-title > .anticon + span > a:hover,
  .ant-dropdown-menu-submenu-title > a:hover,
  .ant-picker-cell-in-view,
  .ant-picker-content th {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-picker-cell-disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
  .ant-picker-cell::before {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    z-index: 1;
    height: 24px;
    transform: translateY(-50%);
    content: "";
  }
  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
    .ant-picker-cell-inner {
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    border: 1px solid #2caad9;
    border-radius: 4px;
    content: "";
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range {
    position: relative;
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: #1e2b31;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    color: #fff;
    background: #2caad9;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before {
    background: #1e2b31;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start::before {
    left: 50%;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-end::before,
  .ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
    right: 50%;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
    position: absolute;
    top: 50%;
    z-index: 0;
    height: 24px;
    border-top: 1px dashed #186987;
    border-bottom: 1px dashed #186987;
    transform: translateY(-50%);
    content: "";
  }
  .ant-picker-cell-range-hover-end::after,
  .ant-picker-cell-range-hover-start::after,
  .ant-picker-cell-range-hover::after {
    right: 0;
    left: 2px;
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
  .ant-picker-panel
    > :not(.ant-picker-date-panel)
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before,
  .ant-picker-panel
    > :not(.ant-picker-date-panel)
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before {
    background: #0d3646;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end)
    .ant-picker-cell-inner {
    border-radius: 4px 0 0 4px;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start)
    .ant-picker-cell-inner,
  .ant-picker-panel-rtl
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end)
    .ant-picker-cell-inner {
    border-radius: 0 4px 4px 0;
  }
  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
    .ant-picker-cell-inner::after,
  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
    .ant-picker-cell-inner::after {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
    background: #0d3646;
    content: "";
  }
  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
    .ant-picker-cell-inner::after {
    right: -7px;
    left: 0;
  }
  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
    .ant-picker-cell-inner::after,
  .ant-picker-panel-rtl
    .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
    .ant-picker-cell-inner::after {
    right: 0;
    left: -7px;
  }
  .ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
    left: 50%;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after,
  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after {
    left: 6px;
    border-left: 1px dashed #186987;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after,
  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after {
    right: 6px;
    border-right: 1px dashed #186987;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .ant-picker-cell-disabled .ant-picker-cell-inner {
    color: rgba(255, 255, 255, 0.3);
    background: 0 0;
  }
  .ant-picker-cell-disabled::before,
  .ant-picker-decade-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
  .ant-picker-month-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
  .ant-picker-quarter-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
  .ant-picker-year-panel .ant-picker-cell-disabled .ant-picker-cell-inner {
    background: #303030;
  }
  .ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: rgba(255, 255, 255, 0.3);
  }
  .ant-picker-decade-panel .ant-picker-content,
  .ant-picker-month-panel .ant-picker-content,
  .ant-picker-year-panel .ant-picker-content {
    height: 264px;
  }
  .ant-picker-month-panel .ant-picker-cell-inner,
  .ant-picker-quarter-panel .ant-picker-cell-inner,
  .ant-picker-year-panel .ant-picker-cell-inner {
    padding: 0 8px;
  }
  .ant-picker-quarter-panel .ant-picker-content {
    height: 56px;
  }
  .ant-picker-footer {
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    min-width: 100%;
    line-height: 38px;
    text-align: center;
    border-bottom: 1px solid transparent;
  }
  .ant-picker-panel .ant-picker-footer {
    border-top: 1px solid #424242;
  }
  .ant-picker-footer-extra {
    padding: 0 12px;
    line-height: 38px;
    text-align: left;
  }
  .ant-drawer .ant-table.ant-table-bordered table thead > tr:not(:last-child) > th,
  .ant-modal .ant-table.ant-table-bordered table thead > tr:not(:last-child) > th,
  .ant-notification .ant-table.ant-table-bordered table thead > tr:not(:last-child) > th,
  .ant-picker-footer-extra:not(:last-child),
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr:not(:last-child) > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th {
    border-bottom: 1px solid #424242;
  }
  .ant-picker-now {
    text-align: left;
  }
  .ant-picker-today-btn {
    color: #2caad9;
  }
  .ant-picker-today-btn:hover {
    color: #287a99;
  }
  .ant-picker-today-btn:active {
    color: #4eb3d2;
  }
  .ant-picker-today-btn.ant-picker-today-btn-disabled {
    color: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
  }
  .ant-picker-decade-panel .ant-picker-cell-inner {
    padding: 0 4px;
  }
  .ant-picker-decade-panel .ant-picker-cell::before {
    display: none;
  }
  .ant-picker-month-panel .ant-picker-body,
  .ant-picker-quarter-panel .ant-picker-body,
  .ant-picker-year-panel .ant-picker-body {
    padding: 0 8px;
  }
  .ant-picker-month-panel .ant-picker-cell-inner,
  .ant-picker-quarter-panel .ant-picker-cell-inner,
  .ant-picker-year-panel .ant-picker-cell-inner {
    width: 60px;
  }
  .ant-picker-month-panel .ant-picker-cell-range-hover-start::after,
  .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
  .ant-picker-year-panel .ant-picker-cell-range-hover-start::after {
    left: 14px;
    border-left: 1px dashed #186987;
    border-radius: 4px 0 0 4px;
  }
  .ant-picker-month-panel .ant-picker-cell-range-hover-end::after,
  .ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start::after,
  .ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
  .ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
  .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
  .ant-picker-year-panel .ant-picker-cell-range-hover-end::after {
    right: 14px;
    border-right: 1px dashed #186987;
    border-radius: 0 4px 4px 0;
  }
  .ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end::after,
  .ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
  .ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end::after {
    left: 14px;
    border-left: 1px dashed #186987;
    border-radius: 4px 0 0 4px;
  }
  .ant-picker-date-panel .ant-picker-body,
  .ant-picker-week-panel .ant-picker-body {
    padding: 8px 12px;
  }
  .ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner,
  .ant-picker-week-panel .ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-week-panel .ant-picker-cell:hover .ant-picker-cell-inner {
    background: 0 0 !important;
  }
  .ant-picker-week-panel-row td {
    transition: background 0.3s;
  }
  .ant-picker-week-panel-row-selected td,
  .ant-picker-week-panel-row-selected:hover td {
    background: #2caad9;
  }
  .ant-picker-week-panel-row-selected td.ant-picker-cell-week,
  .ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
    color: rgba(255, 255, 255, 0.5);
  }
  .ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner::before,
  .ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: #fff;
  }
  .ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
  .ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
    color: #fff;
  }
  .ant-picker-date-panel .ant-picker-content {
    width: 252px;
  }
  .ant-picker-date-panel .ant-picker-content th {
    width: 36px;
  }
  .ant-picker-datetime-panel {
    display: flex;
  }
  .ant-picker-datetime-panel .ant-picker-time-panel {
    border-left: 1px solid #424242;
  }
  .ant-picker-datetime-panel .ant-picker-date-panel,
  .ant-picker-datetime-panel .ant-picker-time-panel {
    transition: opacity 0.3s;
  }
  .ant-picker-datetime-panel-active .ant-picker-date-panel,
  .ant-picker-datetime-panel-active .ant-picker-time-panel {
    opacity: 0.3;
  }
  .ant-picker-datetime-panel-active .ant-picker-date-panel-active,
  .ant-picker-datetime-panel-active .ant-picker-time-panel-active {
    opacity: 1;
  }
  .ant-picker-time-panel {
    width: auto;
    min-width: auto;
  }
  .ant-picker-time-panel .ant-picker-content {
    display: flex;
    flex: auto;
    height: 224px;
  }
  .ant-picker-time-panel-column {
    flex: 1 0 auto;
    width: 56px;
    margin: 0;
    padding: 0;
    overflow-y: hidden;
    text-align: left;
    list-style: none;
    transition: background 0.3s;
  }
  .ant-picker-time-panel-column::after {
    display: block;
    height: 196px;
    content: "";
  }
  .ant-picker-datetime-panel .ant-picker-time-panel-column::after {
    height: 198px;
  }
  .ant-picker-time-panel-column:not(:first-child) {
    border-left: 1px solid #424242;
  }
  .ant-picker-time-panel-column-active {
    background: rgba(30, 43, 49, 0.2);
  }
  .ant-picker-time-panel-column:hover {
    overflow-y: auto;
  }
  .ant-picker-time-panel-column > li {
    margin: 0;
    padding: 0;
  }
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
    display: block;
    width: 100%;
    height: 28px;
    margin: 0;
    padding: 0 0 0 14px;
    color: rgba(255, 255, 255, 0.85);
    line-height: 28px;
    border-radius: 0;
    cursor: pointer;
    transition: background 0.3s;
  }
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: #1e2b31;
  }
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
    color: rgba(255, 255, 255, 0.3);
    background: 0 0;
    cursor: not-allowed;
  }
  :root .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
  :root .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell,
  _:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
  _:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell {
    padding: 21px 0;
  }
  .ant-picker,
  .ant-picker-input > input {
    position: relative;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    border-radius: 4px;
    background: 0 0;
  }
  .ant-picker {
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    padding: 4px 11px;
    display: inline-flex;
    align-items: center;
    border: 1px solid #6f6c6c;
    transition: border 0.3s, box-shadow 0.3s;
  }
  .ant-picker-focused,
  .ant-picker:hover {
    border-color: #287a99;
    border-right-width: 1px !important;
  }
  .ant-input-rtl .ant-picker-focused,
  .ant-input-rtl .ant-picker-input > input:hover,
  .ant-input-rtl .ant-picker:hover {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ant-picker-focused {
    border-color: #2caad9;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(44, 170, 217, 0.2);
  }
  .ant-picker.ant-picker-disabled {
    background: rgba(255, 255, 255, 0.08);
    border-color: #6f6c6c;
    cursor: not-allowed;
  }
  .ant-picker.ant-picker-disabled .ant-picker-suffix {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-picker.ant-picker-borderless {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }
  .ant-picker-input {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 100%;
  }
  .ant-picker-input > input {
    display: inline-block;
    width: 100%;
    background-color: transparent;
    background-image: none;
    transition: all 0.3s;
    flex: auto;
    min-width: 1px;
    height: auto;
    padding: 0;
    border: 0;
  }
  .ant-picker-input > input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-picker-input > input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.3);
    text-overflow: ellipsis;
  }
  .ant-picker-input > input::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-picker-input > input:placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-picker-input > input:hover {
    border-color: #287a99;
    border-right-width: 1px !important;
  }
  .ant-picker-input > input-focused,
  .ant-picker-input > input:focus {
    border-color: #2caad9;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(44, 170, 217, 0.2);
  }
  .ant-input-rtl .ant-picker-input > input-focused,
  .ant-input-rtl .ant-picker-input > input:focus {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ant-picker-input > input-disabled,
  .ant-picker-input > input[disabled] {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-picker-input > input-disabled:hover,
  .ant-picker-input > input[disabled]:hover {
    border-color: #6f6c6c;
    border-right-width: 1px !important;
  }
  .ant-picker-input > input-borderless,
  .ant-picker-input > input-borderless-disabled,
  .ant-picker-input > input-borderless-focused,
  .ant-picker-input > input-borderless:focus,
  .ant-picker-input > input-borderless:hover,
  .ant-picker-input > input-borderless[disabled] {
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }
  textarea.ant-input,
  textarea.ant-input-affix-wrapper,
  textarea.ant-input-number,
  textarea.ant-mentions,
  textarea.ant-picker-input > input {
    max-width: 100%;
    height: auto;
    min-height: 32px;
    line-height: 1.5715;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
  }
  .ant-picker-input > input-lg {
    padding: 6.5px 11px;
    font-size: 16px;
  }
  .ant-picker-input > input-sm {
    padding: 0 7px;
  }
  .ant-picker-input > input:focus {
    box-shadow: none;
  }
  .ant-picker-input > input[disabled] {
    background: 0 0;
  }
  .ant-picker-input:hover .ant-picker-clear {
    opacity: 1;
  }
  .ant-picker-input-placeholder > input {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-picker-large {
    padding: 6.5px 11px;
  }
  .ant-picker-large .ant-picker-input > input {
    font-size: 16px;
  }
  .ant-picker-small {
    padding: 0 7px;
  }
  .ant-picker-suffix {
    align-self: center;
    margin-left: 4px;
    color: rgba(255, 255, 255, 0.3);
    line-height: 1;
    pointer-events: none;
  }
  .ant-picker-suffix > * {
    vertical-align: top;
  }
  .ant-picker-clear {
    position: absolute;
    top: 50%;
    right: 0;
    color: rgba(255, 255, 255, 0.3);
    line-height: 1;
    background: #212121;
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s, color 0.3s;
  }
  .ant-picker-clear > * {
    vertical-align: top;
  }
  .ant-picker-clear:hover,
  .ant-picker-focused .ant-picker-separator {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-picker-separator {
    position: relative;
    display: inline-block;
    width: 1em;
    height: 16px;
    color: rgba(255, 255, 255, 0.3);
    font-size: 16px;
    vertical-align: top;
    cursor: default;
  }
  .ant-picker-disabled .ant-picker-range-separator .ant-picker-separator {
    cursor: not-allowed;
  }
  .ant-picker-range {
    position: relative;
    display: inline-flex;
  }
  .ant-picker-range .ant-picker-clear {
    right: 11px;
  }
  .ant-picker-range.ant-picker-focused .ant-picker-active-bar,
  .ant-picker-range:hover .ant-picker-clear {
    opacity: 1;
  }
  .ant-picker-range .ant-picker-active-bar {
    bottom: -1px;
    height: 2px;
    margin-left: 11px;
    background: #2caad9;
    opacity: 0;
    transition: all 0.3s ease-out;
    pointer-events: none;
  }
  .ant-picker-range-separator {
    align-items: center;
    padding: 0 8px;
    line-height: 1;
  }
  .ant-picker-range.ant-picker-small .ant-picker-clear {
    right: 7px;
  }
  .ant-picker-dropdown {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: absolute;
    z-index: 1050;
  }
  .ant-picker-dropdown-hidden {
    display: none;
  }
  .ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
    top: 1.66666667px;
    display: block;
    transform: rotate(-45deg);
  }
  .ant-picker-dropdown-placement-topLeft .ant-picker-range-arrow {
    bottom: 1.66666667px;
    display: block;
    transform: rotate(135deg);
  }
  .ant-picker-dropdown-range {
    padding: 6.66666667px 0;
  }
  .ant-picker-dropdown-range-hidden {
    display: none;
  }
  .ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
    padding-top: 4px;
  }
  .ant-picker-ranges {
    margin-bottom: 0;
    padding: 4px 12px;
    overflow: hidden;
    line-height: 34px;
    text-align: left;
    list-style: none;
  }
  .ant-picker-ranges > li {
    display: inline-block;
  }
  .ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
    color: #2caad9;
    background: #1e2b31;
    border-color: #244a58;
    cursor: pointer;
  }
  .ant-picker-ranges .ant-picker-ok {
    float: right;
    margin-left: 8px;
  }
  .ant-picker-range-wrapper {
    display: flex;
  }
  .ant-picker-range-arrow {
    position: absolute;
    z-index: 1;
    display: none;
    width: 10px;
    height: 10px;
    margin-left: 16.5px;
    box-shadow: 2px -2px 6px rgba(0, 0, 0, 0.06);
    transition: left 0.3s ease-out;
  }
  .ant-picker-range-arrow::after {
    position: absolute;
    top: 1px;
    right: 1px;
    width: 10px;
    height: 10px;
    border: 5px solid #424242;
    border-color: #212121 #212121 transparent transparent;
    content: "";
  }
  .ant-picker-panel-container {
    overflow: hidden;
    vertical-align: top;
    background: #212121;
    border-radius: 4px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
    transition: margin 0.3s;
  }
  .ant-picker-panel-container .ant-picker-panels {
    display: inline-flex;
    flex-wrap: nowrap;
    direction: ltr;
  }
  .ant-picker-panel-container .ant-picker-panel {
    vertical-align: top;
    background: 0 0;
    border-width: 0 0 1px;
    border-radius: 0;
  }
  .ant-picker-panel-container .ant-picker-panel-focused {
    border-color: #424242;
  }
  .ant-picker-panel-rtl,
  .ant-picker-rtl {
    direction: rtl;
  }
  .ant-picker-rtl .ant-picker-suffix {
    margin-right: 4px;
    margin-left: 0;
  }
  .ant-picker-rtl .ant-picker-clear {
    right: auto;
    left: 0;
  }
  .ant-picker-rtl .ant-picker-separator {
    transform: rotate(180deg);
  }
  .ant-picker-panel-rtl .ant-picker-header-view button:not(:first-child) {
    margin-right: 8px;
    margin-left: 0;
  }
  .ant-picker-rtl.ant-picker-range .ant-picker-clear {
    right: auto;
    left: 11px;
  }
  .ant-picker-rtl.ant-picker-range .ant-picker-active-bar {
    margin-right: 11px;
    margin-left: 0;
  }
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-popup li,
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-popup ul,
  .ant-picker-dropdown-rtl .ant-picker-ranges {
    text-align: right;
  }
  .ant-picker-dropdown-rtl .ant-picker-ranges .ant-picker-ok {
    float: left;
    margin-right: 8px;
    margin-left: 0;
  }
  .ant-picker-panel-rtl .ant-picker-prev-icon,
  .ant-picker-panel-rtl .ant-picker-super-prev-icon {
    transform: rotate(135deg);
  }
  .ant-picker-panel-rtl .ant-picker-next-icon,
  .ant-picker-panel-rtl .ant-picker-super-next-icon {
    transform: rotate(-45deg);
  }
  .ant-picker-cell .ant-picker-cell-inner {
    position: relative;
    z-index: 2;
    display: inline-block;
    min-width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 4px;
    transition: background 0.3s, border 0.3s;
  }
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start::before {
    right: 50%;
    left: 0;
  }
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end::before,
  .ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
    right: 0;
    left: 50%;
  }
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-end::before {
    right: 50%;
    left: 50%;
  }
  .ant-picker-panel-rtl
    .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
    .ant-picker-cell-inner::after {
    right: -7px;
    left: 0;
  }
  .ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
    right: 50%;
    left: 0;
  }
  .ant-picker-panel-rtl
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start)
    .ant-picker-cell-inner {
    border-radius: 4px 0 0 4px;
  }
  .ant-picker-panel-rtl
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after,
  .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
  .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after {
    right: 6px;
    left: 0;
    border-right: 1px dashed #186987;
    border-left: none;
    border-top-left-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .ant-picker-panel-rtl
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after,
  .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
  .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after {
    right: 0;
    left: 6px;
    border-right: none;
    border-left: 1px dashed #186987;
    border-top-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 4px;
  }
  .ant-picker-panel-rtl
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after,
  .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after {
    right: 6px;
    border-right: 1px dashed #186987;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after {
    left: 6px;
    border-left: 1px dashed #186987;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .ant-picker-dropdown-rtl .ant-picker-footer-extra {
    direction: rtl;
    text-align: right;
  }
  .ant-picker-panel-rtl .ant-picker-time-panel {
    direction: ltr;
  }
  .ant-descriptions-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .ant-descriptions-title {
    flex: auto;
    overflow: hidden;
    color: rgba(255, 255, 255, 0.85);
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5715;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ant-descriptions-extra {
    margin-left: auto;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
  }
  .ant-descriptions-view {
    width: 100%;
    overflow: hidden;
    border-radius: 4px;
  }
  .ant-descriptions-view table {
    width: 100%;
    table-layout: fixed;
  }
  .ant-descriptions-row > td,
  .ant-descriptions-row > th {
    padding-bottom: 16px;
  }
  .ant-descriptions-row:last-child {
    border-bottom: none;
  }
  .ant-descriptions-item-content,
  .ant-descriptions-item-label {
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    line-height: 1.5715;
  }
  .ant-descriptions-item-label {
    font-weight: 400;
    text-align: start;
  }
  .ant-descriptions-item-label::after,
  .ant-form-item-label > label::after {
    content: ":";
    position: relative;
    top: -0.5px;
    margin: 0 8px 0 2px;
  }
  .ant-descriptions-item-label.ant-descriptions-item-no-colon::after {
    content: " ";
  }
  .ant-descriptions-item-no-label::after {
    margin: 0;
    content: "";
  }
  .ant-descriptions-item-content {
    display: table-cell;
    flex: 1;
    overflow-wrap: break-word;
  }
  .ant-descriptions-item {
    padding-bottom: 0;
    vertical-align: top;
  }
  .ant-descriptions-item > span {
    display: inline-flex;
    align-items: baseline;
  }
  .ant-descriptions-item-container {
    display: flex;
  }
  .ant-descriptions-middle .ant-descriptions-row > td,
  .ant-descriptions-middle .ant-descriptions-row > th {
    padding-bottom: 12px;
  }
  .ant-descriptions-small .ant-descriptions-row > td,
  .ant-descriptions-small .ant-descriptions-row > th {
    padding-bottom: 8px;
  }
  .ant-descriptions-bordered .ant-descriptions-view {
    border: 1px solid #424242;
  }
  .ant-descriptions-bordered .ant-descriptions-view > table {
    table-layout: auto;
  }
  .ant-descriptions-bordered .ant-descriptions-item-content,
  .ant-descriptions-bordered .ant-descriptions-item-label {
    padding: 16px 24px;
    border-right: 1px solid #424242;
  }
  .ant-descriptions-bordered .ant-descriptions-item-content:last-child,
  .ant-descriptions-bordered .ant-descriptions-item-label:last-child {
    border-right: none;
  }
  .ant-descriptions-bordered .ant-descriptions-item-label {
    background-color: rgba(255, 255, 255, 0.04);
  }
  .ant-descriptions-bordered .ant-descriptions-item-label::after {
    display: none;
  }
  .ant-descriptions-bordered .ant-descriptions-row {
    border-bottom: 1px solid #424242;
  }
  .ant-descriptions-bordered .ant-descriptions-row:last-child {
    border-bottom: none;
  }
  .ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content,
  .ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-label {
    padding: 12px 24px;
  }
  .ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content,
  .ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label {
    padding: 8px 16px;
  }
  .ant-descriptions-rtl {
    direction: rtl;
  }
  .ant-descriptions-rtl .ant-descriptions-item-label::after {
    margin: 0 2px 0 8px;
  }
  .ant-divider {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    border-top: 1px solid rgba(255, 255, 255, 0.12);
  }
  .ant-divider-vertical {
    position: relative;
    top: -0.06em;
    display: inline-block;
    height: 0.9em;
    margin: 0 8px;
    vertical-align: middle;
    border-top: 0;
    border-left: 1px solid rgba(255, 255, 255, 0.12);
  }
  .ant-divider-horizontal {
    display: flex;
    clear: both;
    width: 100%;
    min-width: 100%;
    margin: 24px 0;
  }
  .ant-divider-horizontal.ant-divider-with-text {
    display: flex;
    margin: 16px 0;
    color: rgba(255, 255, 255, 0.85);
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    text-align: center;
    border-color: rgba(255, 255, 255, 0.12);
    border-top: 0;
  }
  .ant-divider-horizontal.ant-divider-with-text::after,
  .ant-divider-horizontal.ant-divider-with-text::before {
    position: relative;
    top: 50%;
    width: 50%;
    border-top: 1px solid transparent;
    border-top-color: inherit;
    border-bottom: 0;
    transform: translateY(50%);
    content: "";
  }
  .ant-divider-horizontal.ant-divider-with-text-left::before {
    top: 50%;
    width: 5%;
  }
  .ant-divider-horizontal.ant-divider-with-text-left::after,
  .ant-divider-horizontal.ant-divider-with-text-right::before {
    top: 50%;
    width: 95%;
  }
  .ant-divider-horizontal.ant-divider-with-text-right::after {
    top: 50%;
    width: 5%;
  }
  .ant-divider-inner-text {
    display: inline-block;
    padding: 0 1em;
  }
  .ant-divider-dashed {
    background: 0 0;
    border-color: rgba(255, 255, 255, 0.12);
    border-style: dashed;
    border-width: 1px 0 0;
  }
  .ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed {
    border-top: 0;
  }
  .ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::after,
  .ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::before {
    border-style: dashed none none;
  }
  .ant-divider-vertical.ant-divider-dashed {
    border-width: 0 0 0 1px;
  }
  .ant-divider-plain.ant-divider-with-text {
    color: rgba(255, 255, 255, 0.85);
    font-weight: 400;
    font-size: 14px;
  }
  .ant-divider-rtl {
    direction: rtl;
  }
  .ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left::before {
    width: 95%;
  }
  .ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left::after,
  .ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right::before {
    width: 5%;
  }
  .ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right::after {
    width: 95%;
  }
  .ant-drawer {
    position: fixed;
    z-index: 1000;
    width: 0%;
    height: 100%;
    transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s, width 0s ease 0.3s;
  }
  .ant-drawer > * {
    transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  }
  .ant-drawer-content-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .ant-drawer .ant-drawer-content {
    width: 100%;
    height: 100%;
  }
  .ant-drawer-left,
  .ant-drawer-right {
    top: 0;
    width: 0%;
    height: 100%;
  }
  .ant-drawer-left .ant-drawer-content-wrapper,
  .ant-drawer-right .ant-drawer-content-wrapper,
  .ant-empty-image img {
    height: 100%;
  }
  .ant-drawer-left.ant-drawer-open,
  .ant-drawer-right.ant-drawer-open {
    width: 100%;
    transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  }
  .ant-drawer-left,
  .ant-drawer-left .ant-drawer-content-wrapper {
    left: 0;
  }
  .ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.32), 9px 0 28px 0 rgba(0, 0, 0, 0.2), 12px 0 48px 16px rgba(0, 0, 0, 0.12);
  }
  .ant-drawer-right,
  .ant-drawer-right .ant-drawer-content-wrapper {
    right: 0;
  }
  .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05),
      -12px 0 48px 16px rgba(0, 0, 0, 0.03);
  }
  .ant-drawer-right.ant-drawer-open.no-mask {
    right: 1px;
    transform: translateX(1px);
  }
  .ant-drawer-bottom,
  .ant-drawer-top {
    left: 0;
    width: 100%;
    height: 0%;
  }
  .ant-drawer-bottom .ant-drawer-content-wrapper,
  .ant-drawer-top .ant-drawer-content-wrapper {
    width: 100%;
  }
  .ant-drawer-bottom.ant-drawer-open,
  .ant-drawer-top.ant-drawer-open {
    height: 100%;
    transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  }
  .ant-drawer-top {
    top: 0;
  }
  .ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.32), 0 9px 28px 0 rgba(0, 0, 0, 0.2), 0 12px 48px 16px rgba(0, 0, 0, 0.12);
  }
  .ant-drawer-bottom,
  .ant-drawer-bottom .ant-drawer-content-wrapper {
    bottom: 0;
  }
  .ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.32), 0 -9px 28px 0 rgba(0, 0, 0, 0.2),
      0 -12px 48px 16px rgba(0, 0, 0, 0.12);
  }
  .ant-drawer-bottom.ant-drawer-open.no-mask {
    bottom: 1px;
    transform: translateY(1px);
  }
  .ant-drawer.ant-drawer-open .ant-drawer-mask {
    height: 100%;
    opacity: 1;
    transition: none;
    animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
    pointer-events: auto;
  }
  .ant-drawer-title {
    margin: 0;
    color: rgba(255, 255, 255, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }
  .ant-drawer-content {
    position: relative;
    z-index: 1;
    overflow: auto;
    background-color: #212121;
    background-clip: padding-box;
    border: 0;
  }
  .ant-drawer-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    display: block;
    padding: 20px;
    color: rgba(255, 255, 255, 0.45);
    font-weight: 700;
    font-size: 16px;
    font-style: normal;
    line-height: 1;
    text-align: center;
    text-transform: none;
    text-decoration: none;
    background: 0 0;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;
    text-rendering: auto;
  }
  .ant-drawer-close:focus,
  .ant-drawer-close:hover {
    color: rgba(255, 255, 255, 0.75);
    text-decoration: none;
  }
  .ant-drawer-header-no-title .ant-drawer-close {
    margin-right: var(--scroll-bar);
    padding-right: calc(20px - var(--scroll-bar));
  }
  .ant-drawer-header {
    position: relative;
    padding: 16px 24px;
    border-bottom: 1px solid #424242;
    border-radius: 4px 4px 0 0;
  }
  .ant-drawer-header,
  .ant-drawer-header-no-title {
    color: rgba(255, 255, 255, 0.85);
    background: #212121;
  }
  .ant-drawer-wrapper-body {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
  }
  .ant-drawer-body {
    flex-grow: 1;
    padding: 24px;
    overflow: auto;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
  }
  .ant-drawer-footer {
    flex-shrink: 0;
    padding: 10px;
    border-top: 1px solid #424242;
  }
  .ant-drawer-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: rgba(0, 0, 0, 0.45);
    opacity: 0;
    filter: alpha(opacity=45);
    transition: opacity 0.3s linear, height 0s ease 0.3s;
    pointer-events: none;
  }
  .ant-drawer-open-content {
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
  }
  .ant-drawer .ant-picker-clear {
    background: #212121;
    background-color: #212121;
  }
  .ant-drawer .ant-anchor-wrapper,
  .ant-drawer .ant-card,
  .ant-drawer .ant-collapse-content,
  .ant-drawer .ant-slider-handle,
  .ant-drawer .ant-timeline-item-head {
    background-color: #212121;
  }
  .ant-drawer .ant-transfer-list-header {
    background: #212121;
    border-bottom: 1px solid #3a3a3a;
  }
  .ant-drawer .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-drawer tr.ant-table-expanded-row:hover > td,
  .ant-drawer tr.ant-table-expanded-row > td,
  .ant-modal tr.ant-table-expanded-row:hover > td,
  .ant-modal tr.ant-table-expanded-row > td,
  .ant-notification tr.ant-table-expanded-row:hover > td,
  .ant-notification tr.ant-table-expanded-row > td {
    background: #272727;
  }
  .ant-drawer .ant-table.ant-table-small thead > tr > th {
    background-color: #212121;
    border-bottom: 1px solid #3a3a3a;
  }
  .ant-drawer .ant-picker-calendar-full,
  .ant-drawer .ant-table,
  .ant-drawer .ant-table tbody > tr > td.ant-table-cell-fix-left,
  .ant-drawer .ant-table tbody > tr > td.ant-table-cell-fix-right {
    background-color: #212121;
  }
  .ant-drawer .ant-table .ant-table-row-expand-icon,
  .ant-drawer .ant-table.ant-table-bordered .ant-table-container,
  .ant-drawer .ant-table.ant-table-bordered .ant-table-footer,
  .ant-drawer .ant-table.ant-table-bordered .ant-table-title {
    border: 1px solid #3a3a3a;
  }
  .ant-drawer .ant-table tbody > tr > td,
  .ant-drawer .ant-table tfoot > tr > td,
  .ant-drawer .ant-table tfoot > tr > th {
    border-bottom: 1px solid #3a3a3a;
  }
  .ant-drawer .ant-table thead > tr > th {
    background-color: #272727;
    border-bottom: 1px solid #3a3a3a;
  }
  .ant-drawer .ant-table tbody > tr.ant-table-row:hover > td,
  .ant-modal .ant-table tbody > tr.ant-table-row:hover > td,
  .ant-notification .ant-table tbody > tr.ant-table-row:hover > td,
  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: #424242;
  }
  .ant-drawer .ant-table.ant-table-bordered .ant-table-cell-fix-right-first::after,
  .ant-drawer .ant-table.ant-table-bordered .ant-table-expanded-row-fixed::after,
  .ant-drawer .ant-table.ant-table-bordered tbody > tr > td,
  .ant-drawer .ant-table.ant-table-bordered tfoot > tr > td,
  .ant-drawer .ant-table.ant-table-bordered tfoot > tr > th,
  .ant-drawer .ant-table.ant-table-bordered thead > tr > th {
    border-right: 1px solid #3a3a3a;
  }
  .ant-drawer .ant-table .ant-table-filter-trigger-container-open {
    background-color: #525252;
  }
  .ant-drawer .ant-picker-calendar-full .ant-picker-panel {
    background-color: #212121;
  }
  .ant-drawer .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    border-top: 2px solid #3a3a3a;
  }
  .ant-drawer .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    background-color: #212121;
    border-bottom: 1px solid #212121;
  }
  .ant-drawer .ant-badge-count {
    box-shadow: 0 0 0 1px #212121;
  }
  .ant-drawer .ant-tree-show-line .ant-tree-switcher {
    background: #212121;
  }
  .ant-drawer-rtl {
    direction: rtl;
  }
  .ant-drawer-rtl .ant-drawer-close {
    right: auto;
    left: 0;
  }
  .ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
    color: #ab2229;
  }
  .ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
    color: #fff;
    background-color: #ab2229;
  }
  .ant-dropdown {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1050;
    display: block;
  }
  .ant-dropdown::before {
    position: absolute;
    top: -7px;
    right: 0;
    bottom: -7px;
    left: -7px;
    z-index: -9999;
    opacity: 0.0001;
    content: " ";
  }
  .ant-dropdown-wrap {
    position: relative;
  }
  .ant-dropdown-wrap .ant-btn > .anticon-down {
    display: inline-block;
    font-size: 10px;
  }
  .ant-dropdown-wrap .anticon-down::before {
    transition: transform 0.2s;
  }
  .ant-dropdown-wrap-open .anticon-down::before {
    transform: rotate(180deg);
  }
  .ant-col-24.ant-form-item-label > label::after,
  .ant-col-xl-24.ant-form-item-label > label::after,
  .ant-dropdown-hidden,
  .ant-dropdown-menu-hidden,
  .ant-form-vertical .ant-form-item-label > label::after {
    display: none;
  }
  .ant-dropdown-show-arrow.ant-dropdown-placement-topCenter,
  .ant-dropdown-show-arrow.ant-dropdown-placement-topLeft,
  .ant-dropdown-show-arrow.ant-dropdown-placement-topRight {
    padding-bottom: 10px;
  }
  .ant-dropdown-show-arrow.ant-dropdown-placement-bottomCenter,
  .ant-dropdown-show-arrow.ant-dropdown-placement-bottomLeft,
  .ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {
    padding-top: 10px;
  }
  .ant-dropdown-arrow {
    position: absolute;
    z-index: 1;
    display: block;
    width: 8.48528137px;
    height: 8.48528137px;
    background: 0 0;
    border-style: solid;
    border-width: 4.24264069px;
    transform: rotate(45deg);
  }
  .ant-dropdown-placement-topCenter > .ant-dropdown-arrow,
  .ant-dropdown-placement-topLeft > .ant-dropdown-arrow,
  .ant-dropdown-placement-topRight > .ant-dropdown-arrow {
    bottom: 6.2px;
    border-color: transparent #212121 #212121 transparent;
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  }
  .ant-dropdown-placement-topCenter > .ant-dropdown-arrow {
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
  .ant-dropdown-placement-topLeft > .ant-dropdown-arrow {
    left: 16px;
  }
  .ant-dropdown-placement-topRight > .ant-dropdown-arrow {
    right: 16px;
  }
  .ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow,
  .ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
  .ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
    top: 6px;
    border-color: #212121 transparent transparent #212121;
    box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
  }
  .ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow {
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
  .ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow {
    left: 16px;
  }
  .ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
    right: 16px;
  }
  .ant-dropdown-menu {
    position: relative;
    margin: 0;
    padding: 4px 0;
    text-align: left;
    list-style-type: none;
    background-color: #212121;
    background-clip: padding-box;
    border-radius: 4px;
    outline: 0;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
  }
  .ant-dropdown-menu-item-group-title {
    padding: 5px 12px;
    color: rgba(255, 255, 255, 0.45);
    transition: all 0.3s;
  }
  .ant-dropdown-menu-submenu-popup {
    position: absolute;
    z-index: 1050;
    background: 0 0;
    box-shadow: none;
  }
  .ant-dropdown-menu-submenu-popup > .ant-dropdown-menu {
    transform-origin: 0 0;
  }
  .ant-dropdown-menu-submenu-popup li,
  .ant-dropdown-menu-submenu-popup ul {
    list-style: none;
  }
  .ant-dropdown-menu-submenu-popup ul {
    margin-right: 0.3em;
    margin-left: 0.3em;
  }
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    clear: both;
    margin: 0;
    padding: 5px 12px;
    color: rgba(255, 255, 255, 0.85);
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    white-space: nowrap;
    cursor: pointer;
    transition: all 0.3s;
  }
  .ant-dropdown-menu-item > .anticon:first-child,
  .ant-dropdown-menu-item > span > .anticon:first-child,
  .ant-dropdown-menu-submenu-title > .anticon:first-child,
  .ant-dropdown-menu-submenu-title > span > .anticon:first-child {
    min-width: 12px;
    margin-right: 8px;
    font-size: 12px;
  }
  .ant-dropdown-menu-item > a,
  .ant-dropdown-menu-submenu-title > a {
    display: block;
    margin: -5px -12px;
    padding: 5px 12px;
    color: rgba(255, 255, 255, 0.85);
    transition: all 0.3s;
  }
  .ant-dropdown-menu-item > .anticon + span > a,
  .ant-dropdown-menu-submenu-title > .anticon + span > a {
    color: rgba(255, 255, 255, 0.85);
    transition: all 0.3s;
  }
  .ant-dropdown-menu-item-selected,
  .ant-dropdown-menu-item-selected > a,
  .ant-dropdown-menu-submenu-title-selected,
  .ant-dropdown-menu-submenu-title-selected > a {
    color: #2caad9;
    background-color: #1e2b31;
  }
  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-dropdown-menu-item-disabled,
  .ant-dropdown-menu-submenu-title-disabled {
    color: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
  }
  .ant-dropdown-menu-item-disabled:hover,
  .ant-dropdown-menu-submenu-title-disabled:hover,
  .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
  .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled
    .ant-dropdown-menu-submenu-title
    .ant-dropdown-menu-submenu-arrow-icon {
    color: rgba(255, 255, 255, 0.3);
    background-color: transparent;
    cursor: not-allowed;
  }
  .ant-dropdown-menu-item-divider,
  .ant-dropdown-menu-submenu-title-divider {
    height: 1px;
    margin: 4px 0;
    overflow: hidden;
    line-height: 0;
    background-color: #424242;
  }
  .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
  .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
    position: absolute;
    right: 8px;
  }
  .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
  .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
    margin-right: 0 !important;
    color: rgba(255, 255, 255, 0.45);
    font-style: normal;
    display: inline-block;
    font-size: 10px;
  }
  .ant-dropdown-menu-item-group-list {
    margin: 0 8px;
    padding: 0;
    list-style: none;
  }
  .ant-dropdown-menu-submenu-title {
    padding-right: 24px;
  }
  .ant-dropdown-menu-submenu-vertical {
    position: relative;
  }
  .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
    position: absolute;
    top: 0;
    left: 100%;
    min-width: 100%;
    margin-left: 4px;
    transform-origin: 0 0;
  }
  .ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
    color: #2caad9;
  }
  .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
  .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomLeft,
  .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomRight,
  .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
  .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomLeft,
  .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomRight {
    animation-name: antSlideUpIn;
  }
  .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
  .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topLeft,
  .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topRight,
  .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
  .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topLeft,
  .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topRight {
    animation-name: antSlideDownIn;
  }
  .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
  .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomLeft,
  .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomRight {
    animation-name: antSlideUpOut;
  }
  .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
  .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topLeft,
  .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topRight {
    animation-name: antSlideDownOut;
  }
  .ant-dropdown-button > .anticon.anticon-down,
  .ant-dropdown-link > .anticon.anticon-down,
  .ant-dropdown-trigger > .anticon.anticon-down {
    vertical-align: baseline;
    display: inline-block;
    font-size: 10px;
  }
  .ant-dropdown-button {
    white-space: nowrap;
  }
  .ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
    padding-right: 8px;
    padding-left: 8px;
  }
  .ant-dropdown-menu-dark,
  .ant-dropdown-menu-dark .ant-dropdown-menu {
    background: #212121;
  }
  .ant-dropdown-menu-dark .ant-dropdown-menu-item,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a .ant-dropdown-menu-submenu-arrow::after,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after,
  .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
  .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after {
    color: rgba(255, 255, 255, 0.65);
  }
  .ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
  .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover {
    color: #fff;
    background: 0 0;
  }
  .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
    color: #fff;
    background: #2caad9;
  }
  .ant-dropdown-rtl {
    direction: rtl;
  }
  .ant-dropdown-rtl.ant-dropdown::before {
    right: -7px;
    left: 0;
  }
  .ant-dropdown-menu.ant-dropdown-menu-rtl,
  .ant-dropdown-rtl .ant-dropdown-menu-item-group-title {
    direction: rtl;
    text-align: right;
  }
  .ant-dropdown-rtl .ant-dropdown-menu-item,
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
    text-align: right;
  }
  .ant-dropdown-rtl .ant-dropdown-menu-item > .anticon:first-child,
  .ant-dropdown-rtl .ant-dropdown-menu-item > span > .anticon:first-child,
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-title > .anticon:first-child,
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-title > span > .anticon:first-child {
    margin-right: 0;
    margin-left: 8px;
  }
  .ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
    right: auto;
    left: 8px;
  }
  .ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
    margin-left: 0 !important;
    transform: scaleX(-1);
  }
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
    padding-right: 12px;
    padding-left: 24px;
  }
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
    right: 100%;
    left: 0;
    margin-right: 4px;
    margin-left: 0;
  }
  .ant-empty {
    margin: 0 8px;
    font-size: 14px;
    line-height: 1.5715;
    text-align: center;
  }
  .ant-empty-image {
    height: 100px;
    margin-bottom: 8px;
  }
  .ant-empty-image svg {
    height: 100%;
    margin: auto;
  }
  .ant-empty-description {
    margin: 0;
  }
  .ant-empty-footer {
    margin-top: 16px;
  }
  .ant-empty-normal,
  .ant-empty-small {
    margin: 32px 0;
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-empty-normal .ant-empty-image {
    height: 40px;
  }
  .ant-empty-small {
    margin: 8px 0;
  }
  .ant-empty-small .ant-empty-image {
    height: 35px;
  }
  .ant-empty-img-default-ellipse {
    fill-opacity: 0.08;
    fill: #fff;
  }
  .ant-empty-img-default-path-1 {
    fill: #262626;
  }
  .ant-empty-img-default-path-2 {
    fill: url(#linearGradient-1);
  }
  .ant-empty-img-default-path-3 {
    fill: #595959;
  }
  .ant-empty-img-default-path-4 {
    fill: #434343;
  }
  .ant-empty-img-default-path-5 {
    fill: #595959;
  }
  .ant-empty-img-default-g {
    fill: #434343;
  }
  .ant-empty-img-simple-ellipse {
    fill: #fff;
    fill-opacity: 0.08;
  }
  .ant-empty-img-simple-g {
    stroke: #434343;
  }
  .ant-empty-img-simple-path {
    fill: #262626;
    stroke: #434343;
  }
  .ant-empty-rtl {
    direction: rtl;
  }
  .ant-form select[multiple],
  .ant-form select[size],
  .ant-form-item .ant-mentions,
  .ant-form-item textarea.ant-input,
  .ant-form-vertical .ant-form-item-label > label {
    height: auto;
  }
  .ant-form-item .ant-upload {
    background: 0 0;
  }
  .ant-form-item .ant-upload.ant-upload-drag {
    background: rgba(255, 255, 255, 0.04);
  }
  .ant-form-item input[type="checkbox"],
  .ant-form-item input[type="radio"] {
    width: 14px;
    height: 14px;
  }
  .ant-form-item .ant-checkbox-inline,
  .ant-form-item .ant-radio-inline {
    display: inline-block;
    margin-left: 8px;
    font-weight: 400;
    vertical-align: middle;
    cursor: pointer;
  }
  .ant-form-item .ant-checkbox-inline:first-child,
  .ant-form-item .ant-radio-inline:first-child {
    margin-left: 0;
  }
  .ant-form-item .ant-checkbox-vertical,
  .ant-form-item .ant-radio-vertical {
    display: block;
  }
  .ant-form-item .ant-checkbox-vertical + .ant-checkbox-vertical,
  .ant-form-item .ant-radio-vertical + .ant-radio-vertical {
    margin-left: 0;
  }
  .ant-form-item .ant-input-number + .ant-form-text {
    margin-left: 8px;
  }
  .ant-form-item .ant-input-number-handler-wrap {
    z-index: 2;
  }
  .ant-form-item .ant-cascader-picker,
  .ant-form-item .ant-select {
    width: 100%;
  }
  .ant-form-item .ant-input-group .ant-cascader-picker,
  .ant-form-item .ant-input-group .ant-select,
  tr.ant-table-expanded-row .ant-descriptions-view table {
    width: auto;
  }
  .ant-form-inline {
    display: flex;
    flex-wrap: wrap;
  }
  .ant-form-inline .ant-form-item {
    flex: none;
    flex-wrap: nowrap;
    margin-right: 16px;
    margin-bottom: 0;
  }
  .ant-form-inline .ant-form-item-with-help {
    margin-bottom: 24px;
  }
  .ant-form-inline .ant-form-item > .ant-form-item-control,
  .ant-form-inline .ant-form-item > .ant-form-item-label {
    display: inline-block;
    vertical-align: top;
  }
  .ant-form-inline .ant-form-item > .ant-form-item-label {
    flex: none;
  }
  .ant-form-inline .ant-form-item .ant-form-item-has-feedback,
  .ant-form-inline .ant-form-item .ant-form-text,
  .ant-input-number-handler-down-inner svg,
  .ant-input-number-handler-up-inner svg {
    display: inline-block;
  }
  .ant-form-horizontal .ant-form-item-label {
    flex-grow: 0;
  }
  .ant-form-horizontal .ant-form-item-control {
    flex: 1 1 0;
  }
  .ant-form-vertical .ant-form-item {
    flex-direction: column;
  }
  .ant-col-24.ant-form-item-label,
  .ant-col-xl-24.ant-form-item-label,
  .ant-form-vertical .ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .ant-col-24.ant-form-item-label > label,
  .ant-col-xl-24.ant-form-item-label > label,
  .ant-form-vertical .ant-form-item-label > label {
    margin: 0;
  }
  .ant-form-rtl.ant-col-24.ant-form-item-label,
  .ant-form-rtl.ant-col-xl-24.ant-form-item-label,
  .ant-form-rtl.ant-form-vertical .ant-form-item-label {
    text-align: right;
  }
  @media (max-width: 575px) {
    .ant-form-item .ant-form-item-label {
      padding: 0 0 8px;
      line-height: 1.5715;
      white-space: initial;
      text-align: left;
    }
    .ant-col-xs-24.ant-form-item-label > label,
    .ant-form-item .ant-form-item-label > label {
      margin: 0;
    }
    .ant-col-xs-24.ant-form-item-label > label::after,
    .ant-form-item .ant-form-item-label > label::after {
      display: none;
    }
    .ant-form-rtl.ant-form-item .ant-form-item-label {
      text-align: right;
    }
    .ant-form .ant-form-item {
      flex-wrap: wrap;
    }
    .ant-form .ant-form-item .ant-form-item-control,
    .ant-form .ant-form-item .ant-form-item-label {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .ant-col-xs-24.ant-form-item-label {
      padding: 0 0 8px;
      line-height: 1.5715;
      white-space: initial;
      text-align: left;
    }
    .ant-form-rtl.ant-col-xs-24.ant-form-item-label {
      text-align: right;
    }
  }
  @media (max-width: 767px) {
    .ant-col-sm-24.ant-form-item-label {
      padding: 0 0 8px;
      line-height: 1.5715;
      white-space: initial;
      text-align: left;
    }
    .ant-col-sm-24.ant-form-item-label > label {
      margin: 0;
    }
    .ant-col-sm-24.ant-form-item-label > label::after {
      display: none;
    }
    .ant-form-rtl.ant-col-sm-24.ant-form-item-label {
      text-align: right;
    }
  }
  @media (max-width: 991px) {
    .ant-col-md-24.ant-form-item-label {
      padding: 0 0 8px;
      line-height: 1.5715;
      white-space: initial;
      text-align: left;
    }
    .ant-col-md-24.ant-form-item-label > label {
      margin: 0;
    }
    .ant-col-md-24.ant-form-item-label > label::after {
      display: none;
    }
    .ant-form-rtl.ant-col-md-24.ant-form-item-label {
      text-align: right;
    }
  }
  @media (max-width: 1199px) {
    .ant-col-lg-24.ant-form-item-label {
      padding: 0 0 8px;
      line-height: 1.5715;
      white-space: initial;
      text-align: left;
    }
    .ant-col-lg-24.ant-form-item-label > label {
      margin: 0;
    }
    .ant-col-lg-24.ant-form-item-label > label::after {
      display: none;
    }
    .ant-form-rtl.ant-col-lg-24.ant-form-item-label {
      text-align: right;
    }
  }
  @media (max-width: 1599px) {
    .ant-col-xl-24.ant-form-item-label {
      padding: 0 0 8px;
      line-height: 1.5715;
      white-space: initial;
      text-align: left;
    }
    .ant-col-xl-24.ant-form-item-label > label {
      margin: 0;
    }
    .ant-col-xl-24.ant-form-item-label > label::after {
      display: none;
    }
    .ant-form-rtl.ant-col-xl-24.ant-form-item-label {
      text-align: right;
    }
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    color: #ab2229;
  }
  .ant-form-item-explain.ant-form-item-explain-warning {
    color: #d99816;
  }
  .ant-form-item-has-feedback .ant-input {
    padding-right: 24px;
  }
  .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 18px;
  }
  .ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
    right: 28px;
  }
  .ant-form-item-has-feedback .ant-switch {
    margin: 2px 0 4px;
  }
  .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
  .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear,
  .ant-form-item-has-feedback > .ant-select .ant-select-arrow,
  .ant-form-item-has-feedback > .ant-select .ant-select-clear {
    right: 32px;
  }
  .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value,
  .ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value {
    padding-right: 42px;
  }
  .ant-form-item-has-feedback .ant-cascader-picker-arrow {
    margin-right: 19px;
  }
  .ant-form-item-has-feedback .ant-cascader-picker-clear {
    right: 32px;
  }
  .ant-form-item-has-feedback .ant-picker,
  .ant-form-item-has-feedback .ant-picker-large {
    padding-right: 29.2px;
  }
  .ant-form-item-has-feedback .ant-picker-small {
    padding-right: 25.2px;
  }
  .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
  .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
  .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
  .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 1;
    width: 32px;
    height: 20px;
    margin-top: -10px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    visibility: visible;
    animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    pointer-events: none;
  }
  .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon svg,
  .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon svg,
  .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon svg,
  .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
  .ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon {
    color: #4bac1b;
    animation-name: diffZoomIn1 !important;
  }
  .ant-form-item-has-warning .ant-form-item-split {
    color: #d99816;
  }
  .ant-form-item-has-warning .ant-input,
  .ant-form-item-has-warning .ant-input-affix-wrapper,
  .ant-form-item-has-warning .ant-input-affix-wrapper:hover,
  .ant-form-item-has-warning .ant-input:hover {
    border-color: #d99816;
  }
  .ant-form-item-has-warning .ant-input-affix-wrapper-focused,
  .ant-form-item-has-warning .ant-input-affix-wrapper:focus,
  .ant-form-item-has-warning .ant-input-focused,
  .ant-form-item-has-warning .ant-input:focus {
    border-color: #d99816;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(217, 152, 22, 0.2);
  }
  .ant-form-item-has-warning .ant-input-affix-wrapper:not(.ant-form-item-has-warning .ant-input-affix-wrapper-disabled),
  .ant-form-item-has-warning .ant-input:not(.ant-form-item-has-warning .ant-input-disabled) {
    background-color: transparent;
  }
  .ant-form-item-has-error .ant-input-affix-wrapper input:focus,
  .ant-form-item-has-warning .ant-input-affix-wrapper input:focus {
    box-shadow: none !important;
  }
  .ant-form-item-has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
    border-color: #d99816;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(217, 152, 22, 0.2);
  }
  .ant-form-item-has-warning .ant-input-prefix {
    color: #d99816;
  }
  .ant-form-item-has-warning .ant-input-group-addon {
    color: #d99816;
    border-color: #d99816;
  }
  .ant-form-item-has-warning .has-feedback {
    color: #d99816;
  }
  .ant-form-item-has-warning.ant-form-item-has-feedback .ant-form-item-children-icon {
    color: #d99816;
    animation-name: diffZoomIn3 !important;
  }
  .ant-form-item-has-warning .ant-select:not(.ant-select-borderless) .ant-select-selector {
    border-color: #d99816 !important;
  }
  .ant-form-item-has-warning .ant-select:not(.ant-select-borderless).ant-select-focused .ant-select-selector,
  .ant-form-item-has-warning .ant-select:not(.ant-select-borderless).ant-select-open .ant-select-selector {
    border-color: #d99816;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(217, 152, 22, 0.2);
  }
  .ant-form-item-has-warning .ant-input-number,
  .ant-form-item-has-warning .ant-picker {
    border-color: #d99816;
  }
  .ant-form-item-has-warning .ant-input-number-focused,
  .ant-form-item-has-warning .ant-input-number:focus,
  .ant-form-item-has-warning .ant-picker-focused,
  .ant-form-item-has-warning .ant-picker:focus {
    border-color: #d99816;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(217, 152, 22, 0.2);
  }
  .ant-form-item-has-warning .ant-input-number:not([disabled]):hover,
  .ant-form-item-has-warning .ant-picker:not([disabled]):hover {
    border-color: #d99816;
  }
  .ant-form-item-has-warning .ant-cascader-picker:focus .ant-cascader-input {
    border-color: #d99816;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(217, 152, 22, 0.2);
  }
  .ant-form-item-has-error .ant-form-item-split {
    color: #ab2229;
  }
  .ant-form-item-has-error .ant-input,
  .ant-form-item-has-error .ant-input-affix-wrapper,
  .ant-form-item-has-error .ant-input-affix-wrapper:hover,
  .ant-form-item-has-error .ant-input:hover {
    border-color: #ab2229;
  }
  .ant-form-item-has-error .ant-input-affix-wrapper-focused,
  .ant-form-item-has-error .ant-input-affix-wrapper:focus,
  .ant-form-item-has-error .ant-input-focused,
  .ant-form-item-has-error .ant-input:focus {
    border-color: #ab2229;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(171, 34, 41, 0.2);
  }
  .ant-form-item-has-error .ant-input-affix-wrapper:not(.ant-form-item-has-error .ant-input-affix-wrapper-disabled),
  .ant-form-item-has-error .ant-input:not(.ant-form-item-has-error .ant-input-disabled) {
    background-color: transparent;
  }
  .ant-form-item-has-error .ant-calendar-picker-open .ant-calendar-picker-input {
    border-color: #ab2229;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(171, 34, 41, 0.2);
  }
  .ant-form-item-has-error .ant-input-prefix {
    color: #ab2229;
  }
  .ant-form-item-has-error .ant-input-group-addon {
    color: #ab2229;
    border-color: #ab2229;
  }
  .ant-form-item-has-error .has-feedback {
    color: #ab2229;
  }
  .ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon {
    color: #ab2229;
    animation-name: diffZoomIn2 !important;
  }
  .ant-form-item-has-error .ant-select:not(.ant-select-borderless) .ant-select-selector {
    border-color: #ab2229 !important;
  }
  .ant-form-item-has-error .ant-select:not(.ant-select-borderless).ant-select-focused .ant-select-selector,
  .ant-form-item-has-error .ant-select:not(.ant-select-borderless).ant-select-open .ant-select-selector {
    border-color: #ab2229;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(171, 34, 41, 0.2);
  }
  .ant-form-item-has-error
    .ant-input-group-addon
    .ant-select.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    border: 0;
  }
  .ant-form-item-has-error .ant-select.ant-select-auto-complete .ant-input:focus {
    border-color: #ab2229;
  }
  .ant-form-item-has-error .ant-input-number,
  .ant-form-item-has-error .ant-picker {
    border-color: #ab2229;
  }
  .ant-form-item-has-error .ant-input-number-focused,
  .ant-form-item-has-error .ant-input-number:focus,
  .ant-form-item-has-error .ant-picker-focused,
  .ant-form-item-has-error .ant-picker:focus {
    border-color: #ab2229;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(171, 34, 41, 0.2);
  }
  .ant-form-item-has-error .ant-input-number:not([disabled]):hover,
  .ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor,
  .ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover,
  .ant-form-item-has-error .ant-picker:not([disabled]):hover {
    border-color: #ab2229;
  }
  .ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus,
  .ant-form-item-has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor {
    border-color: #ab2229;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(171, 34, 41, 0.2);
  }
  .ant-form-item-has-error .ant-cascader-picker:focus .ant-cascader-input {
    border-color: #ab2229;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(171, 34, 41, 0.2);
  }
  .ant-form-item-has-error .ant-transfer-list {
    border-color: #ab2229;
  }
  .ant-form-item-has-error .ant-transfer-list-search:not([disabled]) {
    border-color: #6f6c6c;
  }
  .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):hover {
    border-color: #287a99;
    border-right-width: 1px !important;
  }
  .ant-input-rtl .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):hover {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
    border-color: #2caad9;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(44, 170, 217, 0.2);
  }
  .ant-input-rtl .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ant-form-item-has-error .ant-radio-button-wrapper {
    border-color: #ab2229 !important;
  }
  .ant-form-item-has-error .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: #ab2229;
  }
  .ant-form-item-is-validating.ant-form-item-has-feedback .ant-form-item-children-icon {
    display: inline-block;
    color: #2caad9;
  }
  .ant-form {
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
  }
  .ant-form legend {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    padding: 0;
    color: rgba(255, 255, 255, 0.45);
    font-size: 16px;
    line-height: inherit;
    border: 0;
    border-bottom: 1px solid #6f6c6c;
  }
  .ant-form,
  .ant-form input[type="search"] {
    box-sizing: border-box;
  }
  .ant-form input[type="checkbox"],
  .ant-form input[type="radio"] {
    line-height: normal;
  }
  .ant-form input[type="file"] {
    display: block;
  }
  .ant-form input[type="range"] {
    display: block;
    width: 100%;
  }
  .ant-form input[type="checkbox"]:focus,
  .ant-form input[type="file"]:focus,
  .ant-form input[type="radio"]:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
  .ant-form output,
  .ant-form-item {
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    line-height: 1.5715;
  }
  .ant-form output {
    display: block;
    padding-top: 15px;
  }
  .ant-form .ant-form-text {
    display: inline-block;
    padding-right: 8px;
  }
  .ant-form-small .ant-form-item-label > label {
    height: 24px;
  }
  .ant-form-small .ant-form-item-control-input {
    min-height: 24px;
  }
  .ant-form-large .ant-form-item-label > label {
    height: 40px;
  }
  .ant-form-large .ant-form-item-control-input {
    min-height: 40px;
  }
  .ant-form-item {
    box-sizing: border-box;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    margin: 0 0 24px;
    vertical-align: top;
  }
  .ant-form-item-with-help {
    margin-bottom: 0;
  }
  .ant-form-hide-required-mark .ant-form-item-label > label .ant-form-item-optional,
  .ant-form-item-hidden,
  .ant-form-item-hidden.ant-row {
    display: none;
  }
  .ant-form-item-label {
    display: inline-block;
    flex-grow: 0;
    overflow: hidden;
    white-space: nowrap;
    text-align: right;
    vertical-align: middle;
  }
  .ant-form-item-label-left {
    text-align: left;
  }
  .ant-form-item-label > label {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 32px;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
  }
  .ant-form-item-label > label > .anticon {
    font-size: 14px;
    vertical-align: top;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: inline-block;
    margin-right: 4px;
    color: #ab2229;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
  .ant-form-hide-required-mark
    .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  .ant-form-item-label > label .ant-form-item-optional {
    display: inline-block;
    margin-left: 4px;
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-form-item-label > label .ant-form-item-tooltip {
    -ms-writing-mode: lr-tb;
    writing-mode: horizontal-tb;
    -webkit-margin-start: 4px;
    margin-inline-start: 4px;
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-form-item-label > label.ant-form-item-no-colon::after {
    content: " ";
  }
  .ant-form-item-control {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .ant-form-item-control:first-child:not([class^="ant-col-"]):not([class*=" ant-col-"]) {
    width: 100%;
  }
  .ant-form-item-control-input {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 32px;
  }
  .ant-form-item-control-input-content {
    flex: auto;
    max-width: 100%;
  }
  .ant-form-item-explain,
  .ant-form-item-extra {
    clear: both;
    min-height: 24px;
    padding-top: 0;
    color: rgba(255, 255, 255, 0.45);
    font-size: 14px;
    line-height: 1.5715;
    transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .show-help-appear,
  .show-help-enter,
  .show-help-leave {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .show-help-appear.show-help-appear-active,
  .show-help-enter.show-help-enter-active {
    animation-name: antShowHelpIn;
    animation-play-state: running;
  }
  .show-help-leave.show-help-leave-active {
    animation-name: antShowHelpOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .show-help-appear,
  .show-help-enter {
    opacity: 0;
  }
  .show-help-appear,
  .show-help-enter,
  .show-help-leave {
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-form-rtl {
    direction: rtl;
  }
  .ant-form-rtl .ant-form-item-label {
    text-align: left;
  }
  .ant-form-rtl .ant-form-item-label > label.ant-form-item-required::before {
    margin-right: 0;
    margin-left: 4px;
  }
  .ant-form-rtl .ant-form-item-label > label::after {
    margin: 0 2px 0 8px;
  }
  .ant-form-rtl .ant-form-item-label > label .ant-form-item-optional {
    margin-right: 4px;
    margin-left: 0;
  }
  .ant-col-rtl .ant-form-item-control:first-child {
    width: 100%;
  }
  .ant-form-rtl .ant-form-item-has-feedback .ant-input {
    padding-right: 11px;
    padding-left: 24px;
  }
  .ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 11px;
    padding-left: 18px;
  }
  .ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input {
    padding: 0;
  }
  .ant-form-rtl .ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
    right: auto;
    left: 28px;
  }
  .ant-form-rtl .ant-form-item-has-feedback .ant-input-number {
    padding-left: 18px;
  }
  .ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
  .ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear,
  .ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-arrow,
  .ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-clear {
    right: auto;
    left: 32px;
  }
  .ant-form-rtl
    .ant-form-item-has-feedback
    :not(.ant-input-group-addon)
    > .ant-select
    .ant-select-selection-selected-value,
  .ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value {
    padding-right: 0;
    padding-left: 42px;
  }
  .ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-arrow {
    margin-right: 0;
    margin-left: 19px;
  }
  .ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-clear {
    right: auto;
    left: 32px;
  }
  .ant-form-rtl .ant-form-item-has-feedback .ant-picker,
  .ant-form-rtl .ant-form-item-has-feedback .ant-picker-large {
    padding-right: 11px;
    padding-left: 29.2px;
  }
  .ant-form-rtl .ant-form-item-has-feedback .ant-picker-small {
    padding-right: 7px;
    padding-left: 25.2px;
  }
  .ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
  .ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
  .ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
  .ant-form-rtl .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
    right: auto;
    left: 0;
  }
  .ant-form-rtl.ant-form-inline .ant-form-item {
    margin-right: 0;
    margin-left: 16px;
  }
  .ant-row {
    flex-flow: row wrap;
  }
  .ant-row,
  .ant-row::after,
  .ant-row::before {
    display: flex;
  }
  .ant-row-start {
    justify-content: flex-start;
  }
  .ant-row-center {
    justify-content: center;
  }
  .ant-row-end {
    justify-content: flex-end;
  }
  .ant-row-space-between {
    justify-content: space-between;
  }
  .ant-row-space-around {
    justify-content: space-around;
  }
  .ant-row-top {
    align-items: flex-start;
  }
  .ant-row-middle {
    align-items: center;
  }
  .ant-row-bottom {
    align-items: flex-end;
  }
  .ant-col {
    position: relative;
    max-width: 100%;
    min-height: 1px;
  }
  .ant-col-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-push-24 {
    left: 100%;
  }
  .ant-col-pull-24 {
    right: 100%;
  }
  .ant-col-offset-24 {
    margin-left: 100%;
  }
  .ant-col-order-24 {
    order: 24;
  }
  .ant-col-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-push-23 {
    left: 95.83333333%;
  }
  .ant-col-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-order-23 {
    order: 23;
  }
  .ant-col-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-push-22 {
    left: 91.66666667%;
  }
  .ant-col-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-order-22 {
    order: 22;
  }
  .ant-col-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-push-21 {
    left: 87.5%;
  }
  .ant-col-pull-21 {
    right: 87.5%;
  }
  .ant-col-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-order-21 {
    order: 21;
  }
  .ant-col-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-push-20 {
    left: 83.33333333%;
  }
  .ant-col-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-order-20 {
    order: 20;
  }
  .ant-col-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-push-19 {
    left: 79.16666667%;
  }
  .ant-col-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-order-19 {
    order: 19;
  }
  .ant-col-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-push-18 {
    left: 75%;
  }
  .ant-col-pull-18 {
    right: 75%;
  }
  .ant-col-offset-18 {
    margin-left: 75%;
  }
  .ant-col-order-18 {
    order: 18;
  }
  .ant-col-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-push-17 {
    left: 70.83333333%;
  }
  .ant-col-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-order-17 {
    order: 17;
  }
  .ant-col-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-push-16 {
    left: 66.66666667%;
  }
  .ant-col-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-order-16 {
    order: 16;
  }
  .ant-col-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-push-15 {
    left: 62.5%;
  }
  .ant-col-pull-15 {
    right: 62.5%;
  }
  .ant-col-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-order-15 {
    order: 15;
  }
  .ant-col-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-push-14 {
    left: 58.33333333%;
  }
  .ant-col-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-order-14 {
    order: 14;
  }
  .ant-col-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-push-13 {
    left: 54.16666667%;
  }
  .ant-col-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-order-13 {
    order: 13;
  }
  .ant-col-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-push-12 {
    left: 50%;
  }
  .ant-col-pull-12 {
    right: 50%;
  }
  .ant-col-offset-12 {
    margin-left: 50%;
  }
  .ant-col-order-12 {
    order: 12;
  }
  .ant-col-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-push-11 {
    left: 45.83333333%;
  }
  .ant-col-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-order-11 {
    order: 11;
  }
  .ant-col-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-push-10 {
    left: 41.66666667%;
  }
  .ant-col-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-order-10 {
    order: 10;
  }
  .ant-col-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-push-9 {
    left: 37.5%;
  }
  .ant-col-pull-9 {
    right: 37.5%;
  }
  .ant-col-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-order-9 {
    order: 9;
  }
  .ant-col-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-push-8 {
    left: 33.33333333%;
  }
  .ant-col-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-order-8 {
    order: 8;
  }
  .ant-col-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-push-7 {
    left: 29.16666667%;
  }
  .ant-col-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-order-7 {
    order: 7;
  }
  .ant-col-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-push-6 {
    left: 25%;
  }
  .ant-col-pull-6 {
    right: 25%;
  }
  .ant-col-offset-6 {
    margin-left: 25%;
  }
  .ant-col-order-6 {
    order: 6;
  }
  .ant-col-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-push-5 {
    left: 20.83333333%;
  }
  .ant-col-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-order-5 {
    order: 5;
  }
  .ant-col-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-push-4 {
    left: 16.66666667%;
  }
  .ant-col-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-order-4 {
    order: 4;
  }
  .ant-col-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-push-3 {
    left: 12.5%;
  }
  .ant-col-pull-3 {
    right: 12.5%;
  }
  .ant-col-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-order-3 {
    order: 3;
  }
  .ant-col-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-push-2 {
    left: 8.33333333%;
  }
  .ant-col-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-order-2 {
    order: 2;
  }
  .ant-col-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-push-1 {
    left: 4.16666667%;
  }
  .ant-col-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-order-1 {
    order: 1;
  }
  .ant-col-0 {
    display: none;
  }
  .ant-col-offset-0 {
    margin-left: 0;
  }
  .ant-col-order-0 {
    order: 0;
  }
  .ant-col-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
  .ant-col-xs-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xs-push-24 {
    left: 100%;
  }
  .ant-col-xs-pull-24 {
    right: 100%;
  }
  .ant-col-xs-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xs-order-24 {
    order: 24;
  }
  .ant-col-xs-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-xs-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xs-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xs-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xs-order-23 {
    order: 23;
  }
  .ant-col-xs-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-xs-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xs-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xs-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xs-order-22 {
    order: 22;
  }
  .ant-col-xs-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-xs-push-21 {
    left: 87.5%;
  }
  .ant-col-xs-pull-21 {
    right: 87.5%;
  }
  .ant-col-xs-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xs-order-21 {
    order: 21;
  }
  .ant-col-xs-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-xs-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xs-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xs-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xs-order-20 {
    order: 20;
  }
  .ant-col-xs-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-xs-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xs-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xs-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xs-order-19 {
    order: 19;
  }
  .ant-col-xs-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-xs-push-18 {
    left: 75%;
  }
  .ant-col-xs-pull-18 {
    right: 75%;
  }
  .ant-col-xs-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xs-order-18 {
    order: 18;
  }
  .ant-col-xs-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-xs-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xs-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xs-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xs-order-17 {
    order: 17;
  }
  .ant-col-xs-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-xs-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xs-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xs-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xs-order-16 {
    order: 16;
  }
  .ant-col-xs-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-xs-push-15 {
    left: 62.5%;
  }
  .ant-col-xs-pull-15 {
    right: 62.5%;
  }
  .ant-col-xs-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xs-order-15 {
    order: 15;
  }
  .ant-col-xs-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-xs-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xs-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xs-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xs-order-14 {
    order: 14;
  }
  .ant-col-xs-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-xs-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xs-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xs-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xs-order-13 {
    order: 13;
  }
  .ant-col-xs-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-xs-push-12 {
    left: 50%;
  }
  .ant-col-xs-pull-12 {
    right: 50%;
  }
  .ant-col-xs-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xs-order-12 {
    order: 12;
  }
  .ant-col-xs-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-xs-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xs-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xs-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xs-order-11 {
    order: 11;
  }
  .ant-col-xs-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-xs-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xs-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xs-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xs-order-10 {
    order: 10;
  }
  .ant-col-xs-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-xs-push-9 {
    left: 37.5%;
  }
  .ant-col-xs-pull-9 {
    right: 37.5%;
  }
  .ant-col-xs-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xs-order-9 {
    order: 9;
  }
  .ant-col-xs-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-xs-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xs-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xs-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xs-order-8 {
    order: 8;
  }
  .ant-col-xs-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-xs-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xs-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xs-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xs-order-7 {
    order: 7;
  }
  .ant-col-xs-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-xs-push-6 {
    left: 25%;
  }
  .ant-col-xs-pull-6 {
    right: 25%;
  }
  .ant-col-xs-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xs-order-6 {
    order: 6;
  }
  .ant-col-xs-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-xs-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xs-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xs-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xs-order-5 {
    order: 5;
  }
  .ant-col-xs-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-xs-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xs-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xs-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xs-order-4 {
    order: 4;
  }
  .ant-col-xs-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-xs-push-3 {
    left: 12.5%;
  }
  .ant-col-xs-pull-3 {
    right: 12.5%;
  }
  .ant-col-xs-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xs-order-3 {
    order: 3;
  }
  .ant-col-xs-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-xs-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xs-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xs-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xs-order-2 {
    order: 2;
  }
  .ant-col-xs-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-xs-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xs-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xs-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xs-order-1 {
    order: 1;
  }
  .ant-col-xs-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xs-push-0 {
    left: auto;
  }
  .ant-col-push-0.ant-col-rtl,
  .ant-col-xs-pull-0 {
    right: auto;
  }
  .ant-col-xs-offset-0 {
    margin-left: 0;
  }
  .ant-col-xs-order-0 {
    order: 0;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xs-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-xs-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xs-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-xs-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-xs-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-xs-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-xs-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-xs-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-xs-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-xs-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-xs-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-xs-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-xs-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-xs-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-xs-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-xs-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-xs-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-xs-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-xs-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-xs-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-xs-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-xs-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-xs-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-xs-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-xs-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-xs-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-xs-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-xs-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-xs-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-xs-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-xs-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-xs-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-xs-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-xs-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-xs-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-xs-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-xs-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-xs-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-xs-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-xs-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-xs-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-xs-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-xs-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-xs-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-xs-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-xs-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-xs-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-xs-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-xs-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-xs-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-xs-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-xs-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-xs-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-xs-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-xs-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-xs-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-xs-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-xs-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-xs-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-xs-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-xs-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-xs-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-xs-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-xs-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-xs-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-xs-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-xs-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-xs-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-xs-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-xs-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-xs-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-xs-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-xs-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-xs-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-xs-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
  @media (min-width: 576px) {
    .ant-col-sm-24 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .ant-col-sm-push-24 {
      left: 100%;
    }
    .ant-col-sm-pull-24 {
      right: 100%;
    }
    .ant-col-sm-offset-24 {
      margin-left: 100%;
    }
    .ant-col-sm-order-24 {
      order: 24;
    }
    .ant-col-sm-23 {
      display: block;
      flex: 0 0 95.83333333%;
      max-width: 95.83333333%;
    }
    .ant-col-sm-push-23 {
      left: 95.83333333%;
    }
    .ant-col-sm-pull-23 {
      right: 95.83333333%;
    }
    .ant-col-sm-offset-23 {
      margin-left: 95.83333333%;
    }
    .ant-col-sm-order-23 {
      order: 23;
    }
    .ant-col-sm-22 {
      display: block;
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%;
    }
    .ant-col-sm-push-22 {
      left: 91.66666667%;
    }
    .ant-col-sm-pull-22 {
      right: 91.66666667%;
    }
    .ant-col-sm-offset-22 {
      margin-left: 91.66666667%;
    }
    .ant-col-sm-order-22 {
      order: 22;
    }
    .ant-col-sm-21 {
      display: block;
      flex: 0 0 87.5%;
      max-width: 87.5%;
    }
    .ant-col-sm-push-21 {
      left: 87.5%;
    }
    .ant-col-sm-pull-21 {
      right: 87.5%;
    }
    .ant-col-sm-offset-21 {
      margin-left: 87.5%;
    }
    .ant-col-sm-order-21 {
      order: 21;
    }
    .ant-col-sm-20 {
      display: block;
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%;
    }
    .ant-col-sm-push-20 {
      left: 83.33333333%;
    }
    .ant-col-sm-pull-20 {
      right: 83.33333333%;
    }
    .ant-col-sm-offset-20 {
      margin-left: 83.33333333%;
    }
    .ant-col-sm-order-20 {
      order: 20;
    }
    .ant-col-sm-19 {
      display: block;
      flex: 0 0 79.16666667%;
      max-width: 79.16666667%;
    }
    .ant-col-sm-push-19 {
      left: 79.16666667%;
    }
    .ant-col-sm-pull-19 {
      right: 79.16666667%;
    }
    .ant-col-sm-offset-19 {
      margin-left: 79.16666667%;
    }
    .ant-col-sm-order-19 {
      order: 19;
    }
    .ant-col-sm-18 {
      display: block;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .ant-col-sm-push-18 {
      left: 75%;
    }
    .ant-col-sm-pull-18 {
      right: 75%;
    }
    .ant-col-sm-offset-18 {
      margin-left: 75%;
    }
    .ant-col-sm-order-18 {
      order: 18;
    }
    .ant-col-sm-17 {
      display: block;
      flex: 0 0 70.83333333%;
      max-width: 70.83333333%;
    }
    .ant-col-sm-push-17 {
      left: 70.83333333%;
    }
    .ant-col-sm-pull-17 {
      right: 70.83333333%;
    }
    .ant-col-sm-offset-17 {
      margin-left: 70.83333333%;
    }
    .ant-col-sm-order-17 {
      order: 17;
    }
    .ant-col-sm-16 {
      display: block;
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }
    .ant-col-sm-push-16 {
      left: 66.66666667%;
    }
    .ant-col-sm-pull-16 {
      right: 66.66666667%;
    }
    .ant-col-sm-offset-16 {
      margin-left: 66.66666667%;
    }
    .ant-col-sm-order-16 {
      order: 16;
    }
    .ant-col-sm-15 {
      display: block;
      flex: 0 0 62.5%;
      max-width: 62.5%;
    }
    .ant-col-sm-push-15 {
      left: 62.5%;
    }
    .ant-col-sm-pull-15 {
      right: 62.5%;
    }
    .ant-col-sm-offset-15 {
      margin-left: 62.5%;
    }
    .ant-col-sm-order-15 {
      order: 15;
    }
    .ant-col-sm-14 {
      display: block;
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }
    .ant-col-sm-push-14 {
      left: 58.33333333%;
    }
    .ant-col-sm-pull-14 {
      right: 58.33333333%;
    }
    .ant-col-sm-offset-14 {
      margin-left: 58.33333333%;
    }
    .ant-col-sm-order-14 {
      order: 14;
    }
    .ant-col-sm-13 {
      display: block;
      flex: 0 0 54.16666667%;
      max-width: 54.16666667%;
    }
    .ant-col-sm-push-13 {
      left: 54.16666667%;
    }
    .ant-col-sm-pull-13 {
      right: 54.16666667%;
    }
    .ant-col-sm-offset-13 {
      margin-left: 54.16666667%;
    }
    .ant-col-sm-order-13 {
      order: 13;
    }
    .ant-col-sm-12 {
      display: block;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .ant-col-sm-push-12 {
      left: 50%;
    }
    .ant-col-sm-pull-12 {
      right: 50%;
    }
    .ant-col-sm-offset-12 {
      margin-left: 50%;
    }
    .ant-col-sm-order-12 {
      order: 12;
    }
    .ant-col-sm-11 {
      display: block;
      flex: 0 0 45.83333333%;
      max-width: 45.83333333%;
    }
    .ant-col-sm-push-11 {
      left: 45.83333333%;
    }
    .ant-col-sm-pull-11 {
      right: 45.83333333%;
    }
    .ant-col-sm-offset-11 {
      margin-left: 45.83333333%;
    }
    .ant-col-sm-order-11 {
      order: 11;
    }
    .ant-col-sm-10 {
      display: block;
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }
    .ant-col-sm-push-10 {
      left: 41.66666667%;
    }
    .ant-col-sm-pull-10 {
      right: 41.66666667%;
    }
    .ant-col-sm-offset-10 {
      margin-left: 41.66666667%;
    }
    .ant-col-sm-order-10 {
      order: 10;
    }
    .ant-col-sm-9 {
      display: block;
      flex: 0 0 37.5%;
      max-width: 37.5%;
    }
    .ant-col-sm-push-9 {
      left: 37.5%;
    }
    .ant-col-sm-pull-9 {
      right: 37.5%;
    }
    .ant-col-sm-offset-9 {
      margin-left: 37.5%;
    }
    .ant-col-sm-order-9 {
      order: 9;
    }
    .ant-col-sm-8 {
      display: block;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
    .ant-col-sm-push-8 {
      left: 33.33333333%;
    }
    .ant-col-sm-pull-8 {
      right: 33.33333333%;
    }
    .ant-col-sm-offset-8 {
      margin-left: 33.33333333%;
    }
    .ant-col-sm-order-8 {
      order: 8;
    }
    .ant-col-sm-7 {
      display: block;
      flex: 0 0 29.16666667%;
      max-width: 29.16666667%;
    }
    .ant-col-sm-push-7 {
      left: 29.16666667%;
    }
    .ant-col-sm-pull-7 {
      right: 29.16666667%;
    }
    .ant-col-sm-offset-7 {
      margin-left: 29.16666667%;
    }
    .ant-col-sm-order-7 {
      order: 7;
    }
    .ant-col-sm-6 {
      display: block;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .ant-col-sm-push-6 {
      left: 25%;
    }
    .ant-col-sm-pull-6 {
      right: 25%;
    }
    .ant-col-sm-offset-6 {
      margin-left: 25%;
    }
    .ant-col-sm-order-6 {
      order: 6;
    }
    .ant-col-sm-5 {
      display: block;
      flex: 0 0 20.83333333%;
      max-width: 20.83333333%;
    }
    .ant-col-sm-push-5 {
      left: 20.83333333%;
    }
    .ant-col-sm-pull-5 {
      right: 20.83333333%;
    }
    .ant-col-sm-offset-5 {
      margin-left: 20.83333333%;
    }
    .ant-col-sm-order-5 {
      order: 5;
    }
    .ant-col-sm-4 {
      display: block;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
    }
    .ant-col-sm-push-4 {
      left: 16.66666667%;
    }
    .ant-col-sm-pull-4 {
      right: 16.66666667%;
    }
    .ant-col-sm-offset-4 {
      margin-left: 16.66666667%;
    }
    .ant-col-sm-order-4 {
      order: 4;
    }
    .ant-col-sm-3 {
      display: block;
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
    .ant-col-sm-push-3 {
      left: 12.5%;
    }
    .ant-col-sm-pull-3 {
      right: 12.5%;
    }
    .ant-col-sm-offset-3 {
      margin-left: 12.5%;
    }
    .ant-col-sm-order-3 {
      order: 3;
    }
    .ant-col-sm-2 {
      display: block;
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%;
    }
    .ant-col-sm-push-2 {
      left: 8.33333333%;
    }
    .ant-col-sm-pull-2 {
      right: 8.33333333%;
    }
    .ant-col-sm-offset-2 {
      margin-left: 8.33333333%;
    }
    .ant-col-sm-order-2 {
      order: 2;
    }
    .ant-col-sm-1 {
      display: block;
      flex: 0 0 4.16666667%;
      max-width: 4.16666667%;
    }
    .ant-col-sm-push-1 {
      left: 4.16666667%;
    }
    .ant-col-sm-pull-1 {
      right: 4.16666667%;
    }
    .ant-col-sm-offset-1 {
      margin-left: 4.16666667%;
    }
    .ant-col-sm-order-1 {
      order: 1;
    }
    .ant-col-sm-0 {
      display: none;
    }
    .ant-col-push-0 {
      left: auto;
    }
    .ant-col-pull-0 {
      right: auto;
    }
    .ant-col-sm-push-0 {
      left: auto;
    }
    .ant-col-push-0.ant-col-rtl,
    .ant-col-sm-pull-0 {
      right: auto;
    }
    .ant-col-sm-offset-0 {
      margin-left: 0;
    }
    .ant-col-sm-order-0 {
      order: 0;
    }
    .ant-col-pull-0.ant-col-rtl {
      left: auto;
    }
    .ant-col-sm-push-0.ant-col-rtl {
      right: auto;
    }
    .ant-col-sm-pull-0.ant-col-rtl {
      left: auto;
    }
    .ant-col-sm-offset-0.ant-col-rtl {
      margin-right: 0;
    }
    .ant-col-sm-push-1.ant-col-rtl {
      right: 4.16666667%;
      left: auto;
    }
    .ant-col-sm-pull-1.ant-col-rtl {
      right: auto;
      left: 4.16666667%;
    }
    .ant-col-sm-offset-1.ant-col-rtl {
      margin-right: 4.16666667%;
      margin-left: 0;
    }
    .ant-col-sm-push-2.ant-col-rtl {
      right: 8.33333333%;
      left: auto;
    }
    .ant-col-sm-pull-2.ant-col-rtl {
      right: auto;
      left: 8.33333333%;
    }
    .ant-col-sm-offset-2.ant-col-rtl {
      margin-right: 8.33333333%;
      margin-left: 0;
    }
    .ant-col-sm-push-3.ant-col-rtl {
      right: 12.5%;
      left: auto;
    }
    .ant-col-sm-pull-3.ant-col-rtl {
      right: auto;
      left: 12.5%;
    }
    .ant-col-sm-offset-3.ant-col-rtl {
      margin-right: 12.5%;
      margin-left: 0;
    }
    .ant-col-sm-push-4.ant-col-rtl {
      right: 16.66666667%;
      left: auto;
    }
    .ant-col-sm-pull-4.ant-col-rtl {
      right: auto;
      left: 16.66666667%;
    }
    .ant-col-sm-offset-4.ant-col-rtl {
      margin-right: 16.66666667%;
      margin-left: 0;
    }
    .ant-col-sm-push-5.ant-col-rtl {
      right: 20.83333333%;
      left: auto;
    }
    .ant-col-sm-pull-5.ant-col-rtl {
      right: auto;
      left: 20.83333333%;
    }
    .ant-col-sm-offset-5.ant-col-rtl {
      margin-right: 20.83333333%;
      margin-left: 0;
    }
    .ant-col-sm-push-6.ant-col-rtl {
      right: 25%;
      left: auto;
    }
    .ant-col-sm-pull-6.ant-col-rtl {
      right: auto;
      left: 25%;
    }
    .ant-col-sm-offset-6.ant-col-rtl {
      margin-right: 25%;
      margin-left: 0;
    }
    .ant-col-sm-push-7.ant-col-rtl {
      right: 29.16666667%;
      left: auto;
    }
    .ant-col-sm-pull-7.ant-col-rtl {
      right: auto;
      left: 29.16666667%;
    }
    .ant-col-sm-offset-7.ant-col-rtl {
      margin-right: 29.16666667%;
      margin-left: 0;
    }
    .ant-col-sm-push-8.ant-col-rtl {
      right: 33.33333333%;
      left: auto;
    }
    .ant-col-sm-pull-8.ant-col-rtl {
      right: auto;
      left: 33.33333333%;
    }
    .ant-col-sm-offset-8.ant-col-rtl {
      margin-right: 33.33333333%;
      margin-left: 0;
    }
    .ant-col-sm-push-9.ant-col-rtl {
      right: 37.5%;
      left: auto;
    }
    .ant-col-sm-pull-9.ant-col-rtl {
      right: auto;
      left: 37.5%;
    }
    .ant-col-sm-offset-9.ant-col-rtl {
      margin-right: 37.5%;
      margin-left: 0;
    }
    .ant-col-sm-push-10.ant-col-rtl {
      right: 41.66666667%;
      left: auto;
    }
    .ant-col-sm-pull-10.ant-col-rtl {
      right: auto;
      left: 41.66666667%;
    }
    .ant-col-sm-offset-10.ant-col-rtl {
      margin-right: 41.66666667%;
      margin-left: 0;
    }
    .ant-col-sm-push-11.ant-col-rtl {
      right: 45.83333333%;
      left: auto;
    }
    .ant-col-sm-pull-11.ant-col-rtl {
      right: auto;
      left: 45.83333333%;
    }
    .ant-col-sm-offset-11.ant-col-rtl {
      margin-right: 45.83333333%;
      margin-left: 0;
    }
    .ant-col-sm-push-12.ant-col-rtl {
      right: 50%;
      left: auto;
    }
    .ant-col-sm-pull-12.ant-col-rtl {
      right: auto;
      left: 50%;
    }
    .ant-col-sm-offset-12.ant-col-rtl {
      margin-right: 50%;
      margin-left: 0;
    }
    .ant-col-sm-push-13.ant-col-rtl {
      right: 54.16666667%;
      left: auto;
    }
    .ant-col-sm-pull-13.ant-col-rtl {
      right: auto;
      left: 54.16666667%;
    }
    .ant-col-sm-offset-13.ant-col-rtl {
      margin-right: 54.16666667%;
      margin-left: 0;
    }
    .ant-col-sm-push-14.ant-col-rtl {
      right: 58.33333333%;
      left: auto;
    }
    .ant-col-sm-pull-14.ant-col-rtl {
      right: auto;
      left: 58.33333333%;
    }
    .ant-col-sm-offset-14.ant-col-rtl {
      margin-right: 58.33333333%;
      margin-left: 0;
    }
    .ant-col-sm-push-15.ant-col-rtl {
      right: 62.5%;
      left: auto;
    }
    .ant-col-sm-pull-15.ant-col-rtl {
      right: auto;
      left: 62.5%;
    }
    .ant-col-sm-offset-15.ant-col-rtl {
      margin-right: 62.5%;
      margin-left: 0;
    }
    .ant-col-sm-push-16.ant-col-rtl {
      right: 66.66666667%;
      left: auto;
    }
    .ant-col-sm-pull-16.ant-col-rtl {
      right: auto;
      left: 66.66666667%;
    }
    .ant-col-sm-offset-16.ant-col-rtl {
      margin-right: 66.66666667%;
      margin-left: 0;
    }
    .ant-col-sm-push-17.ant-col-rtl {
      right: 70.83333333%;
      left: auto;
    }
    .ant-col-sm-pull-17.ant-col-rtl {
      right: auto;
      left: 70.83333333%;
    }
    .ant-col-sm-offset-17.ant-col-rtl {
      margin-right: 70.83333333%;
      margin-left: 0;
    }
    .ant-col-sm-push-18.ant-col-rtl {
      right: 75%;
      left: auto;
    }
    .ant-col-sm-pull-18.ant-col-rtl {
      right: auto;
      left: 75%;
    }
    .ant-col-sm-offset-18.ant-col-rtl {
      margin-right: 75%;
      margin-left: 0;
    }
    .ant-col-sm-push-19.ant-col-rtl {
      right: 79.16666667%;
      left: auto;
    }
    .ant-col-sm-pull-19.ant-col-rtl {
      right: auto;
      left: 79.16666667%;
    }
    .ant-col-sm-offset-19.ant-col-rtl {
      margin-right: 79.16666667%;
      margin-left: 0;
    }
    .ant-col-sm-push-20.ant-col-rtl {
      right: 83.33333333%;
      left: auto;
    }
    .ant-col-sm-pull-20.ant-col-rtl {
      right: auto;
      left: 83.33333333%;
    }
    .ant-col-sm-offset-20.ant-col-rtl {
      margin-right: 83.33333333%;
      margin-left: 0;
    }
    .ant-col-sm-push-21.ant-col-rtl {
      right: 87.5%;
      left: auto;
    }
    .ant-col-sm-pull-21.ant-col-rtl {
      right: auto;
      left: 87.5%;
    }
    .ant-col-sm-offset-21.ant-col-rtl {
      margin-right: 87.5%;
      margin-left: 0;
    }
    .ant-col-sm-push-22.ant-col-rtl {
      right: 91.66666667%;
      left: auto;
    }
    .ant-col-sm-pull-22.ant-col-rtl {
      right: auto;
      left: 91.66666667%;
    }
    .ant-col-sm-offset-22.ant-col-rtl {
      margin-right: 91.66666667%;
      margin-left: 0;
    }
    .ant-col-sm-push-23.ant-col-rtl {
      right: 95.83333333%;
      left: auto;
    }
    .ant-col-sm-pull-23.ant-col-rtl {
      right: auto;
      left: 95.83333333%;
    }
    .ant-col-sm-offset-23.ant-col-rtl {
      margin-right: 95.83333333%;
      margin-left: 0;
    }
    .ant-col-sm-push-24.ant-col-rtl {
      right: 100%;
      left: auto;
    }
    .ant-col-sm-pull-24.ant-col-rtl {
      right: auto;
      left: 100%;
    }
    .ant-col-sm-offset-24.ant-col-rtl {
      margin-right: 100%;
      margin-left: 0;
    }
  }
  @media (min-width: 768px) {
    .ant-col-md-24 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .ant-col-md-push-24 {
      left: 100%;
    }
    .ant-col-md-pull-24 {
      right: 100%;
    }
    .ant-col-md-offset-24 {
      margin-left: 100%;
    }
    .ant-col-md-order-24 {
      order: 24;
    }
    .ant-col-md-23 {
      display: block;
      flex: 0 0 95.83333333%;
      max-width: 95.83333333%;
    }
    .ant-col-md-push-23 {
      left: 95.83333333%;
    }
    .ant-col-md-pull-23 {
      right: 95.83333333%;
    }
    .ant-col-md-offset-23 {
      margin-left: 95.83333333%;
    }
    .ant-col-md-order-23 {
      order: 23;
    }
    .ant-col-md-22 {
      display: block;
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%;
    }
    .ant-col-md-push-22 {
      left: 91.66666667%;
    }
    .ant-col-md-pull-22 {
      right: 91.66666667%;
    }
    .ant-col-md-offset-22 {
      margin-left: 91.66666667%;
    }
    .ant-col-md-order-22 {
      order: 22;
    }
    .ant-col-md-21 {
      display: block;
      flex: 0 0 87.5%;
      max-width: 87.5%;
    }
    .ant-col-md-push-21 {
      left: 87.5%;
    }
    .ant-col-md-pull-21 {
      right: 87.5%;
    }
    .ant-col-md-offset-21 {
      margin-left: 87.5%;
    }
    .ant-col-md-order-21 {
      order: 21;
    }
    .ant-col-md-20 {
      display: block;
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%;
    }
    .ant-col-md-push-20 {
      left: 83.33333333%;
    }
    .ant-col-md-pull-20 {
      right: 83.33333333%;
    }
    .ant-col-md-offset-20 {
      margin-left: 83.33333333%;
    }
    .ant-col-md-order-20 {
      order: 20;
    }
    .ant-col-md-19 {
      display: block;
      flex: 0 0 79.16666667%;
      max-width: 79.16666667%;
    }
    .ant-col-md-push-19 {
      left: 79.16666667%;
    }
    .ant-col-md-pull-19 {
      right: 79.16666667%;
    }
    .ant-col-md-offset-19 {
      margin-left: 79.16666667%;
    }
    .ant-col-md-order-19 {
      order: 19;
    }
    .ant-col-md-18 {
      display: block;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .ant-col-md-push-18 {
      left: 75%;
    }
    .ant-col-md-pull-18 {
      right: 75%;
    }
    .ant-col-md-offset-18 {
      margin-left: 75%;
    }
    .ant-col-md-order-18 {
      order: 18;
    }
    .ant-col-md-17 {
      display: block;
      flex: 0 0 70.83333333%;
      max-width: 70.83333333%;
    }
    .ant-col-md-push-17 {
      left: 70.83333333%;
    }
    .ant-col-md-pull-17 {
      right: 70.83333333%;
    }
    .ant-col-md-offset-17 {
      margin-left: 70.83333333%;
    }
    .ant-col-md-order-17 {
      order: 17;
    }
    .ant-col-md-16 {
      display: block;
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }
    .ant-col-md-push-16 {
      left: 66.66666667%;
    }
    .ant-col-md-pull-16 {
      right: 66.66666667%;
    }
    .ant-col-md-offset-16 {
      margin-left: 66.66666667%;
    }
    .ant-col-md-order-16 {
      order: 16;
    }
    .ant-col-md-15 {
      display: block;
      flex: 0 0 62.5%;
      max-width: 62.5%;
    }
    .ant-col-md-push-15 {
      left: 62.5%;
    }
    .ant-col-md-pull-15 {
      right: 62.5%;
    }
    .ant-col-md-offset-15 {
      margin-left: 62.5%;
    }
    .ant-col-md-order-15 {
      order: 15;
    }
    .ant-col-md-14 {
      display: block;
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }
    .ant-col-md-push-14 {
      left: 58.33333333%;
    }
    .ant-col-md-pull-14 {
      right: 58.33333333%;
    }
    .ant-col-md-offset-14 {
      margin-left: 58.33333333%;
    }
    .ant-col-md-order-14 {
      order: 14;
    }
    .ant-col-md-13 {
      display: block;
      flex: 0 0 54.16666667%;
      max-width: 54.16666667%;
    }
    .ant-col-md-push-13 {
      left: 54.16666667%;
    }
    .ant-col-md-pull-13 {
      right: 54.16666667%;
    }
    .ant-col-md-offset-13 {
      margin-left: 54.16666667%;
    }
    .ant-col-md-order-13 {
      order: 13;
    }
    .ant-col-md-12 {
      display: block;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .ant-col-md-push-12 {
      left: 50%;
    }
    .ant-col-md-pull-12 {
      right: 50%;
    }
    .ant-col-md-offset-12 {
      margin-left: 50%;
    }
    .ant-col-md-order-12 {
      order: 12;
    }
    .ant-col-md-11 {
      display: block;
      flex: 0 0 45.83333333%;
      max-width: 45.83333333%;
    }
    .ant-col-md-push-11 {
      left: 45.83333333%;
    }
    .ant-col-md-pull-11 {
      right: 45.83333333%;
    }
    .ant-col-md-offset-11 {
      margin-left: 45.83333333%;
    }
    .ant-col-md-order-11 {
      order: 11;
    }
    .ant-col-md-10 {
      display: block;
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }
    .ant-col-md-push-10 {
      left: 41.66666667%;
    }
    .ant-col-md-pull-10 {
      right: 41.66666667%;
    }
    .ant-col-md-offset-10 {
      margin-left: 41.66666667%;
    }
    .ant-col-md-order-10 {
      order: 10;
    }
    .ant-col-md-9 {
      display: block;
      flex: 0 0 37.5%;
      max-width: 37.5%;
    }
    .ant-col-md-push-9 {
      left: 37.5%;
    }
    .ant-col-md-pull-9 {
      right: 37.5%;
    }
    .ant-col-md-offset-9 {
      margin-left: 37.5%;
    }
    .ant-col-md-order-9 {
      order: 9;
    }
    .ant-col-md-8 {
      display: block;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
    .ant-col-md-push-8 {
      left: 33.33333333%;
    }
    .ant-col-md-pull-8 {
      right: 33.33333333%;
    }
    .ant-col-md-offset-8 {
      margin-left: 33.33333333%;
    }
    .ant-col-md-order-8 {
      order: 8;
    }
    .ant-col-md-7 {
      display: block;
      flex: 0 0 29.16666667%;
      max-width: 29.16666667%;
    }
    .ant-col-md-push-7 {
      left: 29.16666667%;
    }
    .ant-col-md-pull-7 {
      right: 29.16666667%;
    }
    .ant-col-md-offset-7 {
      margin-left: 29.16666667%;
    }
    .ant-col-md-order-7 {
      order: 7;
    }
    .ant-col-md-6 {
      display: block;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .ant-col-md-push-6 {
      left: 25%;
    }
    .ant-col-md-pull-6 {
      right: 25%;
    }
    .ant-col-md-offset-6 {
      margin-left: 25%;
    }
    .ant-col-md-order-6 {
      order: 6;
    }
    .ant-col-md-5 {
      display: block;
      flex: 0 0 20.83333333%;
      max-width: 20.83333333%;
    }
    .ant-col-md-push-5 {
      left: 20.83333333%;
    }
    .ant-col-md-pull-5 {
      right: 20.83333333%;
    }
    .ant-col-md-offset-5 {
      margin-left: 20.83333333%;
    }
    .ant-col-md-order-5 {
      order: 5;
    }
    .ant-col-md-4 {
      display: block;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
    }
    .ant-col-md-push-4 {
      left: 16.66666667%;
    }
    .ant-col-md-pull-4 {
      right: 16.66666667%;
    }
    .ant-col-md-offset-4 {
      margin-left: 16.66666667%;
    }
    .ant-col-md-order-4 {
      order: 4;
    }
    .ant-col-md-3 {
      display: block;
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
    .ant-col-md-push-3 {
      left: 12.5%;
    }
    .ant-col-md-pull-3 {
      right: 12.5%;
    }
    .ant-col-md-offset-3 {
      margin-left: 12.5%;
    }
    .ant-col-md-order-3 {
      order: 3;
    }
    .ant-col-md-2 {
      display: block;
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%;
    }
    .ant-col-md-push-2 {
      left: 8.33333333%;
    }
    .ant-col-md-pull-2 {
      right: 8.33333333%;
    }
    .ant-col-md-offset-2 {
      margin-left: 8.33333333%;
    }
    .ant-col-md-order-2 {
      order: 2;
    }
    .ant-col-md-1 {
      display: block;
      flex: 0 0 4.16666667%;
      max-width: 4.16666667%;
    }
    .ant-col-md-push-1 {
      left: 4.16666667%;
    }
    .ant-col-md-pull-1 {
      right: 4.16666667%;
    }
    .ant-col-md-offset-1 {
      margin-left: 4.16666667%;
    }
    .ant-col-md-order-1 {
      order: 1;
    }
    .ant-col-md-0 {
      display: none;
    }
    .ant-col-push-0 {
      left: auto;
    }
    .ant-col-pull-0 {
      right: auto;
    }
    .ant-col-md-push-0 {
      left: auto;
    }
    .ant-col-md-pull-0,
    .ant-col-push-0.ant-col-rtl {
      right: auto;
    }
    .ant-col-md-offset-0 {
      margin-left: 0;
    }
    .ant-col-md-order-0 {
      order: 0;
    }
    .ant-col-pull-0.ant-col-rtl {
      left: auto;
    }
    .ant-col-md-push-0.ant-col-rtl {
      right: auto;
    }
    .ant-col-md-pull-0.ant-col-rtl {
      left: auto;
    }
    .ant-col-md-offset-0.ant-col-rtl {
      margin-right: 0;
    }
    .ant-col-md-push-1.ant-col-rtl {
      right: 4.16666667%;
      left: auto;
    }
    .ant-col-md-pull-1.ant-col-rtl {
      right: auto;
      left: 4.16666667%;
    }
    .ant-col-md-offset-1.ant-col-rtl {
      margin-right: 4.16666667%;
      margin-left: 0;
    }
    .ant-col-md-push-2.ant-col-rtl {
      right: 8.33333333%;
      left: auto;
    }
    .ant-col-md-pull-2.ant-col-rtl {
      right: auto;
      left: 8.33333333%;
    }
    .ant-col-md-offset-2.ant-col-rtl {
      margin-right: 8.33333333%;
      margin-left: 0;
    }
    .ant-col-md-push-3.ant-col-rtl {
      right: 12.5%;
      left: auto;
    }
    .ant-col-md-pull-3.ant-col-rtl {
      right: auto;
      left: 12.5%;
    }
    .ant-col-md-offset-3.ant-col-rtl {
      margin-right: 12.5%;
      margin-left: 0;
    }
    .ant-col-md-push-4.ant-col-rtl {
      right: 16.66666667%;
      left: auto;
    }
    .ant-col-md-pull-4.ant-col-rtl {
      right: auto;
      left: 16.66666667%;
    }
    .ant-col-md-offset-4.ant-col-rtl {
      margin-right: 16.66666667%;
      margin-left: 0;
    }
    .ant-col-md-push-5.ant-col-rtl {
      right: 20.83333333%;
      left: auto;
    }
    .ant-col-md-pull-5.ant-col-rtl {
      right: auto;
      left: 20.83333333%;
    }
    .ant-col-md-offset-5.ant-col-rtl {
      margin-right: 20.83333333%;
      margin-left: 0;
    }
    .ant-col-md-push-6.ant-col-rtl {
      right: 25%;
      left: auto;
    }
    .ant-col-md-pull-6.ant-col-rtl {
      right: auto;
      left: 25%;
    }
    .ant-col-md-offset-6.ant-col-rtl {
      margin-right: 25%;
      margin-left: 0;
    }
    .ant-col-md-push-7.ant-col-rtl {
      right: 29.16666667%;
      left: auto;
    }
    .ant-col-md-pull-7.ant-col-rtl {
      right: auto;
      left: 29.16666667%;
    }
    .ant-col-md-offset-7.ant-col-rtl {
      margin-right: 29.16666667%;
      margin-left: 0;
    }
    .ant-col-md-push-8.ant-col-rtl {
      right: 33.33333333%;
      left: auto;
    }
    .ant-col-md-pull-8.ant-col-rtl {
      right: auto;
      left: 33.33333333%;
    }
    .ant-col-md-offset-8.ant-col-rtl {
      margin-right: 33.33333333%;
      margin-left: 0;
    }
    .ant-col-md-push-9.ant-col-rtl {
      right: 37.5%;
      left: auto;
    }
    .ant-col-md-pull-9.ant-col-rtl {
      right: auto;
      left: 37.5%;
    }
    .ant-col-md-offset-9.ant-col-rtl {
      margin-right: 37.5%;
      margin-left: 0;
    }
    .ant-col-md-push-10.ant-col-rtl {
      right: 41.66666667%;
      left: auto;
    }
    .ant-col-md-pull-10.ant-col-rtl {
      right: auto;
      left: 41.66666667%;
    }
    .ant-col-md-offset-10.ant-col-rtl {
      margin-right: 41.66666667%;
      margin-left: 0;
    }
    .ant-col-md-push-11.ant-col-rtl {
      right: 45.83333333%;
      left: auto;
    }
    .ant-col-md-pull-11.ant-col-rtl {
      right: auto;
      left: 45.83333333%;
    }
    .ant-col-md-offset-11.ant-col-rtl {
      margin-right: 45.83333333%;
      margin-left: 0;
    }
    .ant-col-md-push-12.ant-col-rtl {
      right: 50%;
      left: auto;
    }
    .ant-col-md-pull-12.ant-col-rtl {
      right: auto;
      left: 50%;
    }
    .ant-col-md-offset-12.ant-col-rtl {
      margin-right: 50%;
      margin-left: 0;
    }
    .ant-col-md-push-13.ant-col-rtl {
      right: 54.16666667%;
      left: auto;
    }
    .ant-col-md-pull-13.ant-col-rtl {
      right: auto;
      left: 54.16666667%;
    }
    .ant-col-md-offset-13.ant-col-rtl {
      margin-right: 54.16666667%;
      margin-left: 0;
    }
    .ant-col-md-push-14.ant-col-rtl {
      right: 58.33333333%;
      left: auto;
    }
    .ant-col-md-pull-14.ant-col-rtl {
      right: auto;
      left: 58.33333333%;
    }
    .ant-col-md-offset-14.ant-col-rtl {
      margin-right: 58.33333333%;
      margin-left: 0;
    }
    .ant-col-md-push-15.ant-col-rtl {
      right: 62.5%;
      left: auto;
    }
    .ant-col-md-pull-15.ant-col-rtl {
      right: auto;
      left: 62.5%;
    }
    .ant-col-md-offset-15.ant-col-rtl {
      margin-right: 62.5%;
      margin-left: 0;
    }
    .ant-col-md-push-16.ant-col-rtl {
      right: 66.66666667%;
      left: auto;
    }
    .ant-col-md-pull-16.ant-col-rtl {
      right: auto;
      left: 66.66666667%;
    }
    .ant-col-md-offset-16.ant-col-rtl {
      margin-right: 66.66666667%;
      margin-left: 0;
    }
    .ant-col-md-push-17.ant-col-rtl {
      right: 70.83333333%;
      left: auto;
    }
    .ant-col-md-pull-17.ant-col-rtl {
      right: auto;
      left: 70.83333333%;
    }
    .ant-col-md-offset-17.ant-col-rtl {
      margin-right: 70.83333333%;
      margin-left: 0;
    }
    .ant-col-md-push-18.ant-col-rtl {
      right: 75%;
      left: auto;
    }
    .ant-col-md-pull-18.ant-col-rtl {
      right: auto;
      left: 75%;
    }
    .ant-col-md-offset-18.ant-col-rtl {
      margin-right: 75%;
      margin-left: 0;
    }
    .ant-col-md-push-19.ant-col-rtl {
      right: 79.16666667%;
      left: auto;
    }
    .ant-col-md-pull-19.ant-col-rtl {
      right: auto;
      left: 79.16666667%;
    }
    .ant-col-md-offset-19.ant-col-rtl {
      margin-right: 79.16666667%;
      margin-left: 0;
    }
    .ant-col-md-push-20.ant-col-rtl {
      right: 83.33333333%;
      left: auto;
    }
    .ant-col-md-pull-20.ant-col-rtl {
      right: auto;
      left: 83.33333333%;
    }
    .ant-col-md-offset-20.ant-col-rtl {
      margin-right: 83.33333333%;
      margin-left: 0;
    }
    .ant-col-md-push-21.ant-col-rtl {
      right: 87.5%;
      left: auto;
    }
    .ant-col-md-pull-21.ant-col-rtl {
      right: auto;
      left: 87.5%;
    }
    .ant-col-md-offset-21.ant-col-rtl {
      margin-right: 87.5%;
      margin-left: 0;
    }
    .ant-col-md-push-22.ant-col-rtl {
      right: 91.66666667%;
      left: auto;
    }
    .ant-col-md-pull-22.ant-col-rtl {
      right: auto;
      left: 91.66666667%;
    }
    .ant-col-md-offset-22.ant-col-rtl {
      margin-right: 91.66666667%;
      margin-left: 0;
    }
    .ant-col-md-push-23.ant-col-rtl {
      right: 95.83333333%;
      left: auto;
    }
    .ant-col-md-pull-23.ant-col-rtl {
      right: auto;
      left: 95.83333333%;
    }
    .ant-col-md-offset-23.ant-col-rtl {
      margin-right: 95.83333333%;
      margin-left: 0;
    }
    .ant-col-md-push-24.ant-col-rtl {
      right: 100%;
      left: auto;
    }
    .ant-col-md-pull-24.ant-col-rtl {
      right: auto;
      left: 100%;
    }
    .ant-col-md-offset-24.ant-col-rtl {
      margin-right: 100%;
      margin-left: 0;
    }
  }
  @media (min-width: 992px) {
    .ant-col-lg-24 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .ant-col-lg-push-24 {
      left: 100%;
    }
    .ant-col-lg-pull-24 {
      right: 100%;
    }
    .ant-col-lg-offset-24 {
      margin-left: 100%;
    }
    .ant-col-lg-order-24 {
      order: 24;
    }
    .ant-col-lg-23 {
      display: block;
      flex: 0 0 95.83333333%;
      max-width: 95.83333333%;
    }
    .ant-col-lg-push-23 {
      left: 95.83333333%;
    }
    .ant-col-lg-pull-23 {
      right: 95.83333333%;
    }
    .ant-col-lg-offset-23 {
      margin-left: 95.83333333%;
    }
    .ant-col-lg-order-23 {
      order: 23;
    }
    .ant-col-lg-22 {
      display: block;
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%;
    }
    .ant-col-lg-push-22 {
      left: 91.66666667%;
    }
    .ant-col-lg-pull-22 {
      right: 91.66666667%;
    }
    .ant-col-lg-offset-22 {
      margin-left: 91.66666667%;
    }
    .ant-col-lg-order-22 {
      order: 22;
    }
    .ant-col-lg-21 {
      display: block;
      flex: 0 0 87.5%;
      max-width: 87.5%;
    }
    .ant-col-lg-push-21 {
      left: 87.5%;
    }
    .ant-col-lg-pull-21 {
      right: 87.5%;
    }
    .ant-col-lg-offset-21 {
      margin-left: 87.5%;
    }
    .ant-col-lg-order-21 {
      order: 21;
    }
    .ant-col-lg-20 {
      display: block;
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%;
    }
    .ant-col-lg-push-20 {
      left: 83.33333333%;
    }
    .ant-col-lg-pull-20 {
      right: 83.33333333%;
    }
    .ant-col-lg-offset-20 {
      margin-left: 83.33333333%;
    }
    .ant-col-lg-order-20 {
      order: 20;
    }
    .ant-col-lg-19 {
      display: block;
      flex: 0 0 79.16666667%;
      max-width: 79.16666667%;
    }
    .ant-col-lg-push-19 {
      left: 79.16666667%;
    }
    .ant-col-lg-pull-19 {
      right: 79.16666667%;
    }
    .ant-col-lg-offset-19 {
      margin-left: 79.16666667%;
    }
    .ant-col-lg-order-19 {
      order: 19;
    }
    .ant-col-lg-18 {
      display: block;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .ant-col-lg-push-18 {
      left: 75%;
    }
    .ant-col-lg-pull-18 {
      right: 75%;
    }
    .ant-col-lg-offset-18 {
      margin-left: 75%;
    }
    .ant-col-lg-order-18 {
      order: 18;
    }
    .ant-col-lg-17 {
      display: block;
      flex: 0 0 70.83333333%;
      max-width: 70.83333333%;
    }
    .ant-col-lg-push-17 {
      left: 70.83333333%;
    }
    .ant-col-lg-pull-17 {
      right: 70.83333333%;
    }
    .ant-col-lg-offset-17 {
      margin-left: 70.83333333%;
    }
    .ant-col-lg-order-17 {
      order: 17;
    }
    .ant-col-lg-16 {
      display: block;
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }
    .ant-col-lg-push-16 {
      left: 66.66666667%;
    }
    .ant-col-lg-pull-16 {
      right: 66.66666667%;
    }
    .ant-col-lg-offset-16 {
      margin-left: 66.66666667%;
    }
    .ant-col-lg-order-16 {
      order: 16;
    }
    .ant-col-lg-15 {
      display: block;
      flex: 0 0 62.5%;
      max-width: 62.5%;
    }
    .ant-col-lg-push-15 {
      left: 62.5%;
    }
    .ant-col-lg-pull-15 {
      right: 62.5%;
    }
    .ant-col-lg-offset-15 {
      margin-left: 62.5%;
    }
    .ant-col-lg-order-15 {
      order: 15;
    }
    .ant-col-lg-14 {
      display: block;
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }
    .ant-col-lg-push-14 {
      left: 58.33333333%;
    }
    .ant-col-lg-pull-14 {
      right: 58.33333333%;
    }
    .ant-col-lg-offset-14 {
      margin-left: 58.33333333%;
    }
    .ant-col-lg-order-14 {
      order: 14;
    }
    .ant-col-lg-13 {
      display: block;
      flex: 0 0 54.16666667%;
      max-width: 54.16666667%;
    }
    .ant-col-lg-push-13 {
      left: 54.16666667%;
    }
    .ant-col-lg-pull-13 {
      right: 54.16666667%;
    }
    .ant-col-lg-offset-13 {
      margin-left: 54.16666667%;
    }
    .ant-col-lg-order-13 {
      order: 13;
    }
    .ant-col-lg-12 {
      display: block;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .ant-col-lg-push-12 {
      left: 50%;
    }
    .ant-col-lg-pull-12 {
      right: 50%;
    }
    .ant-col-lg-offset-12 {
      margin-left: 50%;
    }
    .ant-col-lg-order-12 {
      order: 12;
    }
    .ant-col-lg-11 {
      display: block;
      flex: 0 0 45.83333333%;
      max-width: 45.83333333%;
    }
    .ant-col-lg-push-11 {
      left: 45.83333333%;
    }
    .ant-col-lg-pull-11 {
      right: 45.83333333%;
    }
    .ant-col-lg-offset-11 {
      margin-left: 45.83333333%;
    }
    .ant-col-lg-order-11 {
      order: 11;
    }
    .ant-col-lg-10 {
      display: block;
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }
    .ant-col-lg-push-10 {
      left: 41.66666667%;
    }
    .ant-col-lg-pull-10 {
      right: 41.66666667%;
    }
    .ant-col-lg-offset-10 {
      margin-left: 41.66666667%;
    }
    .ant-col-lg-order-10 {
      order: 10;
    }
    .ant-col-lg-9 {
      display: block;
      flex: 0 0 37.5%;
      max-width: 37.5%;
    }
    .ant-col-lg-push-9 {
      left: 37.5%;
    }
    .ant-col-lg-pull-9 {
      right: 37.5%;
    }
    .ant-col-lg-offset-9 {
      margin-left: 37.5%;
    }
    .ant-col-lg-order-9 {
      order: 9;
    }
    .ant-col-lg-8 {
      display: block;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
    .ant-col-lg-push-8 {
      left: 33.33333333%;
    }
    .ant-col-lg-pull-8 {
      right: 33.33333333%;
    }
    .ant-col-lg-offset-8 {
      margin-left: 33.33333333%;
    }
    .ant-col-lg-order-8 {
      order: 8;
    }
    .ant-col-lg-7 {
      display: block;
      flex: 0 0 29.16666667%;
      max-width: 29.16666667%;
    }
    .ant-col-lg-push-7 {
      left: 29.16666667%;
    }
    .ant-col-lg-pull-7 {
      right: 29.16666667%;
    }
    .ant-col-lg-offset-7 {
      margin-left: 29.16666667%;
    }
    .ant-col-lg-order-7 {
      order: 7;
    }
    .ant-col-lg-6 {
      display: block;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .ant-col-lg-push-6 {
      left: 25%;
    }
    .ant-col-lg-pull-6 {
      right: 25%;
    }
    .ant-col-lg-offset-6 {
      margin-left: 25%;
    }
    .ant-col-lg-order-6 {
      order: 6;
    }
    .ant-col-lg-5 {
      display: block;
      flex: 0 0 20.83333333%;
      max-width: 20.83333333%;
    }
    .ant-col-lg-push-5 {
      left: 20.83333333%;
    }
    .ant-col-lg-pull-5 {
      right: 20.83333333%;
    }
    .ant-col-lg-offset-5 {
      margin-left: 20.83333333%;
    }
    .ant-col-lg-order-5 {
      order: 5;
    }
    .ant-col-lg-4 {
      display: block;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
    }
    .ant-col-lg-push-4 {
      left: 16.66666667%;
    }
    .ant-col-lg-pull-4 {
      right: 16.66666667%;
    }
    .ant-col-lg-offset-4 {
      margin-left: 16.66666667%;
    }
    .ant-col-lg-order-4 {
      order: 4;
    }
    .ant-col-lg-3 {
      display: block;
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
    .ant-col-lg-push-3 {
      left: 12.5%;
    }
    .ant-col-lg-pull-3 {
      right: 12.5%;
    }
    .ant-col-lg-offset-3 {
      margin-left: 12.5%;
    }
    .ant-col-lg-order-3 {
      order: 3;
    }
    .ant-col-lg-2 {
      display: block;
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%;
    }
    .ant-col-lg-push-2 {
      left: 8.33333333%;
    }
    .ant-col-lg-pull-2 {
      right: 8.33333333%;
    }
    .ant-col-lg-offset-2 {
      margin-left: 8.33333333%;
    }
    .ant-col-lg-order-2 {
      order: 2;
    }
    .ant-col-lg-1 {
      display: block;
      flex: 0 0 4.16666667%;
      max-width: 4.16666667%;
    }
    .ant-col-lg-push-1 {
      left: 4.16666667%;
    }
    .ant-col-lg-pull-1 {
      right: 4.16666667%;
    }
    .ant-col-lg-offset-1 {
      margin-left: 4.16666667%;
    }
    .ant-col-lg-order-1 {
      order: 1;
    }
    .ant-col-lg-0 {
      display: none;
    }
    .ant-col-push-0 {
      left: auto;
    }
    .ant-col-pull-0 {
      right: auto;
    }
    .ant-col-lg-push-0 {
      left: auto;
    }
    .ant-col-lg-pull-0,
    .ant-col-push-0.ant-col-rtl {
      right: auto;
    }
    .ant-col-lg-offset-0 {
      margin-left: 0;
    }
    .ant-col-lg-order-0 {
      order: 0;
    }
    .ant-col-pull-0.ant-col-rtl {
      left: auto;
    }
    .ant-col-lg-push-0.ant-col-rtl {
      right: auto;
    }
    .ant-col-lg-pull-0.ant-col-rtl {
      left: auto;
    }
    .ant-col-lg-offset-0.ant-col-rtl {
      margin-right: 0;
    }
    .ant-col-lg-push-1.ant-col-rtl {
      right: 4.16666667%;
      left: auto;
    }
    .ant-col-lg-pull-1.ant-col-rtl {
      right: auto;
      left: 4.16666667%;
    }
    .ant-col-lg-offset-1.ant-col-rtl {
      margin-right: 4.16666667%;
      margin-left: 0;
    }
    .ant-col-lg-push-2.ant-col-rtl {
      right: 8.33333333%;
      left: auto;
    }
    .ant-col-lg-pull-2.ant-col-rtl {
      right: auto;
      left: 8.33333333%;
    }
    .ant-col-lg-offset-2.ant-col-rtl {
      margin-right: 8.33333333%;
      margin-left: 0;
    }
    .ant-col-lg-push-3.ant-col-rtl {
      right: 12.5%;
      left: auto;
    }
    .ant-col-lg-pull-3.ant-col-rtl {
      right: auto;
      left: 12.5%;
    }
    .ant-col-lg-offset-3.ant-col-rtl {
      margin-right: 12.5%;
      margin-left: 0;
    }
    .ant-col-lg-push-4.ant-col-rtl {
      right: 16.66666667%;
      left: auto;
    }
    .ant-col-lg-pull-4.ant-col-rtl {
      right: auto;
      left: 16.66666667%;
    }
    .ant-col-lg-offset-4.ant-col-rtl {
      margin-right: 16.66666667%;
      margin-left: 0;
    }
    .ant-col-lg-push-5.ant-col-rtl {
      right: 20.83333333%;
      left: auto;
    }
    .ant-col-lg-pull-5.ant-col-rtl {
      right: auto;
      left: 20.83333333%;
    }
    .ant-col-lg-offset-5.ant-col-rtl {
      margin-right: 20.83333333%;
      margin-left: 0;
    }
    .ant-col-lg-push-6.ant-col-rtl {
      right: 25%;
      left: auto;
    }
    .ant-col-lg-pull-6.ant-col-rtl {
      right: auto;
      left: 25%;
    }
    .ant-col-lg-offset-6.ant-col-rtl {
      margin-right: 25%;
      margin-left: 0;
    }
    .ant-col-lg-push-7.ant-col-rtl {
      right: 29.16666667%;
      left: auto;
    }
    .ant-col-lg-pull-7.ant-col-rtl {
      right: auto;
      left: 29.16666667%;
    }
    .ant-col-lg-offset-7.ant-col-rtl {
      margin-right: 29.16666667%;
      margin-left: 0;
    }
    .ant-col-lg-push-8.ant-col-rtl {
      right: 33.33333333%;
      left: auto;
    }
    .ant-col-lg-pull-8.ant-col-rtl {
      right: auto;
      left: 33.33333333%;
    }
    .ant-col-lg-offset-8.ant-col-rtl {
      margin-right: 33.33333333%;
      margin-left: 0;
    }
    .ant-col-lg-push-9.ant-col-rtl {
      right: 37.5%;
      left: auto;
    }
    .ant-col-lg-pull-9.ant-col-rtl {
      right: auto;
      left: 37.5%;
    }
    .ant-col-lg-offset-9.ant-col-rtl {
      margin-right: 37.5%;
      margin-left: 0;
    }
    .ant-col-lg-push-10.ant-col-rtl {
      right: 41.66666667%;
      left: auto;
    }
    .ant-col-lg-pull-10.ant-col-rtl {
      right: auto;
      left: 41.66666667%;
    }
    .ant-col-lg-offset-10.ant-col-rtl {
      margin-right: 41.66666667%;
      margin-left: 0;
    }
    .ant-col-lg-push-11.ant-col-rtl {
      right: 45.83333333%;
      left: auto;
    }
    .ant-col-lg-pull-11.ant-col-rtl {
      right: auto;
      left: 45.83333333%;
    }
    .ant-col-lg-offset-11.ant-col-rtl {
      margin-right: 45.83333333%;
      margin-left: 0;
    }
    .ant-col-lg-push-12.ant-col-rtl {
      right: 50%;
      left: auto;
    }
    .ant-col-lg-pull-12.ant-col-rtl {
      right: auto;
      left: 50%;
    }
    .ant-col-lg-offset-12.ant-col-rtl {
      margin-right: 50%;
      margin-left: 0;
    }
    .ant-col-lg-push-13.ant-col-rtl {
      right: 54.16666667%;
      left: auto;
    }
    .ant-col-lg-pull-13.ant-col-rtl {
      right: auto;
      left: 54.16666667%;
    }
    .ant-col-lg-offset-13.ant-col-rtl {
      margin-right: 54.16666667%;
      margin-left: 0;
    }
    .ant-col-lg-push-14.ant-col-rtl {
      right: 58.33333333%;
      left: auto;
    }
    .ant-col-lg-pull-14.ant-col-rtl {
      right: auto;
      left: 58.33333333%;
    }
    .ant-col-lg-offset-14.ant-col-rtl {
      margin-right: 58.33333333%;
      margin-left: 0;
    }
    .ant-col-lg-push-15.ant-col-rtl {
      right: 62.5%;
      left: auto;
    }
    .ant-col-lg-pull-15.ant-col-rtl {
      right: auto;
      left: 62.5%;
    }
    .ant-col-lg-offset-15.ant-col-rtl {
      margin-right: 62.5%;
      margin-left: 0;
    }
    .ant-col-lg-push-16.ant-col-rtl {
      right: 66.66666667%;
      left: auto;
    }
    .ant-col-lg-pull-16.ant-col-rtl {
      right: auto;
      left: 66.66666667%;
    }
    .ant-col-lg-offset-16.ant-col-rtl {
      margin-right: 66.66666667%;
      margin-left: 0;
    }
    .ant-col-lg-push-17.ant-col-rtl {
      right: 70.83333333%;
      left: auto;
    }
    .ant-col-lg-pull-17.ant-col-rtl {
      right: auto;
      left: 70.83333333%;
    }
    .ant-col-lg-offset-17.ant-col-rtl {
      margin-right: 70.83333333%;
      margin-left: 0;
    }
    .ant-col-lg-push-18.ant-col-rtl {
      right: 75%;
      left: auto;
    }
    .ant-col-lg-pull-18.ant-col-rtl {
      right: auto;
      left: 75%;
    }
    .ant-col-lg-offset-18.ant-col-rtl {
      margin-right: 75%;
      margin-left: 0;
    }
    .ant-col-lg-push-19.ant-col-rtl {
      right: 79.16666667%;
      left: auto;
    }
    .ant-col-lg-pull-19.ant-col-rtl {
      right: auto;
      left: 79.16666667%;
    }
    .ant-col-lg-offset-19.ant-col-rtl {
      margin-right: 79.16666667%;
      margin-left: 0;
    }
    .ant-col-lg-push-20.ant-col-rtl {
      right: 83.33333333%;
      left: auto;
    }
    .ant-col-lg-pull-20.ant-col-rtl {
      right: auto;
      left: 83.33333333%;
    }
    .ant-col-lg-offset-20.ant-col-rtl {
      margin-right: 83.33333333%;
      margin-left: 0;
    }
    .ant-col-lg-push-21.ant-col-rtl {
      right: 87.5%;
      left: auto;
    }
    .ant-col-lg-pull-21.ant-col-rtl {
      right: auto;
      left: 87.5%;
    }
    .ant-col-lg-offset-21.ant-col-rtl {
      margin-right: 87.5%;
      margin-left: 0;
    }
    .ant-col-lg-push-22.ant-col-rtl {
      right: 91.66666667%;
      left: auto;
    }
    .ant-col-lg-pull-22.ant-col-rtl {
      right: auto;
      left: 91.66666667%;
    }
    .ant-col-lg-offset-22.ant-col-rtl {
      margin-right: 91.66666667%;
      margin-left: 0;
    }
    .ant-col-lg-push-23.ant-col-rtl {
      right: 95.83333333%;
      left: auto;
    }
    .ant-col-lg-pull-23.ant-col-rtl {
      right: auto;
      left: 95.83333333%;
    }
    .ant-col-lg-offset-23.ant-col-rtl {
      margin-right: 95.83333333%;
      margin-left: 0;
    }
    .ant-col-lg-push-24.ant-col-rtl {
      right: 100%;
      left: auto;
    }
    .ant-col-lg-pull-24.ant-col-rtl {
      right: auto;
      left: 100%;
    }
    .ant-col-lg-offset-24.ant-col-rtl {
      margin-right: 100%;
      margin-left: 0;
    }
  }
  @media (min-width: 1200px) {
    .ant-col-xl-24 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .ant-col-xl-push-24 {
      left: 100%;
    }
    .ant-col-xl-pull-24 {
      right: 100%;
    }
    .ant-col-xl-offset-24 {
      margin-left: 100%;
    }
    .ant-col-xl-order-24 {
      order: 24;
    }
    .ant-col-xl-23 {
      display: block;
      flex: 0 0 95.83333333%;
      max-width: 95.83333333%;
    }
    .ant-col-xl-push-23 {
      left: 95.83333333%;
    }
    .ant-col-xl-pull-23 {
      right: 95.83333333%;
    }
    .ant-col-xl-offset-23 {
      margin-left: 95.83333333%;
    }
    .ant-col-xl-order-23 {
      order: 23;
    }
    .ant-col-xl-22 {
      display: block;
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%;
    }
    .ant-col-xl-push-22 {
      left: 91.66666667%;
    }
    .ant-col-xl-pull-22 {
      right: 91.66666667%;
    }
    .ant-col-xl-offset-22 {
      margin-left: 91.66666667%;
    }
    .ant-col-xl-order-22 {
      order: 22;
    }
    .ant-col-xl-21 {
      display: block;
      flex: 0 0 87.5%;
      max-width: 87.5%;
    }
    .ant-col-xl-push-21 {
      left: 87.5%;
    }
    .ant-col-xl-pull-21 {
      right: 87.5%;
    }
    .ant-col-xl-offset-21 {
      margin-left: 87.5%;
    }
    .ant-col-xl-order-21 {
      order: 21;
    }
    .ant-col-xl-20 {
      display: block;
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%;
    }
    .ant-col-xl-push-20 {
      left: 83.33333333%;
    }
    .ant-col-xl-pull-20 {
      right: 83.33333333%;
    }
    .ant-col-xl-offset-20 {
      margin-left: 83.33333333%;
    }
    .ant-col-xl-order-20 {
      order: 20;
    }
    .ant-col-xl-19 {
      display: block;
      flex: 0 0 79.16666667%;
      max-width: 79.16666667%;
    }
    .ant-col-xl-push-19 {
      left: 79.16666667%;
    }
    .ant-col-xl-pull-19 {
      right: 79.16666667%;
    }
    .ant-col-xl-offset-19 {
      margin-left: 79.16666667%;
    }
    .ant-col-xl-order-19 {
      order: 19;
    }
    .ant-col-xl-18 {
      display: block;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .ant-col-xl-push-18 {
      left: 75%;
    }
    .ant-col-xl-pull-18 {
      right: 75%;
    }
    .ant-col-xl-offset-18 {
      margin-left: 75%;
    }
    .ant-col-xl-order-18 {
      order: 18;
    }
    .ant-col-xl-17 {
      display: block;
      flex: 0 0 70.83333333%;
      max-width: 70.83333333%;
    }
    .ant-col-xl-push-17 {
      left: 70.83333333%;
    }
    .ant-col-xl-pull-17 {
      right: 70.83333333%;
    }
    .ant-col-xl-offset-17 {
      margin-left: 70.83333333%;
    }
    .ant-col-xl-order-17 {
      order: 17;
    }
    .ant-col-xl-16 {
      display: block;
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }
    .ant-col-xl-push-16 {
      left: 66.66666667%;
    }
    .ant-col-xl-pull-16 {
      right: 66.66666667%;
    }
    .ant-col-xl-offset-16 {
      margin-left: 66.66666667%;
    }
    .ant-col-xl-order-16 {
      order: 16;
    }
    .ant-col-xl-15 {
      display: block;
      flex: 0 0 62.5%;
      max-width: 62.5%;
    }
    .ant-col-xl-push-15 {
      left: 62.5%;
    }
    .ant-col-xl-pull-15 {
      right: 62.5%;
    }
    .ant-col-xl-offset-15 {
      margin-left: 62.5%;
    }
    .ant-col-xl-order-15 {
      order: 15;
    }
    .ant-col-xl-14 {
      display: block;
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }
    .ant-col-xl-push-14 {
      left: 58.33333333%;
    }
    .ant-col-xl-pull-14 {
      right: 58.33333333%;
    }
    .ant-col-xl-offset-14 {
      margin-left: 58.33333333%;
    }
    .ant-col-xl-order-14 {
      order: 14;
    }
    .ant-col-xl-13 {
      display: block;
      flex: 0 0 54.16666667%;
      max-width: 54.16666667%;
    }
    .ant-col-xl-push-13 {
      left: 54.16666667%;
    }
    .ant-col-xl-pull-13 {
      right: 54.16666667%;
    }
    .ant-col-xl-offset-13 {
      margin-left: 54.16666667%;
    }
    .ant-col-xl-order-13 {
      order: 13;
    }
    .ant-col-xl-12 {
      display: block;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .ant-col-xl-push-12 {
      left: 50%;
    }
    .ant-col-xl-pull-12 {
      right: 50%;
    }
    .ant-col-xl-offset-12 {
      margin-left: 50%;
    }
    .ant-col-xl-order-12 {
      order: 12;
    }
    .ant-col-xl-11 {
      display: block;
      flex: 0 0 45.83333333%;
      max-width: 45.83333333%;
    }
    .ant-col-xl-push-11 {
      left: 45.83333333%;
    }
    .ant-col-xl-pull-11 {
      right: 45.83333333%;
    }
    .ant-col-xl-offset-11 {
      margin-left: 45.83333333%;
    }
    .ant-col-xl-order-11 {
      order: 11;
    }
    .ant-col-xl-10 {
      display: block;
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }
    .ant-col-xl-push-10 {
      left: 41.66666667%;
    }
    .ant-col-xl-pull-10 {
      right: 41.66666667%;
    }
    .ant-col-xl-offset-10 {
      margin-left: 41.66666667%;
    }
    .ant-col-xl-order-10 {
      order: 10;
    }
    .ant-col-xl-9 {
      display: block;
      flex: 0 0 37.5%;
      max-width: 37.5%;
    }
    .ant-col-xl-push-9 {
      left: 37.5%;
    }
    .ant-col-xl-pull-9 {
      right: 37.5%;
    }
    .ant-col-xl-offset-9 {
      margin-left: 37.5%;
    }
    .ant-col-xl-order-9 {
      order: 9;
    }
    .ant-col-xl-8 {
      display: block;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
    .ant-col-xl-push-8 {
      left: 33.33333333%;
    }
    .ant-col-xl-pull-8 {
      right: 33.33333333%;
    }
    .ant-col-xl-offset-8 {
      margin-left: 33.33333333%;
    }
    .ant-col-xl-order-8 {
      order: 8;
    }
    .ant-col-xl-7 {
      display: block;
      flex: 0 0 29.16666667%;
      max-width: 29.16666667%;
    }
    .ant-col-xl-push-7 {
      left: 29.16666667%;
    }
    .ant-col-xl-pull-7 {
      right: 29.16666667%;
    }
    .ant-col-xl-offset-7 {
      margin-left: 29.16666667%;
    }
    .ant-col-xl-order-7 {
      order: 7;
    }
    .ant-col-xl-6 {
      display: block;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .ant-col-xl-push-6 {
      left: 25%;
    }
    .ant-col-xl-pull-6 {
      right: 25%;
    }
    .ant-col-xl-offset-6 {
      margin-left: 25%;
    }
    .ant-col-xl-order-6 {
      order: 6;
    }
    .ant-col-xl-5 {
      display: block;
      flex: 0 0 20.83333333%;
      max-width: 20.83333333%;
    }
    .ant-col-xl-push-5 {
      left: 20.83333333%;
    }
    .ant-col-xl-pull-5 {
      right: 20.83333333%;
    }
    .ant-col-xl-offset-5 {
      margin-left: 20.83333333%;
    }
    .ant-col-xl-order-5 {
      order: 5;
    }
    .ant-col-xl-4 {
      display: block;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
    }
    .ant-col-xl-push-4 {
      left: 16.66666667%;
    }
    .ant-col-xl-pull-4 {
      right: 16.66666667%;
    }
    .ant-col-xl-offset-4 {
      margin-left: 16.66666667%;
    }
    .ant-col-xl-order-4 {
      order: 4;
    }
    .ant-col-xl-3 {
      display: block;
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
    .ant-col-xl-push-3 {
      left: 12.5%;
    }
    .ant-col-xl-pull-3 {
      right: 12.5%;
    }
    .ant-col-xl-offset-3 {
      margin-left: 12.5%;
    }
    .ant-col-xl-order-3 {
      order: 3;
    }
    .ant-col-xl-2 {
      display: block;
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%;
    }
    .ant-col-xl-push-2 {
      left: 8.33333333%;
    }
    .ant-col-xl-pull-2 {
      right: 8.33333333%;
    }
    .ant-col-xl-offset-2 {
      margin-left: 8.33333333%;
    }
    .ant-col-xl-order-2 {
      order: 2;
    }
    .ant-col-xl-1 {
      display: block;
      flex: 0 0 4.16666667%;
      max-width: 4.16666667%;
    }
    .ant-col-xl-push-1 {
      left: 4.16666667%;
    }
    .ant-col-xl-pull-1 {
      right: 4.16666667%;
    }
    .ant-col-xl-offset-1 {
      margin-left: 4.16666667%;
    }
    .ant-col-xl-order-1 {
      order: 1;
    }
    .ant-col-xl-0 {
      display: none;
    }
    .ant-col-push-0 {
      left: auto;
    }
    .ant-col-pull-0 {
      right: auto;
    }
    .ant-col-xl-push-0 {
      left: auto;
    }
    .ant-col-push-0.ant-col-rtl,
    .ant-col-xl-pull-0 {
      right: auto;
    }
    .ant-col-xl-offset-0 {
      margin-left: 0;
    }
    .ant-col-xl-order-0 {
      order: 0;
    }
    .ant-col-pull-0.ant-col-rtl {
      left: auto;
    }
    .ant-col-xl-push-0.ant-col-rtl {
      right: auto;
    }
    .ant-col-xl-pull-0.ant-col-rtl {
      left: auto;
    }
    .ant-col-xl-offset-0.ant-col-rtl {
      margin-right: 0;
    }
    .ant-col-xl-push-1.ant-col-rtl {
      right: 4.16666667%;
      left: auto;
    }
    .ant-col-xl-pull-1.ant-col-rtl {
      right: auto;
      left: 4.16666667%;
    }
    .ant-col-xl-offset-1.ant-col-rtl {
      margin-right: 4.16666667%;
      margin-left: 0;
    }
    .ant-col-xl-push-2.ant-col-rtl {
      right: 8.33333333%;
      left: auto;
    }
    .ant-col-xl-pull-2.ant-col-rtl {
      right: auto;
      left: 8.33333333%;
    }
    .ant-col-xl-offset-2.ant-col-rtl {
      margin-right: 8.33333333%;
      margin-left: 0;
    }
    .ant-col-xl-push-3.ant-col-rtl {
      right: 12.5%;
      left: auto;
    }
    .ant-col-xl-pull-3.ant-col-rtl {
      right: auto;
      left: 12.5%;
    }
    .ant-col-xl-offset-3.ant-col-rtl {
      margin-right: 12.5%;
      margin-left: 0;
    }
    .ant-col-xl-push-4.ant-col-rtl {
      right: 16.66666667%;
      left: auto;
    }
    .ant-col-xl-pull-4.ant-col-rtl {
      right: auto;
      left: 16.66666667%;
    }
    .ant-col-xl-offset-4.ant-col-rtl {
      margin-right: 16.66666667%;
      margin-left: 0;
    }
    .ant-col-xl-push-5.ant-col-rtl {
      right: 20.83333333%;
      left: auto;
    }
    .ant-col-xl-pull-5.ant-col-rtl {
      right: auto;
      left: 20.83333333%;
    }
    .ant-col-xl-offset-5.ant-col-rtl {
      margin-right: 20.83333333%;
      margin-left: 0;
    }
    .ant-col-xl-push-6.ant-col-rtl {
      right: 25%;
      left: auto;
    }
    .ant-col-xl-pull-6.ant-col-rtl {
      right: auto;
      left: 25%;
    }
    .ant-col-xl-offset-6.ant-col-rtl {
      margin-right: 25%;
      margin-left: 0;
    }
    .ant-col-xl-push-7.ant-col-rtl {
      right: 29.16666667%;
      left: auto;
    }
    .ant-col-xl-pull-7.ant-col-rtl {
      right: auto;
      left: 29.16666667%;
    }
    .ant-col-xl-offset-7.ant-col-rtl {
      margin-right: 29.16666667%;
      margin-left: 0;
    }
    .ant-col-xl-push-8.ant-col-rtl {
      right: 33.33333333%;
      left: auto;
    }
    .ant-col-xl-pull-8.ant-col-rtl {
      right: auto;
      left: 33.33333333%;
    }
    .ant-col-xl-offset-8.ant-col-rtl {
      margin-right: 33.33333333%;
      margin-left: 0;
    }
    .ant-col-xl-push-9.ant-col-rtl {
      right: 37.5%;
      left: auto;
    }
    .ant-col-xl-pull-9.ant-col-rtl {
      right: auto;
      left: 37.5%;
    }
    .ant-col-xl-offset-9.ant-col-rtl {
      margin-right: 37.5%;
      margin-left: 0;
    }
    .ant-col-xl-push-10.ant-col-rtl {
      right: 41.66666667%;
      left: auto;
    }
    .ant-col-xl-pull-10.ant-col-rtl {
      right: auto;
      left: 41.66666667%;
    }
    .ant-col-xl-offset-10.ant-col-rtl {
      margin-right: 41.66666667%;
      margin-left: 0;
    }
    .ant-col-xl-push-11.ant-col-rtl {
      right: 45.83333333%;
      left: auto;
    }
    .ant-col-xl-pull-11.ant-col-rtl {
      right: auto;
      left: 45.83333333%;
    }
    .ant-col-xl-offset-11.ant-col-rtl {
      margin-right: 45.83333333%;
      margin-left: 0;
    }
    .ant-col-xl-push-12.ant-col-rtl {
      right: 50%;
      left: auto;
    }
    .ant-col-xl-pull-12.ant-col-rtl {
      right: auto;
      left: 50%;
    }
    .ant-col-xl-offset-12.ant-col-rtl {
      margin-right: 50%;
      margin-left: 0;
    }
    .ant-col-xl-push-13.ant-col-rtl {
      right: 54.16666667%;
      left: auto;
    }
    .ant-col-xl-pull-13.ant-col-rtl {
      right: auto;
      left: 54.16666667%;
    }
    .ant-col-xl-offset-13.ant-col-rtl {
      margin-right: 54.16666667%;
      margin-left: 0;
    }
    .ant-col-xl-push-14.ant-col-rtl {
      right: 58.33333333%;
      left: auto;
    }
    .ant-col-xl-pull-14.ant-col-rtl {
      right: auto;
      left: 58.33333333%;
    }
    .ant-col-xl-offset-14.ant-col-rtl {
      margin-right: 58.33333333%;
      margin-left: 0;
    }
    .ant-col-xl-push-15.ant-col-rtl {
      right: 62.5%;
      left: auto;
    }
    .ant-col-xl-pull-15.ant-col-rtl {
      right: auto;
      left: 62.5%;
    }
    .ant-col-xl-offset-15.ant-col-rtl {
      margin-right: 62.5%;
      margin-left: 0;
    }
    .ant-col-xl-push-16.ant-col-rtl {
      right: 66.66666667%;
      left: auto;
    }
    .ant-col-xl-pull-16.ant-col-rtl {
      right: auto;
      left: 66.66666667%;
    }
    .ant-col-xl-offset-16.ant-col-rtl {
      margin-right: 66.66666667%;
      margin-left: 0;
    }
    .ant-col-xl-push-17.ant-col-rtl {
      right: 70.83333333%;
      left: auto;
    }
    .ant-col-xl-pull-17.ant-col-rtl {
      right: auto;
      left: 70.83333333%;
    }
    .ant-col-xl-offset-17.ant-col-rtl {
      margin-right: 70.83333333%;
      margin-left: 0;
    }
    .ant-col-xl-push-18.ant-col-rtl {
      right: 75%;
      left: auto;
    }
    .ant-col-xl-pull-18.ant-col-rtl {
      right: auto;
      left: 75%;
    }
    .ant-col-xl-offset-18.ant-col-rtl {
      margin-right: 75%;
      margin-left: 0;
    }
    .ant-col-xl-push-19.ant-col-rtl {
      right: 79.16666667%;
      left: auto;
    }
    .ant-col-xl-pull-19.ant-col-rtl {
      right: auto;
      left: 79.16666667%;
    }
    .ant-col-xl-offset-19.ant-col-rtl {
      margin-right: 79.16666667%;
      margin-left: 0;
    }
    .ant-col-xl-push-20.ant-col-rtl {
      right: 83.33333333%;
      left: auto;
    }
    .ant-col-xl-pull-20.ant-col-rtl {
      right: auto;
      left: 83.33333333%;
    }
    .ant-col-xl-offset-20.ant-col-rtl {
      margin-right: 83.33333333%;
      margin-left: 0;
    }
    .ant-col-xl-push-21.ant-col-rtl {
      right: 87.5%;
      left: auto;
    }
    .ant-col-xl-pull-21.ant-col-rtl {
      right: auto;
      left: 87.5%;
    }
    .ant-col-xl-offset-21.ant-col-rtl {
      margin-right: 87.5%;
      margin-left: 0;
    }
    .ant-col-xl-push-22.ant-col-rtl {
      right: 91.66666667%;
      left: auto;
    }
    .ant-col-xl-pull-22.ant-col-rtl {
      right: auto;
      left: 91.66666667%;
    }
    .ant-col-xl-offset-22.ant-col-rtl {
      margin-right: 91.66666667%;
      margin-left: 0;
    }
    .ant-col-xl-push-23.ant-col-rtl {
      right: 95.83333333%;
      left: auto;
    }
    .ant-col-xl-pull-23.ant-col-rtl {
      right: auto;
      left: 95.83333333%;
    }
    .ant-col-xl-offset-23.ant-col-rtl {
      margin-right: 95.83333333%;
      margin-left: 0;
    }
    .ant-col-xl-push-24.ant-col-rtl {
      right: 100%;
      left: auto;
    }
    .ant-col-xl-pull-24.ant-col-rtl {
      right: auto;
      left: 100%;
    }
    .ant-col-xl-offset-24.ant-col-rtl {
      margin-right: 100%;
      margin-left: 0;
    }
  }
  @media (min-width: 1600px) {
    .ant-col-xxl-24 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .ant-col-xxl-push-24 {
      left: 100%;
    }
    .ant-col-xxl-pull-24 {
      right: 100%;
    }
    .ant-col-xxl-offset-24 {
      margin-left: 100%;
    }
    .ant-col-xxl-order-24 {
      order: 24;
    }
    .ant-col-xxl-23 {
      display: block;
      flex: 0 0 95.83333333%;
      max-width: 95.83333333%;
    }
    .ant-col-xxl-push-23 {
      left: 95.83333333%;
    }
    .ant-col-xxl-pull-23 {
      right: 95.83333333%;
    }
    .ant-col-xxl-offset-23 {
      margin-left: 95.83333333%;
    }
    .ant-col-xxl-order-23 {
      order: 23;
    }
    .ant-col-xxl-22 {
      display: block;
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%;
    }
    .ant-col-xxl-push-22 {
      left: 91.66666667%;
    }
    .ant-col-xxl-pull-22 {
      right: 91.66666667%;
    }
    .ant-col-xxl-offset-22 {
      margin-left: 91.66666667%;
    }
    .ant-col-xxl-order-22 {
      order: 22;
    }
    .ant-col-xxl-21 {
      display: block;
      flex: 0 0 87.5%;
      max-width: 87.5%;
    }
    .ant-col-xxl-push-21 {
      left: 87.5%;
    }
    .ant-col-xxl-pull-21 {
      right: 87.5%;
    }
    .ant-col-xxl-offset-21 {
      margin-left: 87.5%;
    }
    .ant-col-xxl-order-21 {
      order: 21;
    }
    .ant-col-xxl-20 {
      display: block;
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%;
    }
    .ant-col-xxl-push-20 {
      left: 83.33333333%;
    }
    .ant-col-xxl-pull-20 {
      right: 83.33333333%;
    }
    .ant-col-xxl-offset-20 {
      margin-left: 83.33333333%;
    }
    .ant-col-xxl-order-20 {
      order: 20;
    }
    .ant-col-xxl-19 {
      display: block;
      flex: 0 0 79.16666667%;
      max-width: 79.16666667%;
    }
    .ant-col-xxl-push-19 {
      left: 79.16666667%;
    }
    .ant-col-xxl-pull-19 {
      right: 79.16666667%;
    }
    .ant-col-xxl-offset-19 {
      margin-left: 79.16666667%;
    }
    .ant-col-xxl-order-19 {
      order: 19;
    }
    .ant-col-xxl-18 {
      display: block;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .ant-col-xxl-push-18 {
      left: 75%;
    }
    .ant-col-xxl-pull-18 {
      right: 75%;
    }
    .ant-col-xxl-offset-18 {
      margin-left: 75%;
    }
    .ant-col-xxl-order-18 {
      order: 18;
    }
    .ant-col-xxl-17 {
      display: block;
      flex: 0 0 70.83333333%;
      max-width: 70.83333333%;
    }
    .ant-col-xxl-push-17 {
      left: 70.83333333%;
    }
    .ant-col-xxl-pull-17 {
      right: 70.83333333%;
    }
    .ant-col-xxl-offset-17 {
      margin-left: 70.83333333%;
    }
    .ant-col-xxl-order-17 {
      order: 17;
    }
    .ant-col-xxl-16 {
      display: block;
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }
    .ant-col-xxl-push-16 {
      left: 66.66666667%;
    }
    .ant-col-xxl-pull-16 {
      right: 66.66666667%;
    }
    .ant-col-xxl-offset-16 {
      margin-left: 66.66666667%;
    }
    .ant-col-xxl-order-16 {
      order: 16;
    }
    .ant-col-xxl-15 {
      display: block;
      flex: 0 0 62.5%;
      max-width: 62.5%;
    }
    .ant-col-xxl-push-15 {
      left: 62.5%;
    }
    .ant-col-xxl-pull-15 {
      right: 62.5%;
    }
    .ant-col-xxl-offset-15 {
      margin-left: 62.5%;
    }
    .ant-col-xxl-order-15 {
      order: 15;
    }
    .ant-col-xxl-14 {
      display: block;
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }
    .ant-col-xxl-push-14 {
      left: 58.33333333%;
    }
    .ant-col-xxl-pull-14 {
      right: 58.33333333%;
    }
    .ant-col-xxl-offset-14 {
      margin-left: 58.33333333%;
    }
    .ant-col-xxl-order-14 {
      order: 14;
    }
    .ant-col-xxl-13 {
      display: block;
      flex: 0 0 54.16666667%;
      max-width: 54.16666667%;
    }
    .ant-col-xxl-push-13 {
      left: 54.16666667%;
    }
    .ant-col-xxl-pull-13 {
      right: 54.16666667%;
    }
    .ant-col-xxl-offset-13 {
      margin-left: 54.16666667%;
    }
    .ant-col-xxl-order-13 {
      order: 13;
    }
    .ant-col-xxl-12 {
      display: block;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .ant-col-xxl-push-12 {
      left: 50%;
    }
    .ant-col-xxl-pull-12 {
      right: 50%;
    }
    .ant-col-xxl-offset-12 {
      margin-left: 50%;
    }
    .ant-col-xxl-order-12 {
      order: 12;
    }
    .ant-col-xxl-11 {
      display: block;
      flex: 0 0 45.83333333%;
      max-width: 45.83333333%;
    }
    .ant-col-xxl-push-11 {
      left: 45.83333333%;
    }
    .ant-col-xxl-pull-11 {
      right: 45.83333333%;
    }
    .ant-col-xxl-offset-11 {
      margin-left: 45.83333333%;
    }
    .ant-col-xxl-order-11 {
      order: 11;
    }
    .ant-col-xxl-10 {
      display: block;
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }
    .ant-col-xxl-push-10 {
      left: 41.66666667%;
    }
    .ant-col-xxl-pull-10 {
      right: 41.66666667%;
    }
    .ant-col-xxl-offset-10 {
      margin-left: 41.66666667%;
    }
    .ant-col-xxl-order-10 {
      order: 10;
    }
    .ant-col-xxl-9 {
      display: block;
      flex: 0 0 37.5%;
      max-width: 37.5%;
    }
    .ant-col-xxl-push-9 {
      left: 37.5%;
    }
    .ant-col-xxl-pull-9 {
      right: 37.5%;
    }
    .ant-col-xxl-offset-9 {
      margin-left: 37.5%;
    }
    .ant-col-xxl-order-9 {
      order: 9;
    }
    .ant-col-xxl-8 {
      display: block;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
    .ant-col-xxl-push-8 {
      left: 33.33333333%;
    }
    .ant-col-xxl-pull-8 {
      right: 33.33333333%;
    }
    .ant-col-xxl-offset-8 {
      margin-left: 33.33333333%;
    }
    .ant-col-xxl-order-8 {
      order: 8;
    }
    .ant-col-xxl-7 {
      display: block;
      flex: 0 0 29.16666667%;
      max-width: 29.16666667%;
    }
    .ant-col-xxl-push-7 {
      left: 29.16666667%;
    }
    .ant-col-xxl-pull-7 {
      right: 29.16666667%;
    }
    .ant-col-xxl-offset-7 {
      margin-left: 29.16666667%;
    }
    .ant-col-xxl-order-7 {
      order: 7;
    }
    .ant-col-xxl-6 {
      display: block;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .ant-col-xxl-push-6 {
      left: 25%;
    }
    .ant-col-xxl-pull-6 {
      right: 25%;
    }
    .ant-col-xxl-offset-6 {
      margin-left: 25%;
    }
    .ant-col-xxl-order-6 {
      order: 6;
    }
    .ant-col-xxl-5 {
      display: block;
      flex: 0 0 20.83333333%;
      max-width: 20.83333333%;
    }
    .ant-col-xxl-push-5 {
      left: 20.83333333%;
    }
    .ant-col-xxl-pull-5 {
      right: 20.83333333%;
    }
    .ant-col-xxl-offset-5 {
      margin-left: 20.83333333%;
    }
    .ant-col-xxl-order-5 {
      order: 5;
    }
    .ant-col-xxl-4 {
      display: block;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
    }
    .ant-col-xxl-push-4 {
      left: 16.66666667%;
    }
    .ant-col-xxl-pull-4 {
      right: 16.66666667%;
    }
    .ant-col-xxl-offset-4 {
      margin-left: 16.66666667%;
    }
    .ant-col-xxl-order-4 {
      order: 4;
    }
    .ant-col-xxl-3 {
      display: block;
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
    .ant-col-xxl-push-3 {
      left: 12.5%;
    }
    .ant-col-xxl-pull-3 {
      right: 12.5%;
    }
    .ant-col-xxl-offset-3 {
      margin-left: 12.5%;
    }
    .ant-col-xxl-order-3 {
      order: 3;
    }
    .ant-col-xxl-2 {
      display: block;
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%;
    }
    .ant-col-xxl-push-2 {
      left: 8.33333333%;
    }
    .ant-col-xxl-pull-2 {
      right: 8.33333333%;
    }
    .ant-col-xxl-offset-2 {
      margin-left: 8.33333333%;
    }
    .ant-col-xxl-order-2 {
      order: 2;
    }
    .ant-col-xxl-1 {
      display: block;
      flex: 0 0 4.16666667%;
      max-width: 4.16666667%;
    }
    .ant-col-xxl-push-1 {
      left: 4.16666667%;
    }
    .ant-col-xxl-pull-1 {
      right: 4.16666667%;
    }
    .ant-col-xxl-offset-1 {
      margin-left: 4.16666667%;
    }
    .ant-col-xxl-order-1 {
      order: 1;
    }
    .ant-col-xxl-0 {
      display: none;
    }
    .ant-col-push-0 {
      left: auto;
    }
    .ant-col-pull-0 {
      right: auto;
    }
    .ant-col-xxl-push-0 {
      left: auto;
    }
    .ant-col-push-0.ant-col-rtl,
    .ant-col-xxl-pull-0 {
      right: auto;
    }
    .ant-col-xxl-offset-0 {
      margin-left: 0;
    }
    .ant-col-xxl-order-0 {
      order: 0;
    }
    .ant-col-pull-0.ant-col-rtl {
      left: auto;
    }
    .ant-col-xxl-push-0.ant-col-rtl {
      right: auto;
    }
    .ant-col-xxl-pull-0.ant-col-rtl {
      left: auto;
    }
    .ant-col-xxl-offset-0.ant-col-rtl {
      margin-right: 0;
    }
    .ant-col-xxl-push-1.ant-col-rtl {
      right: 4.16666667%;
      left: auto;
    }
    .ant-col-xxl-pull-1.ant-col-rtl {
      right: auto;
      left: 4.16666667%;
    }
    .ant-col-xxl-offset-1.ant-col-rtl {
      margin-right: 4.16666667%;
      margin-left: 0;
    }
    .ant-col-xxl-push-2.ant-col-rtl {
      right: 8.33333333%;
      left: auto;
    }
    .ant-col-xxl-pull-2.ant-col-rtl {
      right: auto;
      left: 8.33333333%;
    }
    .ant-col-xxl-offset-2.ant-col-rtl {
      margin-right: 8.33333333%;
      margin-left: 0;
    }
    .ant-col-xxl-push-3.ant-col-rtl {
      right: 12.5%;
      left: auto;
    }
    .ant-col-xxl-pull-3.ant-col-rtl {
      right: auto;
      left: 12.5%;
    }
    .ant-col-xxl-offset-3.ant-col-rtl {
      margin-right: 12.5%;
      margin-left: 0;
    }
    .ant-col-xxl-push-4.ant-col-rtl {
      right: 16.66666667%;
      left: auto;
    }
    .ant-col-xxl-pull-4.ant-col-rtl {
      right: auto;
      left: 16.66666667%;
    }
    .ant-col-xxl-offset-4.ant-col-rtl {
      margin-right: 16.66666667%;
      margin-left: 0;
    }
    .ant-col-xxl-push-5.ant-col-rtl {
      right: 20.83333333%;
      left: auto;
    }
    .ant-col-xxl-pull-5.ant-col-rtl {
      right: auto;
      left: 20.83333333%;
    }
    .ant-col-xxl-offset-5.ant-col-rtl {
      margin-right: 20.83333333%;
      margin-left: 0;
    }
    .ant-col-xxl-push-6.ant-col-rtl {
      right: 25%;
      left: auto;
    }
    .ant-col-xxl-pull-6.ant-col-rtl {
      right: auto;
      left: 25%;
    }
    .ant-col-xxl-offset-6.ant-col-rtl {
      margin-right: 25%;
      margin-left: 0;
    }
    .ant-col-xxl-push-7.ant-col-rtl {
      right: 29.16666667%;
      left: auto;
    }
    .ant-col-xxl-pull-7.ant-col-rtl {
      right: auto;
      left: 29.16666667%;
    }
    .ant-col-xxl-offset-7.ant-col-rtl {
      margin-right: 29.16666667%;
      margin-left: 0;
    }
    .ant-col-xxl-push-8.ant-col-rtl {
      right: 33.33333333%;
      left: auto;
    }
    .ant-col-xxl-pull-8.ant-col-rtl {
      right: auto;
      left: 33.33333333%;
    }
    .ant-col-xxl-offset-8.ant-col-rtl {
      margin-right: 33.33333333%;
      margin-left: 0;
    }
    .ant-col-xxl-push-9.ant-col-rtl {
      right: 37.5%;
      left: auto;
    }
    .ant-col-xxl-pull-9.ant-col-rtl {
      right: auto;
      left: 37.5%;
    }
    .ant-col-xxl-offset-9.ant-col-rtl {
      margin-right: 37.5%;
      margin-left: 0;
    }
    .ant-col-xxl-push-10.ant-col-rtl {
      right: 41.66666667%;
      left: auto;
    }
    .ant-col-xxl-pull-10.ant-col-rtl {
      right: auto;
      left: 41.66666667%;
    }
    .ant-col-xxl-offset-10.ant-col-rtl {
      margin-right: 41.66666667%;
      margin-left: 0;
    }
    .ant-col-xxl-push-11.ant-col-rtl {
      right: 45.83333333%;
      left: auto;
    }
    .ant-col-xxl-pull-11.ant-col-rtl {
      right: auto;
      left: 45.83333333%;
    }
    .ant-col-xxl-offset-11.ant-col-rtl {
      margin-right: 45.83333333%;
      margin-left: 0;
    }
    .ant-col-xxl-push-12.ant-col-rtl {
      right: 50%;
      left: auto;
    }
    .ant-col-xxl-pull-12.ant-col-rtl {
      right: auto;
      left: 50%;
    }
    .ant-col-xxl-offset-12.ant-col-rtl {
      margin-right: 50%;
      margin-left: 0;
    }
    .ant-col-xxl-push-13.ant-col-rtl {
      right: 54.16666667%;
      left: auto;
    }
    .ant-col-xxl-pull-13.ant-col-rtl {
      right: auto;
      left: 54.16666667%;
    }
    .ant-col-xxl-offset-13.ant-col-rtl {
      margin-right: 54.16666667%;
      margin-left: 0;
    }
    .ant-col-xxl-push-14.ant-col-rtl {
      right: 58.33333333%;
      left: auto;
    }
    .ant-col-xxl-pull-14.ant-col-rtl {
      right: auto;
      left: 58.33333333%;
    }
    .ant-col-xxl-offset-14.ant-col-rtl {
      margin-right: 58.33333333%;
      margin-left: 0;
    }
    .ant-col-xxl-push-15.ant-col-rtl {
      right: 62.5%;
      left: auto;
    }
    .ant-col-xxl-pull-15.ant-col-rtl {
      right: auto;
      left: 62.5%;
    }
    .ant-col-xxl-offset-15.ant-col-rtl {
      margin-right: 62.5%;
      margin-left: 0;
    }
    .ant-col-xxl-push-16.ant-col-rtl {
      right: 66.66666667%;
      left: auto;
    }
    .ant-col-xxl-pull-16.ant-col-rtl {
      right: auto;
      left: 66.66666667%;
    }
    .ant-col-xxl-offset-16.ant-col-rtl {
      margin-right: 66.66666667%;
      margin-left: 0;
    }
    .ant-col-xxl-push-17.ant-col-rtl {
      right: 70.83333333%;
      left: auto;
    }
    .ant-col-xxl-pull-17.ant-col-rtl {
      right: auto;
      left: 70.83333333%;
    }
    .ant-col-xxl-offset-17.ant-col-rtl {
      margin-right: 70.83333333%;
      margin-left: 0;
    }
    .ant-col-xxl-push-18.ant-col-rtl {
      right: 75%;
      left: auto;
    }
    .ant-col-xxl-pull-18.ant-col-rtl {
      right: auto;
      left: 75%;
    }
    .ant-col-xxl-offset-18.ant-col-rtl {
      margin-right: 75%;
      margin-left: 0;
    }
    .ant-col-xxl-push-19.ant-col-rtl {
      right: 79.16666667%;
      left: auto;
    }
    .ant-col-xxl-pull-19.ant-col-rtl {
      right: auto;
      left: 79.16666667%;
    }
    .ant-col-xxl-offset-19.ant-col-rtl {
      margin-right: 79.16666667%;
      margin-left: 0;
    }
    .ant-col-xxl-push-20.ant-col-rtl {
      right: 83.33333333%;
      left: auto;
    }
    .ant-col-xxl-pull-20.ant-col-rtl {
      right: auto;
      left: 83.33333333%;
    }
    .ant-col-xxl-offset-20.ant-col-rtl {
      margin-right: 83.33333333%;
      margin-left: 0;
    }
    .ant-col-xxl-push-21.ant-col-rtl {
      right: 87.5%;
      left: auto;
    }
    .ant-col-xxl-pull-21.ant-col-rtl {
      right: auto;
      left: 87.5%;
    }
    .ant-col-xxl-offset-21.ant-col-rtl {
      margin-right: 87.5%;
      margin-left: 0;
    }
    .ant-col-xxl-push-22.ant-col-rtl {
      right: 91.66666667%;
      left: auto;
    }
    .ant-col-xxl-pull-22.ant-col-rtl {
      right: auto;
      left: 91.66666667%;
    }
    .ant-col-xxl-offset-22.ant-col-rtl {
      margin-right: 91.66666667%;
      margin-left: 0;
    }
    .ant-col-xxl-push-23.ant-col-rtl {
      right: 95.83333333%;
      left: auto;
    }
    .ant-col-xxl-pull-23.ant-col-rtl {
      right: auto;
      left: 95.83333333%;
    }
    .ant-col-xxl-offset-23.ant-col-rtl {
      margin-right: 95.83333333%;
      margin-left: 0;
    }
    .ant-col-xxl-push-24.ant-col-rtl {
      right: 100%;
      left: auto;
    }
    .ant-col-xxl-pull-24.ant-col-rtl {
      right: auto;
      left: 100%;
    }
    .ant-col-xxl-offset-24.ant-col-rtl {
      margin-right: 100%;
      margin-left: 0;
    }
  }
  .ant-row-rtl {
    direction: rtl;
  }
  .ant-image {
    position: relative;
    display: inline-block;
  }
  .ant-image-img {
    width: 100%;
    height: auto;
  }
  .ant-image-img-placeholder {
    background-color: #f5f5f5;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQuNSAyLjVoLTEzQS41LjUgMCAwIDAgMSAzdjEwYS41LjUgMCAwIDAgLjUuNWgxM2EuNS41IDAgMCAwIC41LS41VjNhLjUuNSAwIDAgMC0uNS0uNXpNNS4yODEgNC43NWExIDEgMCAwIDEgMCAyIDEgMSAwIDAgMSAwLTJ6bTguMDMgNi44M2EuMTI3LjEyNyAwIDAgMS0uMDgxLjAzSDIuNzY5YS4xMjUuMTI1IDAgMCAxLS4wOTYtLjIwN2wyLjY2MS0zLjE1NmEuMTI2LjEyNiAwIDAgMSAuMTc3LS4wMTZsLjAxNi4wMTZMNy4wOCAxMC4wOWwyLjQ3LTIuOTNhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTUuMDE2IDMuNTg4IDQuMjQ0YS4xMjcuMTI3IDAgMCAxLS4wMi4xNzV6IiBmaWxsPSIjOEM4QzhDIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 30%;
  }
  .ant-image-placeholder {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .ant-image-preview {
    height: 100%;
    text-align: center;
  }
  .ant-image-preview-body {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
  }
  .ant-image-preview-img,
  .ant-image-preview-img-wrapper {
    transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  }
  .ant-image-preview-img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
    transform: scale3d(1, 1, 1);
    cursor: -webkit-grab;
    cursor: grab;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: auto;
  }
  .ant-image-preview-img-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .ant-image-preview-img-wrapper::before {
    display: inline-block;
    width: 1px;
    height: 50%;
    margin-right: -1px;
    content: "";
  }
  .ant-image-preview-moving .ant-image-preview-img {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
  .ant-image-preview-moving .ant-image-preview-img-wrapper {
    transition-duration: 0s;
  }
  .ant-image-preview-wrap {
    z-index: 1080;
  }
  .ant-image-preview-operations {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    font-feature-settings: "tnum";
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    color: rgba(255, 255, 255, 0.85);
    list-style: none;
    background: rgba(0, 0, 0, 0.1);
    pointer-events: auto;
  }
  .ant-image-preview-operations-operation {
    margin-left: 12px;
    padding: 12px;
    cursor: pointer;
  }
  .ant-image-preview-operations-operation-disabled {
    color: rgba(255, 255, 255, 0.45);
    pointer-events: none;
  }
  .ant-image-preview-operations-operation:last-of-type {
    margin-left: 0;
  }
  .ant-image-preview-operations-icon {
    font-size: 18px;
  }
  .ant-input-affix-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: transparent;
    background-image: none;
    border: 1px solid #6f6c6c;
    border-radius: 4px;
    transition: all 0.3s;
    display: inline-flex;
  }
  .ant-input-affix-wrapper::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-input-affix-wrapper:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.3);
    text-overflow: ellipsis;
  }
  .ant-input-affix-wrapper::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-input-affix-wrapper:placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-input-affix-wrapper:hover {
    border-color: #287a99;
    border-right-width: 1px !important;
  }
  .ant-input-rtl .ant-input-affix-wrapper:hover {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:focus {
    border-color: #2caad9;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(44, 170, 217, 0.2);
  }
  .ant-input-rtl .ant-input-affix-wrapper-focused,
  .ant-input-rtl .ant-input-affix-wrapper:focus {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ant-input-affix-wrapper-disabled:hover,
  .ant-input-affix-wrapper[disabled]:hover {
    border-color: #6f6c6c;
    border-right-width: 1px !important;
  }
  .ant-input-affix-wrapper-disabled,
  .ant-input-affix-wrapper[disabled] {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-input-affix-wrapper-borderless,
  .ant-input-affix-wrapper-borderless-disabled,
  .ant-input-affix-wrapper-borderless-focused,
  .ant-input-affix-wrapper-borderless:focus,
  .ant-input-affix-wrapper-borderless:hover,
  .ant-input-affix-wrapper-borderless[disabled] {
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }
  .ant-input-affix-wrapper-lg {
    padding: 6.5px 11px;
    font-size: 16px;
  }
  .ant-input-affix-wrapper-sm {
    padding: 0 7px;
  }
  .ant-input-affix-wrapper-rtl {
    direction: rtl;
  }
  .ant-input-affix-wrapper-disabled .ant-input[disabled] {
    background: 0 0;
  }
  .ant-input-affix-wrapper > input.ant-input {
    padding: 0;
    border: 0;
    outline: 0;
  }
  .ant-input-affix-wrapper > input.ant-input:focus {
    box-shadow: none;
  }
  .ant-input-affix-wrapper::before {
    width: 0;
    visibility: hidden;
    content: "\a0";
  }
  .ant-input-prefix,
  .ant-input-suffix {
    display: flex;
    flex: none;
    align-items: center;
  }
  .ant-input-prefix {
    margin-right: 4px;
  }
  .ant-input-suffix {
    margin-left: 4px;
  }
  .ant-input-clear-icon {
    color: rgba(255, 255, 255, 0.3);
    font-size: 12px;
    cursor: pointer;
    transition: color 0.3s;
    margin: 0 4px;
    vertical-align: -1px;
  }
  .ant-input-clear-icon:hover {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-input-clear-icon:active {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-input-clear-icon + i,
  .ant-input-textarea-clear-icon + i {
    margin-left: 6px;
  }
  .ant-input-clear-icon-hidden {
    visibility: hidden;
  }
  .ant-input-clear-icon:last-child {
    margin-right: 0;
  }
  .ant-input-affix-wrapper-textarea-with-clear-btn {
    padding: 0 !important;
    border: 0 !important;
  }
  .ant-input-textarea-clear-icon {
    color: rgba(255, 255, 255, 0.3);
    font-size: 12px;
    cursor: pointer;
    transition: color 0.3s;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    margin: 8px 8px 0 0;
  }
  .ant-input-textarea-clear-icon:hover {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-input-textarea-clear-icon:active {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-input-textarea-clear-icon-hidden {
    visibility: hidden;
  }
  .ant-input {
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: transparent;
    background-image: none;
    border: 1px solid #6f6c6c;
    border-radius: 4px;
    transition: all 0.3s;
  }
  .ant-input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.3);
    text-overflow: ellipsis;
  }
  .ant-input::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-input:placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-input:hover {
    border-color: #287a99;
    border-right-width: 1px !important;
  }
  .ant-input-rtl .ant-input:hover {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ant-input-focused,
  .ant-input:focus {
    border-color: #2caad9;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(44, 170, 217, 0.2);
  }
  .ant-input-rtl .ant-input-focused,
  .ant-input-rtl .ant-input:focus {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ant-input-disabled:hover,
  .ant-input[disabled]:hover {
    border-color: #6f6c6c;
    border-right-width: 1px !important;
  }
  .ant-input-disabled,
  .ant-input[disabled] {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-input-borderless,
  .ant-input-borderless-disabled,
  .ant-input-borderless-focused,
  .ant-input-borderless:focus,
  .ant-input-borderless:hover,
  .ant-input-borderless[disabled] {
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }
  .ant-input-lg {
    padding: 6.5px 11px;
    font-size: 16px;
  }
  .ant-input-sm {
    padding: 0 7px;
  }
  .ant-input-rtl {
    direction: rtl;
  }
  .ant-input-group {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: table;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }
  .ant-input-group[class*="col-"] {
    float: none;
    padding-right: 0;
    padding-left: 0;
  }
  .ant-input-group > [class*="col-"] {
    padding-right: 8px;
  }
  .ant-input-group > [class*="col-"]:last-child {
    padding-right: 0;
  }
  .ant-input-group-addon,
  .ant-input-group-wrap,
  .ant-input-group > .ant-input {
    display: table-cell;
  }
  .ant-input-group-addon:not(:first-child):not(:last-child),
  .ant-input-group-wrap:not(:first-child):not(:last-child),
  .ant-input-group > .ant-input:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .ant-input-group-addon,
  .ant-input-group-wrap {
    width: 1px;
    white-space: nowrap;
    vertical-align: middle;
  }
  .ant-input-group-wrap > * {
    display: block !important;
  }
  .ant-input-group .ant-input {
    float: left;
    width: 100%;
    margin-bottom: 0;
    text-align: inherit;
  }
  .ant-input-group .ant-input:focus {
    z-index: 1;
    border-right-width: 1px;
  }
  .ant-input-group .ant-input:hover {
    z-index: 1;
    border-right-width: 1px;
  }
  .ant-input-group-addon {
    position: relative;
    padding: 0 11px;
    color: rgba(255, 255, 255, 0.85);
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.04);
    border: 1px solid #6f6c6c;
    border-radius: 4px;
    transition: all 0.3s;
  }
  .ant-input-group-addon .ant-select {
    margin: -5px -11px;
  }
  .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: inherit;
    border: 1px solid transparent;
    box-shadow: none;
  }
  .ant-input-group-addon .ant-select-focused .ant-select-selector,
  .ant-input-group-addon .ant-select-open .ant-select-selector,
  .ant-list-item-meta-title > a:hover {
    color: #2caad9;
  }
  .ant-input-group-addon > i:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
  }
  .ant-input-group-addon:first-child,
  .ant-input-group-addon:first-child .ant-select .ant-select-selector,
  .ant-input-group > .ant-input:first-child,
  .ant-input-group > .ant-input:first-child .ant-select .ant-select-selector {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ant-input-group-addon:first-child {
    border-right: 0;
  }
  .ant-input-group-addon:last-child {
    border-left: 0;
  }
  .ant-input-group-addon:last-child,
  .ant-input-group-addon:last-child .ant-select .ant-select-selector,
  .ant-input-group > .ant-input:last-child,
  .ant-input-group > .ant-input:last-child .ant-select .ant-select-selector {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ant-input-group-lg .ant-input,
  .ant-input-group-lg > .ant-input-group-addon {
    padding: 6.5px 11px;
    font-size: 16px;
  }
  .ant-input-group-sm .ant-input,
  .ant-input-group-sm > .ant-input-group-addon {
    padding: 0 7px;
  }
  .ant-input-group-lg .ant-select-single .ant-select-selector {
    height: 40px;
  }
  .ant-input-group-sm .ant-select-single .ant-select-selector {
    height: 24px;
  }
  .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ant-input-group.ant-input-group-compact {
    display: block;
  }
  .ant-input-group.ant-input-group-compact::before {
    display: table;
    content: "";
  }
  .ant-input-group.ant-input-group-compact::after {
    display: table;
    clear: both;
    content: "";
  }
  .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
  .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
  .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
    border-right-width: 1px;
  }
  .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
  .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
  .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover {
    z-index: 1;
  }
  .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
  .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
  .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
    z-index: 1;
  }
  .ant-input-group.ant-input-group-compact > * {
    display: inline-block;
    float: none;
    vertical-align: top;
    border-radius: 0;
  }
  .ant-input-group.ant-input-group-compact > .ant-input-affix-wrapper,
  .ant-input-group.ant-input-group-compact > .ant-picker-range {
    display: inline-flex;
  }
  .ant-input-group.ant-input-group-compact > :not(:last-child) {
    margin-right: -1px;
    border-right-width: 1px;
  }
  .ant-input-group.ant-input-group-compact .ant-input {
    float: none;
  }
  .ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor,
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector,
  .ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input {
    border-right-width: 1px;
    border-radius: 0;
  }
  .ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input:hover,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
  .ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:hover,
  .ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor:hover,
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
  .ant-input-group.ant-input-group-compact > .ant-select-focused,
  .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-arrow,
  .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:hover,
  .ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input:hover {
    z-index: 1;
  }
  .ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input:focus,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
  .ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:focus,
  .ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor:focus,
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
  .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:focus,
  .ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input:focus {
    z-index: 1;
  }
  .ant-input-group.ant-input-group-compact > .ant-calendar-picker:first-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-mention-wrapper:first-child .ant-mention-editor,
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
  .ant-input-group.ant-input-group-compact > .ant-time-picker:first-child .ant-time-picker-input,
  .ant-input-group.ant-input-group-compact > :first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor,
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
  .ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input,
  .ant-input-group.ant-input-group-compact > :last-child {
    border-right-width: 1px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
    vertical-align: top;
  }
  .ant-input-group-rtl .ant-input-group-addon:first-child,
  .ant-input-group > .ant-input-rtl:first-child {
    border-radius: 0 4px 4px 0;
  }
  .ant-input-group-rtl .ant-input-group-addon:first-child {
    border-right: 1px solid #6f6c6c;
    border-left: 0;
  }
  .ant-input-group-rtl .ant-input-group-addon:last-child {
    border-right: 0;
    border-left: 1px solid #6f6c6c;
  }
  .ant-input-group-rtl.ant-input-group-addon:last-child,
  .ant-input-group-rtl.ant-input-group > .ant-input:last-child {
    border-radius: 4px 0 0 4px;
  }
  .ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
    border-radius: 4px 0 0 4px;
  }
  .ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-radius: 0 4px 4px 0;
  }
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > :not(:last-child) {
    margin-right: 0;
    margin-left: -1px;
    border-left-width: 1px;
  }
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-calendar-picker:first-child .ant-input,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:first-child .ant-mention-editor,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-time-picker:first-child .ant-time-picker-input,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > :first-child {
    border-radius: 0 4px 4px 0;
  }
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > :last-child {
    border-left-width: 1px;
    border-radius: 4px 0 0 4px;
  }
  .ant-input-group-wrapper {
    display: inline-block;
    width: 100%;
    text-align: start;
    vertical-align: top;
  }
  .ant-input-password-icon,
  .ant-input-search-icon {
    color: rgba(255, 255, 255, 0.45);
    cursor: pointer;
    transition: all 0.3s;
  }
  .ant-input-password-icon:hover {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-input[type="color"] {
    height: 32px;
  }
  .ant-input[type="color"].ant-input-lg {
    height: 40px;
  }
  .ant-input[type="color"].ant-input-sm {
    height: 24px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .ant-input-textarea-show-count::after {
    display: block;
    color: #d9d9d9;
    text-align: right;
    content: attr(data-count);
  }
  .ant-input-search-icon {
    padding: 0 9px;
    margin-left: 0.5em;
  }
  .ant-input-search-icon::before {
    transform: translateX(-10px);
  }
  .ant-input-search-icon::after {
    width: 32px;
  }
  .ant-input-affix-wrapper-lg .ant-input-search-icon {
    padding: 0 12px;
  }
  .ant-input-affix-wrapper-lg .ant-input-search-icon::before {
    transform: translateX(-13px);
  }
  .ant-input-affix-wrapper-lg .ant-input-search-icon::after {
    width: 40px;
  }
  .ant-input-affix-wrapper-sm .ant-input-search-icon {
    padding: 0 6px;
  }
  .ant-input-affix-wrapper-sm .ant-input-search-icon::before {
    transform: translateX(-7px);
  }
  .ant-input-affix-wrapper-sm .ant-input-search-icon::after {
    width: 24px;
  }
  .ant-input-search-icon:hover {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-input-search-icon::before {
    position: absolute;
    top: 0;
    bottom: 0;
    display: block;
    border-left: 1px solid #6f6c6c;
    transition: all 0.3s;
    content: "";
  }
  .ant-input-search-icon::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
  }
  .ant-input-search:not(.ant-input-search-enter-button) {
    padding-right: 0;
  }
  .ant-input-search-enter-button input,
  .ant-input-search-enter-button.ant-input-affix-wrapper {
    border-right: 0;
  }
  .ant-input-search-enter-button input:focus,
  .ant-input-search-enter-button input:hover {
    border-color: #287a99;
  }
  .ant-input-search-enter-button input + .ant-input-group-addon,
  .ant-input-search-enter-button + .ant-input-group-addon {
    padding: 0;
    border: 0;
  }
  .ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button,
  .ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ant-input-group-rtl,
  .ant-input-group-wrapper-rtl,
  .ant-input-search-rtl {
    direction: rtl;
  }
  .ant-input-affix-wrapper.ant-input-affix-wrapper-rtl > input.ant-input {
    border: 0;
    outline: 0;
  }
  .ant-input-affix-wrapper-rtl .ant-input-prefix {
    margin: 0 0 0 4px;
  }
  .ant-input-affix-wrapper-rtl .ant-input-suffix {
    margin: 0 4px 0 0;
  }
  .ant-input-affix-wrapper-rtl .ant-input-clear-icon:last-child {
    margin-right: 4px;
    margin-left: 0;
  }
  .ant-input-affix-wrapper-rtl .ant-input-textarea-clear-icon {
    right: auto;
    left: 0;
    margin: 8px 0 0 8px;
  }
  .ant-input-search-rtl .ant-input-search-icon {
    margin-right: 0.5em;
    margin-left: 0;
  }
  .ant-input-search-rtl .ant-input-search-icon::before {
    border-left: none;
  }
  .ant-input-search-rtl .ant-input-search-icon::after {
    right: auto;
    left: 0;
    border-right: 1px solid #6f6c6c;
    transition: all 0.3s;
  }
  .ant-input-search-rtl.ant-input-search:not(.ant-input-search-enter-button) {
    padding-right: 11px;
    padding-left: 0;
  }
  .ant-input-search-rtl.ant-input-search-enter-button input,
  .ant-input-search-rtl.ant-input-search-enter-button.ant-input-affix-wrapper {
    border-right: 1px solid #6f6c6c;
    border-left: 0;
  }
  .ant-input-search-rtl.ant-input-search-enter-button input:focus,
  .ant-input-search-rtl.ant-input-search-enter-button input:hover,
  .ant-input-search-rtl.ant-input-search-enter-button.ant-input-affix-wrapper:focus,
  .ant-input-search-rtl.ant-input-search-enter-button.ant-input-affix-wrapper:hover {
    border-color: #287a99;
  }
  .ant-input-search-rtl.ant-input-search-enter-button input + .ant-input-group-addon,
  .ant-input-search-rtl.ant-input-search-enter-button + .ant-input-group-addon {
    padding: 0;
    border: 0;
  }
  .ant-input-search-rtl.ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button,
  .ant-input-search-rtl.ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button {
    width: 100%;
    border-radius: 4px 0 0 4px;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ant-input {
      height: 32px;
    }
    .ant-input-lg {
      height: 40px;
    }
    .ant-input-sm {
      height: 24px;
    }
    .ant-input-affix-wrapper > input.ant-input {
      height: auto;
    }
  }
  .ant-input-number {
    box-sizing: border-box;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    min-width: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: transparent;
    background-image: none;
    transition: all 0.3s;
    display: inline-block;
    width: 90px;
    margin: 0;
    padding: 0;
    border: 1px solid #6f6c6c;
    border-radius: 4px;
  }
  .ant-input-number::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-input-number:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.3);
    text-overflow: ellipsis;
  }
  .ant-input-number::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-input-number:placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-input-rtl .ant-input-number:hover {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ant-input-number-focused,
  .ant-input-number:focus {
    border-color: #2caad9;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(44, 170, 217, 0.2);
  }
  .ant-input-rtl .ant-input-number-focused,
  .ant-input-rtl .ant-input-number:focus {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ant-input-number[disabled]:hover {
    border-color: #6f6c6c;
    border-right-width: 1px !important;
  }
  .ant-input-number[disabled] {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-input-number-borderless,
  .ant-input-number-borderless-disabled,
  .ant-input-number-borderless-focused,
  .ant-input-number-borderless:focus,
  .ant-input-number-borderless:hover,
  .ant-input-number-borderless[disabled] {
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }
  .ant-input-number-handler {
    position: relative;
    display: block;
    width: 100%;
    height: 50%;
    overflow: hidden;
    color: rgba(255, 255, 255, 0.45);
    font-weight: 700;
    line-height: 0;
    text-align: center;
    transition: all 0.1s linear;
  }
  .ant-input-number-handler:active {
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-input-number-handler:hover .ant-input-number-handler-down-inner,
  .ant-input-number-handler:hover .ant-input-number-handler-up-inner {
    color: #287a99;
  }
  .ant-input-number-handler-down-inner,
  .ant-input-number-handler-up-inner {
    display: inline-block;
    color: inherit;
    font-style: normal;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    right: 4px;
    width: 12px;
    height: 12px;
    color: rgba(255, 255, 255, 0.45);
    line-height: 12px;
    transition: all 0.1s linear;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-input-number-handler-down-inner > *,
  .ant-input-number-handler-up-inner > * {
    line-height: 1;
  }
  .ant-input-number-handler-down-inner::before,
  .ant-input-number-handler-up-inner::before {
    display: none;
  }
  .ant-input-number-handler-down-inner .ant-input-number-handler-down-inner-icon,
  .ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon,
  .ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon,
  .ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon {
    display: block;
  }
  .ant-input-number-focused,
  .ant-input-number:hover {
    border-color: #287a99;
    border-right-width: 1px !important;
  }
  .ant-input-number:hover + .ant-form-item-children-icon {
    opacity: 0;
    transition: opacity 0.24s linear 0.24s;
  }
  .ant-input-number-focused {
    border-color: #2caad9;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(44, 170, 217, 0.2);
  }
  .ant-input-rtl .ant-input-number-focused {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ant-input-number-disabled {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-input-number-disabled:hover {
    border-color: #6f6c6c;
    border-right-width: 1px !important;
  }
  .ant-input-number-disabled .ant-input-number-input {
    cursor: not-allowed;
  }
  .ant-input-number-disabled .ant-input-number-handler-wrap,
  .ant-input-number-readonly .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-input-number-input {
    width: 100%;
    height: 30px;
    padding: 0 11px;
    text-align: left;
    background-color: transparent;
    border: 0;
    border-radius: 4px;
    outline: 0;
    transition: all 0.3s linear;
    -moz-appearance: textfield !important;
  }
  .ant-input-number-input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-input-number-input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.3);
    text-overflow: ellipsis;
  }
  .ant-input-number-input::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-input-number-input:placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-input-number-input[type="number"]::-webkit-inner-spin-button,
  .ant-input-number-input[type="number"]::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }
  .ant-input-number-lg {
    padding: 0;
    font-size: 16px;
  }
  .ant-input-number-lg input {
    height: 38px;
  }
  .ant-input-number-sm {
    padding: 0;
  }
  .ant-input-number-sm input {
    height: 22px;
    padding: 0 7px;
  }
  .ant-input-number-handler-wrap {
    position: absolute;
    top: 0;
    right: 0;
    width: 22px;
    height: 100%;
    background: #212121;
    border-left: 1px solid #6f6c6c;
    border-radius: 0 4px 4px 0;
    opacity: 0;
    transition: opacity 0.24s linear 0.1s;
  }
  .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner,
  .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner {
    display: inline-block;
    font-size: 7px;
    min-width: auto;
    margin-right: 0;
  }
  .ant-input-number-handler-wrap:hover .ant-input-number-handler {
    height: 40%;
  }
  .ant-input-number:hover .ant-input-number-handler-wrap {
    opacity: 1;
  }
  .ant-input-number-handler-up {
    border-top-right-radius: 4px;
    cursor: pointer;
  }
  .ant-input-number-handler-up-inner {
    top: 50%;
    margin-top: -5px;
    text-align: center;
  }
  .ant-input-number-handler-down:hover,
  .ant-input-number-handler-up:hover {
    height: 60% !important;
  }
  .ant-input-number-handler-down {
    top: 0;
    border-top: 1px solid #6f6c6c;
    border-bottom-right-radius: 4px;
    cursor: pointer;
  }
  .ant-input-number-handler-down-inner {
    top: 50%;
    text-align: center;
    transform: translateY(-50%);
  }
  .ant-input-number-handler-down-disabled,
  .ant-input-number-handler-up-disabled {
    cursor: not-allowed;
  }
  .ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner,
  .ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-input-number-rtl {
    direction: rtl;
  }
  .ant-input-number-rtl .ant-input-number-handler-wrap {
    right: auto;
    left: 0;
    border-right: 1px solid #6f6c6c;
    border-left: 0;
    border-radius: 4px 0 0 4px;
  }
  .ant-input-number-rtl .ant-input-number-input {
    direction: ltr;
    text-align: right;
  }
  .ant-layout {
    display: flex;
    flex: auto;
    flex-direction: column;
    min-height: 0;
    background: #212121;
  }
  .ant-layout,
  .ant-layout * {
    box-sizing: border-box;
  }
  .ant-layout.ant-layout-has-sider {
    flex-direction: row;
  }
  .ant-layout.ant-layout-has-sider > .ant-layout,
  .ant-layout.ant-layout-has-sider > .ant-layout-content {
    overflow-x: hidden;
  }
  .ant-layout-footer,
  .ant-layout-header {
    flex: 0 0 auto;
    color: rgba(255, 255, 255, 0.85);
    background: #212121;
  }
  .ant-layout-header {
    height: 64px;
    padding: 0 50px;
    line-height: 64px;
  }
  .ant-layout-footer {
    padding: 24px 50px;
    font-size: 14px;
  }
  .ant-layout-content {
    flex: auto;
    min-height: 0;
  }
  .ant-layout-sider {
    position: relative;
    min-width: 0;
    background: #212121;
    transition: all 0.2s;
  }
  .ant-layout-sider-children {
    height: 100%;
    margin-top: -0.1px;
    padding-top: 0.1px;
  }
  .ant-layout-sider-has-trigger {
    padding-bottom: 48px;
  }
  .ant-layout-sider-right {
    order: 1;
  }
  .ant-layout-sider-trigger {
    position: fixed;
    bottom: 0;
    z-index: 1;
    height: 48px;
    color: #fff;
    line-height: 48px;
    text-align: center;
    background: #262626;
    cursor: pointer;
    transition: all 0.2s;
  }
  .ant-layout-sider-zero-width > * {
    overflow: hidden;
  }
  .ant-layout-sider-zero-width-trigger {
    position: absolute;
    top: 64px;
    right: -36px;
    z-index: 1;
    width: 36px;
    height: 42px;
    color: #fff;
    font-size: 18px;
    line-height: 42px;
    text-align: center;
    background: #212121;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  .ant-layout-sider-zero-width-trigger::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    transition: all 0.3s;
    content: "";
  }
  .ant-layout-sider-zero-width-trigger:hover::after {
    background: rgba(255, 255, 255, 0.1);
  }
  .ant-layout-sider-zero-width-trigger-right {
    left: -36px;
    border-radius: 4px 0 0 4px;
  }
  .ant-layout-sider-light {
    background: #fff;
  }
  .ant-layout-sider-light .ant-layout-sider-trigger,
  .ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
    color: rgba(255, 255, 255, 0.85);
    background: #fff;
  }
  .ant-layout-rtl {
    direction: rtl;
  }
  .ant-list .ant-card {
    background: 0 0;
  }
  .ant-list {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
  }
  .ant-list * {
    outline: 0;
  }
  .ant-list-pagination {
    margin-top: 24px;
    text-align: right;
  }
  .ant-list-pagination .ant-pagination-options {
    text-align: left;
  }
  .ant-list-more {
    margin-top: 12px;
    text-align: center;
  }
  .ant-list-more button {
    padding-right: 32px;
    padding-left: 32px;
  }
  .ant-list-spin {
    min-height: 40px;
    text-align: center;
  }
  .ant-list-empty-text {
    padding: 16px;
    color: rgba(255, 255, 255, 0.3);
    font-size: 14px;
    text-align: center;
  }
  .ant-list-items,
  .ant-menu ol,
  .ant-menu ul,
  .ant-pagination ol,
  .ant-pagination ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .ant-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-list-item-meta {
    display: flex;
    flex: 1;
    align-items: flex-start;
    max-width: 100%;
  }
  .ant-list-item-meta-avatar {
    margin-right: 16px;
  }
  .ant-list-item-meta-content {
    flex: 1 0;
    width: 0;
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-list-item-meta-title {
    margin-bottom: 4px;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    line-height: 1.5715;
  }
  .ant-list-item-meta-title > a {
    color: rgba(255, 255, 255, 0.85);
    transition: all 0.3s;
  }
  .ant-list-item-action > li,
  .ant-list-item-meta-description {
    color: rgba(255, 255, 255, 0.45);
    font-size: 14px;
    line-height: 1.5715;
  }
  .ant-list-item-action {
    flex: 0 0 auto;
    margin-left: 48px;
    padding: 0;
    font-size: 0;
    list-style: none;
  }
  .ant-list-item-action > li {
    position: relative;
    display: inline-block;
    padding: 0 8px;
    text-align: center;
  }
  .ant-list-item-action > li:first-child,
  .ant-list-vertical .ant-list-item-action > li:first-child {
    padding-left: 0;
  }
  .ant-list-item-action-split {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 14px;
    margin-top: -7px;
    background-color: #424242;
  }
  .ant-list-footer,
  .ant-list-header {
    background: 0 0;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .ant-list-empty {
    padding: 16px 0;
    color: rgba(255, 255, 255, 0.45);
    font-size: 12px;
    text-align: center;
  }
  .ant-list-split .ant-list-header,
  .ant-list-split .ant-list-item {
    border-bottom: 1px solid #424242;
  }
  .ant-list-split .ant-list-item:last-child {
    border-bottom: none;
  }
  .ant-list-split.ant-list-empty .ant-list-footer {
    border-top: 1px solid #424242;
  }
  .ant-list-loading .ant-list-spin-nested-loading {
    min-height: 32px;
  }
  .ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child,
  .ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child td {
    border-bottom: 1px solid #424242;
  }
  .ant-list-lg .ant-list-item {
    padding: 16px 24px;
  }
  .ant-list-sm .ant-list-item {
    padding: 8px 16px;
  }
  .ant-list-vertical .ant-list-item {
    align-items: initial;
  }
  .ant-list-vertical .ant-list-item-main {
    display: block;
    flex: 1;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin-left: 40px;
  }
  .ant-list-vertical .ant-list-item-meta {
    margin-bottom: 16px;
  }
  .ant-list-vertical .ant-list-item-meta-title {
    margin-bottom: 12px;
    color: rgba(255, 255, 255, 0.85);
    font-size: 16px;
    line-height: 24px;
  }
  .ant-list-vertical .ant-list-item-action {
    margin-top: 16px;
    margin-left: auto;
  }
  .ant-list-vertical .ant-list-item-action > li {
    padding: 0 16px;
  }
  .ant-list-grid .ant-col > .ant-list-item {
    display: block;
    max-width: 100%;
    margin-bottom: 16px;
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  .ant-list-item-no-flex {
    display: block;
  }
  .ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
    float: right;
  }
  .ant-list-bordered {
    border: 1px solid #6f6c6c;
    border-radius: 4px;
  }
  .ant-list-bordered .ant-list-footer,
  .ant-list-bordered .ant-list-header,
  .ant-list-bordered .ant-list-item {
    padding-right: 24px;
    padding-left: 24px;
  }
  .ant-list-bordered .ant-list-pagination {
    margin: 16px 24px;
  }
  .ant-list-bordered.ant-list-sm .ant-list-footer,
  .ant-list-bordered.ant-list-sm .ant-list-header,
  .ant-list-bordered.ant-list-sm .ant-list-item {
    padding: 8px 16px;
  }
  .ant-list-bordered.ant-list-lg .ant-list-footer,
  .ant-list-bordered.ant-list-lg .ant-list-header,
  .ant-list-bordered.ant-list-lg .ant-list-item {
    padding: 16px 24px;
  }
  @media screen and (max-width: 768px) {
    .ant-list-item-action,
    .ant-list-vertical .ant-list-item-extra {
      margin-left: 24px;
    }
  }
  @media screen and (max-width: 576px) {
    .ant-list-item {
      flex-wrap: wrap;
    }
    .ant-list-item-action {
      margin-left: 12px;
    }
    .ant-list-vertical .ant-list-item {
      flex-wrap: wrap-reverse;
    }
    .ant-list-vertical .ant-list-item-main {
      min-width: 220px;
    }
    .ant-list-vertical .ant-list-item-extra {
      margin: auto auto 16px;
    }
  }
  .ant-list-rtl {
    direction: rtl;
    text-align: right;
  }
  .ant-list-rtl .ReactVirtualized__List .ant-list-item {
    direction: rtl;
  }
  .ant-list-rtl .ant-list-pagination {
    text-align: left;
  }
  .ant-list-rtl .ant-list-item-meta-avatar {
    margin-right: 0;
    margin-left: 16px;
  }
  .ant-list-rtl .ant-list-item-action {
    margin-right: 48px;
    margin-left: 0;
  }
  .ant-list-rtl .ant-list-vertical .ant-list-item-action > li:first-child,
  .ant-list.ant-list-rtl .ant-list-item-action > li:first-child {
    padding-right: 0;
    padding-left: 16px;
  }
  .ant-list-rtl .ant-list-item-action-split {
    right: auto;
    left: 0;
  }
  .ant-list-rtl.ant-list-vertical .ant-list-item-extra {
    margin-right: 40px;
    margin-left: 0;
  }
  .ant-list-rtl.ant-list-vertical .ant-list-item-action {
    margin-right: auto;
  }
  .ant-list-rtl .ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
    float: left;
  }
  @media screen and (max-width: 768px) {
    .ant-list-rtl .ant-list-item-action,
    .ant-list-rtl .ant-list-vertical .ant-list-item-extra {
      margin-right: 24px;
      margin-left: 0;
    }
  }
  @media screen and (max-width: 576px) {
    .ant-list-rtl .ant-list-item-action {
      margin-right: 22px;
      margin-left: 0;
    }
    .ant-list-rtl.ant-list-vertical .ant-list-item-extra {
      margin: auto auto 16px;
    }
  }
  .ant-mentions {
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    width: 100%;
    min-width: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    background-color: transparent;
    background-image: none;
    border: 1px solid #6f6c6c;
    border-radius: 4px;
    transition: all 0.3s;
    position: relative;
    display: inline-block;
    height: auto;
    padding: 0;
    overflow: hidden;
    line-height: 1.5715;
    white-space: pre-wrap;
    vertical-align: bottom;
  }
  .ant-mentions::-webkit-input-placeholder,
  .ant-mentions > textarea::-webkit-input-placeholder,
  .ant-pagination-options-quick-jumper input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-mentions:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.3);
    text-overflow: ellipsis;
  }
  .ant-mentions::placeholder,
  .ant-mentions > textarea::placeholder,
  .ant-pagination-options-quick-jumper input::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-mentions:placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-mentions:hover {
    border-color: #287a99;
    border-right-width: 1px !important;
  }
  .ant-input-rtl .ant-mentions:hover {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ant-mentions-focused,
  .ant-mentions:focus {
    border-color: #2caad9;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(44, 170, 217, 0.2);
  }
  .ant-input-rtl .ant-mentions-focused,
  .ant-input-rtl .ant-mentions:focus {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ant-mentions-disabled:hover,
  .ant-mentions-disabled > textarea:hover,
  .ant-mentions[disabled]:hover,
  .ant-pagination-options-quick-jumper input-disabled:hover {
    border-color: #6f6c6c;
    border-right-width: 1px !important;
  }
  .ant-mentions-disabled,
  .ant-mentions-disabled > textarea,
  .ant-mentions[disabled],
  .ant-pagination-options-quick-jumper input-disabled {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-mentions-borderless,
  .ant-mentions-borderless-disabled,
  .ant-mentions-borderless-focused,
  .ant-mentions-borderless:focus,
  .ant-mentions-borderless:hover,
  .ant-mentions-borderless[disabled],
  .ant-pagination-options-quick-jumper input-borderless,
  .ant-pagination-options-quick-jumper input-borderless-disabled,
  .ant-pagination-options-quick-jumper input-borderless-focused,
  .ant-pagination-options-quick-jumper input-borderless:focus,
  .ant-pagination-options-quick-jumper input-borderless:hover,
  .ant-pagination-options-quick-jumper input-borderless[disabled] {
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }
  .ant-mentions-lg,
  .ant-pagination-options-quick-jumper input-lg {
    padding: 6.5px 11px;
    font-size: 16px;
  }
  .ant-mentions-sm,
  .ant-pagination-options-quick-jumper input-sm {
    padding: 0 7px;
  }
  .ant-mentions-focused {
    border-color: #2caad9;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(44, 170, 217, 0.2);
  }
  .ant-input-rtl .ant-mentions-focused,
  .ant-input-rtl .ant-pagination-options-quick-jumper input:hover {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ant-mentions-measure,
  .ant-mentions > textarea {
    min-height: 30px;
    margin: 0;
    padding: 4px 11px;
    overflow: inherit;
    overflow-x: hidden;
    overflow-y: auto;
    font-weight: inherit;
    font-size: inherit;
    font-family: inherit;
    font-style: inherit;
    font-variant: inherit;
    font-size-adjust: inherit;
    font-stretch: inherit;
    line-height: inherit;
    direction: inherit;
    letter-spacing: inherit;
    white-space: inherit;
    text-align: inherit;
    vertical-align: top;
    word-wrap: break-word;
    word-break: inherit;
    -moz-tab-size: inherit;
    tab-size: inherit;
  }
  .ant-mentions > textarea {
    width: 100%;
    border: 0;
    outline: 0;
    resize: none;
    background-color: transparent;
  }
  .ant-mentions > textarea:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.3);
    text-overflow: ellipsis;
  }
  .ant-mentions > textarea:placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-mentions-measure {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    color: transparent;
    pointer-events: none;
  }
  .ant-mentions-measure > span {
    display: inline-block;
    min-height: 1em;
  }
  .ant-mentions-dropdown {
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1050;
    box-sizing: border-box;
    font-size: 14px;
    font-variant: initial;
    background-color: #212121;
    border-radius: 4px;
    outline: 0;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
  }
  .ant-mentions-dropdown-hidden {
    display: none;
  }
  .ant-mentions-dropdown-menu {
    max-height: 250px;
    margin-bottom: 0;
    padding-left: 0;
    overflow: auto;
    list-style: none;
    outline: 0;
  }
  .ant-mentions-dropdown-menu-item {
    position: relative;
    display: block;
    min-width: 100px;
    padding: 5px 12px;
    overflow: hidden;
    color: rgba(255, 255, 255, 0.85);
    font-weight: 400;
    line-height: 1.5715;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  .ant-mentions-dropdown-menu-item:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-mentions-dropdown-menu-item:first-child {
    border-radius: 4px 4px 0 0;
  }
  .ant-mentions-dropdown-menu-item:last-child {
    border-radius: 0 0 4px 4px;
  }
  .ant-mentions-dropdown-menu-item-disabled {
    color: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
  }
  .ant-mentions-dropdown-menu-item-disabled:hover {
    color: rgba(255, 255, 255, 0.3);
    background-color: #212121;
    cursor: not-allowed;
  }
  .ant-mentions-dropdown-menu-item-selected {
    color: rgba(255, 255, 255, 0.85);
    font-weight: 600;
    background-color: rgba(255, 255, 255, 0.04);
  }
  .ant-mentions-dropdown-menu-item-active {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-mentions-rtl {
    direction: rtl;
  }
  .ant-menu-item-danger.ant-menu-item,
  .ant-menu-item-danger.ant-menu-item-active,
  .ant-menu-item-danger.ant-menu-item-selected,
  .ant-menu-item-danger.ant-menu-item-selected > a,
  .ant-menu-item-danger.ant-menu-item-selected > a:hover,
  .ant-menu-item-danger.ant-menu-item:hover {
    color: #ab2229;
  }
  .ant-menu-item-danger.ant-menu-item:active {
    background: #351d20;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
    background-color: #351d20;
  }
  .ant-menu-inline .ant-menu-item-danger.ant-menu-item::after {
    border-right-color: #ab2229;
  }
  .ant-menu-dark .ant-menu-item-danger.ant-menu-item,
  .ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover,
  .ant-menu-dark .ant-menu-item-danger.ant-menu-item > a {
    color: #ab2229;
  }
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
    color: #fff;
    background-color: #ab2229;
  }
  .ant-menu {
    box-sizing: border-box;
    font-variant: tabular-nums;
    font-feature-settings: "tnum";
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    line-height: 0;
    text-align: left;
    list-style: none;
    background: #212121;
    outline: 0;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
    transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  }
  .ant-menu::after,
  .ant-menu::before {
    display: table;
    content: "";
  }
  .ant-menu::after {
    clear: both;
  }
  .ant-menu-hidden {
    display: none;
  }
  .ant-menu-item-group-title {
    height: 1.5715;
    padding: 8px 16px;
    color: rgba(255, 255, 255, 0.45);
    font-size: 14px;
    line-height: 1.5715;
    transition: all 0.3s;
  }
  .ant-menu-horizontal .ant-menu-submenu {
    transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-submenu,
  .ant-menu-submenu-inline {
    transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-item a:hover,
  .ant-menu-item > .ant-badge a:hover,
  .ant-menu-submenu-selected {
    color: #2caad9;
  }
  .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background: #1e2b31;
  }
  .ant-menu-submenu .ant-menu-sub {
    cursor: initial;
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-item a,
  .ant-menu-item > .ant-badge a {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-menu-item a::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    content: "";
  }
  .ant-menu-item-divider {
    height: 1px;
    overflow: hidden;
    line-height: 0;
    background-color: #424242;
  }
  .ant-menu-item-active,
  .ant-menu-item:hover,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
    color: #2caad9;
  }
  .ant-menu-horizontal .ant-menu-item,
  .ant-menu-horizontal .ant-menu-submenu {
    margin-top: -1px;
  }
  .ant-menu-horizontal > .ant-menu-item-active,
  .ant-menu-horizontal > .ant-menu-item:hover,
  .ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
    background-color: transparent;
  }
  .ant-menu-item-selected,
  .ant-menu-item-selected a,
  .ant-menu-item-selected a:hover {
    color: #2caad9;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #1e2b31;
  }
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tbody
    > tr
    > .ant-table-cell-fix-right-first::after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tfoot
    > tr
    > .ant-table-cell-fix-right-first::after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > thead
    > tr
    > .ant-table-cell-fix-right-first::after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > .ant-table-cell-fix-right-first::after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tfoot
    > tr
    > .ant-table-cell-fix-right-first::after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > .ant-table-cell-fix-right-first::after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > tbody
    > tr
    > .ant-table-cell-fix-right-first::after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > tfoot
    > tr
    > .ant-table-cell-fix-right-first::after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > thead
    > tr
    > .ant-table-cell-fix-right-first::after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th {
    border-right: 1px solid #424242;
  }
  .ant-menu-vertical-right {
    border-left: 1px solid #424242;
  }
  .ant-menu-vertical-left.ant-menu-sub,
  .ant-menu-vertical-right.ant-menu-sub,
  .ant-menu-vertical.ant-menu-sub {
    min-width: 160px;
    max-height: calc(100vh - 100px);
    padding: 0;
    overflow: hidden;
    border-right: 0;
    transform-origin: 0 0;
  }
  .ant-menu-vertical-left.ant-menu-sub:not([class*="-active"]),
  .ant-menu-vertical-right.ant-menu-sub:not([class*="-active"]),
  .ant-menu-vertical.ant-menu-sub:not([class*="-active"]) {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
  .ant-menu-vertical-right.ant-menu-sub .ant-menu-item,
  .ant-menu-vertical.ant-menu-sub .ant-menu-item {
    left: 0;
    margin-left: 0;
    border-right: 0;
  }
  .ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
  .ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after,
  .ant-menu-vertical.ant-menu-sub .ant-menu-item::after {
    border-right: 0;
  }
  .ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
  .ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
  .ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
  .ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu,
  .ant-menu-vertical.ant-menu-sub > .ant-menu-item,
  .ant-menu-vertical.ant-menu-sub > .ant-menu-submenu {
    transform-origin: 0 0;
  }
  .ant-menu-horizontal.ant-menu-sub {
    min-width: 114px;
  }
  .ant-menu-horizontal .ant-menu-item,
  .ant-menu-horizontal .ant-menu-submenu-title {
    transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-item,
  .ant-menu-submenu-title {
    position: relative;
    display: block;
    margin: 0;
    padding: 0 20px;
    white-space: nowrap;
    cursor: pointer;
    transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-item .anticon,
  .ant-menu-submenu-title .anticon {
    min-width: 14px;
    margin-right: 10px;
    font-size: 14px;
    transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-item .anticon + span,
  .ant-menu-submenu-title .anticon + span {
    opacity: 1;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-item.ant-menu-item-only-child > .anticon,
  .ant-menu-submenu-title.ant-menu-item-only-child > .anticon,
  table tr td.ant-table-selection-column .ant-radio-wrapper,
  table tr th.ant-table-selection-column .ant-radio-wrapper {
    margin-right: 0;
  }
  .ant-menu > .ant-menu-item-divider {
    height: 1px;
    margin: 1px 0;
    padding: 0;
    overflow: hidden;
    line-height: 0;
    background-color: #424242;
  }
  .ant-menu-submenu-popup {
    position: absolute;
    z-index: 1050;
    border-radius: 4px;
    box-shadow: none;
  }
  .ant-menu-submenu-popup::before {
    position: absolute;
    top: -7px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0.0001;
    content: " ";
  }
  .ant-menu-submenu-placement-rightTop::before {
    top: 0;
    left: -7px;
  }
  .ant-menu-submenu > .ant-menu {
    background-color: #212121;
    border-radius: 4px;
  }
  .ant-menu-submenu > .ant-menu-submenu-title::after {
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-submenu-popup > .ant-menu {
    background-color: #212121;
  }
  .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    position: absolute;
    top: 50%;
    right: 16px;
    width: 10px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
    position: absolute;
    width: 6px;
    height: 1.5px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85));
    border-radius: 2px;
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    content: "";
  }
  .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
    transform: rotate(45deg) translateY(-2px);
  }
  .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
    transform: rotate(-45deg) translateY(2px);
  }
  .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
    background: linear-gradient(to right, #2caad9, #2caad9);
  }
  .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
    transform: rotate(45deg) translateY(-2px);
  }
  .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
    transform: rotate(-45deg) translateY(2px);
  }
  .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
    transform: rotate(-45deg) translateX(2px);
  }
  .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
    transform: rotate(45deg) translateX(-2px);
  }
  .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    transform: translateY(-2px);
  }
  .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
    transform: rotate(-45deg) translateX(-2px);
  }
  .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
    transform: rotate(45deg) translateX(2px);
  }
  .ant-menu-horizontal > .ant-menu-item a:hover,
  .ant-menu-vertical .ant-menu-submenu-selected,
  .ant-menu-vertical-left .ant-menu-submenu-selected,
  .ant-menu-vertical-right .ant-menu-submenu-selected {
    color: #2caad9;
  }
  .ant-menu-horizontal {
    line-height: 0px;
    white-space: nowrap;
    border: 0;
    border-bottom: 1px solid #424242;
    box-shadow: none;
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    margin: -1px 20px 0;
    padding: 0;
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover {
    color: #2caad9;
    border-bottom: 2px solid #2caad9;
  }
  .ant-menu-horizontal > .ant-menu-item,
  .ant-menu-horizontal > .ant-menu-submenu {
    position: relative;
    top: 1px;
    display: inline-block;
    vertical-align: bottom;
    border-bottom: 2px solid transparent;
  }
  .ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0;
  }
  .ant-menu-horizontal > .ant-menu-item a,
  .ant-menu-inline-collapsed-tooltip a {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-menu-horizontal > .ant-menu-item a::before {
    bottom: -2px;
  }
  .ant-menu-horizontal > .ant-menu-item-selected a {
    color: #2caad9;
  }
  .ant-menu-horizontal::after {
    display: block;
    clear: both;
    height: 0;
    content: "\20";
  }
  .ant-menu-inline .ant-menu-item,
  .ant-menu-vertical .ant-menu-item,
  .ant-menu-vertical-left .ant-menu-item,
  .ant-menu-vertical-right .ant-menu-item {
    position: relative;
  }
  .ant-menu-inline .ant-menu-item::after,
  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-right: 3px solid #2caad9;
    transform: scaleY(0.0001);
    opacity: 0;
    transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    content: "";
  }
  .ant-menu-inline .ant-menu-item,
  .ant-menu-inline .ant-menu-submenu-title,
  .ant-menu-vertical .ant-menu-item,
  .ant-menu-vertical .ant-menu-submenu-title,
  .ant-menu-vertical-left .ant-menu-item,
  .ant-menu-vertical-left .ant-menu-submenu-title,
  .ant-menu-vertical-right .ant-menu-item,
  .ant-menu-vertical-right .ant-menu-submenu-title {
    height: 40px;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 0 16px;
    overflow: hidden;
    line-height: 40px;
    text-overflow: ellipsis;
  }
  .ant-menu-inline .ant-menu-submenu,
  .ant-menu-vertical .ant-menu-submenu,
  .ant-menu-vertical-left .ant-menu-submenu,
  .ant-menu-vertical-right .ant-menu-submenu {
    padding-bottom: 0.02px;
  }
  .ant-menu-inline .ant-menu-item:not(:last-child),
  .ant-menu-vertical .ant-menu-item:not(:last-child),
  .ant-menu-vertical-left .ant-menu-item:not(:last-child),
  .ant-menu-vertical-right .ant-menu-item:not(:last-child) {
    margin-bottom: 8px;
  }
  .ant-menu-inline > .ant-menu-item,
  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-left > .ant-menu-item,
  .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-right > .ant-menu-item,
  .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical > .ant-menu-item,
  .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 40px;
    line-height: 40px;
  }
  .ant-menu-vertical .ant-menu-submenu-title {
    padding-right: 34px;
  }
  .ant-menu-inline {
    width: 100%;
  }
  .ant-menu-inline .ant-menu-item-selected::after,
  .ant-menu-inline .ant-menu-selected::after {
    transform: scaleY(1);
    opacity: 1;
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-inline .ant-menu-item,
  .ant-menu-inline .ant-menu-submenu-title {
    width: calc(100% + 1px);
  }
  .ant-menu-inline .ant-menu-submenu-title {
    padding-right: 34px;
  }
  .ant-menu-inline-collapsed {
    width: 80px;
  }
  .ant-menu-inline-collapsed > .ant-menu-item,
  .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
  .ant-menu-inline-collapsed
    > .ant-menu-item-group
    > .ant-menu-item-group-list
    > .ant-menu-submenu
    > .ant-menu-submenu-title,
  .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    left: 0;
    padding: 0 32px;
    text-overflow: clip;
  }
  .ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
  .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
  .ant-menu-inline-collapsed
    > .ant-menu-item-group
    > .ant-menu-item-group-list
    > .ant-menu-submenu
    > .ant-menu-submenu-title
    .ant-menu-submenu-arrow,
  .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    display: none;
  }
  .ant-menu-inline-collapsed > .ant-menu-item .anticon,
  .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
  .ant-menu-inline-collapsed
    > .ant-menu-item-group
    > .ant-menu-item-group-list
    > .ant-menu-submenu
    > .ant-menu-submenu-title
    .anticon,
  .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
    margin: 0;
    font-size: 16px;
    line-height: 40px;
  }
  .ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
  .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
  .ant-menu-inline-collapsed
    > .ant-menu-item-group
    > .ant-menu-item-group-list
    > .ant-menu-submenu
    > .ant-menu-submenu-title
    .anticon
    + span,
  .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
    display: inline-block;
    max-width: 0;
    opacity: 0;
  }
  .ant-menu-inline-collapsed .anticon {
    display: inline-block;
  }
  .ant-menu-inline-collapsed-tooltip {
    pointer-events: none;
  }
  .ant-menu-inline-collapsed-tooltip .anticon {
    display: none;
  }
  .ant-menu-inline-collapsed .ant-menu-item-group-title {
    padding-right: 4px;
    padding-left: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ant-menu-item-group-list {
    margin: 0;
    padding: 0;
  }
  .ant-menu-item-group-list .ant-menu-item,
  .ant-menu-item-group-list .ant-menu-submenu-title {
    padding: 0 16px 0 28px;
  }
  .ant-menu-root.ant-menu-inline,
  .ant-menu-root.ant-menu-vertical,
  .ant-menu-root.ant-menu-vertical-left,
  .ant-menu-root.ant-menu-vertical-right {
    box-shadow: none;
  }
  .ant-menu-root.ant-menu-inline-collapsed .ant-menu-item > .ant-menu-inline-collapsed-noicon,
  .ant-menu-root.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title > .ant-menu-inline-collapsed-noicon {
    font-size: 16px;
    text-align: center;
  }
  .ant-menu-sub.ant-menu-inline {
    padding: 0;
    border-radius: 0;
    box-shadow: none;
  }
  .ant-menu-sub.ant-menu-inline > .ant-menu-item,
  .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 40px;
    line-height: 40px;
    list-style-position: inside;
    list-style-type: disc;
  }
  .ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
    padding-left: 32px;
  }
  .ant-menu-item-disabled,
  .ant-menu-submenu-disabled {
    background: 0 0;
    border-color: transparent !important;
  }
  .ant-menu-item-disabled a,
  .ant-menu-submenu-disabled a {
    color: rgba(255, 255, 255, 0.3) !important;
    pointer-events: none;
  }
  .ant-menu-item-disabled,
  .ant-menu-item-disabled > .ant-menu-submenu-title,
  .ant-menu-submenu-disabled,
  .ant-menu-submenu-disabled > .ant-menu-submenu-title {
    color: rgba(255, 255, 255, 0.3) !important;
    cursor: not-allowed;
  }
  .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
    background: rgba(255, 255, 255, 0.3) !important;
  }
  .ant-layout-header .ant-menu {
    line-height: inherit;
  }
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    color: rgba(255, 255, 255, 0.65);
    background: #212121;
  }
  .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow {
    opacity: 0.45;
    transition: all 0.3s;
  }
  .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
    background: #fff;
  }
  .ant-menu-dark.ant-menu-submenu-popup {
    background: 0 0;
  }
  .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #212121;
  }
  .ant-menu-dark.ant-menu-horizontal {
    border-bottom: 0;
  }
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
    top: 0;
    margin-top: 0;
    padding: 0 20px;
    border-color: #212121;
    border-bottom: 0;
  }
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
    background-color: #2caad9;
  }
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a::before {
    bottom: 0;
  }
  .ant-menu-dark .ant-menu-item,
  .ant-menu-dark .ant-menu-item-group-title,
  .ant-menu-dark .ant-menu-item > a,
  .ant-menu-dark .ant-menu-item > span > a {
    color: rgba(255, 255, 255, 0.65);
  }
  .ant-menu-dark .ant-menu-item-selected::after,
  .ant-menu-dark.ant-menu-inline,
  .ant-menu-dark.ant-menu-inline .ant-menu-item::after,
  .ant-menu-dark.ant-menu-vertical,
  .ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
  .ant-menu-dark.ant-menu-vertical-left,
  .ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-dark.ant-menu-vertical-right,
  .ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after,
  .ant-table.ant-table-bordered.ant-table-scroll-horizontal
    > .ant-table-container
    > .ant-table-body
    > table
    > tbody
    > tr.ant-table-expanded-row
    > td,
  .ant-table.ant-table-bordered.ant-table-scroll-horizontal
    > .ant-table-container
    > .ant-table-body
    > table
    > tbody
    > tr.ant-table-placeholder
    > td {
    border-right: 0;
  }
  .ant-menu-dark.ant-menu-inline .ant-menu-item,
  .ant-menu-dark.ant-menu-vertical .ant-menu-item,
  .ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
  .ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
    left: 0;
    margin-left: 0;
    border-right: 0;
  }
  .ant-menu-dark.ant-menu-inline .ant-menu-item,
  .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
    width: 100%;
  }
  .ant-menu-dark .ant-menu-item:hover {
    color: #fff;
  }
  .ant-menu-dark .ant-menu-item-active,
  .ant-menu-dark .ant-menu-submenu-active,
  .ant-menu-dark .ant-menu-submenu-open,
  .ant-menu-dark .ant-menu-submenu-selected,
  .ant-menu-dark .ant-menu-submenu-title:hover {
    color: #fff;
    background-color: transparent;
  }
  .ant-menu-dark .ant-menu-item-active > a,
  .ant-menu-dark .ant-menu-item-active > span > a,
  .ant-menu-dark .ant-menu-item-selected > a,
  .ant-menu-dark .ant-menu-item-selected > a:hover,
  .ant-menu-dark .ant-menu-item-selected > span > a,
  .ant-menu-dark .ant-menu-item-selected > span > a:hover,
  .ant-menu-dark .ant-menu-item:hover > a,
  .ant-menu-dark .ant-menu-item:hover > span > a,
  .ant-menu-dark .ant-menu-submenu-active > a,
  .ant-menu-dark .ant-menu-submenu-active > span > a,
  .ant-menu-dark .ant-menu-submenu-open > a,
  .ant-menu-dark .ant-menu-submenu-open > span > a,
  .ant-menu-dark .ant-menu-submenu-selected > a,
  .ant-menu-dark .ant-menu-submenu-selected > span > a,
  .ant-menu-dark .ant-menu-submenu-title:hover > a,
  .ant-menu-dark .ant-menu-submenu-title:hover > span > a {
    color: #fff;
  }
  .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
  .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
  .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
  .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
  .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
  .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
  .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    opacity: 1;
  }
  .ant-menu-dark .ant-menu-item:hover {
    background-color: transparent;
  }
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #2caad9;
  }
  .ant-menu-dark .ant-menu-item-selected {
    color: #fff;
    border-right: 0;
  }
  .ant-menu-dark .ant-menu-item-selected .anticon,
  .ant-menu-dark .ant-menu-item-selected .anticon + span {
    color: #fff;
  }
  .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
  .ant-menu.ant-menu-dark .ant-menu-item-selected {
    background-color: #2caad9;
  }
  .ant-menu-dark .ant-menu-item-disabled,
  .ant-menu-dark .ant-menu-item-disabled > a,
  .ant-menu-dark .ant-menu-item-disabled > span > a,
  .ant-menu-dark .ant-menu-submenu-disabled,
  .ant-menu-dark .ant-menu-submenu-disabled > a,
  .ant-menu-dark .ant-menu-submenu-disabled > span > a {
    color: rgba(255, 255, 255, 0.3) !important;
    opacity: 0.8;
  }
  .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
  .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
    color: rgba(255, 255, 255, 0.3) !important;
  }
  .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
    background: rgba(255, 255, 255, 0.3) !important;
  }
  .ant-menu.ant-menu-rtl {
    direction: rtl;
    text-align: right;
  }
  .ant-menu-rtl .ant-menu-item-group-title {
    text-align: right;
  }
  .ant-menu-rtl.ant-menu-inline,
  .ant-menu-rtl.ant-menu-vertical {
    border-right: none;
    border-left: 1px solid #424242;
  }
  .ant-menu-rtl.ant-menu-dark.ant-menu-inline,
  .ant-menu-rtl.ant-menu-dark.ant-menu-vertical {
    border-left: none;
  }
  .ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub,
  .ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
  .ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
  .ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub,
  .ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
  .ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu,
  .ant-menu-rtl.ant-menu-vertical.ant-menu-sub,
  .ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
  .ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu {
    transform-origin: top right;
  }
  .ant-menu-rtl .ant-menu-item .anticon,
  .ant-menu-rtl .ant-menu-submenu-title .anticon {
    margin-right: auto;
    margin-left: 10px;
  }
  .ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .anticon,
  .ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .anticon {
    margin-left: 0;
  }
  .ant-menu-rtl .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    right: auto;
    left: 16px;
  }
  .ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
    transform: rotate(-45deg) translateY(-2px);
  }
  .ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
    transform: rotate(45deg) translateY(2px);
  }
  .ant-menu-rtl.ant-menu-inline .ant-menu-item::after,
  .ant-menu-rtl.ant-menu-vertical .ant-menu-item::after,
  .ant-menu-rtl.ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-rtl.ant-menu-vertical-right .ant-menu-item::after {
    right: auto;
    left: 0;
  }
  .ant-menu-rtl.ant-menu-inline .ant-menu-item,
  .ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title,
  .ant-menu-rtl.ant-menu-vertical .ant-menu-item,
  .ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title,
  .ant-menu-rtl.ant-menu-vertical-left .ant-menu-item,
  .ant-menu-rtl.ant-menu-vertical-left .ant-menu-submenu-title,
  .ant-menu-rtl.ant-menu-vertical-right .ant-menu-item,
  .ant-menu-rtl.ant-menu-vertical-right .ant-menu-submenu-title {
    text-align: right;
  }
  .ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
    padding-right: 0;
    padding-left: 34px;
  }
  .ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title {
    padding-right: 16px;
    padding-left: 34px;
  }
  .ant-menu-rtl.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-submenu-title {
    padding: 0 32px;
  }
  .ant-menu-rtl .ant-menu-item-group-list .ant-menu-item,
  .ant-menu-rtl .ant-menu-item-group-list .ant-menu-submenu-title {
    padding: 0 28px 0 16px;
  }
  .ant-menu-sub.ant-menu-inline,
  .ant-page-header-footer .ant-tabs > .ant-tabs-nav::before {
    border: 0;
  }
  .ant-menu-rtl.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
    padding-right: 32px;
    padding-left: 0;
  }
  .ant-message {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: fixed;
    top: 8px;
    left: 0;
    z-index: 1010;
    width: 100%;
    pointer-events: none;
  }
  .ant-message-notice {
    padding: 8px;
    text-align: center;
  }
  .ant-message-notice-content {
    display: inline-block;
    padding: 10px 16px;
    background: #212121;
    border-radius: 4px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
    pointer-events: all;
  }
  .ant-message-success .anticon {
    color: #4bac1b;
  }
  .ant-message-error .anticon {
    color: #ab2229;
  }
  .ant-message-warning .anticon {
    color: #d99816;
  }
  .ant-message-info .anticon,
  .ant-message-loading .anticon {
    color: #2caad9;
  }
  .ant-message .anticon {
    position: relative;
    top: 1px;
    margin-right: 8px;
    font-size: 16px;
  }
  .ant-message-notice.move-up-leave.move-up-leave-active {
    animation-name: MessageMoveOut;
    animation-duration: 0.3s;
  }
  .ant-message-rtl,
  .ant-message-rtl span {
    direction: rtl;
  }
  .ant-message-rtl .anticon {
    margin-right: 0;
    margin-left: 8px;
  }
  .ant-modal {
    box-sizing: border-box;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    top: 100px;
    width: auto;
    max-width: calc(100vw - 32px);
    margin: 0 auto;
    padding: 0 0 24px;
  }
  .ant-modal-wrap {
    z-index: 1000;
  }
  .ant-modal-title {
    margin: 0;
    color: rgba(255, 255, 255, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    word-wrap: break-word;
  }
  .ant-modal-content {
    position: relative;
    background-color: #212121;
    background-clip: padding-box;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
    pointer-events: auto;
  }
  .ant-modal-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    color: rgba(255, 255, 255, 0.45);
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    background: 0 0;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;
  }
  .ant-modal-close-x {
    display: block;
    width: 56px;
    height: 56px;
    font-size: 16px;
    font-style: normal;
    line-height: 56px;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
  }
  .ant-modal-close:focus,
  .ant-modal-close:hover {
    color: rgba(255, 255, 255, 0.75);
    text-decoration: none;
  }
  .ant-modal-header {
    padding: 16px 24px;
    color: rgba(255, 255, 255, 0.85);
    background: #212121;
    border-bottom: 1px solid #424242;
    border-radius: 4px 4px 0 0;
  }
  .ant-modal-body {
    padding: 24px;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
  }
  .ant-modal-footer {
    padding: 10px 16px;
    text-align: right;
    background: 0 0;
    border-top: 1px solid #424242;
    border-radius: 0 0 4px 4px;
  }
  .ant-modal-confirm .ant-modal-confirm-btns button + button,
  .ant-modal-footer button + button {
    margin-bottom: 0;
    margin-left: 8px;
  }
  .ant-modal-open {
    overflow: hidden;
  }
  .ant-modal-centered {
    text-align: center;
  }
  .ant-modal-centered::before {
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
    content: "";
  }
  .ant-modal-centered .ant-modal {
    top: 0;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
  @media (max-width: 767px) {
    .ant-modal {
      max-width: calc(100vw - 16px);
      margin: 8px auto;
    }
    .ant-modal-centered .ant-modal {
      flex: 1;
    }
  }
  .ant-modal-confirm .ant-modal-close,
  .ant-modal-confirm .ant-modal-header {
    display: none;
  }
  .ant-modal-confirm .ant-modal-body {
    padding: 32px 32px 24px;
  }
  .ant-modal-confirm-body-wrapper::before {
    display: table;
    content: "";
  }
  .ant-modal-confirm-body-wrapper::after {
    display: table;
    clear: both;
    content: "";
  }
  .ant-modal-confirm-body .ant-modal-confirm-title {
    display: block;
    overflow: hidden;
    color: rgba(255, 255, 255, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
  }
  .ant-modal-confirm-body .ant-modal-confirm-content {
    margin-top: 8px;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
  }
  .ant-modal-confirm-body > .anticon {
    float: left;
    margin-right: 16px;
    font-size: 22px;
  }
  .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
    margin-left: 38px;
  }
  .ant-modal-confirm .ant-modal-confirm-btns {
    float: right;
    margin-top: 24px;
  }
  .ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
    color: #ab2229;
  }
  .ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon,
  .ant-modal-confirm-warning .ant-modal-confirm-body > .anticon {
    color: #d99816;
  }
  .ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
    color: #2caad9;
  }
  .ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
    color: #4bac1b;
  }
  .ant-modal .ant-anchor-wrapper,
  .ant-modal .ant-card,
  .ant-modal .ant-collapse-content,
  .ant-modal .ant-picker-clear,
  .ant-modal .ant-slider-handle,
  .ant-modal .ant-timeline-item-head {
    background-color: #212121;
  }
  .ant-modal .ant-transfer-list-header {
    background: #212121;
    border-bottom: 1px solid #3a3a3a;
  }
  .ant-modal .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-modal .ant-table.ant-table-small thead > tr > th {
    background-color: #212121;
    border-bottom: 1px solid #3a3a3a;
  }
  .ant-modal .ant-picker-calendar-full,
  .ant-modal .ant-table,
  .ant-modal .ant-table tbody > tr > td.ant-table-cell-fix-left,
  .ant-modal .ant-table tbody > tr > td.ant-table-cell-fix-right {
    background-color: #212121;
  }
  .ant-modal .ant-table .ant-table-row-expand-icon,
  .ant-modal .ant-table.ant-table-bordered .ant-table-container,
  .ant-modal .ant-table.ant-table-bordered .ant-table-footer,
  .ant-modal .ant-table.ant-table-bordered .ant-table-title {
    border: 1px solid #3a3a3a;
  }
  .ant-modal .ant-table tbody > tr > td,
  .ant-modal .ant-table tfoot > tr > td,
  .ant-modal .ant-table tfoot > tr > th {
    border-bottom: 1px solid #3a3a3a;
  }
  .ant-modal .ant-table thead > tr > th {
    background-color: #272727;
    border-bottom: 1px solid #3a3a3a;
  }
  .ant-modal .ant-table.ant-table-bordered .ant-table-cell-fix-right-first::after,
  .ant-modal .ant-table.ant-table-bordered .ant-table-expanded-row-fixed::after,
  .ant-modal .ant-table.ant-table-bordered tbody > tr > td,
  .ant-modal .ant-table.ant-table-bordered tfoot > tr > td,
  .ant-modal .ant-table.ant-table-bordered tfoot > tr > th,
  .ant-modal .ant-table.ant-table-bordered thead > tr > th {
    border-right: 1px solid #3a3a3a;
  }
  .ant-modal .ant-table .ant-table-filter-trigger-container-open {
    background-color: #525252;
  }
  .ant-modal .ant-picker-calendar-full .ant-picker-panel {
    background-color: #212121;
  }
  .ant-modal .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    border-top: 2px solid #3a3a3a;
  }
  .ant-modal .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    background-color: #212121;
    border-bottom: 1px solid #212121;
  }
  .ant-modal .ant-badge-count {
    box-shadow: 0 0 0 1px #212121;
  }
  .ant-modal .ant-tree-show-line .ant-tree-switcher {
    background: #212121;
  }
  .ant-modal-wrap-rtl {
    direction: rtl;
  }
  .ant-modal-wrap-rtl .ant-modal-close {
    right: initial;
    left: 0;
  }
  .ant-modal-wrap-rtl .ant-modal-footer {
    text-align: left;
  }
  .ant-modal-wrap-rtl .ant-modal-confirm-btns button + button,
  .ant-modal-wrap-rtl .ant-modal-footer button + button {
    margin-right: 8px;
    margin-left: 0;
  }
  .ant-modal-wrap-rtl .ant-modal-confirm-body {
    direction: rtl;
  }
  .ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon {
    float: right;
    margin-right: 0;
    margin-left: 16px;
  }
  .ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
    margin-right: 38px;
    margin-left: 0;
  }
  .ant-modal-wrap-rtl .ant-modal-confirm-btns {
    float: left;
  }
  .ant-modal-wrap-rtl.ant-modal-centered .ant-modal,
  .ant-table-wrapper-rtl .ant-table table {
    text-align: right;
  }
  .ant-notification .ant-anchor-wrapper,
  .ant-notification .ant-card,
  .ant-notification .ant-collapse-content,
  .ant-notification .ant-picker-clear,
  .ant-notification .ant-slider-handle,
  .ant-notification .ant-timeline-item-head {
    background-color: #212121;
  }
  .ant-notification .ant-transfer-list-header {
    background: #212121;
    border-bottom: 1px solid #3a3a3a;
  }
  .ant-notification .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-notification .ant-table.ant-table-small thead > tr > th {
    background-color: #212121;
    border-bottom: 1px solid #3a3a3a;
  }
  .ant-notification .ant-picker-calendar-full,
  .ant-notification .ant-table,
  .ant-notification .ant-table tbody > tr > td.ant-table-cell-fix-left,
  .ant-notification .ant-table tbody > tr > td.ant-table-cell-fix-right {
    background-color: #212121;
  }
  .ant-notification .ant-table .ant-table-row-expand-icon,
  .ant-notification .ant-table.ant-table-bordered .ant-table-container,
  .ant-notification .ant-table.ant-table-bordered .ant-table-footer,
  .ant-notification .ant-table.ant-table-bordered .ant-table-title {
    border: 1px solid #3a3a3a;
  }
  .ant-notification .ant-table tbody > tr > td,
  .ant-notification .ant-table tfoot > tr > td,
  .ant-notification .ant-table tfoot > tr > th {
    border-bottom: 1px solid #3a3a3a;
  }
  .ant-notification .ant-table thead > tr > th {
    background-color: #272727;
    border-bottom: 1px solid #3a3a3a;
  }
  .ant-notification .ant-table.ant-table-bordered .ant-table-cell-fix-right-first::after,
  .ant-notification .ant-table.ant-table-bordered .ant-table-expanded-row-fixed::after,
  .ant-notification .ant-table.ant-table-bordered tbody > tr > td,
  .ant-notification .ant-table.ant-table-bordered tfoot > tr > td,
  .ant-notification .ant-table.ant-table-bordered tfoot > tr > th,
  .ant-notification .ant-table.ant-table-bordered thead > tr > th {
    border-right: 1px solid #3a3a3a;
  }
  .ant-notification .ant-table .ant-table-filter-trigger-container-open {
    background-color: #525252;
  }
  .ant-notification .ant-picker-calendar-full .ant-picker-panel {
    background-color: #212121;
  }
  .ant-notification .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    border-top: 2px solid #3a3a3a;
  }
  .ant-notification .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    background-color: #212121;
    border-bottom: 1px solid #212121;
  }
  .ant-notification .ant-badge-count {
    box-shadow: 0 0 0 1px #212121;
  }
  .ant-notification .ant-tree-show-line .ant-tree-switcher {
    background: #212121;
  }
  .ant-notification {
    box-sizing: border-box;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: fixed;
    z-index: 1010;
    max-width: calc(100vw - 32px);
    margin: 0 24px 0 0;
  }
  .ant-notification-bottomLeft,
  .ant-notification-topLeft {
    margin-right: 0;
    margin-left: 24px;
  }
  .ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
  .ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
  .ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
  .ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active {
    animation-name: NotificationLeftFadeIn;
  }
  .ant-notification-close-icon {
    font-size: 14px;
    cursor: pointer;
  }
  .ant-notification-hook-holder,
  .ant-notification-notice {
    position: relative;
    width: 384px;
    margin-bottom: 16px;
    margin-left: auto;
    overflow: hidden;
    background: #212121;
    border-radius: 4px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
  }
  .ant-notification-bottomLeft .ant-notification-hook-holder,
  .ant-notification-bottomLeft .ant-notification-notice,
  .ant-notification-topLeft .ant-notification-hook-holder,
  .ant-notification-topLeft .ant-notification-notice {
    margin-right: auto;
    margin-left: 0;
  }
  .ant-notification-hook-holder > .ant-notification-notice {
    margin-bottom: 0;
    box-shadow: none;
  }
  .ant-notification-notice {
    padding: 16px 24px;
    line-height: 1.5715;
  }
  .ant-notification-notice-message {
    display: inline-block;
    margin-bottom: 8px;
    color: rgba(255, 255, 255, 0.85);
    font-size: 16px;
    line-height: 24px;
  }
  .ant-notification-notice-message-single-line-auto-margin {
    display: block;
    width: calc(384px - 24px * 2 - 24px - 48px - 100%);
    max-width: 4px;
    background-color: transparent;
    pointer-events: none;
  }
  .ant-notification-notice-message-single-line-auto-margin::before {
    display: block;
    content: "";
  }
  .ant-notification-notice-description {
    font-size: 14px;
  }
  .ant-notification-notice-closable .ant-notification-notice-message {
    padding-right: 24px;
  }
  .ant-notification-notice-with-icon .ant-notification-notice-message {
    margin-bottom: 4px;
    margin-left: 48px;
    font-size: 16px;
  }
  .ant-notification-notice-with-icon .ant-notification-notice-description {
    margin-left: 48px;
    font-size: 14px;
  }
  .ant-notification-notice-icon {
    position: absolute;
    margin-left: 4px;
    font-size: 24px;
    line-height: 24px;
  }
  .anticon.ant-notification-notice-icon-success {
    color: #4bac1b;
  }
  .anticon.ant-notification-notice-icon-info {
    color: #2caad9;
  }
  .anticon.ant-notification-notice-icon-warning {
    color: #d99816;
  }
  .anticon.ant-notification-notice-icon-error {
    color: #ab2229;
  }
  .ant-notification-notice-close {
    position: absolute;
    top: 16px;
    right: 22px;
    color: rgba(255, 255, 255, 0.45);
    outline: 0;
  }
  .ant-notification-notice-close:hover {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-notification-notice-btn {
    float: right;
    margin-top: 16px;
  }
  .ant-notification .notification-fade-effect {
    animation-duration: 0.24s;
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-fill-mode: both;
  }
  .ant-notification-fade-appear,
  .ant-notification-fade-enter,
  .ant-notification-fade-leave {
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .ant-notification-fade-appear,
  .ant-notification-fade-enter {
    opacity: 0;
    animation-duration: 0.24s;
  }
  .ant-notification-fade-leave {
    animation-duration: 0.2s;
  }
  .ant-notification-fade-appear.ant-notification-fade-appear-active,
  .ant-notification-fade-enter.ant-notification-fade-enter-active {
    animation-name: NotificationFadeIn;
    animation-play-state: running;
  }
  .ant-notification-fade-leave.ant-notification-fade-leave-active {
    animation-name: NotificationFadeOut;
    animation-play-state: running;
  }
  .ant-notification-rtl {
    direction: rtl;
  }
  .ant-notification-rtl .ant-notification-notice-closable .ant-notification-notice-message {
    padding-right: 0;
    padding-left: 24px;
  }
  .ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-description,
  .ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-message {
    margin-right: 48px;
    margin-left: 0;
  }
  .ant-notification-rtl .ant-notification-notice-icon {
    margin-right: 4px;
    margin-left: 0;
  }
  .ant-notification-rtl .ant-notification-notice-close {
    right: auto;
    left: 22px;
  }
  .ant-notification-rtl .ant-notification-notice-btn {
    float: left;
  }
  .ant-page-header {
    box-sizing: border-box;
    margin: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    padding: 16px 24px;
    background-color: #212121;
  }
  .ant-page-header-ghost {
    background-color: transparent;
  }
  .ant-page-header.has-breadcrumb {
    padding-top: 12px;
  }
  .ant-page-header.has-footer {
    padding-bottom: 0;
  }
  .ant-page-header-back {
    margin-right: 16px;
    font-size: 16px;
    line-height: 1;
  }
  .ant-page-header-back-button {
    text-decoration: none;
    outline: 0;
    transition: color 0.3s;
    color: inherit;
    cursor: pointer;
  }
  .ant-page-header-back-button:focus,
  .ant-page-header-back-button:hover {
    color: #287a99;
  }
  .ant-page-header-back-button:active {
    color: #4eb3d2;
  }
  .ant-page-header .ant-divider-vertical {
    height: 14px;
    margin: 0 12px;
    vertical-align: middle;
  }
  .ant-breadcrumb + .ant-page-header-heading {
    margin-top: 8px;
  }
  .ant-page-header-heading {
    display: flex;
    justify-content: space-between;
  }
  .ant-page-header-heading-left {
    display: flex;
    align-items: center;
    margin: 4px 0;
    overflow: hidden;
  }
  .ant-page-header-heading-sub-title,
  .ant-page-header-heading-title {
    margin-right: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ant-page-header-heading-title {
    margin-bottom: 0;
    color: rgba(255, 255, 255, 0.85);
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
  }
  .ant-page-header-heading .ant-avatar {
    margin-right: 12px;
  }
  .ant-page-header-heading-sub-title {
    color: rgba(255, 255, 255, 0.45);
    font-size: 14px;
    line-height: 1.5715;
  }
  .ant-page-header-heading-extra {
    margin: 4px 0;
    white-space: nowrap;
  }
  .ant-page-header-heading-extra > * {
    margin-left: 12px;
    white-space: unset;
  }
  .ant-page-header-heading-extra > :first-child {
    margin-left: 0;
  }
  .ant-page-header-content {
    padding-top: 12px;
  }
  .ant-page-header-footer,
  .ant-skeleton-content .ant-skeleton-paragraph > li + li {
    margin-top: 16px;
  }
  .ant-page-header-footer .ant-tabs > .ant-tabs-nav,
  .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column {
    margin: 0;
  }
  .ant-page-header-footer .ant-tabs .ant-tabs-tab {
    padding: 8px 0;
    font-size: 16px;
  }
  .ant-page-header-compact .ant-page-header-heading {
    flex-wrap: wrap;
  }
  .ant-page-header-rtl,
  .ant-pagination-options-quick-jumper input-rtl {
    direction: rtl;
  }
  .ant-page-header-rtl .ant-page-header-back {
    float: right;
    margin-right: 0;
    margin-left: 16px;
  }
  .ant-page-header-rtl .ant-page-header-heading .ant-avatar,
  .ant-page-header-rtl .ant-page-header-heading-title {
    margin-right: 0;
    margin-left: 12px;
  }
  .ant-page-header-rtl .ant-page-header-heading-sub-title {
    float: right;
    margin-right: 0;
    margin-left: 12px;
  }
  .ant-page-header-rtl .ant-page-header-footer .ant-tabs-bar .ant-tabs-nav,
  .ant-page-header-rtl .ant-page-header-heading-tags {
    float: right;
  }
  .ant-page-header-rtl .ant-page-header-heading-extra {
    float: left;
  }
  .ant-page-header-rtl .ant-page-header-heading-extra > * {
    margin-right: 12px;
    margin-left: 0;
  }
  .ant-page-header-rtl .ant-page-header-heading-extra > :first-child {
    margin-right: 0;
  }
  .ant-pagination {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
  }
  .ant-pagination::after {
    display: block;
    clear: both;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    content: " ";
  }
  .ant-pagination-item,
  .ant-pagination-total-text {
    display: inline-block;
    height: 32px;
    margin-right: 8px;
    line-height: 30px;
    vertical-align: middle;
  }
  .ant-pagination-item {
    min-width: 32px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    text-align: center;
    list-style: none;
    background-color: transparent;
    border: 1px solid #6f6c6c;
    border-radius: 4px;
    outline: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-pagination-item a {
    display: block;
    padding: 0 6px;
    color: rgba(255, 255, 255, 0.85);
    transition: none;
  }
  .ant-pagination-item a:hover {
    text-decoration: none;
  }
  .ant-pagination-item:focus,
  .ant-pagination-item:hover {
    border-color: #2caad9;
    transition: all 0.3s;
  }
  .ant-pagination-item:focus a,
  .ant-pagination-item:hover a {
    color: #2caad9;
  }
  .ant-pagination-item-active {
    font-weight: 500;
    background: 0 0;
    border-color: #2caad9;
  }
  .ant-pagination-item-active a {
    color: #2caad9;
  }
  .ant-pagination-item-active:focus,
  .ant-pagination-item-active:hover {
    border-color: #287a99;
  }
  .ant-pagination-item-active:focus a,
  .ant-pagination-item-active:hover a {
    color: #287a99;
  }
  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    outline: 0;
  }
  .ant-pagination-jump-next .ant-pagination-item-container,
  .ant-pagination-jump-prev .ant-pagination-item-container {
    position: relative;
  }
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: #2caad9;
    font-size: 12px;
    letter-spacing: -1px;
    opacity: 0;
    transition: all 0.2s;
  }
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis,
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    margin: auto;
    color: rgba(255, 255, 255, 0.3);
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 2px;
    text-align: center;
    text-indent: 0.13em;
    opacity: 1;
    transition: all 0.2s;
  }
  .ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
  .ant-pagination-jump-next:hover .ant-pagination-item-link-icon,
  .ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
  .ant-pagination-jump-prev:hover .ant-pagination-item-link-icon {
    opacity: 1;
  }
  .ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next:hover .ant-pagination-item-ellipsis,
  .ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
  .ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis {
    opacity: 0;
  }
  .ant-pagination-jump-next,
  .ant-pagination-jump-prev,
  .ant-pagination-prev {
    margin-right: 8px;
  }
  .ant-pagination-jump-next,
  .ant-pagination-jump-prev,
  .ant-pagination-next,
  .ant-pagination-prev {
    display: inline-block;
    min-width: 32px;
    height: 32px;
    color: rgba(255, 255, 255, 0.85);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    line-height: 32px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s;
  }
  .ant-pagination-next,
  .ant-pagination-prev {
    font-family: Arial, Helvetica, sans-serif;
    outline: 0;
  }
  .ant-pagination-next button,
  .ant-pagination-prev button {
    color: rgba(255, 255, 255, 0.85);
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-pagination-next:hover button,
  .ant-pagination-prev:hover button {
    border-color: #287a99;
  }
  .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination-prev .ant-pagination-item-link {
    display: block;
    width: 100%;
    height: 100%;
    font-size: 12px;
    text-align: center;
    background-color: transparent;
    border: 1px solid #6f6c6c;
    border-radius: 4px;
    outline: 0;
    transition: all 0.3s;
  }
  .ant-pagination-next:focus .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link,
  .ant-pagination-prev:focus .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link {
    color: #2caad9;
    border-color: #2caad9;
  }
  .ant-pagination-disabled,
  .ant-pagination-disabled:focus,
  .ant-pagination-disabled:hover {
    cursor: not-allowed;
  }
  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:focus .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link {
    color: rgba(255, 255, 255, 0.3);
    border-color: #6f6c6c;
    cursor: not-allowed;
  }
  .ant-pagination-slash {
    margin: 0 10px 0 5px;
  }
  .ant-pagination-options {
    display: inline-block;
    margin-left: 16px;
    vertical-align: middle;
  }
  @media all and (-ms-high-contrast: none) {
    .ant-pagination-options,
    .ant-pagination-options ::-ms-backdrop {
      vertical-align: top;
    }
  }
  .ant-pagination-options-size-changer.ant-select {
    display: inline-block;
    width: auto;
    margin-right: 8px;
  }
  .ant-pagination-options-quick-jumper {
    display: inline-block;
    height: 32px;
    line-height: 32px;
    vertical-align: top;
  }
  .ant-pagination-options-quick-jumper input {
    position: relative;
    display: inline-block;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: transparent;
    background-image: none;
    border: 1px solid #6f6c6c;
    border-radius: 4px;
    transition: all 0.3s;
    width: 50px;
    margin: 0 8px;
  }
  .ant-pagination-options-quick-jumper input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.3);
    text-overflow: ellipsis;
  }
  .ant-pagination-options-quick-jumper input:placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-pagination-options-quick-jumper input:hover {
    border-color: #287a99;
    border-right-width: 1px !important;
  }
  .ant-pagination-options-quick-jumper input-focused,
  .ant-pagination-options-quick-jumper input:focus {
    border-color: #2caad9;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(44, 170, 217, 0.2);
  }
  .ant-input-rtl .ant-pagination-options-quick-jumper input-focused,
  .ant-input-rtl .ant-pagination-options-quick-jumper input:focus {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ant-pagination-options-quick-jumper input[disabled] {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-pagination-options-quick-jumper input[disabled]:hover {
    border-color: #6f6c6c;
    border-right-width: 1px !important;
  }
  textarea.ant-pagination-options-quick-jumper input {
    max-width: 100%;
    height: auto;
    min-height: 32px;
    line-height: 1.5715;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
  }
  .ant-pagination-simple .ant-pagination-next,
  .ant-pagination-simple .ant-pagination-prev {
    height: 24px;
    line-height: 24px;
    vertical-align: top;
  }
  .ant-pagination-simple .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link {
    height: 24px;
    background-color: transparent;
    border: 0;
  }
  .ant-pagination-simple .ant-pagination-next .ant-pagination-item-link::after,
  .ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link::after,
  .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link::after,
  .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link::after,
  .ant-pagination.mini .ant-pagination-simple-pager,
  .ant-pagination.mini .ant-pagination-total-text {
    height: 24px;
    line-height: 24px;
  }
  .ant-pagination-simple .ant-pagination-simple-pager {
    display: inline-block;
    height: 24px;
    margin-right: 8px;
  }
  .ant-pagination-simple .ant-pagination-simple-pager input {
    box-sizing: border-box;
    height: 100%;
    margin-right: 8px;
    padding: 0 6px;
    text-align: center;
    background-color: transparent;
    border: 1px solid #6f6c6c;
    border-radius: 4px;
    outline: 0;
    transition: border-color 0.3s;
  }
  .ant-pagination-simple .ant-pagination-simple-pager input:hover {
    border-color: #2caad9;
  }
  .ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
    border-color: #6f6c6c;
    cursor: not-allowed;
  }
  .ant-pagination.mini .ant-pagination-item {
    min-width: 24px;
    height: 24px;
    margin: 0;
    line-height: 22px;
  }
  .ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
    background: 0 0;
    border-color: transparent;
  }
  .ant-pagination.mini .ant-pagination-next,
  .ant-pagination.mini .ant-pagination-prev {
    min-width: 24px;
    height: 24px;
    margin: 0;
    line-height: 24px;
  }
  .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link {
    background: 0 0;
    border-color: transparent;
  }
  .ant-pagination.mini .ant-pagination-jump-next,
  .ant-pagination.mini .ant-pagination-jump-prev {
    height: 24px;
    margin-right: 0;
    line-height: 24px;
  }
  .ant-pagination.mini .ant-pagination-options {
    margin-left: 2px;
  }
  .ant-pagination.mini .ant-pagination-options-size-changer {
    top: 0;
  }
  .ant-pagination.mini .ant-pagination-options-quick-jumper {
    height: 24px;
    line-height: 24px;
  }
  .ant-pagination.mini .ant-pagination-options-quick-jumper input {
    padding: 0 7px;
    width: 44px;
  }
  .ant-pagination.ant-pagination-disabled {
    cursor: not-allowed;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item {
    background: rgba(255, 255, 255, 0.08);
    border-color: #6f6c6c;
    cursor: not-allowed;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item a {
    color: rgba(255, 255, 255, 0.3);
    background: 0 0;
    border: 0;
    cursor: not-allowed;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
    background: rgba(255, 255, 255, 0.25);
    border-color: transparent;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
    color: #000;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
    border-color: #6f6c6c;
    cursor: not-allowed;
  }
  .ant-pagination-simple.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
    background: 0 0;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item-link-icon {
    opacity: 0;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item-ellipsis {
    opacity: 1;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
    color: rgba(255, 255, 255, 0.3);
  }
  @media only screen and (max-width: 992px) {
    .ant-pagination-item-after-jump-prev,
    .ant-pagination-item-before-jump-next {
      display: none;
    }
  }
  @media only screen and (max-width: 576px) {
    .ant-pagination-options {
      display: none;
    }
  }
  .ant-pagination-rtl {
    direction: rtl;
  }
  .ant-pagination-rtl .ant-pagination-item,
  .ant-pagination-rtl .ant-pagination-jump-next,
  .ant-pagination-rtl .ant-pagination-jump-prev,
  .ant-pagination-rtl .ant-pagination-options-size-changer.ant-select,
  .ant-pagination-rtl .ant-pagination-prev,
  .ant-pagination-rtl .ant-pagination-total-text,
  .ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager,
  .ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager input {
    margin-right: 0;
    margin-left: 8px;
  }
  .ant-pagination-rtl .ant-pagination-slash {
    margin: 0 5px 0 10px;
  }
  .ant-pagination-rtl .ant-pagination-options {
    margin-right: 16px;
    margin-left: 0;
  }
  .ant-pagination-rtl.ant-pagination.mini .ant-pagination-options {
    margin-right: 2px;
    margin-left: 0;
  }
  .ant-popconfirm {
    z-index: 1060;
  }
  .ant-popover {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1030;
    font-weight: 400;
    white-space: normal;
    text-align: left;
    cursor: auto;
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
  .ant-popover::after {
    position: absolute;
    background: rgba(255, 255, 255, 0.01);
    content: "";
  }
  .ant-popover-hidden {
    display: none;
  }
  .ant-popover-placement-top,
  .ant-popover-placement-topLeft,
  .ant-popover-placement-topRight {
    padding-bottom: 10px;
  }
  .ant-popover-placement-right,
  .ant-popover-placement-rightBottom,
  .ant-popover-placement-rightTop {
    padding-left: 10px;
  }
  .ant-popover-placement-bottom,
  .ant-popover-placement-bottomLeft,
  .ant-popover-placement-bottomRight {
    padding-top: 10px;
  }
  .ant-popover-placement-left,
  .ant-popover-placement-leftBottom,
  .ant-popover-placement-leftTop {
    padding-right: 10px;
  }
  .ant-popover-inner {
    background-color: #212121;
    background-clip: padding-box;
    border-radius: 4px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.45) \9;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ant-popover-inner {
      box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
    }
  }
  .ant-popover-title {
    min-width: 177px;
    min-height: 32px;
    margin: 0;
    padding: 5px 16px 4px;
    color: rgba(255, 255, 255, 0.85);
    font-weight: 500;
    border-bottom: 1px solid #424242;
  }
  .ant-popover-inner-content,
  .ant-popover-message {
    padding: 12px 16px;
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-popover-message {
    position: relative;
    padding: 4px 0 12px;
    font-size: 14px;
  }
  .ant-popover-message > .anticon {
    position: absolute;
    top: 8.0005px;
    color: #d99816;
    font-size: 14px;
  }
  .ant-popover-message-title {
    padding-left: 22px;
  }
  .ant-popover-buttons {
    margin-bottom: 4px;
    text-align: right;
  }
  .ant-popover-buttons button {
    margin-left: 8px;
  }
  .ant-popover-arrow {
    position: absolute;
    display: block;
    width: 8.48528137px;
    height: 8.48528137px;
    background: 0 0;
    border-style: solid;
    border-width: 4.24264069px;
    transform: rotate(45deg);
  }
  .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
  .ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
  .ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
    bottom: 6.2px;
    border-color: transparent #212121 #212121 transparent;
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  }
  .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
  .ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow {
    left: 16px;
  }
  .ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
    right: 16px;
  }
  .ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
  .ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow,
  .ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
    left: 6px;
    border-color: transparent transparent #212121 #212121;
    box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  }
  .ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }
  .ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
    top: 12px;
  }
  .ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
    bottom: 12px;
  }
  .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
  .ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
  .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
    top: 6px;
    border-color: #212121 transparent transparent #212121;
    box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
  }
  .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
  .ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow {
    left: 16px;
  }
  .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
    right: 16px;
  }
  .ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
  .ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow,
  .ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow {
    right: 6px;
    border-color: #212121 #212121 transparent transparent;
    box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  }
  .ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }
  .ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow {
    top: 12px;
  }
  .ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
    bottom: 12px;
  }
  .ant-popover-rtl {
    direction: rtl;
    text-align: right;
  }
  .ant-popover-rtl .ant-popover-message-title {
    padding-right: 22px;
    padding-left: 16px;
  }
  .ant-popover-rtl .ant-popover-buttons {
    text-align: left;
  }
  .ant-popover-rtl .ant-popover-buttons button {
    margin-right: 8px;
    margin-left: 0;
  }
  .ant-progress {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    display: inline-block;
  }
  .ant-progress-line {
    position: relative;
    width: 100%;
    font-size: 14px;
  }
  .ant-progress-steps,
  .ant-select-arrow svg,
  .ant-select-multiple .ant-select-selection-item-remove svg {
    display: inline-block;
  }
  .ant-progress-steps-outer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .ant-progress-steps-item {
    flex-shrink: 0;
    min-width: 2px;
    margin-right: 2px;
    background: rgba(255, 255, 255, 0.08);
    transition: all 0.3s;
  }
  .ant-progress-steps-item-active {
    background: #197fde;
  }
  .ant-progress-small.ant-progress-line,
  .ant-progress-small.ant-progress-line .ant-progress-text .anticon {
    font-size: 12px;
  }
  .ant-progress-outer {
    display: inline-block;
    width: 100%;
    margin-right: 0;
    padding-right: 0;
  }
  .ant-progress-show-info .ant-progress-outer {
    margin-right: calc(-2em - 8px);
    padding-right: calc(2em + 8px);
  }
  .ant-progress-inner {
    position: relative;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    vertical-align: middle;
    background-color: rgba(255, 255, 255, 0.08);
    border-radius: 100px;
  }
  .ant-progress-circle-trail {
    stroke: rgba(255, 255, 255, 0.08);
  }
  .ant-progress-circle-path {
    animation: ant-progress-appear 0.3s;
  }
  .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: #197fde;
  }
  .ant-progress-bg,
  .ant-progress-success-bg {
    border-radius: 100px;
    transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
  }
  .ant-progress-bg {
    position: relative;
    background-color: #197fde;
  }
  .ant-progress-success-bg {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #4bac1b;
  }
  .ant-progress-text {
    display: inline-block;
    width: 2em;
    margin-left: 8px;
    color: rgba(255, 255, 255, 0.45);
    font-size: 1em;
    line-height: 1;
    white-space: nowrap;
    text-align: left;
    vertical-align: middle;
    word-break: normal;
  }
  .ant-progress-text .anticon {
    font-size: 14px;
  }
  .ant-progress-status-active .ant-progress-bg::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #212121;
    border-radius: 10px;
    opacity: 0;
    animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
    content: "";
  }
  .ant-progress-status-exception .ant-progress-bg {
    background-color: #ab2229;
  }
  .ant-progress-status-exception .ant-progress-text {
    color: #ab2229;
  }
  .ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: #ab2229;
  }
  .ant-progress-status-success .ant-progress-bg {
    background-color: #4bac1b;
  }
  .ant-progress-status-success .ant-progress-text {
    color: #4bac1b;
  }
  .ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: #4bac1b;
  }
  .ant-progress-circle .ant-progress-inner {
    position: relative;
    line-height: 1;
    background-color: transparent;
  }
  .ant-progress-circle .ant-progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 1em;
    line-height: 1;
    white-space: normal;
    text-align: center;
    transform: translate(-50%, -50%);
  }
  .ant-progress-circle .ant-progress-text .anticon {
    font-size: 1.16666667em;
  }
  .ant-progress-circle.ant-progress-status-exception .ant-progress-text {
    color: #ab2229;
  }
  .ant-progress-circle.ant-progress-status-success .ant-progress-text {
    color: #4bac1b;
  }
  .ant-progress-rtl {
    direction: rtl;
  }
  .ant-progress-rtl.ant-progress-show-info .ant-progress-outer {
    margin-right: 0;
    margin-left: calc(-2em - 8px);
    padding-right: 0;
    padding-left: calc(2em + 8px);
  }
  .ant-progress-rtl .ant-progress-success-bg {
    right: 0;
    left: auto;
  }
  .ant-progress-rtl.ant-progress-line .ant-progress-text,
  .ant-progress-rtl.ant-progress-steps .ant-progress-text {
    margin-right: 8px;
    margin-left: 0;
    text-align: right;
  }
  .ant-radio-group {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    display: inline-block;
    font-size: 0;
    line-height: unset;
  }
  .ant-radio-group .ant-badge-count {
    z-index: 1;
  }
  .ant-radio-group > .ant-badge:not(:first-child) > .ant-radio-button-wrapper {
    border-left: none;
  }
  .ant-radio,
  .ant-radio-wrapper {
    box-sizing: border-box;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    margin: 0 8px 0 0;
    white-space: nowrap;
    cursor: pointer;
  }
  .ant-radio {
    margin: 0;
    top: 0;
    line-height: 1;
    vertical-align: sub;
    outline: 0;
  }
  .ant-radio-input:focus + .ant-radio-inner,
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner {
    border-color: #2caad9;
  }
  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px rgba(44, 170, 217, 0.08);
  }
  .ant-radio-checked::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #2caad9;
    border-radius: 50%;
    visibility: hidden;
    animation: antRadioEffect 0.36s ease-in-out;
    animation-fill-mode: both;
    content: "";
  }
  .ant-radio-wrapper:hover .ant-radio::after,
  .ant-radio:hover::after {
    visibility: visible;
  }
  .ant-radio-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    background-color: transparent;
    border-color: #6f6c6c;
    border-style: solid;
    border-width: 1px;
    border-radius: 100px;
    transition: all 0.3s;
  }
  .ant-radio-inner::after {
    position: absolute;
    top: 3px;
    left: 3px;
    display: table;
    width: 8px;
    height: 8px;
    background-color: #2caad9;
    border-top: 0;
    border-left: 0;
    border-radius: 8px;
    transform: scale(0);
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    content: " ";
  }
  .ant-radio-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #2caad9;
  }
  .ant-radio-checked .ant-radio-inner::after {
    transform: scale(1);
    opacity: 1;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ant-radio-disabled .ant-radio-inner {
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #6f6c6c !important;
    cursor: not-allowed;
  }
  .ant-radio-disabled .ant-radio-inner::after {
    background-color: rgba(255, 255, 255, 0.2);
  }
  .ant-radio-disabled .ant-radio-input {
    cursor: not-allowed;
  }
  .ant-radio-disabled + span {
    color: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
  }
  span.ant-radio + * {
    padding-right: 8px;
    padding-left: 8px;
  }
  .ant-radio-button-wrapper {
    position: relative;
    display: inline-block;
    height: 32px;
    margin: 0;
    padding: 0 15px;
    font-size: 14px;
    line-height: 30px;
    background: 0 0;
    border: 1px solid #6f6c6c;
    border-top-width: 1.02px;
    border-left-width: 0;
    cursor: pointer;
    transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
  }
  .ant-radio-button-wrapper,
  .ant-radio-button-wrapper a {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-radio-button-wrapper > .ant-radio-button {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
  .ant-radio-group-large .ant-radio-button-wrapper {
    height: 40px;
    font-size: 16px;
    line-height: 38px;
  }
  .ant-radio-group-small .ant-radio-button-wrapper {
    height: 24px;
    padding: 0 7px;
    line-height: 22px;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    position: absolute;
    top: -1px;
    left: -1px;
    display: block;
    box-sizing: content-box;
    width: 1px;
    height: 100%;
    padding: 1px 0;
    background-color: #6f6c6c;
    transition: background-color 0.3s;
    content: "";
  }
  .ant-radio-button-wrapper:first-child {
    border-left: 1px solid #6f6c6c;
    border-radius: 4px 0 0 4px;
  }
  .ant-radio-button-wrapper:last-child {
    border-radius: 0 4px 4px 0;
  }
  .ant-radio-button-wrapper:first-child:last-child {
    border-radius: 4px;
  }
  .ant-radio-button-wrapper:hover {
    position: relative;
    color: #2caad9;
  }
  .ant-radio-button-wrapper:focus-within {
    box-shadow: 0 0 0 3px rgba(44, 170, 217, 0.08);
  }
  .ant-radio-button-wrapper .ant-radio-inner,
  .ant-radio-button-wrapper input[type="checkbox"],
  .ant-radio-button-wrapper input[type="radio"] {
    width: 0;
    height: 0;
    opacity: 0;
    pointer-events: none;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #2caad9;
    background: 0 0;
    border-color: #2caad9;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #2caad9;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    border-color: #2caad9;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #287a99;
    border-color: #287a99;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
    background-color: #287a99;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
    color: #4eb3d2;
    border-color: #4eb3d2;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
    background-color: #4eb3d2;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: 0 0 0 3px rgba(44, 170, 217, 0.08);
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: #2caad9;
    border-color: #2caad9;
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #fff;
    background: #287a99;
    border-color: #287a99;
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
    color: #fff;
    background: #4eb3d2;
    border-color: #4eb3d2;
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: 0 0 0 3px rgba(44, 170, 217, 0.08);
  }
  .ant-radio-button-wrapper-disabled {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #6f6c6c;
    cursor: not-allowed;
  }
  .ant-radio-button-wrapper-disabled:first-child,
  .ant-radio-button-wrapper-disabled:hover {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #6f6c6c;
  }
  .ant-radio-button-wrapper-disabled:first-child {
    border-left-color: #6f6c6c;
  }
  .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.2);
    border-color: #6f6c6c;
    box-shadow: none;
  }
  @supports (-moz-appearance: meterbar) and (background-blend-mode: difference, normal) {
    .ant-radio {
      vertical-align: text-bottom;
    }
  }
  .ant-radio-group.ant-radio-group-rtl {
    direction: rtl;
  }
  .ant-radio-wrapper.ant-radio-wrapper-rtl {
    margin-right: 0;
    margin-left: 8px;
    direction: rtl;
  }
  .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl {
    border-right-width: 0;
    border-left-width: 1px;
  }
  .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:not(:first-child)::before {
    right: -1px;
    left: 0;
  }
  .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
    border-right: 1px solid #6f6c6c;
    border-radius: 0 4px 4px 0;
  }
  .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
    border-right-color: #287a99;
  }
  .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:last-child {
    border-radius: 4px 0 0 4px;
  }
  .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
    border-right-color: #6f6c6c;
  }
  .ant-rate,
  .ant-rate-star {
    display: inline-block;
    margin: 0;
    padding: 0;
  }
  .ant-rate {
    box-sizing: border-box;
    color: rgba(255, 255, 255, 0.85);
    font-variant: tabular-nums;
    font-feature-settings: "tnum";
    color: #d9bf16;
    font-size: 20px;
    line-height: unset;
    list-style: none;
    outline: 0;
  }
  .ant-rate-disabled .ant-rate-star {
    cursor: default;
  }
  .ant-rate-disabled .ant-rate-star:hover {
    transform: scale(1);
  }
  .ant-rate-star {
    position: relative;
    color: inherit;
    cursor: pointer;
    transition: all 0.3s;
  }
  .ant-rate-star:not(:last-child) {
    margin-right: 8px;
  }
  .ant-rate-star > div:focus,
  .ant-rate-star > div:hover {
    transform: scale(1.1);
  }
  .ant-rate-star-first,
  .ant-rate-star-second {
    color: rgba(255, 255, 255, 0.12);
    transition: all 0.3s;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-rate-star-first .anticon,
  .ant-rate-star-second .anticon {
    vertical-align: middle;
  }
  .ant-rate-star-first {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    overflow: hidden;
    opacity: 0;
  }
  .ant-rate-star-half .ant-rate-star-first,
  .ant-rate-star-half .ant-rate-star-second {
    opacity: 1;
  }
  .ant-rate-star-full .ant-rate-star-second,
  .ant-rate-star-half .ant-rate-star-first {
    color: inherit;
  }
  .ant-rate-text {
    display: inline-block;
    margin: 0 8px;
    font-size: 14px;
  }
  .ant-rate-rtl {
    direction: rtl;
  }
  .ant-rate-rtl .ant-rate-star:not(:last-child) {
    margin-right: 0;
    margin-left: 8px;
  }
  .ant-rate-rtl .ant-rate-star-first {
    right: 0;
    left: auto;
  }
  .ant-result {
    padding: 48px 32px;
  }
  .ant-result-success .ant-result-icon > .anticon {
    color: #4bac1b;
  }
  .ant-result-error .ant-result-icon > .anticon {
    color: #ab2229;
  }
  .ant-result-info .ant-result-icon > .anticon {
    color: #2caad9;
  }
  .ant-result-warning .ant-result-icon > .anticon {
    color: #d99816;
  }
  .ant-result-image {
    width: 250px;
    height: 295px;
    margin: auto;
  }
  .ant-result-icon {
    margin-bottom: 24px;
    text-align: center;
  }
  .ant-result-icon > .anticon {
    font-size: 72px;
  }
  .ant-result-title {
    color: rgba(255, 255, 255, 0.85);
    font-size: 24px;
    line-height: 1.8;
    text-align: center;
  }
  .ant-result-subtitle {
    color: rgba(255, 255, 255, 0.45);
    font-size: 14px;
    line-height: 1.6;
    text-align: center;
  }
  .ant-result-extra {
    margin: 24px 0 0;
    text-align: center;
  }
  .ant-result-extra > * {
    margin-right: 8px;
  }
  .ant-result-extra > :last-child {
    margin-right: 0;
  }
  .ant-result-content {
    margin-top: 24px;
    padding: 24px 40px;
    background-color: rgba(255, 255, 255, 0.04);
  }
  .ant-result-rtl {
    direction: rtl;
  }
  .ant-result-rtl .ant-result-extra > * {
    margin-right: 0;
    margin-left: 8px;
  }
  .ant-result-rtl .ant-result-extra > :last-child {
    margin-left: 0;
  }
  .ant-select-single .ant-select-selector {
    display: flex;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-search {
    position: absolute;
    top: 0;
    right: 11px;
    bottom: 0;
    left: 11px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-search-input {
    width: 100%;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    padding: 0;
    line-height: 30px;
    transition: all 0.3s;
  }
  @supports (-moz-appearance: meterbar) {
    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 30px;
    }
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item {
    position: relative;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder,
  span.ant-transfer-list-search-action {
    pointer-events: none;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item::after,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder::after,
  .ant-select-single .ant-select-selector::after {
    display: inline-block;
    width: 0;
    visibility: hidden;
    content: "\a0";
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    right: 25px;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 18px;
  }
  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: transparent;
    border: 1px solid #6f6c6c;
    border-radius: 4px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    height: 32px;
    padding: 0 11px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector input {
    cursor: pointer;
  }
  .ant-select-show-search.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    cursor: text;
  }
  .ant-select-show-search.ant-select-single:not(.ant-select-customize-input) .ant-select-selector input {
    cursor: auto;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #2caad9;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(44, 170, 217, 0.2);
  }
  .ant-input-rtl
    .ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
    cursor: not-allowed;
  }
  .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector input {
    cursor: not-allowed;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    margin: 0;
    padding: 0;
    background: 0 0;
    border: 0;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 30px;
  }
  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input::-webkit-search-cancel-button {
    display: none;
    -webkit-appearance: none;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
    line-height: 30px;
  }
  .ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder::after,
  .ant-select-single.ant-select-customize-input .ant-select-selector::after {
    display: none;
  }
  .ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-search {
    position: static;
    width: 100%;
  }
  .ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
    position: absolute;
    right: 0;
    left: 0;
    padding: 0 11px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-placeholder,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after {
    line-height: 38px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input)
    .ant-select-selection-search-input {
    height: 38px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    height: 24px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-placeholder,
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::after {
    line-height: 22px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input)
    .ant-select-selection-search-input {
    height: 22px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selection-search {
    right: 7px;
    left: 7px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 7px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
    right: 28px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow
    .ant-select-selection-placeholder {
    padding-right: 21px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 11px;
  }
  .ant-select-multiple .ant-select-selector {
    position: relative;
    background-color: transparent;
    border: 1px solid #6f6c6c;
    border-radius: 4px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 1px 4px;
  }
  .ant-select-multiple .ant-select-selector input,
  .ant-upload input[type="file"] {
    cursor: pointer;
  }
  .ant-select-show-search.ant-select-multiple .ant-select-selector input {
    cursor: auto;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select-multiple .ant-select-selector {
    border-color: #2caad9;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(44, 170, 217, 0.2);
  }
  .ant-input-rtl .ant-select-focused:not(.ant-select-disabled).ant-select-multiple .ant-select-selector {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ant-select-disabled.ant-select-multiple .ant-select-selector {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-select-disabled.ant-select-multiple .ant-select-selector input {
    cursor: not-allowed;
  }
  .ant-select-multiple .ant-select-selector .ant-select-selection-search-input {
    margin: 0;
    padding: 0;
    background: 0 0;
    border: 0;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .ant-select-multiple .ant-select-selector .ant-select-selection-search-input::-webkit-search-cancel-button {
    display: none;
    -webkit-appearance: none;
  }
  .ant-select-show-search.ant-select-multiple .ant-select-selector {
    cursor: text;
  }
  .ant-select-disabled.ant-select-multiple .ant-select-selector {
    background: #212121;
    cursor: not-allowed;
  }
  .ant-select-multiple .ant-select-selector::after {
    display: inline-block;
    width: 0;
    margin: 2px 0;
    line-height: 24px;
    content: "\a0";
  }
  .ant-select-multiple.ant-select-allow-clear .ant-select-selector,
  .ant-select-multiple.ant-select-show-arrow .ant-select-selector {
    padding-right: 24px;
  }
  .ant-select-multiple .ant-select-selection-item {
    position: relative;
    display: flex;
    flex: none;
    box-sizing: border-box;
    max-width: 100%;
    height: 24px;
    margin-top: 2px;
    margin-right: 4px;
    margin-bottom: 2px;
    padding: 0 4px 0 8px;
    line-height: 22px;
    background: rgba(255, 255, 255, 0.08);
    border: 1px solid #424242;
    border-radius: 4px;
    cursor: default;
    transition: font-size 0.3s, line-height 0.3s, height 0.3s;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
    color: #595959;
    border-color: #212121;
    cursor: not-allowed;
  }
  .ant-select-multiple .ant-select-selection-item-content {
    display: inline-block;
    margin-right: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ant-select-multiple .ant-select-selection-item-remove {
    color: inherit;
    font-style: normal;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgba(255, 255, 255, 0.45);
    font-weight: 700;
    line-height: inherit;
    cursor: pointer;
    display: inline-block;
    font-size: 10px;
  }
  .ant-select-multiple .ant-select-selection-item-remove > * {
    line-height: 1;
  }
  .ant-select-multiple .ant-select-selection-item-remove::before {
    display: none;
  }
  .ant-select-multiple .ant-select-selection-item-remove .ant-select-multiple .ant-select-selection-item-remove-icon {
    display: block;
  }
  .ant-select-multiple .ant-select-selection-item-remove > .anticon {
    vertical-align: -0.2em;
  }
  .ant-select-multiple .ant-select-selection-item-remove:hover {
    color: rgba(255, 255, 255, 0.75);
  }
  .ant-select-multiple .ant-select-selection-search {
    position: relative;
    margin-left: 0.5px;
  }
  .ant-select-multiple .ant-select-selection-search-input,
  .ant-select-multiple .ant-select-selection-search-mirror {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    line-height: 1.5715;
    transition: all 0.3s;
  }
  .ant-select-multiple .ant-select-selection-search-input {
    width: 100%;
    min-width: 4.1px;
  }
  .ant-select-multiple .ant-select-selection-search-mirror {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    white-space: nowrap;
    visibility: hidden;
  }
  .ant-select-multiple .ant-select-selection-search:first-child .ant-select-selection-search-input {
    margin-left: 6.5px;
  }
  .ant-select-multiple .ant-select-selection-placeholder {
    position: absolute;
    top: 50%;
    right: 11px;
    left: 11px;
    transform: translateY(-50%);
    transition: all 0.3s;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selector::after {
    line-height: 32px;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-search {
    height: 33px;
    line-height: 33px;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-search-input,
  .ant-select-multiple.ant-select-lg .ant-select-selection-search-mirror {
    height: 32px;
    line-height: 30px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selector::after {
    line-height: 16px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selection-item {
    height: 16px;
    line-height: 14px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selection-search {
    height: 17px;
    line-height: 17px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selection-search-input,
  .ant-select-multiple.ant-select-sm .ant-select-selection-search-mirror {
    height: 16px;
    line-height: 14px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
    left: 7px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selection-search:first-child .ant-select-selection-search-input {
    margin-left: 3px;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    height: 32px;
    line-height: 32px;
  }
  .ant-select-disabled .ant-select-selection-item-remove {
    display: none;
  }
  .ant-select {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #287a99;
    border-right-width: 1px !important;
  }
  .ant-input-rtl .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ant-select-selection-item {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  @media all and (-ms-high-contrast: none) {
    .ant-select-selection-item,
    .ant-select-selection-item ::-ms-backdrop {
      flex: auto;
    }
  }
  .ant-select-selection-placeholder {
    flex: 1;
    overflow: hidden;
    color: rgba(255, 255, 255, 0.3);
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  @media all and (-ms-high-contrast: none) {
    .ant-select-selection-placeholder,
    .ant-select-selection-placeholder ::-ms-backdrop {
      flex: auto;
    }
  }
  .ant-select-arrow {
    display: inline-block;
    color: inherit;
    font-style: normal;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 53%;
    right: 11px;
    width: 12px;
    height: 12px;
    margin-top: -6px;
    color: rgba(255, 255, 255, 0.3);
    font-size: 12px;
    line-height: 1;
    text-align: center;
    pointer-events: none;
  }
  .ant-select-arrow > * {
    line-height: 1;
  }
  .ant-select-arrow::before {
    display: none;
  }
  .ant-select-arrow .ant-select-arrow-icon,
  .ant-select-clear::before {
    display: block;
  }
  .ant-select-arrow .anticon {
    vertical-align: top;
    transition: transform 0.3s;
  }
  .ant-select-arrow .anticon > svg {
    vertical-align: top;
  }
  .ant-select-arrow .anticon:not(.ant-select-suffix) {
    pointer-events: auto;
  }
  .ant-select-disabled .ant-select-arrow {
    cursor: not-allowed;
  }
  .ant-select-clear {
    position: absolute;
    top: 50%;
    right: 11px;
    z-index: 1;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-top: -6px;
    color: rgba(255, 255, 255, 0.3);
    font-size: 12px;
    font-style: normal;
    line-height: 1;
    text-align: center;
    text-transform: none;
    background: #212121;
    cursor: pointer;
    opacity: 0;
    transition: color 0.3s ease, opacity 0.15s ease;
    text-rendering: auto;
  }
  .ant-select-clear:hover {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-select:hover .ant-select-clear {
    opacity: 1;
  }
  .ant-select-dropdown {
    margin: 0;
    color: rgba(255, 255, 255, 0.85);
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1050;
    box-sizing: border-box;
    padding: 4px 0;
    overflow: hidden;
    font-size: 14px;
    font-variant: initial;
    background-color: #212121;
    border-radius: 4px;
    outline: 0;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
  }
  .ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft,
  .ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft {
    animation-name: antSlideUpIn;
  }
  .ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft,
  .ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft {
    animation-name: antSlideDownIn;
  }
  .ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
    animation-name: antSlideUpOut;
  }
  .ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
    animation-name: antSlideDownOut;
  }
  .ant-select-dropdown-hidden {
    display: none;
  }
  .ant-select-dropdown-empty {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-select-item,
  .ant-select-item-empty {
    position: relative;
    display: block;
    min-height: 32px;
    padding: 5px 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-select-item {
    color: rgba(255, 255, 255, 0.85);
    cursor: pointer;
    transition: background 0.3s ease;
  }
  .ant-select-item-group {
    color: rgba(255, 255, 255, 0.45);
    font-size: 12px;
    cursor: default;
  }
  .ant-select-item-option {
    display: flex;
  }
  .ant-select-item-option-content {
    flex: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ant-select-item-option-state {
    flex: none;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: rgba(255, 255, 255, 0.85);
    font-weight: 600;
    background-color: #1e2b31;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    color: #2caad9;
  }
  .ant-select-item-option-disabled {
    color: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
  }
  .ant-select-item-option-grouped {
    padding-left: 24px;
  }
  .ant-select-lg {
    font-size: 16px;
  }
  .ant-select-borderless .ant-select-selector {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }
  .ant-select-dropdown-rtl,
  .ant-select-rtl {
    direction: rtl;
  }
  .ant-select-rtl .ant-select-arrow,
  .ant-select-rtl .ant-select-clear {
    right: initial;
    left: 11px;
  }
  .ant-select-dropdown-rtl .ant-select-item-option-grouped {
    padding-right: 24px;
    padding-left: 12px;
  }
  .ant-select-rtl.ant-select-multiple.ant-select-allow-clear .ant-select-selector,
  .ant-select-rtl.ant-select-multiple.ant-select-show-arrow .ant-select-selector {
    padding-right: 4px;
    padding-left: 24px;
  }
  .ant-select-rtl.ant-select-multiple .ant-select-selection-item {
    margin-right: 0;
    margin-left: 4px;
    padding: 0 8px 0 4px;
    text-align: right;
  }
  .ant-select-rtl.ant-select-multiple .ant-select-selection-item-content {
    margin-right: 0;
    margin-left: 4px;
    text-align: right;
  }
  .ant-select-rtl.ant-select-multiple .ant-select-selection-search {
    margin-right: 0.5px;
    margin-left: 4px;
  }
  .ant-select-rtl.ant-select-multiple .ant-select-selection-search-mirror {
    right: 0;
    left: auto;
  }
  .ant-select-rtl.ant-select-multiple .ant-select-selection-placeholder {
    right: 11px;
    left: auto;
  }
  .ant-select-rtl.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
    right: 7px;
  }
  .ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    right: 0;
    left: 9px;
    text-align: right;
  }
  .ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    right: 11px;
    left: 25px;
  }
  .ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 0;
    padding-left: 18px;
  }
  .ant-select-rtl.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 11px;
  }
  .ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow
    .ant-select-selection-search {
    right: 0;
  }
  .ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow
    .ant-select-selection-item,
  .ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow
    .ant-select-selection-placeholder {
    padding-right: 0;
    padding-left: 21px;
  }
  .ant-skeleton {
    display: table;
    width: 100%;
  }
  .ant-skeleton-header {
    display: table-cell;
    padding-right: 16px;
    vertical-align: top;
  }
  .ant-skeleton-header .ant-skeleton-avatar {
    display: inline-block;
    vertical-align: top;
    background: #424242;
    width: 32px;
    height: 32px;
    line-height: 32px;
  }
  .ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle,
  .ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle,
  .ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
    border-radius: 50%;
  }
  .ant-skeleton-header .ant-skeleton-avatar-lg {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .ant-skeleton-header .ant-skeleton-avatar-sm {
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
  .ant-skeleton-content {
    display: table-cell;
    width: 100%;
    vertical-align: top;
  }
  .ant-skeleton-content .ant-skeleton-title {
    width: 100%;
    height: 16px;
    margin-top: 16px;
    background: #424242;
  }
  .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
    margin-top: 24px;
  }
  .ant-skeleton-content .ant-skeleton-paragraph,
  .ant-table.ant-table-middle .ant-table-thead th.ant-table-column-has-sorters,
  .ant-table.ant-table-small .ant-table-thead th.ant-table-column-has-sorters {
    padding: 0;
  }
  .ant-skeleton-content .ant-skeleton-paragraph > li {
    width: 100%;
    height: 16px;
    list-style: none;
    background: #424242;
  }
  .ant-skeleton-content .ant-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
    width: 61%;
  }
  .ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
    margin-top: 12px;
  }
  .ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
    margin-top: 28px;
  }
  .ant-skeleton-round .ant-skeleton-content .ant-skeleton-paragraph > li,
  .ant-skeleton-round .ant-skeleton-content .ant-skeleton-title {
    border-radius: 100px;
  }
  .ant-skeleton.ant-skeleton-active .ant-skeleton-avatar,
  .ant-skeleton.ant-skeleton-active .ant-skeleton-button,
  .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li,
  .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
  .ant-skeleton.ant-skeleton-active .ant-skeleton-image,
  .ant-skeleton.ant-skeleton-active .ant-skeleton-input {
    background: linear-gradient(90deg, #424242 25%, rgba(255, 255, 255, 0.16) 37%, #424242 63%);
    background-size: 400% 100%;
    animation: ant-skeleton-loading 1.4s ease infinite;
  }
  .ant-skeleton-element {
    display: inline-block;
    width: auto;
  }
  .ant-skeleton-element .ant-skeleton-button {
    display: inline-block;
    vertical-align: top;
    background: #424242;
    border-radius: 4px;
    width: 64px;
    height: 32px;
    line-height: 32px;
  }
  .ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-circle {
    width: 32px;
    border-radius: 50%;
  }
  .ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-round {
    border-radius: 32px;
  }
  .ant-skeleton-element .ant-skeleton-button-lg {
    width: 80px;
    height: 40px;
    line-height: 40px;
  }
  .ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-circle {
    width: 40px;
    border-radius: 50%;
  }
  .ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-round {
    border-radius: 40px;
  }
  .ant-skeleton-element .ant-skeleton-button-sm {
    width: 48px;
    height: 24px;
    line-height: 24px;
  }
  .ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-circle {
    width: 24px;
    border-radius: 50%;
  }
  .ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-round {
    border-radius: 24px;
  }
  .ant-skeleton-element .ant-skeleton-avatar {
    display: inline-block;
    vertical-align: top;
    background: #424242;
    width: 32px;
    height: 32px;
    line-height: 32px;
  }
  .ant-skeleton-element .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle,
  .ant-skeleton-element .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle,
  .ant-skeleton-element .ant-skeleton-avatar.ant-skeleton-avatar-circle,
  .ant-skeleton-element .ant-skeleton-image-svg.ant-skeleton-image-circle,
  .ant-skeleton-element .ant-skeleton-image.ant-skeleton-image-circle {
    border-radius: 50%;
  }
  .ant-skeleton-element .ant-skeleton-avatar-lg {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .ant-skeleton-element .ant-skeleton-avatar-sm {
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
  .ant-skeleton-element .ant-skeleton-input {
    display: inline-block;
    vertical-align: top;
    background: #424242;
    width: 100%;
    height: 32px;
    line-height: 32px;
  }
  .ant-skeleton-element .ant-skeleton-input-lg {
    width: 100%;
    height: 40px;
    line-height: 40px;
  }
  .ant-skeleton-element .ant-skeleton-input-sm {
    width: 100%;
    height: 24px;
    line-height: 24px;
  }
  .ant-skeleton-element .ant-skeleton-image {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    background: #424242;
    width: 96px;
    height: 96px;
    line-height: 96px;
  }
  .ant-skeleton-element .ant-skeleton-image-path {
    fill: #bfbfbf;
  }
  .ant-skeleton-element .ant-skeleton-image-svg {
    width: 48px;
    height: 48px;
    line-height: 48px;
    max-width: 192px;
    max-height: 192px;
  }
  .ant-skeleton-rtl {
    direction: rtl;
  }
  .ant-skeleton-rtl .ant-skeleton-header {
    padding-right: 0;
    padding-left: 16px;
  }
  .ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar,
  .ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li,
  .ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title {
    animation-name: ant-skeleton-loading-rtl;
  }
  .ant-slider {
    box-sizing: border-box;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    height: 12px;
    margin: 10px 6px;
    padding: 4px 0;
    cursor: pointer;
    touch-action: none;
  }
  .ant-slider-vertical {
    width: 12px;
    height: 100%;
    margin: 6px 10px;
    padding: 0 4px;
  }
  .ant-slider-vertical .ant-slider-rail {
    width: 4px;
    height: 100%;
  }
  .ant-slider-vertical .ant-slider-track {
    width: 4px;
  }
  .ant-slider-vertical .ant-slider-handle {
    margin-top: -6px;
    margin-left: -5px;
  }
  .ant-slider-vertical .ant-slider-mark {
    top: 0;
    left: 12px;
    width: 18px;
    height: 100%;
  }
  .ant-slider-vertical .ant-slider-mark-text {
    left: 4px;
    white-space: nowrap;
  }
  .ant-slider-vertical .ant-slider-step {
    width: 4px;
    height: 100%;
  }
  .ant-slider-vertical .ant-slider-dot {
    top: auto;
    left: 2px;
    margin-bottom: -4px;
  }
  .ant-slider-tooltip .ant-tooltip-inner {
    min-width: unset;
  }
  .ant-slider-rtl.ant-slider-vertical .ant-slider-handle {
    margin-right: -5px;
    margin-left: 0;
  }
  .ant-slider-rtl.ant-slider-vertical .ant-slider-mark {
    right: 12px;
    left: auto;
  }
  .ant-slider-rtl.ant-slider-vertical .ant-slider-mark-text {
    right: 4px;
    left: auto;
  }
  .ant-slider-rtl.ant-slider-vertical .ant-slider-dot {
    right: 2px;
    left: auto;
  }
  .ant-slider-with-marks {
    margin-bottom: 28px;
  }
  .ant-slider-rail,
  .ant-slider-track {
    position: absolute;
    height: 4px;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  .ant-slider-rail {
    width: 100%;
    background-color: #262626;
  }
  .ant-slider-track {
    background-color: #244a58;
  }
  .ant-slider-handle {
    position: absolute;
    width: 14px;
    height: 14px;
    margin-top: -5px;
    background-color: #212121;
    border: solid 2px #244a58;
    border-radius: 50%;
    box-shadow: 0;
    cursor: pointer;
    transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  }
  .ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
    border-color: #56bbe1;
    box-shadow: 0 0 0 5px rgba(44, 170, 217, 0.12);
  }
  .ant-slider-handle:focus {
    border-color: #56bbe1;
    outline: 0;
    box-shadow: 0 0 0 5px rgba(44, 170, 217, 0.12);
  }
  .ant-slider-handle.ant-tooltip-open {
    border-color: #2caad9;
  }
  .ant-slider:hover .ant-slider-rail {
    background-color: #6f6c6c;
  }
  .ant-slider:hover .ant-slider-track {
    background-color: #265f74;
  }
  .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: #265f74;
  }
  .ant-slider-mark {
    position: absolute;
    top: 14px;
    left: 0;
    width: 100%;
    font-size: 14px;
  }
  .ant-slider-mark-text {
    position: absolute;
    display: inline-block;
    color: rgba(255, 255, 255, 0.45);
    text-align: center;
    word-break: keep-all;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-slider-mark-text-active {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-slider-step {
    position: absolute;
    width: 100%;
    height: 4px;
    background: 0 0;
  }
  .ant-slider-dot {
    position: absolute;
    top: -2px;
    width: 8px;
    height: 8px;
    margin-left: -4px;
    background-color: #212121;
    border: 2px solid #424242;
    border-radius: 50%;
    cursor: pointer;
  }
  .ant-slider-dot:first-child {
    margin-left: -4px;
  }
  .ant-slider-dot:last-child {
    margin-left: -4px;
  }
  .ant-slider-dot-active {
    border-color: #265f74;
  }
  .ant-slider-disabled .ant-slider-track {
    background-color: rgba(255, 255, 255, 0.3) !important;
  }
  .ant-slider-disabled,
  .ant-slider-disabled .ant-slider-handle {
    cursor: not-allowed;
  }
  .ant-slider-disabled .ant-slider-dot,
  .ant-slider-disabled .ant-slider-handle {
    background-color: #212121;
    border-color: rgba(255, 255, 255, 0.3) !important;
    box-shadow: none;
  }
  .ant-slider-disabled .ant-slider-dot,
  .ant-slider-disabled .ant-slider-mark-text {
    cursor: not-allowed !important;
  }
  .ant-slider-rtl {
    direction: rtl;
  }
  .ant-slider-rtl .ant-slider-mark {
    right: 0;
    left: auto;
  }
  .ant-slider-rtl .ant-slider-dot {
    margin-right: -4px;
    margin-left: 0;
  }
  .ant-slider-rtl .ant-slider-dot:first-child {
    margin-right: -4px;
    margin-left: 0;
  }
  .ant-slider-rtl .ant-slider-dot:last-child {
    margin-right: -4px;
    margin-left: 0;
  }
  .ant-space {
    display: inline-flex;
  }
  .ant-space-vertical {
    flex-direction: column;
  }
  .ant-space-align-center {
    align-items: center;
  }
  .ant-space-align-start {
    align-items: flex-start;
  }
  .ant-space-align-end {
    align-items: flex-end;
  }
  .ant-space-align-baseline {
    align-items: baseline;
  }
  .ant-space-item:empty {
    display: none;
  }
  .ant-space-rtl {
    direction: rtl;
  }
  .ant-spin {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: absolute;
    display: none;
    color: #2caad9;
    text-align: center;
    vertical-align: middle;
    opacity: 0;
    transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ant-spin-spinning {
    position: static;
    display: inline-block;
    opacity: 1;
  }
  .ant-spin-nested-loading {
    position: relative;
  }
  .ant-spin-nested-loading > div > .ant-spin {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    display: block;
    width: 100%;
    height: 100%;
    max-height: 400px;
  }
  .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -10px;
  }
  .ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
    position: absolute;
    top: 50%;
    width: 100%;
    padding-top: 5px;
    text-shadow: 0 1px 2px #212121;
  }
  .ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
    margin-top: -20px;
  }
  .ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
    margin: -7px;
  }
  .ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
    padding-top: 2px;
  }
  .ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
    margin-top: -17px;
  }
  .ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
    margin: -16px;
  }
  .ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
    padding-top: 11px;
  }
  .ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
    margin-top: -26px;
  }
  .ant-spin-container {
    position: relative;
    transition: opacity 0.3s;
  }
  .ant-spin-container::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: none \9;
    width: 100%;
    height: 100%;
    background: #212121;
    opacity: 0;
    transition: all 0.3s;
    content: "";
    pointer-events: none;
  }
  .ant-spin-blur {
    clear: both;
    overflow: hidden;
    opacity: 0.5;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
  }
  .ant-spin-blur::after {
    opacity: 0.4;
    pointer-events: auto;
  }
  .ant-spin-tip {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-spin-dot {
    position: relative;
    display: inline-block;
    font-size: 20px;
    width: 1em;
    height: 1em;
  }
  .ant-spin-dot-item {
    position: absolute;
    display: block;
    width: 9px;
    height: 9px;
    background-color: #2caad9;
    border-radius: 100%;
    transform: scale(0.75);
    transform-origin: 50% 50%;
    opacity: 0.3;
    animation: antSpinMove 1s infinite linear alternate;
  }
  .ant-spin-dot-item:nth-child(1) {
    top: 0;
    left: 0;
  }
  .ant-spin-dot-item:nth-child(2) {
    top: 0;
    right: 0;
    animation-delay: 0.4s;
  }
  .ant-spin-dot-item:nth-child(3) {
    right: 0;
    bottom: 0;
    animation-delay: 0.8s;
  }
  .ant-spin-dot-item:nth-child(4) {
    bottom: 0;
    left: 0;
    animation-delay: 1.2s;
  }
  .ant-spin-dot-spin {
    transform: rotate(45deg);
    animation: antRotate 1.2s infinite linear;
  }
  .ant-spin-sm .ant-spin-dot {
    font-size: 14px;
  }
  .ant-spin-sm .ant-spin-dot i {
    width: 6px;
    height: 6px;
  }
  .ant-spin-lg .ant-spin-dot {
    font-size: 32px;
  }
  .ant-spin-lg .ant-spin-dot i {
    width: 14px;
    height: 14px;
  }
  .ant-spin.ant-spin-show-text .ant-spin-text {
    display: block;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .ant-spin-blur {
      background: #212121;
      opacity: 0.5;
    }
  }
  .ant-spin-rtl {
    direction: rtl;
  }
  .ant-spin-rtl .ant-spin-dot-spin {
    transform: rotate(-45deg);
    animation-name: antRotateRtl;
  }
  .ant-statistic {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
  }
  .ant-statistic-title {
    margin-bottom: 4px;
    color: rgba(255, 255, 255, 0.45);
    font-size: 14px;
  }
  .ant-statistic-content {
    color: rgba(255, 255, 255, 0.85);
    font-size: 24px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
  .ant-statistic-content-value {
    display: inline-block;
    direction: ltr;
  }
  .ant-statistic-content-value-decimal {
    font-size: 16px;
  }
  .ant-statistic-content-prefix,
  .ant-statistic-content-suffix {
    display: inline-block;
  }
  .ant-statistic-content-prefix {
    margin-right: 4px;
  }
  .ant-statistic-content-suffix {
    margin-left: 4px;
    font-size: 16px;
  }
  .ant-statistic-rtl {
    direction: rtl;
  }
  .ant-statistic-rtl .ant-statistic-content-prefix {
    margin-right: 0;
    margin-left: 4px;
  }
  .ant-statistic-rtl .ant-statistic-content-suffix {
    margin-right: 4px;
    margin-left: 0;
  }
  .ant-steps {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    display: flex;
    width: 100%;
    font-size: 0;
    text-align: initial;
  }
  .ant-steps-item {
    position: relative;
    display: inline-block;
    flex: 1;
    overflow: hidden;
    vertical-align: top;
  }
  .ant-steps-item-container {
    outline: 0;
  }
  .ant-steps-item:last-child {
    flex: none;
  }
  .ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
  .ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail {
    display: none;
  }
  .ant-steps-item-content,
  .ant-steps-item-icon {
    display: inline-block;
    vertical-align: top;
  }
  .ant-steps-item-icon {
    width: 32px;
    height: 32px;
    margin: 0 8px 0 0;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    line-height: 32px;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 32px;
    transition: background-color 0.3s, border-color 0.3s;
  }
  .ant-steps-item-icon .ant-steps-icon {
    position: relative;
    top: -1px;
    color: #2caad9;
    line-height: 1;
  }
  .ant-steps-item-tail {
    position: absolute;
    top: 12px;
    left: 0;
    width: 100%;
    padding: 0 10px;
  }
  .ant-steps-item-tail::after {
    display: inline-block;
    width: 100%;
    height: 1px;
    background: #424242;
    border-radius: 1px;
    transition: background 0.3s;
    content: "";
  }
  .ant-steps-item-title {
    position: relative;
    display: inline-block;
    padding-right: 16px;
    color: rgba(255, 255, 255, 0.85);
    font-size: 16px;
    line-height: 32px;
  }
  .ant-steps-item-title::after {
    position: absolute;
    top: 16px;
    left: 100%;
    display: block;
    width: 9999px;
    height: 1px;
    background: #424242;
    content: "";
  }
  .ant-steps-item-subtitle {
    display: inline;
    margin-left: 8px;
    font-weight: 400;
  }
  .ant-steps-item-description,
  .ant-steps-item-subtitle {
    color: rgba(255, 255, 255, 0.45);
    font-size: 14px;
  }
  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.3);
  }
  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon,
  .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: rgba(255, 255, 255, 0.3);
  }
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description,
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after,
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #424242;
  }
  .ant-steps-item-process .ant-steps-item-icon {
    background-color: transparent;
    border-color: #2caad9;
    background: #2caad9;
  }
  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: #2caad9;
  }
  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: #2caad9;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description,
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon {
    color: #fff;
  }
  .ant-steps-item-process .ant-steps-item-title {
    font-weight: 500;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: transparent;
    border-color: #2caad9;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #2caad9;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: #2caad9;
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #2caad9;
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-steps-item-error .ant-steps-item-icon {
    background-color: transparent;
    border-color: #ab2229;
  }
  .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: #ab2229;
  }
  .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon,
  .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description,
  .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #ab2229;
  }
  .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
  .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #424242;
  }
  .ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
    background: #ab2229;
  }
  .ant-steps-item-disabled {
    cursor: not-allowed;
  }
  .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"] {
    cursor: pointer;
  }
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role="button"]
    .ant-steps-item-description,
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role="button"]
    .ant-steps-item-icon
    .ant-steps-icon,
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role="button"]
    .ant-steps-item-subtitle,
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role="button"]
    .ant-steps-item-title {
    transition: color 0.3s;
  }
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-icon
    .ant-steps-icon,
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-description,
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-subtitle,
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-title {
    color: #2caad9;
  }
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-icon {
    border-color: #2caad9;
  }
  .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-left: 16px;
    white-space: nowrap;
  }
  .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
    padding-left: 0;
  }
  .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
    padding-right: 0;
  }
  .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
    display: none;
  }
  .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
    max-width: 140px;
    white-space: normal;
  }
  .ant-steps-item-custom .ant-steps-item-icon {
    height: auto;
    background: 0 0;
    border: 0;
  }
  .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
    top: 0;
    left: 0.5px;
    width: 32px;
    height: 32px;
    font-size: 24px;
    line-height: 32px;
  }
  .ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: #2caad9;
  }
  .ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
    width: auto;
    background: 0 0;
  }
  .ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-left: 12px;
  }
  .ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
    padding-left: 0;
  }
  .ant-steps-small .ant-steps-item-icon {
    width: 24px;
    height: 24px;
    margin: 0 8px 0 0;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    border-radius: 24px;
  }
  .ant-steps-small .ant-steps-item-title {
    padding-right: 12px;
    font-size: 14px;
    line-height: 24px;
  }
  .ant-steps-small .ant-steps-item-title::after {
    top: 12px;
  }
  .ant-steps-small .ant-steps-item-description {
    color: rgba(255, 255, 255, 0.45);
    font-size: 14px;
  }
  .ant-steps-small .ant-steps-item-tail {
    top: 8px;
  }
  .ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
    width: inherit;
    height: inherit;
    line-height: inherit;
    background: 0 0;
    border: 0;
    border-radius: 0;
  }
  .ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
    font-size: 24px;
    line-height: 24px;
    transform: none;
  }
  .ant-steps-vertical {
    display: flex;
    flex-direction: column;
  }
  .ant-steps-vertical .ant-steps-item {
    display: block;
    flex: 1 0 auto;
    padding-left: 0;
    overflow: visible;
  }
  .ant-steps-vertical .ant-steps-item-icon {
    float: left;
    margin-right: 16px;
  }
  .ant-steps-vertical .ant-steps-item-content {
    display: block;
    min-height: 48px;
    overflow: hidden;
  }
  .ant-steps-vertical .ant-steps-item-title {
    line-height: 32px;
  }
  .ant-steps-vertical .ant-steps-item-description {
    padding-bottom: 12px;
  }
  .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    position: absolute;
    top: 0;
    left: 16px;
    width: 1px;
    height: 100%;
    padding: 38px 0 6px;
  }
  .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
    width: 1px;
    height: 100%;
  }
  .ant-steps-vertical > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
    display: block;
  }
  .ant-steps-label-vertical .ant-steps-item-title::after,
  .ant-steps-vertical
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    display: none;
  }
  .ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
    position: absolute;
    top: 0;
    left: 12px;
    padding: 30px 0 6px;
  }
  .ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
    line-height: 24px;
  }
  .ant-steps-rtl.ant-steps-vertical .ant-steps-item-icon {
    float: right;
    margin-right: 0;
    margin-left: 16px;
  }
  .ant-steps-rtl.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    right: 16px;
    left: auto;
  }
  .ant-steps-rtl.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
    right: 12px;
    left: auto;
  }
  @media (max-width: 480px) {
    .ant-steps-horizontal.ant-steps-label-horizontal {
      display: flex;
      flex-direction: column;
    }
    .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
      display: block;
      flex: 1 0 auto;
      padding-left: 0;
      overflow: visible;
    }
    .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
      float: left;
      margin-right: 16px;
    }
    .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
      display: block;
      min-height: 48px;
      overflow: hidden;
    }
    .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-title {
      line-height: 32px;
    }
    .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-description {
      padding-bottom: 12px;
    }
    .ant-steps-horizontal.ant-steps-label-horizontal
      > .ant-steps-item
      > .ant-steps-item-container
      > .ant-steps-item-tail {
      position: absolute;
      top: 0;
      left: 16px;
      width: 1px;
      height: 100%;
      padding: 38px 0 6px;
    }
    .ant-steps-horizontal.ant-steps-label-horizontal
      > .ant-steps-item
      > .ant-steps-item-container
      > .ant-steps-item-tail::after {
      width: 1px;
      height: 100%;
    }
    .ant-steps-horizontal.ant-steps-label-horizontal
      > .ant-steps-item:not(:last-child)
      > .ant-steps-item-container
      > .ant-steps-item-tail {
      display: block;
    }
    .ant-steps-horizontal.ant-steps-label-horizontal
      > .ant-steps-item
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after {
      display: none;
    }
    .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
      position: absolute;
      top: 0;
      left: 12px;
      padding: 30px 0 6px;
    }
    .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
      line-height: 24px;
    }
    .ant-steps-rtl.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
      float: right;
      margin-right: 0;
      margin-left: 16px;
    }
    .ant-steps-rtl.ant-steps-horizontal.ant-steps-label-horizontal
      > .ant-steps-item
      > .ant-steps-item-container
      > .ant-steps-item-tail {
      right: 16px;
      left: auto;
    }
    .ant-steps-rtl.ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small
      .ant-steps-item-container
      .ant-steps-item-tail {
      right: 12px;
      left: auto;
    }
  }
  .ant-steps-label-vertical .ant-steps-item {
    overflow: visible;
  }
  .ant-steps-label-vertical .ant-steps-item-tail {
    margin-left: 58px;
    padding: 3.5px 24px;
  }
  .ant-steps-label-vertical .ant-steps-item-content {
    display: block;
    width: 116px;
    margin-top: 8px;
    text-align: center;
  }
  .ant-steps-label-vertical .ant-steps-item-icon {
    display: inline-block;
    margin-left: 42px;
  }
  .ant-steps-label-vertical .ant-steps-item-title {
    padding-right: 0;
    padding-left: 0;
  }
  .ant-steps-label-vertical .ant-steps-item-subtitle {
    display: block;
    margin-bottom: 4px;
    margin-left: 0;
    line-height: 1.5715;
  }
  .ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
    margin-left: 46px;
  }
  .ant-steps-dot .ant-steps-item-title,
  .ant-steps-dot.ant-steps-small .ant-steps-item-title {
    line-height: 1.5715;
  }
  .ant-steps-dot .ant-steps-item-tail,
  .ant-steps-dot.ant-steps-small .ant-steps-item-tail {
    top: 2px;
    width: 100%;
    margin: 0 0 0 70px;
    padding: 0;
  }
  .ant-steps-dot .ant-steps-item-tail::after,
  .ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
    width: calc(100% - 20px);
    height: 3px;
    margin-left: 12px;
  }
  .ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
  .ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
    left: 2px;
  }
  .ant-steps-dot .ant-steps-item-icon,
  .ant-steps-dot.ant-steps-small .ant-steps-item-icon {
    width: 8px;
    height: 8px;
    margin-left: 67px;
    padding-right: 0;
    line-height: 8px;
    background: 0 0;
    border: 0;
  }
  .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
  .ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    transition: all 0.3s;
  }
  .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
  .ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
    position: absolute;
    top: -12px;
    left: -26px;
    width: 60px;
    height: 32px;
    background: rgba(0, 0, 0, 0.001);
    content: "";
  }
  .ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
  .ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
    position: relative;
    top: -1px;
    width: 10px;
    height: 10px;
    line-height: 10px;
  }
  .ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
    margin-top: 8px;
    margin-left: 0;
    background: 0 0;
  }
  .ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    top: 2px;
    left: -9px;
    margin: 0;
    padding: 22px 0 4px;
  }
  .ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
    left: 0;
  }
  .ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
    left: -2px;
  }
  .ant-steps-navigation {
    padding-top: 12px;
  }
  .ant-steps-navigation.ant-steps-small .ant-steps-item-container {
    margin-left: -12px;
  }
  .ant-steps-navigation .ant-steps-item {
    overflow: visible;
    text-align: center;
  }
  .ant-steps-navigation .ant-steps-item-container {
    display: inline-block;
    height: 100%;
    margin-left: -16px;
    padding-bottom: 12px;
    text-align: left;
    transition: opacity 0.3s;
  }
  .ant-steps-navigation .ant-steps-item-container .ant-steps-item-content {
    max-width: auto;
  }
  .ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
    max-width: 100%;
    padding-right: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ant-steps-navigation .ant-steps-item-container .ant-steps-item-title::after {
    display: none;
  }
  .ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role="button"] {
    cursor: pointer;
  }
  .ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role="button"]:hover {
    opacity: 0.85;
  }
  .ant-steps-navigation .ant-steps-item:last-child {
    flex: 1;
  }
  .ant-steps-navigation .ant-steps-item:last-child::after {
    display: none;
  }
  .ant-steps-navigation .ant-steps-item::after {
    position: absolute;
    top: 50%;
    left: 100%;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-top: -14px;
    margin-left: -2px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: none;
    border-left: none;
    transform: rotate(45deg);
    content: "";
  }
  .ant-steps-navigation .ant-steps-item::before {
    position: absolute;
    bottom: 0;
    left: 50%;
    display: inline-block;
    width: 0;
    height: 2px;
    background-color: #2caad9;
    transition: width 0.3s, left 0.3s;
    transition-timing-function: ease-out;
    content: "";
  }
  .ant-steps-navigation .ant-steps-item.ant-steps-item-active::before {
    left: 0;
    width: 100%;
  }
  @media (max-width: 480px) {
    .ant-steps-navigation > .ant-steps-item {
      margin-right: 0 !important;
    }
    .ant-steps-navigation > .ant-steps-item::before {
      display: none;
    }
    .ant-steps-navigation > .ant-steps-item.ant-steps-item-active::before {
      top: 0;
      right: 0;
      left: unset;
      display: block;
      width: 3px;
      height: calc(100% - 24px);
    }
    .ant-steps-navigation > .ant-steps-item::after {
      position: relative;
      top: -2px;
      left: 50%;
      display: block;
      width: 8px;
      height: 8px;
      margin-bottom: 8px;
      text-align: center;
      transform: rotate(135deg);
    }
    .ant-steps-navigation > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
      visibility: hidden;
    }
  }
  .ant-steps-rtl {
    direction: rtl;
  }
  .ant-steps.ant-steps-rtl .ant-steps-item-icon {
    margin-right: 0;
    margin-left: 8px;
  }
  .ant-steps-rtl .ant-steps-item-tail {
    right: 0;
    left: auto;
  }
  .ant-steps-rtl .ant-steps-item-title {
    padding-right: 0;
    padding-left: 16px;
  }
  .ant-steps-rtl .ant-steps-item-title::after {
    right: 100%;
    left: auto;
  }
  .ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-right: 16px;
    padding-left: 0;
  }
  .ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
    padding-right: 0;
  }
  .ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
    padding-left: 0;
  }
  .ant-steps-rtl .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
    right: 0.5px;
    left: auto;
  }
  .ant-steps-rtl.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
    margin-right: -12px;
    margin-left: 0;
  }
  .ant-steps-rtl.ant-steps-navigation .ant-steps-item-container {
    margin-right: -16px;
    margin-left: 0;
    text-align: right;
  }
  .ant-steps-rtl.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
    padding-left: 0;
  }
  .ant-steps-rtl.ant-steps-navigation .ant-steps-item::after {
    right: 100%;
    left: auto;
    margin-right: -2px;
    margin-left: 0;
    transform: rotate(225deg);
  }
  .ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-right: 12px;
    padding-left: 0;
  }
  .ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
    padding-right: 0;
  }
  .ant-steps-rtl.ant-steps-small .ant-steps-item-title {
    padding-right: 0;
    padding-left: 12px;
  }
  .ant-steps-rtl.ant-steps-label-vertical .ant-steps-item-title {
    padding-left: 0;
  }
  .ant-steps-rtl.ant-steps-dot .ant-steps-item-tail,
  .ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
    margin: 0 70px 0 0;
  }
  .ant-steps-rtl.ant-steps-dot .ant-steps-item-tail::after,
  .ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
    margin-right: 12px;
    margin-left: 0;
  }
  .ant-steps-rtl.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
  .ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
    right: 2px;
    left: auto;
  }
  .ant-steps-rtl.ant-steps-dot .ant-steps-item-icon,
  .ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
    margin-right: 67px;
    margin-left: 0;
  }
  .ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
  .ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
    float: right;
  }
  .ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
  .ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
    right: -26px;
    left: auto;
  }
  .ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
    margin-right: 0;
    margin-left: 16px;
  }
  .ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    right: -9px;
    left: auto;
  }
  .ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
    right: 0;
    left: auto;
  }
  .ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
    right: -2px;
    left: auto;
  }
  .ant-steps:not(.ant-steps-dot):not(.ant-steps-navigation):not(.ant-steps-vertical) .ant-steps-item {
    padding-top: 4px;
  }
  .ant-steps:not(.ant-steps-dot):not(.ant-steps-navigation) .ant-steps-item .ant-steps-item-icon {
    position: relative;
  }
  .ant-steps:not(.ant-steps-dot):not(.ant-steps-navigation) .ant-steps-item .ant-steps-item-icon .ant-progress {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
  }
  .ant-switch {
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    min-width: 44px;
    height: 22px;
    line-height: 22px;
    vertical-align: middle;
    background-color: rgba(255, 255, 255, 0.3);
    border: 0;
    border-radius: 100px;
    cursor: pointer;
    transition: all 0.2s;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-switch:focus {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
  }
  .ant-switch-checked:focus {
    box-shadow: 0 0 0 2px rgba(44, 170, 217, 0.2);
  }
  .ant-switch:focus:hover {
    box-shadow: none;
  }
  .ant-switch-checked {
    background-color: #2caad9;
  }
  .ant-switch-disabled,
  .ant-switch-loading {
    cursor: not-allowed;
    opacity: 0.4;
  }
  .ant-switch-disabled *,
  .ant-switch-loading * {
    box-shadow: none;
    cursor: not-allowed;
  }
  .ant-switch-inner {
    display: block;
    margin: 0 7px 0 25px;
    color: #fff;
    font-size: 12px;
    transition: margin 0.2s;
  }
  .ant-switch-checked .ant-switch-inner {
    margin: 0 25px 0 7px;
  }
  .ant-switch-handle,
  .ant-switch-handle::before {
    position: absolute;
    transition: all 0.2s ease-in-out;
  }
  .ant-switch-handle {
    top: 2px;
    left: 2px;
    width: 18px;
    height: 18px;
  }
  .ant-switch-handle::before {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    border-radius: 9px;
    box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
    content: "";
  }
  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 18px - 2px);
  }
  .ant-switch:not(.ant-switch-disabled):active .ant-switch-handle::before {
    right: -30%;
    left: 0;
  }
  .ant-switch-rtl:not(.ant-switch-rtl-disabled):active .ant-switch-handle::before,
  .ant-switch:not(.ant-switch-disabled):active.ant-switch-checked .ant-switch-handle::before {
    right: 0;
    left: -30%;
  }
  .ant-switch-loading-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    color: rgba(0, 0, 0, 0.65);
    transform: translate(-50%, -50%);
  }
  .ant-switch-checked .ant-switch-loading-icon {
    color: #2caad9;
  }
  .ant-switch-small {
    min-width: 28px;
    height: 16px;
    line-height: 16px;
  }
  .ant-switch-small .ant-switch-inner {
    margin: 0 5px 0 18px;
    font-size: 12px;
  }
  .ant-switch-small .ant-switch-handle {
    width: 12px;
    height: 12px;
  }
  .ant-switch-small .ant-switch-loading-icon {
    transform: translate(-50%, -50%) scale(0.66667);
  }
  .ant-switch-small.ant-switch-checked .ant-switch-inner {
    margin: 0 18px 0 5px;
  }
  .ant-switch-small.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 12px - 2px);
  }
  .ant-switch-rtl {
    direction: rtl;
  }
  .ant-switch-rtl .ant-switch-inner {
    margin: 0 25px 0 7px;
  }
  .ant-switch-rtl .ant-switch-handle {
    right: 2px;
    left: auto;
  }
  .ant-switch-rtl:not(.ant-switch-rtl-disabled):active.ant-switch-checked .ant-switch-handle::before {
    right: -30%;
    left: 0;
  }
  .ant-switch-rtl.ant-switch-checked .ant-switch-inner {
    margin: 0 7px 0 25px;
  }
  .ant-switch-rtl.ant-switch-checked .ant-switch-handle {
    right: calc(100% - 18px - 2px);
  }
  .ant-switch-rtl.ant-switch-small.ant-switch-checked .ant-switch-handle {
    right: calc(100% - 12px - 2px);
  }
  .ant-table.ant-table-middle,
  .ant-table.ant-table-small {
    font-size: 14px;
  }
  .ant-table.ant-table-middle .ant-table-footer,
  .ant-table.ant-table-middle .ant-table-tbody > tr > td,
  .ant-table.ant-table-middle .ant-table-thead > tr > th,
  .ant-table.ant-table-middle .ant-table-title,
  .ant-table.ant-table-middle tfoot > tr > td,
  .ant-table.ant-table-middle tfoot > tr > th {
    padding: 12px 8px;
  }
  .ant-table.ant-table-middle .ant-table-expanded-row-fixed,
  .ant-table.ant-table-middle .ant-table-thead .ant-table-filter-column {
    margin: -12px -8px;
  }
  .ant-table.ant-table-middle .ant-table-thead .ant-table-filter-column-title {
    padding: 12px 2.3em 12px 8px;
  }
  .ant-table.ant-table-middle .ant-table-thead .ant-table-column-sorters {
    padding: 12px 8px;
  }
  .ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
    margin: -12px -8px -12px 25px;
  }
  .ant-table.ant-table-small .ant-table-footer,
  .ant-table.ant-table-small .ant-table-tbody > tr > td,
  .ant-table.ant-table-small .ant-table-thead > tr > th,
  .ant-table.ant-table-small .ant-table-title,
  .ant-table.ant-table-small tfoot > tr > td,
  .ant-table.ant-table-small tfoot > tr > th {
    padding: 8px;
  }
  .ant-table.ant-table-small .ant-table-expanded-row-fixed,
  .ant-table.ant-table-small .ant-table-thead .ant-table-filter-column {
    margin: -8px;
  }
  .ant-table.ant-table-small .ant-table-thead .ant-table-filter-column-title {
    padding: 8px 2.3em 8px 8px;
  }
  .ant-table.ant-table-small .ant-table-thead .ant-table-column-sorters {
    padding: 8px;
  }
  .ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
    margin: -8px -8px -8px 25px;
  }
  .ant-table-small .ant-table-thead > tr > th {
    background-color: #1d1d1d;
  }
  .ant-table-small .ant-table-selection-column {
    width: 46px;
    min-width: 46px;
  }
  .ant-table.ant-table-bordered > .ant-table-title {
    border: 1px solid #424242;
    border-bottom: 0;
  }
  .ant-table.ant-table-bordered > .ant-table-container {
    border: 1px solid #424242;
    border-right: 0;
    border-bottom: 0;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed {
    margin: -16px -17px;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed::after,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed::after,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed::after {
    position: absolute;
    top: 0;
    right: 1px;
    bottom: 0;
    border-right: 1px solid #424242;
    content: "";
  }
  .ant-table.ant-table-bordered.ant-table-middle
    > .ant-table-container
    > .ant-table-body
    > table
    > tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed,
  .ant-table.ant-table-bordered.ant-table-middle
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed {
    margin: -12px -9px;
  }
  .ant-table.ant-table-bordered.ant-table-small
    > .ant-table-container
    > .ant-table-body
    > table
    > tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed,
  .ant-table.ant-table-bordered.ant-table-small
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed {
    margin: -8px -9px;
  }
  .ant-table.ant-table-bordered > .ant-table-footer {
    border: 1px solid #424242;
    border-top: 0;
  }
  .ant-table-cell .ant-table-container:first-child {
    border-top: 0;
  }
  .ant-table-cell-scrollbar {
    box-shadow: 0 1px 0 1px #1d1d1d;
  }
  .ant-table-wrapper {
    clear: both;
    max-width: 100%;
  }
  .ant-table-wrapper::before {
    display: table;
    content: "";
  }
  .ant-table-wrapper::after {
    display: table;
    clear: both;
    content: "";
  }
  .ant-table {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    z-index: 0;
    font-size: 14px;
    background: #212121;
    border-radius: 4px;
  }
  .ant-table table {
    width: 100%;
    text-align: left;
    border-radius: 4px 4px 0 0;
    border-collapse: separate;
    border-spacing: 0;
  }
  .ant-table tfoot > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    position: relative;
    padding: 16px;
    overflow-wrap: break-word;
  }
  .ant-table-cell-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: keep-all;
  }
  .ant-table-cell-ellipsis.ant-table-cell-fix-left-last,
  .ant-table-cell-ellipsis.ant-table-cell-fix-right-first {
    overflow: visible;
  }
  .ant-table-cell-ellipsis.ant-table-cell-fix-left-last .ant-table-cell-content,
  .ant-table-cell-ellipsis.ant-table-cell-fix-right-first .ant-table-cell-content {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ant-table-title {
    padding: 16px;
  }
  .ant-table-footer {
    padding: 16px;
    color: rgba(255, 255, 255, 0.85);
    background: rgba(255, 255, 255, 0.04);
  }
  .ant-table-thead > tr > th {
    color: rgba(255, 255, 255, 0.85);
    font-weight: 500;
    text-align: left;
    background: #1d1d1d;
    border-bottom: 1px solid #424242;
    transition: background 0.3s ease;
  }
  .ant-table-thead > tr > th[colspan]:not([colspan="1"]),
  .ant-table-wrapper-rtl .ant-table-thead > tr > th[colspan]:not([colspan="1"]) {
    text-align: center;
  }
  .ant-table-thead > tr:not(:last-child) > th[colspan] {
    border-bottom: 0;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #424242;
    transition: background 0.3s;
  }
  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
    margin: -16px -16px -16px 33px;
  }
  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td {
    border-bottom: 0;
  }
  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child,
  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child {
    border-radius: 0;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #262626;
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #1e2b31;
    border-color: rgba(0, 0, 0, 0.03);
  }
  .ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background: #1a252b;
  }
  .ant-table tfoot > tr > td,
  .ant-table tfoot > tr > th {
    border-bottom: 1px solid #424242;
  }
  .ant-table-pagination.ant-pagination {
    margin: 16px 0;
  }
  .ant-table-pagination {
    display: flex;
  }
  .ant-table-pagination-left {
    justify-content: flex-start;
  }
  .ant-table-pagination-center {
    justify-content: center;
  }
  .ant-table-pagination-right {
    justify-content: flex-end;
  }
  .ant-table-thead th.ant-table-column-has-sorters {
    padding: 0;
    cursor: pointer;
    transition: all 0.3s;
  }
  .ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container {
    background: #474747;
  }
  .ant-table-thead th.ant-table-column-sort {
    background: #262626;
  }
  td.ant-table-column-sort {
    background: rgba(255, 255, 255, 0.01);
  }
  .ant-table-column-sorters-with-tooltip {
    display: inline-block;
    width: 100%;
  }
  .ant-table-column-sorters {
    display: inline-flex;
    align-items: center;
    padding: 16px;
  }
  .ant-table-column-sorter {
    margin-top: 0.15em;
    margin-bottom: -0.15em;
    margin-left: 8px;
    color: #bfbfbf;
  }
  .ant-table-column-sorter-full {
    margin-top: -0.2em;
    margin-bottom: 0;
  }
  .ant-table-column-sorter-inner {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
  }
  .ant-table-column-sorter-down,
  .ant-table-column-sorter-up {
    display: inline-block;
    font-size: 11px;
  }
  .ant-table-column-sorter-down.active,
  .ant-table-column-sorter-up.active {
    color: #2caad9;
  }
  .ant-table-column-sorter-up + .ant-table-column-sorter-down {
    margin-top: -0.3em;
  }
  .ant-table-filter-column {
    display: flex;
    align-items: center;
    margin: -16px;
  }
  .ant-table-filter-column-title {
    flex: auto;
    padding: 16px 2.3em 16px 16px;
  }
  .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
    padding: 0 2.3em 0 0;
  }
  .ant-table-filter-trigger-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex: none;
    align-items: stretch;
    align-self: stretch;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  .ant-table-filter-trigger-container-open,
  .ant-table-filter-trigger-container:hover,
  .ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container:hover {
    background: #434343;
  }
  .ant-table-filter-trigger {
    display: block;
    width: 2.3em;
    color: #bfbfbf;
    font-size: 12px;
    transition: color 0.3s;
  }
  .ant-table-filter-trigger .anticon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .ant-table-filter-trigger-container-open .ant-table-filter-trigger,
  .ant-table-filter-trigger:hover {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-table-filter-trigger.active {
    color: #2caad9;
  }
  .ant-table-filter-dropdown {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    min-width: 120px;
    background-color: #212121;
    border-radius: 4px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
  }
  .ant-table-filter-dropdown .ant-dropdown-menu {
    max-height: 264px;
    overflow-x: hidden;
    border: 0;
    box-shadow: none;
  }
  .ant-table-filter-dropdown-submenu > ul {
    max-height: calc(100vh - 130px);
    overflow-x: hidden;
    overflow-y: auto;
  }
  .ant-table-filter-dropdown .ant-checkbox-wrapper + span,
  .ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
    padding-left: 8px;
  }
  .ant-table-filter-dropdown-btns {
    display: flex;
    justify-content: space-between;
    padding: 7px 8px 7px 3px;
    overflow: hidden;
    background-color: #212121;
    border-top: 1px solid #424242;
  }
  .ant-table .ant-table-selection-col {
    width: 60px;
  }
  table tr td.ant-table-selection-column,
  table tr th.ant-table-selection-column {
    padding-right: 8px;
    padding-left: 8px;
    text-align: center;
  }
  .ant-table-selection {
    position: relative;
  }
  .ant-table-selection-extra {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    transition: all 0.3s;
  }
  .ant-table-selection-extra .anticon {
    display: inline-block;
    font-size: 10px;
    color: #bfbfbf;
  }
  .ant-table-selection-extra .anticon:hover {
    color: #a6a6a6;
  }
  .ant-table-expand-icon-col {
    width: 48px;
  }
  .ant-table-row-expand-icon-cell,
  .ant-table-tbody > tr.ant-table-placeholder {
    text-align: center;
  }
  .ant-table-row-indent {
    float: left;
    height: 1px;
  }
  .ant-table-row-expand-icon {
    text-decoration: none;
    cursor: pointer;
    position: relative;
    display: inline-flex;
    float: left;
    box-sizing: border-box;
    width: 17px;
    height: 17px;
    padding: 0;
    color: inherit;
    line-height: 17px;
    background: 0 0;
    border: 1px solid #424242;
    border-radius: 4px;
    outline: 0;
    transition: all 0.3s;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-table-row-expand-icon:focus,
  .ant-table-row-expand-icon:hover {
    color: #287a99;
  }
  .ant-table-row-expand-icon:active {
    color: #4eb3d2;
  }
  .ant-table-row-expand-icon:active,
  .ant-table-row-expand-icon:focus,
  .ant-table-row-expand-icon:hover {
    border-color: currentColor;
  }
  .ant-table-row-expand-icon::after,
  .ant-table-row-expand-icon::before {
    position: absolute;
    background: currentColor;
    transition: transform 0.3s ease-out;
    content: "";
  }
  .ant-table-row-expand-icon::before {
    top: 7px;
    right: 3px;
    left: 3px;
    height: 1px;
  }
  .ant-table-row-expand-icon::after {
    top: 3px;
    bottom: 3px;
    left: 7px;
    width: 1px;
    transform: rotate(90deg);
  }
  .ant-table-row-expand-icon-collapsed::before {
    transform: rotate(-180deg);
  }
  .ant-table-row-expand-icon-collapsed::after {
    transform: rotate(0deg);
  }
  .ant-table-row-expand-icon-spaced {
    background: 0 0;
    border: 0;
    visibility: hidden;
  }
  .ant-table-row-expand-icon-spaced::after,
  .ant-table-row-expand-icon-spaced::before {
    display: none;
    content: none;
  }
  .ant-table-row-indent + .ant-table-row-expand-icon {
    margin-top: 2.5005px;
    margin-right: 8px;
  }
  .ant-transfer-customize-list
    .ant-table-wrapper
    .ant-table-small
    > .ant-table-content
    > .ant-table-body
    > table
    > .ant-table-thead
    > tr
    > th,
  tr.ant-table-expanded-row:hover > td,
  tr.ant-table-expanded-row > td {
    background: #1d1d1d;
  }
  .ant-table .ant-table-expanded-row-fixed {
    position: relative;
    margin: -16px;
    padding: 16px;
  }
  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: #212121;
  }
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    position: -webkit-sticky !important;
    position: sticky !important;
    z-index: 2;
    background: #212121;
  }
  .ant-table-cell-fix-left-first::after,
  .ant-table-cell-fix-left-last::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: -1px;
    width: 30px;
    transform: translateX(100%);
    transition: box-shadow 0.3s;
    content: "";
    pointer-events: none;
  }
  .ant-table-cell-fix-right-first::after,
  .ant-table-cell-fix-right-last::after {
    position: absolute;
    top: 0;
    bottom: -1px;
    left: 0;
    width: 30px;
    transform: translateX(-100%);
    transition: box-shadow 0.3s;
    content: "";
    pointer-events: none;
  }
  .ant-table .ant-table-container::after,
  .ant-table .ant-table-container::before {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    width: 30px;
    transition: box-shadow 0.3s;
    content: "";
    pointer-events: none;
  }
  .ant-table .ant-table-container::before {
    left: 0;
  }
  .ant-table .ant-table-container::after {
    right: 0;
  }
  .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container,
  .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container {
    position: relative;
  }
  .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
    box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.45);
  }
  .ant-table-ping-left .ant-table-cell-fix-left-first::after,
  .ant-table-ping-left .ant-table-cell-fix-left-last::after {
    box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.45);
  }
  .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
    box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.45);
  }
  .ant-table-ping-right .ant-table-cell-fix-right-first::after,
  .ant-table-ping-right .ant-table-cell-fix-right-last::after {
    box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.45);
  }
  .ant-table-sticky-header,
  .ant-table-sticky-scroll {
    position: -webkit-sticky;
    position: sticky;
    z-index: calc(2 + 1);
  }
  .ant-table-sticky-scroll {
    bottom: 0;
    display: flex;
    align-items: center;
    background: #fff;
    border-top: 1px solid #424242;
    opacity: 0.6;
  }
  .ant-table-sticky-scroll:hover {
    transform-origin: center bottom;
  }
  .ant-table-sticky-scroll-bar {
    height: 8px;
    background-color: rgba(0, 0, 0, 0.35);
    border-radius: 4px;
  }
  .ant-table-sticky-scroll-bar-active,
  .ant-table-sticky-scroll-bar:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  @media all and (-ms-high-contrast: none) {
    .ant-table-ping-left .ant-table-cell-fix-left-last::after,
    .ant-table-ping-right .ant-table-cell-fix-right-first::after {
      box-shadow: none !important;
    }
  }
  .ant-table-title {
    border-radius: 4px 4px 0 0;
  }
  .ant-table-title + .ant-table-container {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .ant-table-title + .ant-table-container table > thead > tr:first-child th:first-child {
    border-radius: 0;
  }
  .ant-table-title + .ant-table-container table > thead > tr:first-child th:last-child {
    border-radius: 0;
  }
  .ant-table-container {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 4px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 4px;
  }
  .ant-table-footer {
    border-radius: 0 0 4px 4px;
  }
  .ant-table-rtl,
  .ant-table-wrapper-rtl,
  .ant-tabs-dropdown-rtl,
  .ant-tabs-rtl {
    direction: rtl;
  }
  .ant-table-wrapper-rtl .ant-table-thead > tr > th {
    text-align: right;
  }
  .ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table.ant-table-rtl {
    margin: -16px 33px -16px -16px;
  }
  .ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-left {
    justify-content: flex-end;
  }
  .ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-right {
    justify-content: flex-start;
  }
  .ant-table-wrapper-rtl .ant-table-column-sorter {
    margin-right: 8px;
    margin-left: 0;
  }
  .ant-table-wrapper-rtl .ant-table-filter-column-title {
    padding: 16px 16px 16px 2.3em;
  }
  .ant-table-rtl .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
    padding: 0 0 0 2.3em;
  }
  .ant-table-wrapper-rtl .ant-table-filter-trigger-container {
    right: auto;
    left: 0;
  }
  .ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
  .ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span,
  .ant-dropdown-rtl .ant-table-filter-dropdown .ant-checkbox-wrapper + span,
  .ant-dropdown-rtl .ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
    padding-right: 8px;
    padding-left: 0;
  }
  .ant-table-wrapper-rtl .ant-table-selection {
    text-align: center;
  }
  .ant-table-wrapper-rtl .ant-table-selection-extra {
    right: auto;
    left: 0;
  }
  .ant-table-wrapper-rtl .ant-table-row-expand-icon,
  .ant-table-wrapper-rtl .ant-table-row-indent {
    float: right;
  }
  .ant-table-wrapper-rtl .ant-table-row-indent + .ant-table-row-expand-icon {
    margin-right: 0;
    margin-left: 8px;
  }
  .ant-table-wrapper-rtl .ant-table-row-expand-icon::after {
    transform: rotate(-90deg);
  }
  .ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::before {
    transform: rotate(180deg);
  }
  .ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::after {
    transform: rotate(0deg);
  }
  .ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
    padding: 8px 0;
    font-size: 14px;
  }
  .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    padding: 16px 0;
    font-size: 16px;
  }
  .ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
    padding: 6px 16px;
  }
  .ant-tabs-card.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    padding: 7px 16px 6px;
  }
  .ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab {
    margin: 0 0 0 32px;
  }
  .ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab:last-of-type {
    margin-left: 0;
  }
  .ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .anticon {
    margin-right: 0;
    margin-left: 12px;
  }
  .ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove {
    margin-right: 8px;
    margin-left: -4px;
  }
  .ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove .anticon {
    margin: 0;
  }
  .ant-tabs-rtl.ant-tabs-left > .ant-tabs-nav {
    order: 1;
  }
  .ant-tabs-rtl.ant-tabs-left > .ant-tabs-content-holder,
  .ant-tabs-rtl.ant-tabs-right > .ant-tabs-nav {
    order: 0;
  }
  .ant-tabs-rtl.ant-tabs-right > .ant-tabs-content-holder {
    order: 1;
  }
  .ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
  .ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
  .ant-tabs-rtl.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
  .ant-tabs-rtl.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type) {
    margin-right: 0;
    margin-left: 2px;
  }
  .ant-tabs-dropdown-rtl .ant-tabs-dropdown-menu-item {
    text-align: right;
  }
  .ant-tabs-bottom,
  .ant-tabs-top {
    flex-direction: column;
  }
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav,
  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav {
    margin: 0 0 16px;
  }
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before,
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: 1px solid #424242;
    content: "";
  }
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 2px;
  }
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar-animated,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
    transition: width 0.3s, left 0.3s, right 0.3s;
  }
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
    top: 0;
    bottom: 0;
    width: 30px;
  }
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
    left: 0;
    box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
  }
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
    right: 0;
    box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08);
  }
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after {
    opacity: 1;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav::before {
    bottom: 0;
  }
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    order: 1;
    margin-top: 16px;
    margin-bottom: 0;
  }
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    top: 0;
  }
  .ant-tabs-bottom > .ant-tabs-content-holder,
  .ant-tabs-bottom > div > .ant-tabs-content-holder {
    order: 0;
  }
  .ant-tabs-left > .ant-tabs-nav,
  .ant-tabs-left > div > .ant-tabs-nav,
  .ant-tabs-right > .ant-tabs-nav,
  .ant-tabs-right > div > .ant-tabs-nav {
    flex-direction: column;
    min-width: 50px;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
    margin: 0 0 16px;
    padding: 8px 24px;
    text-align: center;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab:last-of-type,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab:last-of-type,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-tab:last-of-type,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab:last-of-type {
    margin-bottom: 0;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    flex-direction: column;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
    right: 0;
    left: 0;
    height: 30px;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
    top: 0;
    box-shadow: inset 0 10px 8px -8px rgba(0, 0, 0, 0.08);
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
    bottom: 0;
    box-shadow: inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08);
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before {
    opacity: 1;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
    width: 2px;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar-animated,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar-animated,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
    transition: height 0.3s, top 0.3s;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-operations,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-operations,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-operations,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-operations {
    flex-direction: column;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar {
    right: 0;
  }
  .ant-tabs-left > .ant-tabs-content-holder,
  .ant-tabs-left > div > .ant-tabs-content-holder {
    margin-left: -1px;
    border-left: 1px solid #424242;
  }
  .ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
  .ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
    padding-left: 24px;
  }
  .ant-tabs-right > .ant-tabs-nav,
  .ant-tabs-right > div > .ant-tabs-nav {
    order: 1;
  }
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
    left: 0;
  }
  .ant-tabs-right > .ant-tabs-content-holder,
  .ant-tabs-right > div > .ant-tabs-content-holder {
    order: 0;
    margin-right: -1px;
    border-right: 1px solid #424242;
  }
  .ant-tabs-right > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
  .ant-tabs-right > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
    padding-right: 24px;
  }
  .ant-tabs-dropdown {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1050;
    display: block;
  }
  .ant-tabs-dropdown-hidden {
    display: none;
  }
  .ant-tabs-dropdown-menu {
    max-height: 200px;
    margin: 0;
    padding: 4px 0;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: left;
    list-style-type: none;
    background-color: #212121;
    background-clip: padding-box;
    border-radius: 4px;
    outline: 0;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
  }
  .ant-tabs-dropdown-menu-item {
    min-width: 120px;
    margin: 0;
    padding: 5px 12px;
    overflow: hidden;
    color: rgba(255, 255, 255, 0.85);
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    transition: all 0.3s;
  }
  .ant-tabs-dropdown-menu-item:hover {
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-tabs-dropdown-menu-item-disabled,
  .ant-tabs-dropdown-menu-item-disabled:hover {
    color: rgba(255, 255, 255, 0.3);
    background: 0 0;
    cursor: not-allowed;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    margin: 0;
    padding: 8px 16px;
    background: rgba(255, 255, 255, 0.04);
    border: 1px solid #424242;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    color: #2caad9;
    background: #212121;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-ink-bar {
    visibility: hidden;
  }
  .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
  .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type) {
    margin-right: 2px;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 4px 4px 0 0;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    border-bottom-color: #212121;
  }
  .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 0 0 4px 4px;
  }
  .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab-active {
    border-top-color: #212121;
  }
  .ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
  .ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
  .ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
  .ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type) {
    margin-bottom: 2px;
  }
  .ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 4px 0 0 4px;
  }
  .ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
    border-right-color: #212121;
  }
  .ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 0 4px 4px 0;
  }
  .ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab-active {
    border-left-color: #212121;
  }
  .ant-tabs {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    display: flex;
    overflow: hidden;
  }
  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    position: relative;
    display: flex;
    flex: none;
    align-items: center;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    position: relative;
    display: inline-block;
    display: flex;
    flex: auto;
    align-self: stretch;
    overflow: hidden;
    white-space: nowrap;
    transform: translate(0);
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
    content: "";
    pointer-events: none;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    position: relative;
    display: flex;
    transition: transform 0.3s;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
    display: flex;
    align-self: stretch;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden {
    position: absolute;
    visibility: hidden;
    pointer-events: none;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
    position: relative;
    padding: 8px 16px;
    background: 0 0;
    border: 0;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more::after,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 5px;
    transform: translateY(100%);
    content: "";
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add {
    min-width: 40px;
    padding: 0 8px;
    background: rgba(255, 255, 255, 0.04);
    border: 1px solid #424242;
    border-radius: 4px 4px 0 0;
    outline: 0;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
    color: #287a99;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active,
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus {
    color: #4eb3d2;
  }
  .ant-tabs-extra-content {
    flex: none;
  }
  .ant-tabs-centered > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*="ant-tabs-nav-wrap-ping"]),
  .ant-tabs-centered > div > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*="ant-tabs-nav-wrap-ping"]) {
    justify-content: center;
  }
  .ant-tabs-ink-bar {
    position: absolute;
    background: #2caad9;
    pointer-events: none;
  }
  .ant-tabs-tab {
    position: relative;
    display: inline-flex;
    align-items: center;
    margin: 0 32px 0 0;
    padding: 12px 0;
    font-size: 14px;
    background: 0 0;
    border: 0;
    outline: 0;
    cursor: pointer;
  }
  .ant-tabs-tab:last-of-type {
    margin-right: 0;
    margin-left: 0;
  }
  .ant-tabs-tab-btn:active,
  .ant-tabs-tab-btn:focus,
  .ant-tabs-tab-remove:active,
  .ant-tabs-tab-remove:focus {
    color: #4eb3d2;
  }
  .ant-tabs-tab-btn {
    outline: 0;
  }
  .ant-tabs-tab-remove {
    flex: none;
    margin-right: -4px;
    margin-left: 8px;
    color: rgba(255, 255, 255, 0.45);
    font-size: 12px;
    background: 0 0;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: all 0.3s;
  }
  .ant-tabs-tab-remove:hover {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-tabs-tab:hover {
    color: #287a99;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #2caad9;
    font-weight: 500;
  }
  .ant-tabs-tab.ant-tabs-tab-disabled {
    color: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
  }
  .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:active,
  .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:focus,
  .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:active,
  .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:focus {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-tabs-tab .ant-tabs-tab-remove .anticon {
    margin: 0;
  }
  .ant-tabs-tab .anticon {
    margin-right: 12px;
  }
  .ant-tabs-content {
    display: flex;
    width: 100%;
  }
  .ant-tabs-content-holder {
    flex: auto;
    min-width: 0;
    min-height: 0;
  }
  .ant-tabs-content-animated {
    transition: margin 0.3s;
  }
  .ant-tabs-tabpane {
    flex: none;
    width: 100%;
    outline: 0;
  }
  .ant-tag {
    box-sizing: border-box;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    display: inline-block;
    height: auto;
    margin: 0 8px 0 0;
    padding: 0 7px;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    background: rgba(255, 255, 255, 0.04);
    border: 1px solid #6f6c6c;
    border-radius: 4px;
    cursor: default;
    opacity: 1;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ant-tag:hover {
    opacity: 0.85;
  }
  .ant-tag,
  .ant-tag a,
  .ant-tag a:hover,
  .ant-tag-close-icon:hover {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-tag > a:first-child:last-child {
    display: inline-block;
    margin: 0 -8px;
    padding: 0 8px;
  }
  .ant-tag-close-icon {
    display: inline-block;
    font-size: 10px;
    margin-left: 3px;
    color: rgba(255, 255, 255, 0.45);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ant-tag-has-color {
    border-color: transparent;
    color: #fff;
  }
  .ant-tag-has-color .anticon-close,
  .ant-tag-has-color .anticon-close:hover,
  .ant-tag-has-color a,
  .ant-tag-has-color a:hover {
    color: #fff;
  }
  .ant-tag-checkable {
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;
  }
  .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
    color: #2caad9;
  }
  .ant-tag-checkable-checked,
  .ant-tag-checkable:active {
    color: #fff;
  }
  .ant-tag-checkable-checked {
    background-color: #2caad9;
  }
  .ant-tag-checkable:active {
    background-color: #4eb3d2;
  }
  .ant-tag-hidden {
    display: none;
  }
  .ant-tag-pink {
    color: #cd2d84;
    background: #341e2c;
    border-color: #5e2544;
  }
  .ant-tag-pink-inverse {
    color: #fff;
    background: #cd2d84;
    border-color: #cd2d84;
  }
  .ant-tag-magenta {
    color: #cd2d84;
    background: #341e2c;
    border-color: #5e2544;
  }
  .ant-tag-magenta-inverse {
    color: #fff;
    background: #cd2d84;
    border-color: #cd2d84;
  }
  .ant-tag-red {
    color: #d5222b;
    background: #351d20;
    border-color: #612125;
  }
  .ant-tag-red-inverse {
    color: #fff;
    background: #d5222b;
    border-color: #d5222b;
  }
  .ant-tag-volcano {
    color: #d94c1d;
    background: #36211c;
    border-color: #623020;
  }
  .ant-tag-volcano-inverse {
    color: #fff;
    background: #d94c1d;
    border-color: #d94c1d;
  }
  .ant-tag-orange {
    color: #d97c18;
    background: #36281c;
    border-color: #62411e;
  }
  .ant-tag-orange-inverse {
    color: #fff;
    background: #d97c18;
    border-color: #d97c18;
  }
  .ant-tag-yellow {
    color: #d9bf16;
    background: #36311c;
    border-color: #62591d;
  }
  .ant-tag-yellow-inverse {
    color: #fff;
    background: #d9bf16;
    border-color: #d9bf16;
  }
  .ant-tag-gold {
    color: #d99816;
    background: #362c1c;
    border-color: #624b1d;
  }
  .ant-tag-gold-inverse {
    color: #fff;
    background: #d99816;
    border-color: #d99816;
  }
  .ant-tag-cyan {
    color: #15aaaa;
    background: #1c2c2e;
    border-color: #1d5151;
  }
  .ant-tag-cyan-inverse {
    color: #fff;
    background: #15aaaa;
    border-color: #15aaaa;
  }
  .ant-tag-lime {
    color: #8dbd13;
    background: #2a311c;
    border-color: #47581c;
  }
  .ant-tag-lime-inverse {
    color: #fff;
    background: #8dbd13;
    border-color: #8dbd13;
  }
  .ant-tag-green {
    color: #4bac1b;
    background: #212e1d;
    border-color: #30521f;
  }
  .ant-tag-green-inverse {
    color: #fff;
    background: #4bac1b;
    border-color: #4bac1b;
  }
  .ant-tag-blue {
    color: #197fde;
    background: #1c2837;
    border-color: #1e4264;
  }
  .ant-tag-blue-inverse {
    color: #fff;
    background: #197fde;
    border-color: #197fde;
  }
  .ant-tag-geekblue {
    color: #2d4ccd;
    background: #1e2134;
    border-color: #25305e;
  }
  .ant-tag-geekblue-inverse {
    color: #fff;
    background: #2d4ccd;
    border-color: #2d4ccd;
  }
  .ant-tag-purple {
    color: #662cb7;
    background: #251e30;
    border-color: #392556;
  }
  .ant-tag-purple-inverse {
    color: #fff;
    background: #662cb7;
    border-color: #662cb7;
  }
  .ant-tag-success {
    color: #4bac1b;
    background: #212e1d;
    border-color: #30521f;
  }
  .ant-tag-processing {
    color: #197fde;
    background: #1c2837;
    border-color: #1e4264;
  }
  .ant-tag-error {
    color: #d5222b;
    background: #351d20;
    border-color: #612125;
  }
  .ant-tag-warning {
    color: #d97c18;
    background: #36281c;
    border-color: #62411e;
  }
  .ant-tag > .anticon + span,
  .ant-tag > span + .anticon {
    margin-left: 7px;
  }
  .ant-tag.ant-tag-rtl {
    margin-right: 0;
    margin-left: 8px;
    direction: rtl;
    text-align: right;
  }
  .ant-tag-rtl .ant-tag-close-icon {
    margin-right: 3px;
    margin-left: 0;
  }
  .ant-tag-rtl.ant-tag > .anticon + span,
  .ant-tag-rtl.ant-tag > span + .anticon {
    margin-right: 7px;
    margin-left: 0;
  }
  .ant-timeline,
  .ant-timeline-item {
    margin: 0;
    font-size: 14px;
    list-style: none;
  }
  .ant-timeline {
    box-sizing: border-box;
    color: rgba(255, 255, 255, 0.85);
    font-variant: tabular-nums;
    line-height: 1.5715;
    font-feature-settings: "tnum";
    padding: 0;
  }
  .ant-timeline-item {
    position: relative;
    padding-bottom: 20px;
  }
  .ant-timeline-item-tail {
    position: absolute;
    top: 10px;
    left: 4px;
    height: calc(100% - 10px);
    border-left: 2px solid #424242;
  }
  .ant-timeline-item-pending .ant-timeline-item-head {
    font-size: 12px;
    background-color: transparent;
  }
  .ant-timeline-item-last > .ant-timeline-item-tail,
  .ant-timeline-item-pending .ant-timeline-item-tail {
    display: none;
  }
  .ant-timeline-item-head {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #212121;
    border: 2px solid transparent;
    border-radius: 100px;
  }
  .ant-timeline-item-head-blue {
    color: #2caad9;
    border-color: #2caad9;
  }
  .ant-timeline-item-head-red {
    color: #ab2229;
    border-color: #ab2229;
  }
  .ant-timeline-item-head-green {
    color: #4bac1b;
    border-color: #4bac1b;
  }
  .ant-timeline-item-head-gray {
    color: rgba(255, 255, 255, 0.3);
    border-color: rgba(255, 255, 255, 0.3);
  }
  .ant-timeline-item-head-custom {
    position: absolute;
    top: 5.5px;
    left: 5px;
    width: auto;
    height: auto;
    margin-top: 0;
    padding: 3px 1px;
    line-height: 1;
    text-align: center;
    border: 0;
    border-radius: 0;
    transform: translate(-50%, -50%);
  }
  .ant-timeline-item-content {
    position: relative;
    top: -7.001px;
    margin: 0 0 0 26px;
    word-break: break-word;
  }
  .ant-timeline-item-last > .ant-timeline-item-content {
    min-height: 48px;
  }
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
  .ant-timeline.ant-timeline-label .ant-timeline-item-head,
  .ant-timeline.ant-timeline-label .ant-timeline-item-head-custom,
  .ant-timeline.ant-timeline-label .ant-timeline-item-tail,
  .ant-timeline.ant-timeline-right .ant-timeline-item-head,
  .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
  .ant-timeline.ant-timeline-right .ant-timeline-item-tail {
    left: 50%;
  }
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
  .ant-timeline.ant-timeline-label .ant-timeline-item-head,
  .ant-timeline.ant-timeline-right .ant-timeline-item-head {
    margin-left: -4px;
  }
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
  .ant-timeline.ant-timeline-label .ant-timeline-item-head-custom,
  .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom {
    margin-left: 1px;
  }
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
  .ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content,
  .ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content {
    left: calc(50% - 4px);
    width: calc(50% - 14px);
    text-align: left;
  }
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content {
    width: calc(50% - 12px);
    margin: 0;
    text-align: right;
  }
  .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
    margin: 0;
    text-align: right;
  }
  .ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
    width: calc(50% - 12px);
    margin: 0;
    text-align: right;
  }
  .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
  .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom,
  .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail {
    left: calc(100% - 4px - 2px);
  }
  .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
    width: calc(100% - 18px);
  }
  .ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
    display: block;
    height: calc(100% - 14px);
    border-left: 2px dotted #424242;
  }
  .ant-timeline.ant-timeline-reverse .ant-timeline-item-last .ant-timeline-item-tail {
    display: none;
  }
  .ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
    top: 15px;
    display: block;
    height: calc(100% - 15px);
    border-left: 2px dotted #424242;
  }
  .ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-content {
    min-height: 48px;
  }
  .ant-timeline.ant-timeline-label .ant-timeline-item-label {
    position: absolute;
    top: -7.001px;
    width: calc(50% - 12px);
    text-align: right;
  }
  .ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
    left: calc(50% + 14px);
    width: calc(50% - 14px);
    text-align: left;
  }
  .ant-timeline-rtl {
    direction: rtl;
  }
  .ant-timeline-rtl .ant-timeline-item-tail {
    right: 4px;
    left: auto;
    border-right: 2px solid #424242;
    border-left: none;
  }
  .ant-timeline-rtl .ant-timeline-item-head-custom {
    right: 5px;
    left: auto;
    transform: translate(50%, -50%);
  }
  .ant-timeline-rtl .ant-timeline-item-content {
    margin: 0 18px 0 0;
  }
  .ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
  .ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
  .ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
  .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head,
  .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom,
  .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
  .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head,
  .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
  .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-tail {
    right: 50%;
    left: auto;
  }
  .ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
  .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head,
  .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head {
    margin-right: -4px;
    margin-left: 0;
  }
  .ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
  .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom,
  .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom {
    margin-right: 1px;
    margin-left: 0;
  }
  .ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
  .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content,
  .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content {
    right: calc(50% - 4px);
    left: auto;
    text-align: right;
  }
  .ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
  .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-label,
  .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
    text-align: left;
  }
  .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
  .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom,
  .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail {
    right: 0;
    left: auto;
  }
  .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
    width: 100%;
    margin-right: 18px;
    text-align: right;
  }
  .ant-timeline-rtl.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail,
  .ant-timeline-rtl.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
    border-right: 2px dotted #424242;
    border-left: none;
  }
  .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
    right: calc(50% + 14px);
    text-align: right;
  }
  .ant-tooltip {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: absolute;
    z-index: 1070;
    display: block;
    max-width: 250px;
    visibility: visible;
  }
  .ant-tooltip-hidden {
    display: none;
  }
  .ant-tooltip-placement-top,
  .ant-tooltip-placement-topLeft,
  .ant-tooltip-placement-topRight {
    padding-bottom: 8px;
  }
  .ant-tooltip-placement-right,
  .ant-tooltip-placement-rightBottom,
  .ant-tooltip-placement-rightTop {
    padding-left: 8px;
  }
  .ant-tooltip-placement-bottom,
  .ant-tooltip-placement-bottomLeft,
  .ant-tooltip-placement-bottomRight {
    padding-top: 8px;
  }
  .ant-tooltip-placement-left,
  .ant-tooltip-placement-leftBottom,
  .ant-tooltip-placement-leftTop {
    padding-right: 8px;
  }
  .ant-tooltip-inner {
    min-width: 30px;
    min-height: 32px;
    padding: 6px 8px;
    color: #fff;
    text-align: left;
    text-decoration: none;
    word-wrap: break-word;
    background-color: #434343;
    border-radius: 4px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
  }
  .ant-tooltip-arrow {
    position: absolute;
    display: block;
    width: 13.07106781px;
    height: 13.07106781px;
    overflow: hidden;
    background: 0 0;
    pointer-events: none;
  }
  .ant-tooltip-arrow-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 5px;
    height: 5px;
    margin: auto;
    background-color: #434343;
    content: "";
    pointer-events: auto;
  }
  .ant-tooltip-placement-top .ant-tooltip-arrow,
  .ant-tooltip-placement-topLeft .ant-tooltip-arrow,
  .ant-tooltip-placement-topRight .ant-tooltip-arrow {
    bottom: -5.07106781px;
  }
  .ant-tooltip-placement-top .ant-tooltip-arrow-content,
  .ant-tooltip-placement-topLeft .ant-tooltip-arrow-content,
  .ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
    transform: translateY(-6.53553391px) rotate(45deg);
  }
  .ant-tooltip-placement-top .ant-tooltip-arrow {
    left: 50%;
    transform: translateX(-50%);
  }
  .ant-tooltip-placement-topLeft .ant-tooltip-arrow {
    left: 13px;
  }
  .ant-tooltip-placement-topRight .ant-tooltip-arrow {
    right: 13px;
  }
  .ant-tooltip-placement-right .ant-tooltip-arrow,
  .ant-tooltip-placement-rightBottom .ant-tooltip-arrow,
  .ant-tooltip-placement-rightTop .ant-tooltip-arrow {
    left: -5.07106781px;
  }
  .ant-tooltip-placement-right .ant-tooltip-arrow-content,
  .ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content,
  .ant-tooltip-placement-rightTop .ant-tooltip-arrow-content {
    box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
    transform: translateX(6.53553391px) rotate(45deg);
  }
  .ant-tooltip-placement-right .ant-tooltip-arrow {
    top: 50%;
    transform: translateY(-50%);
  }
  .ant-tooltip-placement-rightTop .ant-tooltip-arrow {
    top: 5px;
  }
  .ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
    bottom: 5px;
  }
  .ant-tooltip-placement-left .ant-tooltip-arrow,
  .ant-tooltip-placement-leftBottom .ant-tooltip-arrow,
  .ant-tooltip-placement-leftTop .ant-tooltip-arrow {
    right: -5.07106781px;
  }
  .ant-tooltip-placement-left .ant-tooltip-arrow-content,
  .ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content,
  .ant-tooltip-placement-leftTop .ant-tooltip-arrow-content {
    box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
    transform: translateX(-6.53553391px) rotate(45deg);
  }
  .ant-tooltip-placement-left .ant-tooltip-arrow {
    top: 50%;
    transform: translateY(-50%);
  }
  .ant-tooltip-placement-leftTop .ant-tooltip-arrow {
    top: 5px;
  }
  .ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
    bottom: 5px;
  }
  .ant-tooltip-placement-bottom .ant-tooltip-arrow,
  .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
  .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
    top: -5.07106781px;
  }
  .ant-tooltip-placement-bottom .ant-tooltip-arrow-content,
  .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content,
  .ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
    box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
    transform: translateY(6.53553391px) rotate(45deg);
  }
  .ant-tooltip-placement-bottom .ant-tooltip-arrow {
    left: 50%;
    transform: translateX(-50%);
  }
  .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
    left: 13px;
  }
  .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
    right: 13px;
  }
  .ant-tooltip-magenta .ant-tooltip-arrow-content,
  .ant-tooltip-magenta .ant-tooltip-inner,
  .ant-tooltip-pink .ant-tooltip-arrow-content,
  .ant-tooltip-pink .ant-tooltip-inner {
    background-color: #cd2d84;
  }
  .ant-tooltip-red .ant-tooltip-arrow-content,
  .ant-tooltip-red .ant-tooltip-inner {
    background-color: #d5222b;
  }
  .ant-tooltip-volcano .ant-tooltip-arrow-content,
  .ant-tooltip-volcano .ant-tooltip-inner {
    background-color: #d94c1d;
  }
  .ant-tooltip-orange .ant-tooltip-arrow-content,
  .ant-tooltip-orange .ant-tooltip-inner {
    background-color: #d97c18;
  }
  .ant-tooltip-yellow .ant-tooltip-arrow-content,
  .ant-tooltip-yellow .ant-tooltip-inner {
    background-color: #d9bf16;
  }
  .ant-tooltip-gold .ant-tooltip-arrow-content,
  .ant-tooltip-gold .ant-tooltip-inner {
    background-color: #d99816;
  }
  .ant-tooltip-cyan .ant-tooltip-arrow-content,
  .ant-tooltip-cyan .ant-tooltip-inner {
    background-color: #15aaaa;
  }
  .ant-tooltip-lime .ant-tooltip-arrow-content,
  .ant-tooltip-lime .ant-tooltip-inner {
    background-color: #8dbd13;
  }
  .ant-tooltip-green .ant-tooltip-arrow-content,
  .ant-tooltip-green .ant-tooltip-inner {
    background-color: #4bac1b;
  }
  .ant-tooltip-blue .ant-tooltip-arrow-content,
  .ant-tooltip-blue .ant-tooltip-inner {
    background-color: #197fde;
  }
  .ant-tooltip-geekblue .ant-tooltip-arrow-content,
  .ant-tooltip-geekblue .ant-tooltip-inner {
    background-color: #2d4ccd;
  }
  .ant-tooltip-purple .ant-tooltip-arrow-content,
  .ant-tooltip-purple .ant-tooltip-inner {
    background-color: #662cb7;
  }
  .ant-tooltip-rtl {
    direction: rtl;
  }
  .ant-tooltip-rtl .ant-tooltip-inner {
    text-align: right;
  }
  .ant-transfer-customize-list .ant-transfer-list {
    flex: 1 1 50%;
    width: auto;
    height: auto;
    min-height: 200px;
  }
  .ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
    border: 0;
    border-radius: 0;
  }
  .ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-body {
    margin: 0;
  }
  .ant-transfer-customize-list .ant-table-wrapper .ant-table-pagination.ant-pagination {
    margin: 16px 0 4px;
  }
  .ant-transfer-customize-list .ant-input[disabled] {
    background-color: transparent;
  }
  .ant-transfer {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: flex;
    align-items: stretch;
  }
  .ant-transfer-disabled .ant-transfer-list,
  .ant-tree.ant-tree-directory .ant-tree-treenode:hover::before {
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-transfer-list {
    display: flex;
    flex-direction: column;
    width: 180px;
    height: 200px;
    border: 1px solid #6f6c6c;
    border-radius: 4px;
  }
  .ant-transfer-list-with-pagination {
    width: 250px;
    height: auto;
  }
  .ant-transfer-list-search {
    padding-right: 24px;
    padding-left: 8px;
  }
  .ant-transfer-list-search-action {
    position: absolute;
    top: 12px;
    right: 12px;
    bottom: 12px;
    width: 28px;
    color: rgba(255, 255, 255, 0.3);
    line-height: 32px;
    text-align: center;
  }
  .ant-transfer-list-search-action .anticon {
    color: rgba(255, 255, 255, 0.3);
    transition: all 0.3s;
  }
  .ant-transfer-list-search-action .anticon:hover {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-transfer-list-header {
    display: flex;
    flex: none;
    align-items: center;
    height: 40px;
    padding: 8px 12px 9px;
    color: rgba(255, 255, 255, 0.85);
    background: #212121;
    border-bottom: 1px solid #424242;
    border-radius: 4px 4px 0 0;
  }
  .ant-transfer-list-header > :not(:last-child) {
    margin-right: 4px;
  }
  .ant-transfer-list-header > * {
    flex: none;
  }
  .ant-transfer-list-header-title {
    flex: auto;
    overflow: hidden;
    white-space: nowrap;
    text-align: right;
    text-overflow: ellipsis;
  }
  .ant-transfer-list-header-dropdown {
    transform: translateY(10%);
    cursor: pointer;
    display: inline-block;
    font-size: 10px;
  }
  .ant-transfer-list-header-dropdown[disabled] {
    cursor: not-allowed;
  }
  .ant-transfer-list-body {
    display: flex;
    flex: auto;
    flex-direction: column;
    overflow: hidden;
    font-size: 14px;
  }
  .ant-transfer-list-body-search-wrapper {
    position: relative;
    flex: none;
    padding: 12px;
  }
  .ant-transfer-list-content {
    flex: auto;
    margin: 0;
    padding: 0;
    overflow: auto;
    list-style: none;
  }
  .ant-transfer-list-content-item {
    display: flex;
    align-items: center;
    min-height: 32px;
    padding: 6px 12px;
    overflow: hidden;
    line-height: 20px;
    transition: all 0.3s;
  }
  .ant-transfer-list-content-item > :not(:last-child) {
    margin-right: 8px;
  }
  .ant-transfer-list-content-item > * {
    flex: none;
  }
  .ant-transfer-list-content-item-text {
    flex: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ant-transfer-list-content-item-remove {
    text-decoration: none;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;
    position: relative;
    color: #6f6c6c;
  }
  .ant-transfer-list-content-item-remove:focus,
  .ant-transfer-list-content-item-remove:hover,
  .ant-typography a:focus,
  .ant-typography a:hover,
  a.ant-typography:focus,
  a.ant-typography:hover {
    color: #287a99;
  }
  .ant-transfer-list-content-item-remove:active,
  .ant-typography a:active,
  a.ant-typography:active {
    color: #4eb3d2;
  }
  .ant-transfer-list-content-item-remove::after {
    position: absolute;
    top: -6px;
    right: -50%;
    bottom: -6px;
    left: -50%;
    content: "";
  }
  .ant-transfer-list-content-item-remove:hover {
    color: #287a99;
  }
  .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
    background-color: #262626;
    cursor: pointer;
  }
  .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover {
    background-color: #1a252b;
  }
  .ant-transfer-list-content-show-remove
    .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
    background: 0 0;
    cursor: default;
  }
  .ant-transfer-list-content-item-checked {
    background-color: #1e2b31;
  }
  .ant-transfer-list-content-item-disabled,
  .ant-tree-checkbox-disabled + span {
    color: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
  }
  .ant-transfer-list-pagination {
    padding: 8px 0;
    text-align: right;
    border-top: 1px solid #424242;
  }
  .ant-transfer-list-body-not-found {
    flex: none;
    width: 100%;
    margin: auto 0;
    color: rgba(255, 255, 255, 0.3);
    text-align: center;
  }
  .ant-transfer-list-footer {
    border-top: 1px solid #424242;
  }
  .ant-transfer-operation {
    display: flex;
    flex: none;
    flex-direction: column;
    align-self: center;
    margin: 0 8px;
    overflow: hidden;
    vertical-align: middle;
  }
  .ant-transfer-operation .ant-btn {
    display: block;
  }
  .ant-transfer-operation .ant-btn:first-child {
    margin-bottom: 4px;
  }
  .ant-transfer-operation .ant-btn .anticon {
    font-size: 12px;
  }
  .ant-transfer .ant-empty-image {
    max-height: -2px;
  }
  .ant-transfer-rtl {
    direction: rtl;
  }
  .ant-transfer-rtl .ant-transfer-list-search {
    padding-right: 8px;
    padding-left: 24px;
  }
  .ant-transfer-rtl .ant-transfer-list-search-action {
    right: auto;
    left: 12px;
  }
  .ant-transfer-rtl .ant-transfer-list-header > :not(:last-child) {
    margin-right: 0;
    margin-left: 4px;
  }
  .ant-transfer-rtl .ant-transfer-list-header {
    right: 0;
    left: auto;
  }
  .ant-transfer-rtl .ant-transfer-list-header-title,
  .ant-transfer-rtl .ant-transfer-list-pagination {
    text-align: left;
  }
  .ant-transfer-rtl .ant-transfer-list-content-item > :not(:last-child) {
    margin-right: 0;
    margin-left: 8px;
  }
  .ant-transfer-rtl .ant-transfer-list-footer {
    right: 0;
    left: auto;
  }
  .ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line::before {
    height: 14px !important;
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode {
    position: relative;
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 4px;
    left: 0;
    transition: background-color 0.3s;
    content: "";
    pointer-events: none;
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode > * {
    z-index: 1;
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-switcher {
    transition: color 0.3s;
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper {
    border-radius: 0;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
    background: 0 0;
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode-selected::before,
  .ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before {
    background: #2caad9;
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
    color: #fff;
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected,
  .ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper {
    color: #fff;
    background: 0 0;
  }
  .ant-tree-checkbox {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    top: -0.09em;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    vertical-align: middle;
    outline: 0;
    cursor: pointer;
  }
  .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner,
  .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
  .ant-tree-checkbox:hover .ant-tree-checkbox-inner {
    border-color: #2caad9;
  }
  .ant-tree-checkbox-checked::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #2caad9;
    border-radius: 4px;
    visibility: hidden;
    animation: antCheckboxEffect 0.36s ease-in-out;
    animation-fill-mode: backwards;
    content: "";
  }
  .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox::after,
  .ant-tree-checkbox:hover::after {
    visibility: visible;
  }
  .ant-tree-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    direction: ltr;
    background-color: transparent;
    border: 1px solid #6f6c6c;
    border-radius: 4px;
    border-collapse: separate;
    transition: all 0.3s;
  }
  .ant-tree-checkbox-checked .ant-tree-checkbox-inner::after,
  .ant-tree-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    content: " ";
  }
  .ant-tree-checkbox-inner::after {
    top: 50%;
    left: 22%;
    width: 5.71428571px;
    height: 9.14285714px;
    transform: rotate(45deg) scale(0) translate(-50%, -50%);
    opacity: 0;
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  }
  .ant-tree-checkbox-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
  .ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  }
  .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background-color: #2caad9;
    border-color: #2caad9;
  }
  .ant-tree-checkbox-disabled,
  .ant-tree-checkbox-disabled .ant-tree-checkbox-input {
    cursor: not-allowed;
  }
  .ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
    border-color: rgba(255, 255, 255, 0.3);
    animation-name: none;
  }
  .ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #6f6c6c !important;
  }
  .ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
    border-color: rgba(255, 255, 255, 0.08);
    border-collapse: separate;
    animation-name: none;
  }
  .ant-tree-checkbox-disabled:hover::after,
  .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-disabled::after {
    visibility: hidden;
  }
  .ant-tree-checkbox-group,
  .ant-tree-checkbox-wrapper {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    display: inline-block;
  }
  .ant-tree-checkbox-wrapper {
    line-height: unset;
    cursor: pointer;
  }
  .ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-disabled {
    cursor: not-allowed;
  }
  .ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
    margin-left: 8px;
  }
  .ant-tree-checkbox + span {
    padding-right: 8px;
    padding-left: 8px;
  }
  .ant-tree-checkbox-group {
    line-height: 1.5715;
  }
  .ant-tree-checkbox-group-item {
    display: inline-block;
    margin-right: 8px;
  }
  .ant-tree-checkbox-group-item:last-child {
    margin-right: 0;
  }
  .ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
    margin-left: 0;
  }
  .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
    background-color: transparent;
    border-color: #6f6c6c;
  }
  .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    background-color: #2caad9;
    border: 0;
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    content: " ";
  }
  .ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
    background-color: rgba(255, 255, 255, 0.3);
    border-color: rgba(255, 255, 255, 0.3);
  }
  .ant-tree-checkbox-rtl {
    direction: rtl;
  }
  .ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item {
    margin-right: 0;
    margin-left: 8px;
  }
  .ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item:last-child {
    margin-left: 0 !important;
  }
  .ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
    margin-left: 8px;
  }
  .ant-tree {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    background: 0 0;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  .ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
    background: #1e2b31;
  }
  .ant-tree-list-holder-inner {
    align-items: flex-start;
  }
  .ant-tree.ant-tree-block-node .ant-tree-list-holder-inner {
    align-items: stretch;
  }
  .ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
    flex: auto;
  }
  .ant-tree .ant-tree-treenode {
    display: flex;
    align-items: flex-start;
    padding: 0 0 4px;
    outline: 0;
  }
  .ant-select-tree-checkbox-disabled + span,
  .ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
    color: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
  }
  .ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper:hover {
    background: 0 0;
  }
  .ant-tree .ant-tree-treenode-active .ant-tree-node-content-wrapper {
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-tree-indent {
    align-self: stretch;
    white-space: nowrap;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-tree-indent-unit {
    display: inline-block;
    width: 24px;
  }
  .ant-tree .ant-tree-switcher {
    flex: none;
    width: 24px;
    height: 24px;
    margin: 0;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
  }
  .ant-tree .ant-tree-switcher .ant-select-tree-switcher-icon,
  .ant-tree .ant-tree-switcher .ant-tree-switcher-icon {
    font-size: 10px;
    display: inline-block;
    vertical-align: baseline;
  }
  .ant-tree .ant-tree-switcher .ant-select-tree-switcher-icon svg,
  .ant-tree .ant-tree-switcher .ant-tree-switcher-icon svg {
    transition: transform 0.3s;
  }
  .ant-tree .ant-tree-switcher-noop {
    cursor: default;
  }
  .ant-tree .ant-tree-switcher_close .ant-tree-switcher-icon svg {
    transform: rotate(-90deg);
  }
  .ant-tree .ant-tree-switcher-loading-icon {
    color: #2caad9;
  }
  .ant-tree .ant-tree-switcher-leaf-line {
    z-index: 1;
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  .ant-tree .ant-tree-switcher-leaf-line::before {
    position: absolute;
    height: 24px;
    margin-left: -1px;
    border-left: 1px solid #d9d9d9;
    content: " ";
  }
  .ant-tree .ant-tree-switcher-leaf-line::after {
    position: absolute;
    width: 10px;
    height: 14px;
    margin-left: -1px;
    border-bottom: 1px solid #d9d9d9;
    content: " ";
  }
  .ant-tree .ant-tree-checkbox {
    top: initial;
    margin: 4px 8px 0 0;
  }
  .ant-tree .ant-tree-node-content-wrapper {
    min-height: 24px;
    margin: 0;
    padding: 0 4px;
    color: inherit;
    line-height: 24px;
    background: 0 0;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s, border 0s, line-height 0s;
  }
  .ant-tree .ant-tree-node-content-wrapper:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: #203a46;
  }
  .ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    vertical-align: top;
  }
  .ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle:empty {
    display: none;
  }
  .ant-tree-node-content-wrapper[draggable="true"] {
    line-height: 20px;
    border-top: 2px transparent solid;
    border-bottom: 2px transparent solid;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-tree .ant-tree-treenode.drag-over > [draggable] {
    color: #fff;
    background-color: #2caad9;
    opacity: 0.8;
  }
  .ant-tree .ant-tree-treenode.drag-over-gap-top > [draggable] {
    border-top-color: #2caad9;
  }
  .ant-tree .ant-tree-treenode.drag-over-gap-bottom > [draggable] {
    border-bottom-color: #2caad9;
  }
  .ant-tree-show-line .ant-tree-indent-unit {
    position: relative;
    height: 100%;
  }
  .ant-tree-show-line .ant-tree-indent-unit:first-child::after {
    position: absolute;
    top: calc(100% - 24px - 4px);
    right: 12px;
    bottom: -4px;
    border-right: 1px solid #6f6c6c;
    content: "";
  }
  .ant-tree-show-line .ant-tree-indent-unit::before {
    position: absolute;
    top: calc(100% - 4px);
    right: -12px;
    bottom: -28px;
    border-right: 1px solid #6f6c6c;
    content: "";
  }
  .ant-tree-show-line .ant-tree-indent-unit-end-first-level::after,
  .ant-tree-show-line .ant-tree-indent-unit-end::before {
    display: none;
  }
  .ant-select-tree-show-line
    .ant-select-tree-treenode-motion:not(.ant-motion-collapse-leave):not(.ant-motion-collapse-appear-active)
    .ant-select-tree-indent-unit::after,
  .ant-select-tree-show-line
    .ant-select-tree-treenode-motion:not(.ant-motion-collapse-leave):not(.ant-motion-collapse-appear-active)
    .ant-select-tree-indent-unit::before,
  .ant-tree-show-line
    .ant-tree-treenode-motion:not(.ant-motion-collapse-leave):not(.ant-motion-collapse-appear-active)
    .ant-tree-indent-unit::after,
  .ant-tree-show-line
    .ant-tree-treenode-motion:not(.ant-motion-collapse-leave):not(.ant-motion-collapse-appear-active)
    .ant-tree-indent-unit::before {
    display: none;
  }
  .ant-tree-show-line .ant-tree-switcher {
    z-index: 1;
    background: #212121;
  }
  .ant-tree .ant-tree-treenode-rtl,
  .ant-tree-rtl {
    direction: rtl;
  }
  .ant-tree-rtl.ant-tree .ant-tree-switcher_close .ant-tree-switcher-icon svg,
  .ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
    transform: rotate(90deg);
  }
  .ant-tree-rtl.ant-tree .ant-tree-switcher-loading-icon {
    transform: scaleY(-1);
  }
  .ant-tree-rtl.ant-tree-show-line .ant-tree-indent-unit::before {
    right: auto;
    left: -13px;
    border-right: none;
    border-left: 1px solid #6f6c6c;
  }
  .ant-tree-rtl.ant-tree .ant-tree-checkbox,
  .ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-checkbox {
    margin: 4px 0 0 8px;
  }
  .ant-select-tree-checkbox {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    top: -0.09em;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    vertical-align: middle;
    outline: 0;
    cursor: pointer;
  }
  .ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner,
  .ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
  .ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner {
    border-color: #2caad9;
  }
  .ant-select-tree-checkbox-checked::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #2caad9;
    border-radius: 4px;
    visibility: hidden;
    animation: antCheckboxEffect 0.36s ease-in-out;
    animation-fill-mode: backwards;
    content: "";
  }
  .ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox::after,
  .ant-select-tree-checkbox:hover::after {
    visibility: visible;
  }
  .ant-select-tree-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    direction: ltr;
    background-color: transparent;
    border: 1px solid #6f6c6c;
    border-radius: 4px;
    border-collapse: separate;
    transition: all 0.3s;
  }
  .ant-select-tree-checkbox-inner::after {
    position: absolute;
    top: 50%;
    left: 22%;
    display: table;
    width: 5.71428571px;
    height: 9.14285714px;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(0) translate(-50%, -50%);
    opacity: 0;
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    content: " ";
  }
  .ant-select-tree-checkbox-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
  .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: " ";
  }
  .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
    background-color: #2caad9;
    border-color: #2caad9;
  }
  .ant-select-tree-checkbox-disabled,
  .ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-input {
    cursor: not-allowed;
  }
  .ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
    border-color: rgba(255, 255, 255, 0.3);
    animation-name: none;
  }
  .ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #6f6c6c !important;
  }
  .ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
    border-color: rgba(255, 255, 255, 0.08);
    border-collapse: separate;
    animation-name: none;
  }
  .ant-select-tree-checkbox-disabled:hover::after,
  .ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-disabled::after {
    visibility: hidden;
  }
  .ant-select-tree-checkbox-group,
  .ant-select-tree-checkbox-wrapper {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    display: inline-block;
  }
  .ant-select-tree-checkbox-wrapper {
    line-height: unset;
    cursor: pointer;
  }
  .ant-select-tree-checkbox-wrapper.ant-select-tree-checkbox-wrapper-disabled {
    cursor: not-allowed;
  }
  .ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
    margin-left: 8px;
  }
  .ant-select-tree-checkbox + span {
    padding-right: 8px;
    padding-left: 8px;
  }
  .ant-select-tree-checkbox-group {
    line-height: 1.5715;
  }
  .ant-select-tree-checkbox-group-item {
    display: inline-block;
    margin-right: 8px;
  }
  .ant-select-tree-checkbox-group-item:last-child {
    margin-right: 0;
  }
  .ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
    margin-left: 0;
  }
  .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
    background-color: transparent;
    border-color: #6f6c6c;
  }
  .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    background-color: #2caad9;
    border: 0;
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    content: " ";
  }
  .ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
    background-color: rgba(255, 255, 255, 0.3);
    border-color: rgba(255, 255, 255, 0.3);
  }
  .ant-select-tree-checkbox-rtl {
    direction: rtl;
  }
  .ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item {
    margin-right: 0;
    margin-left: 8px;
  }
  .ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item:last-child {
    margin-left: 0 !important;
  }
  .ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
    margin-left: 8px;
  }
  .ant-tree-select-dropdown {
    padding: 8px 4px 0;
  }
  .ant-tree-select-dropdown-rtl {
    direction: rtl;
  }
  .ant-tree-select-dropdown .ant-select-tree {
    border-radius: 0;
  }
  .ant-tree-select-dropdown .ant-select-tree-list-holder-inner {
    align-items: stretch;
  }
  .ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode {
    padding-bottom: 8px;
  }
  .ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-node-content-wrapper,
  .ant-tree-select-dropdown
    .ant-select-tree-list-holder-inner
    .ant-select-tree-treenode
    .ant-select-tree-node-content-wrapper {
    flex: auto;
  }
  .ant-select-tree {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    background: 0 0;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  .ant-select-tree-focused:not(:hover):not(.ant-select-tree-active-focused) {
    background: #1e2b31;
  }
  .ant-select-tree-list-holder-inner {
    align-items: flex-start;
  }
  .ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner {
    align-items: stretch;
  }
  .ant-select-tree .ant-select-tree-treenode {
    display: flex;
    align-items: flex-start;
    padding: 0 0 4px;
    outline: 0;
  }
  .ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
    color: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
  }
  .ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper:hover {
    background: 0 0;
  }
  .ant-select-tree .ant-select-tree-treenode-active .ant-select-tree-node-content-wrapper {
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-select-tree-indent {
    align-self: stretch;
    white-space: nowrap;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-select-tree-indent-unit {
    display: inline-block;
    width: 24px;
  }
  .ant-select-tree .ant-select-tree-switcher {
    flex: none;
    width: 24px;
    height: 24px;
    margin: 0;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
  }
  .ant-select-tree .ant-select-tree-switcher .ant-select-tree-switcher-icon,
  .ant-select-tree .ant-select-tree-switcher .ant-tree-switcher-icon {
    font-size: 10px;
    display: inline-block;
    vertical-align: baseline;
  }
  .ant-select-tree .ant-select-tree-switcher .ant-select-tree-switcher-icon svg,
  .ant-select-tree .ant-select-tree-switcher .ant-tree-switcher-icon svg {
    transition: transform 0.3s;
  }
  .ant-select-tree .ant-select-tree-switcher-noop {
    cursor: default;
  }
  .ant-select-tree .ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
    transform: rotate(-90deg);
  }
  .ant-select-tree .ant-select-tree-switcher-loading-icon {
    color: #2caad9;
  }
  .ant-select-tree .ant-select-tree-switcher-leaf-line {
    z-index: 1;
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  .ant-select-tree .ant-select-tree-switcher-leaf-line::before {
    position: absolute;
    height: 24px;
    margin-left: -1px;
    border-left: 1px solid #d9d9d9;
    content: " ";
  }
  .ant-select-tree .ant-select-tree-switcher-leaf-line::after {
    position: absolute;
    width: 10px;
    height: 14px;
    margin-left: -1px;
    border-bottom: 1px solid #d9d9d9;
    content: " ";
  }
  .ant-select-tree .ant-select-tree-checkbox {
    top: initial;
    margin: 4px 8px 0 0;
  }
  .ant-select-tree .ant-select-tree-node-content-wrapper {
    min-height: 24px;
    margin: 0;
    padding: 0 4px;
    color: inherit;
    line-height: 24px;
    background: 0 0;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s, border 0s, line-height 0s;
  }
  .ant-select-tree .ant-select-tree-node-content-wrapper:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
    background-color: #203a46;
  }
  .ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle {
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    vertical-align: top;
  }
  .ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle:empty {
    display: none;
  }
  .ant-select-tree-node-content-wrapper[draggable="true"] {
    line-height: 20px;
    border-top: 2px transparent solid;
    border-bottom: 2px transparent solid;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-select-tree .ant-select-tree-treenode.drag-over > [draggable] {
    color: #fff;
    background-color: #2caad9;
    opacity: 0.8;
  }
  .ant-select-tree .ant-select-tree-treenode.drag-over-gap-top > [draggable] {
    border-top-color: #2caad9;
  }
  .ant-select-tree .ant-select-tree-treenode.drag-over-gap-bottom > [draggable] {
    border-bottom-color: #2caad9;
  }
  .ant-select-tree-show-line .ant-select-tree-indent-unit {
    position: relative;
    height: 100%;
  }
  .ant-select-tree-show-line .ant-select-tree-indent-unit:first-child::after {
    position: absolute;
    top: calc(100% - 24px - 4px);
    right: 12px;
    bottom: -4px;
    border-right: 1px solid #6f6c6c;
    content: "";
  }
  .ant-select-tree-show-line .ant-select-tree-indent-unit::before {
    position: absolute;
    top: calc(100% - 4px);
    right: -12px;
    bottom: -28px;
    border-right: 1px solid #6f6c6c;
    content: "";
  }
  .ant-select-tree-show-line .ant-select-tree-indent-unit-end-first-level::after,
  .ant-select-tree-show-line .ant-select-tree-indent-unit-end::before,
  .ant-upload-list-picture-card.ant-upload-list::after {
    display: none;
  }
  .ant-select-tree-show-line .ant-select-tree-switcher {
    z-index: 1;
    background: #212121;
  }
  .ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher-loading-icon {
    transform: scaleY(-1);
  }
  .ant-typography {
    color: rgba(255, 255, 255, 0.85);
    overflow-wrap: break-word;
  }
  .ant-typography.ant-typography-secondary {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-typography.ant-typography-success {
    color: #4bac1b;
  }
  .ant-typography.ant-typography-warning {
    color: #d99816;
  }
  .ant-typography.ant-typography-danger {
    color: #ab2229;
  }
  .ant-typography.ant-typography-disabled {
    color: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-typography p,
  div.ant-typography {
    margin-bottom: 1em;
  }
  .ant-typography h1,
  h1.ant-typography {
    margin-bottom: 0.5em;
    color: rgba(255, 255, 255, 0.85);
    font-weight: 600;
    font-size: 38px;
    line-height: 1.23;
  }
  .ant-typography h2,
  .ant-typography h3,
  h2.ant-typography,
  h3.ant-typography {
    margin-bottom: 0.5em;
    color: rgba(255, 255, 255, 0.85);
    font-weight: 600;
    font-size: 30px;
    line-height: 1.35;
  }
  .ant-typography h3,
  h3.ant-typography {
    font-size: 24px;
  }
  .ant-typography h4,
  .ant-typography h5,
  h4.ant-typography,
  h5.ant-typography {
    margin-bottom: 0.5em;
    color: rgba(255, 255, 255, 0.85);
    font-weight: 600;
    font-size: 20px;
    line-height: 1.4;
  }
  .ant-typography h5,
  h5.ant-typography {
    font-size: 16px;
    line-height: 1.5;
  }
  .ant-typography div + h1,
  .ant-typography div + h2,
  .ant-typography div + h3,
  .ant-typography div + h4,
  .ant-typography div + h5,
  .ant-typography h1 + h1,
  .ant-typography h1 + h2,
  .ant-typography h1 + h3,
  .ant-typography h1 + h4,
  .ant-typography h1 + h5,
  .ant-typography h2 + h1,
  .ant-typography h2 + h2,
  .ant-typography h2 + h3,
  .ant-typography h2 + h4,
  .ant-typography h2 + h5,
  .ant-typography h3 + h1,
  .ant-typography h3 + h2,
  .ant-typography h3 + h3,
  .ant-typography h3 + h4,
  .ant-typography h3 + h5,
  .ant-typography h4 + h1,
  .ant-typography h4 + h2,
  .ant-typography h4 + h3,
  .ant-typography h4 + h4,
  .ant-typography h4 + h5,
  .ant-typography h5 + h1,
  .ant-typography h5 + h2,
  .ant-typography h5 + h3,
  .ant-typography h5 + h4,
  .ant-typography h5 + h5,
  .ant-typography li + h1,
  .ant-typography li + h2,
  .ant-typography li + h3,
  .ant-typography li + h4,
  .ant-typography li + h5,
  .ant-typography p + h1,
  .ant-typography p + h2,
  .ant-typography p + h3,
  .ant-typography p + h4,
  .ant-typography p + h5,
  .ant-typography ul + h1,
  .ant-typography ul + h2,
  .ant-typography ul + h3,
  .ant-typography ul + h4,
  .ant-typography ul + h5,
  .ant-typography + h1.ant-typography,
  .ant-typography + h2.ant-typography,
  .ant-typography + h3.ant-typography,
  .ant-typography + h4.ant-typography,
  .ant-typography + h5.ant-typography {
    margin-top: 1.2em;
  }
  a.ant-typography-ellipsis,
  span.ant-typography-ellipsis {
    display: inline-block;
  }
  .ant-typography a,
  .ant-typography-copy,
  .ant-typography-edit,
  .ant-typography-expand,
  a.ant-typography {
    color: #2caad9;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;
    text-decoration: none;
  }
  .ant-typography a:active,
  .ant-typography a:hover,
  a.ant-typography:active,
  a.ant-typography:hover {
    text-decoration: none;
  }
  .ant-typography a[disabled],
  a.ant-typography[disabled] {
    color: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
    pointer-events: none;
  }
  .ant-typography code,
  .ant-typography kbd {
    margin: 0 0.2em;
    border: 1px solid rgba(100, 100, 100, 0.2);
    border-radius: 3px;
  }
  .ant-typography code {
    padding: 0.2em 0.4em 0.1em;
    font-size: 85%;
    background: rgba(150, 150, 150, 0.1);
  }
  .ant-typography kbd {
    padding: 0.15em 0.4em 0.1em;
    font-size: 90%;
    background: rgba(150, 150, 150, 0.06);
    border-bottom-width: 2px;
  }
  .ant-typography mark {
    padding: 0;
    background-color: #624b1d;
  }
  .ant-typography ins,
  .ant-typography u {
    text-decoration: underline;
    -webkit-text-decoration-skip: ink;
    text-decoration-skip-ink: auto;
  }
  .ant-typography del,
  .ant-typography s {
    text-decoration: line-through;
  }
  .ant-typography strong {
    font-weight: 600;
  }
  .ant-typography-copy,
  .ant-typography-edit,
  .ant-typography-expand {
    margin-left: 4px;
  }
  .ant-typography-copy:focus,
  .ant-typography-copy:hover,
  .ant-typography-edit:focus,
  .ant-typography-edit:hover,
  .ant-typography-expand:focus,
  .ant-typography-expand:hover {
    color: #287a99;
  }
  .ant-typography-copy:active,
  .ant-typography-edit:active,
  .ant-typography-expand:active {
    color: #4eb3d2;
  }
  .ant-typography-copy-success,
  .ant-typography-copy-success:focus,
  .ant-typography-copy-success:hover {
    color: #4bac1b;
  }
  .ant-typography-edit-content {
    position: relative;
  }
  div.ant-typography-edit-content {
    left: -12px;
    margin-top: -5px;
    margin-bottom: calc(1em - 4px - 1px);
  }
  .ant-typography-edit-content-confirm {
    position: absolute;
    right: 10px;
    bottom: 8px;
    color: rgba(255, 255, 255, 0.45);
    pointer-events: none;
  }
  .ant-typography-edit-content textarea {
    -moz-transition: none;
  }
  .ant-typography ol,
  .ant-typography ul {
    margin: 0 0 1em;
    padding: 0;
  }
  .ant-typography ol li,
  .ant-typography ul li {
    margin: 0 0 0 20px;
    padding: 0 0 0 4px;
  }
  .ant-typography ul {
    list-style-type: circle;
  }
  .ant-typography ul ul {
    list-style-type: disc;
  }
  .ant-typography ol {
    list-style-type: decimal;
  }
  .ant-typography-ellipsis-single-line {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  a.ant-typography-ellipsis-single-line,
  span.ant-typography-ellipsis-single-line {
    vertical-align: bottom;
  }
  .ant-typography-ellipsis-multiple-line {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .ant-typography-rtl {
    direction: rtl;
  }
  .ant-typography-rtl .ant-typography-copy,
  .ant-typography-rtl .ant-typography-edit,
  .ant-typography-rtl .ant-typography-expand {
    margin-right: 4px;
    margin-left: 0;
  }
  .ant-typography-rtl .ant-typography-expand {
    float: left;
  }
  div.ant-typography-edit-content.ant-typography-rtl {
    right: -12px;
    left: auto;
  }
  .ant-typography-rtl .ant-typography-edit-content-confirm {
    right: auto;
    left: 10px;
  }
  .ant-typography-rtl.ant-typography ol li,
  .ant-typography-rtl.ant-typography ul li {
    margin: 0 20px 0 0;
    padding: 0 4px 0 0;
  }
  .ant-upload {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    outline: 0;
  }
  .ant-upload p {
    margin: 0;
  }
  .ant-upload-btn {
    display: block;
    width: 100%;
    outline: 0;
  }
  .ant-upload.ant-upload-select {
    display: inline-block;
  }
  .ant-upload.ant-upload-disabled {
    cursor: not-allowed;
  }
  .ant-upload.ant-upload-drag,
  .ant-upload.ant-upload-select-picture-card {
    text-align: center;
    border: 1px dashed #6f6c6c;
    border-radius: 4px;
    cursor: pointer;
    transition: border-color 0.3s;
  }
  .ant-upload.ant-upload-select-picture-card {
    width: 104px;
    height: 104px;
    margin-right: 8px;
    margin-bottom: 8px;
    vertical-align: top;
    background-color: rgba(255, 255, 255, 0.04);
  }
  .ant-upload.ant-upload-select-picture-card > .ant-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
  }
  .ant-upload.ant-upload-select-picture-card:hover {
    border-color: #2caad9;
  }
  .ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
    border-color: #6f6c6c;
  }
  .ant-upload.ant-upload-drag {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.04);
  }
  .ant-upload.ant-upload-drag .ant-upload {
    padding: 16px 0;
  }
  .ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
    border-color: #4eb3d2;
  }
  .ant-upload.ant-upload-drag.ant-upload-disabled {
    cursor: not-allowed;
  }
  .ant-upload.ant-upload-drag .ant-upload-btn {
    display: table;
    height: 100%;
  }
  .ant-upload.ant-upload-drag .ant-upload-drag-container {
    display: table-cell;
    vertical-align: middle;
  }
  .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: #287a99;
  }
  .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
    margin-bottom: 20px;
  }
  .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: #287a99;
    font-size: 48px;
  }
  .ant-upload.ant-upload-drag p.ant-upload-text {
    margin: 0 0 4px;
    color: rgba(255, 255, 255, 0.85);
    font-size: 16px;
  }
  .ant-upload.ant-upload-drag p.ant-upload-hint {
    color: rgba(255, 255, 255, 0.45);
    font-size: 14px;
  }
  .ant-upload.ant-upload-drag .anticon-plus {
    color: rgba(255, 255, 255, 0.3);
    font-size: 30px;
    transition: all 0.3s;
  }
  .ant-upload.ant-upload-drag .anticon-plus:hover,
  .ant-upload.ant-upload-drag:hover .anticon-plus {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-upload-picture-card-wrapper {
    display: inline-block;
    width: 100%;
  }
  .ant-upload-picture-card-wrapper::before {
    display: table;
    content: "";
  }
  .ant-upload-picture-card-wrapper::after {
    display: table;
    clear: both;
    content: "";
  }
  .ant-upload-list {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    line-height: 1.5715;
  }
  .ant-upload-list::after,
  .ant-upload-list::before {
    display: table;
    content: "";
  }
  .ant-upload-list::after {
    clear: both;
  }
  .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-1 {
    padding-right: 14px;
  }
  .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-2 {
    padding-right: 28px;
  }
  .ant-upload-list-item {
    position: relative;
    height: 22.001px;
    margin-top: 8px;
    font-size: 14px;
  }
  .ant-upload-list-item-name {
    display: inline-block;
    width: 100%;
    padding-left: 22px;
    overflow: hidden;
    line-height: 1.5715;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ant-upload-list-item-name-icon-count-1 {
    padding-right: 14px;
  }
  .ant-upload-list-item-card-actions {
    position: absolute;
    right: 0;
  }
  .ant-upload-list-item-card-actions-btn {
    opacity: 0;
  }
  .ant-upload-list-item-card-actions-btn.ant-btn-sm {
    height: 20px;
    line-height: 1;
  }
  .ant-upload-list-item-card-actions.picture {
    top: 22px;
    line-height: 0;
  }
  .ant-upload-list-item-card-actions-btn:focus,
  .ant-upload-list-item-card-actions.picture .ant-upload-list-item-card-actions-btn {
    opacity: 1;
  }
  .ant-upload-list-item-card-actions .anticon {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-upload-list-item-info {
    height: 100%;
    padding: 0 12px 0 4px;
    transition: background-color 0.3s;
  }
  .ant-upload-list-item-info > span {
    display: block;
    width: 100%;
    height: 100%;
  }
  .ant-upload-list-item .anticon-close,
  .ant-upload-list-item-info .ant-upload-text-icon .anticon,
  .ant-upload-list-item-info .anticon-loading .anticon {
    position: absolute;
    top: 5px;
    color: rgba(255, 255, 255, 0.45);
    font-size: 14px;
  }
  .ant-upload-list-item .anticon-close {
    display: inline-block;
    font-size: 10px;
    top: 6px;
    right: 4px;
    line-height: 0;
    cursor: pointer;
    opacity: 0;
    transition: all 0.3s;
  }
  .ant-upload-list-item .anticon-close:hover {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-upload-list-item:hover .ant-upload-list-item-info {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-upload-list-item:hover .ant-upload-list-item-card-actions-btn,
  .ant-upload-list-item:hover .anticon-close {
    opacity: 1;
  }
  .ant-upload-list-item-error,
  .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon,
  .ant-upload-list-item-error .ant-upload-list-item-name,
  .ant-upload-list-item-error .ant-upload-text-icon > .anticon {
    color: #ab2229;
  }
  .ant-upload-list-item-error .ant-upload-list-item-card-actions-btn,
  .ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
    opacity: 1;
  }
  .ant-upload-list-item-progress {
    position: absolute;
    bottom: -12px;
    width: 100%;
    padding-left: 26px;
    font-size: 14px;
    line-height: 0;
  }
  .ant-upload-list-picture .ant-upload-list-item {
    height: 66px;
  }
  .ant-upload-list-picture .ant-upload-list-item,
  .ant-upload-list-picture-card .ant-upload-list-item {
    position: relative;
    padding: 8px;
    border: 1px solid #6f6c6c;
    border-radius: 4px;
  }
  .ant-upload-list-picture .ant-upload-list-item:hover,
  .ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
  .ant-upload-list-picture-card .ant-upload-list-item:hover,
  .ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
    background: 0 0;
  }
  .ant-upload-list-picture .ant-upload-list-item-error,
  .ant-upload-list-picture-card .ant-upload-list-item-error {
    border-color: #ab2229;
  }
  .ant-upload-list-picture .ant-upload-list-item-uploading,
  .ant-upload-list-picture-card .ant-upload-list-item-uploading {
    border-style: dashed;
  }
  .ant-upload-list-picture .ant-upload-list-item-thumbnail {
    position: absolute;
    width: 48px;
    height: 48px;
  }
  .ant-upload-list-picture .ant-upload-list-item-thumbnail,
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
    top: 8px;
    left: 8px;
    line-height: 54px;
    text-align: center;
    opacity: 0.8;
  }
  .ant-upload-list-picture .ant-upload-list-item-icon .anticon,
  .ant-upload-list-picture .ant-upload-list-item-thumbnail .anticon,
  .ant-upload-list-picture-card .ant-upload-list-item-icon .anticon,
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail .anticon {
    font-size: 26px;
  }
  .ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill="#e6f7ff"],
  .ant-upload-list-picture-card
    .ant-upload-list-item-error
    .ant-upload-list-item-thumbnail
    .anticon
    svg
    path[fill="#e6f7ff"] {
    fill: #ebdedd;
  }
  .ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill="#1890ff"],
  .ant-upload-list-picture-card
    .ant-upload-list-item-error
    .ant-upload-list-item-thumbnail
    .anticon
    svg
    path[fill="#1890ff"] {
    fill: #ab2229;
  }
  .ant-upload-list-picture .ant-upload-list-item-icon,
  .ant-upload-list-picture-card .ant-upload-list-item-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 26px;
    transform: translate(-50%, -50%);
  }
  .ant-upload-list-picture .ant-upload-list-item-image,
  .ant-upload-list-picture-card .ant-upload-list-item-image {
    max-width: 100%;
  }
  .ant-upload-list-picture .ant-upload-list-item-thumbnail img {
    display: block;
    width: 48px;
    height: 48px;
    overflow: hidden;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    overflow: hidden;
  }
  .ant-upload-list-picture .ant-upload-list-item-name {
    display: inline-block;
    margin: 0 0 0 8px;
    padding-right: 8px;
    padding-left: 48px;
    line-height: 44px;
  }
  .ant-upload-list-picture .ant-upload-list-item-name,
  .ant-upload-list-picture-card .ant-upload-list-item-name {
    box-sizing: border-box;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all 0.3s;
  }
  .ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
  .ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
    padding-right: 18px;
  }
  .ant-upload-list-picture .ant-upload-list-item-name-icon-count-2,
  .ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
    padding-right: 36px;
  }
  .ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
  .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
    line-height: 28px;
  }
  .ant-upload-list-picture .ant-upload-list-item-progress {
    bottom: 14px;
    width: calc(100% - 24px);
    margin-top: 0;
    padding-left: 56px;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-progress {
    margin-top: 0;
  }
  .ant-upload-list-picture .anticon-close,
  .ant-upload-list-picture-card .anticon-close {
    position: absolute;
    top: 8px;
    right: 8px;
    line-height: 1;
    opacity: 1;
  }
  .ant-upload-list-picture-card-container {
    display: inline-block;
    width: 104px;
    height: 104px;
    margin: 0 8px 8px 0;
    vertical-align: top;
  }
  .ant-upload-list-picture-card .ant-upload-list-item {
    height: 100%;
    margin: 0;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-info {
    position: relative;
    height: 100%;
    overflow: hidden;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-info::before {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: all 0.3s;
    content: " ";
  }
  .ant-upload-list-picture-card .ant-upload-list-item-actions {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    white-space: nowrap;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete,
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye {
    z-index: 10;
    width: 16px;
    margin: 0 4px;
    color: rgba(255, 255, 255, 0.85);
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover,
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye:hover {
    color: #fff;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-actions:hover,
  .ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions {
    opacity: 1;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    position: static;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-name {
    display: none;
    margin: 8px 0 0;
    padding: 0;
    line-height: 1.5715;
    text-align: center;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
    position: absolute;
    bottom: 10px;
    display: block;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
    background-color: rgba(255, 255, 255, 0.04);
  }
  .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
    height: auto;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete,
  .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye,
  .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info::before {
    display: none;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-progress {
    bottom: 32px;
    width: calc(100% - 14px);
    padding-left: 0;
  }
  .ant-upload-list .ant-upload-success-icon {
    color: #4bac1b;
    font-weight: 700;
  }
  .ant-upload-list .ant-upload-animate-enter,
  .ant-upload-list .ant-upload-animate-inline-enter,
  .ant-upload-list .ant-upload-animate-inline-leave,
  .ant-upload-list .ant-upload-animate-leave {
    animation-duration: 0.3s;
    animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ant-upload-list .ant-upload-animate-enter {
    animation-name: uploadAnimateIn;
  }
  .ant-upload-list .ant-upload-animate-leave {
    animation-name: uploadAnimateOut;
  }
  .ant-upload-list .ant-upload-animate-inline-enter {
    animation-name: uploadAnimateInlineIn;
  }
  .ant-upload-list .ant-upload-animate-inline-leave {
    animation-name: uploadAnimateInlineOut;
  }
  .ant-upload-list-rtl,
  .ant-upload-rtl {
    direction: rtl;
  }
  .ant-upload-rtl.ant-upload.ant-upload-select-picture-card {
    margin-right: auto;
    margin-left: 8px;
  }
  .ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-1 {
    padding-right: 22px;
    padding-left: 14px;
  }
  .ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-2 {
    padding-right: 22px;
    padding-left: 28px;
  }
  .ant-upload-list-rtl .ant-upload-list-item-name {
    padding-right: 22px;
    padding-left: 0;
  }
  .ant-upload-list-rtl .ant-upload-list-item-name-icon-count-1 {
    padding-left: 14px;
  }
  .ant-upload-list-rtl .ant-upload-list-item-card-actions {
    right: auto;
    left: 0;
  }
  .ant-upload-list-rtl .ant-upload-list-item-card-actions .anticon,
  .ant-upload-list-rtl .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
    padding-right: 0;
    padding-left: 5px;
  }
  .ant-upload-list-rtl .ant-upload-list-item-info {
    padding: 0 4px 0 12px;
  }
  .ant-upload-list-rtl .ant-upload-list-item .anticon-close {
    right: auto;
    left: 4px;
  }
  .ant-upload-list-rtl .ant-upload-list-item-progress {
    padding-right: 26px;
    padding-left: 0;
  }
  .ant-upload-list-picture .ant-upload-list-item-info,
  .ant-upload-list-picture-card .ant-upload-list-item-info {
    padding: 0;
  }
  .ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-thumbnail,
  .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
    right: 8px;
    left: auto;
  }
  .ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-icon,
  .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-icon {
    right: 50%;
    left: auto;
    transform: translate(50%, -50%);
  }
  .ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name,
  .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name {
    margin: 0 8px 0 0;
    padding-right: 48px;
    padding-left: 8px;
  }
  .ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
  .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
    padding-right: 48px;
    padding-left: 18px;
  }
  .ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2,
  .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
    padding-right: 48px;
    padding-left: 36px;
  }
  .ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-progress,
  .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-progress {
    padding-right: 0;
    padding-left: 0;
  }
  .ant-upload-list-rtl.ant-upload-list-picture .anticon-close,
  .ant-upload-list-rtl.ant-upload-list-picture-card .anticon-close {
    right: auto;
    left: 8px;
  }
  .ant-upload-list-rtl .ant-upload-list-picture-card-container {
    margin: 0 0 8px 8px;
  }
  .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-actions {
    right: 50%;
    left: auto;
    transform: translate(50%, -50%);
  }
  .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
    margin: 8px 0 0;
    padding: 0;
  }
  .highlight {
    background-color: #3f3f3f;
  }
  