.directory-navbar-row {
  background-color: #100F0F;
  position: fixed;
  width: 100%;
  z-index: 2;
  border-bottom: 1px solid #368cff;
}

.directory-title{
  padding-top: 150px;
  font-weight: 700;
  font-family: Rajdhani;
}

#directory {
font-family: Rajdhani;
background: #FFFFFF;
background: linear-gradient(to right, #001eff 0%, #00bbff 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;

}

.directory-apply-button-col{
  display: flex;
  justify-content: flex-end;
}

.directory-apply-button-col button{
  margin-top: 150px;
}