* {
  box-sizing: border-box;
}

body {
  margin: 0px;
  font-family: 'segoe ui';
}

.textGradient {
  background: #FFFFFF;
  background: linear-gradient(to right, #001eff 0%, #00bbff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
  
}

.connectButton {
  display: inline;
  float: right;
  font-size: 1.2em;
  margin-right: 100px;
  margin-top: -10px;
  background-color: transparent;
  border: 0;
}

.connectButton:hover {
  background: #FFFFFF;
  background: linear-gradient(to right, #001eff 0%, #00bbff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
}

.nav {
  height: 50px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  position: relative;
}

.nav > .nav-header {
  display: inline;
}

.nav > .nav-header > .nav-title {
  display: inline-block;
  width: 200px;
  color: #fff;
  padding: 10px 10px 10px 10px;
}

.nav > .nav-btn {
  display: none;
}

.nav > .nav-links {
  display: inline;
  float: right;
  font-size: 18px;
  margin-left: 150px;
  text-align: left;
}

.nav > .nav-links > a, .nav > .nav-links > p {
  display: inline-block;
  padding: 13px 10px 13px 10px;
  text-decoration: none;
  color: #efefef;
  cursor: pointer;
}

.nav > .nav-links > a:hover, .nav > .nav-links > p:hover {
  color: #368cff;
}

.nav > #nav-check {
  display: none;
}

.nav-img {
  width: 100%;
}

@media (max-width:1075px) {
  .connectButton {
    position: absolute;
    right: 75px;
    top: 20px;
  }
}

@media (max-width:4000px) {
  .nav > .nav-btn {
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .nav > .nav-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
  }
  .nav > .nav-btn > label:hover,.nav  #nav-check:checked ~ .nav-btn > label {
    background-color: rgba(255, 255, 255, 0.05);
  }
  .nav > .nav-btn > label > span {
    display: block;
    width: 25px;
    height: 10px;
    border-top: 2px solid #eee;
    
  }

}
.nav > .nav-btn-close > label > p {
  display: block;
  width: 25px;
  height: 10px;
  margin: auto;
  padding: auto;
  font-size: 2em;
  text-align: center;
  line-height: .75;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.nav > .nav-btn-close > label:hover,.nav  #nav-check:checked ~ .nav-btn > label {
  background-color: rgba(255, 255, 255, 0.05);
}

.nav > .nav-btn-close {
  display: inline-block;
  position: absolute;
  right: 0px;
  top: 0px;
}
.nav > .nav-btn-close > label {
  display: inline-block;
  width: 50px;
  height: 50px;
  padding: 13px;
}

  .nav > .nav-links {
    position: absolute;
    display: block;
    width: 33%;
    background-color: rgba(0, 0, 0, 0.9);
    height: 0px;
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 50px;
    right: 0px;
  }
  .nav > .nav-links > a {
    display: block;
    width: 100%;
  }
  .nav > #nav-check:not(:checked) ~ .nav-links {
    height: 0px;
  }
  .nav > #nav-check:checked ~ .nav-links {
    height: calc(100vh - 50px);
    overflow-y: auto;
    z-index: 10;
  }
  .connectButton {
    position: absolute;
    right: 75px;
    top: 20px;
  }


@media (max-width:515px) {
  .connectButton {
    position: absolute;
    right: 0;
    top: 20px;
    margin-right: 55px;
  }
  .nav > .nav-links {
    text-align: center;
  }
}