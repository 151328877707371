footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.dework {
  padding: 24px;
  padding-top: 20px;
  width: 200px;
  height: 200px;
  border: 1px solid #368cff;
  margin: 10px;
  border-radius: 25px;
  background-color:hsla(88,0%,0%,1);
  background-image:
  radial-gradient(at 86% 84%, hsla(237,84%,62%,0.2) 0px, transparent 50%),
  radial-gradient(at 59% 82%, hsla(240,75%,60%,0.2) 0px, transparent 50%),
  radial-gradient(at 39% 30%, hsla(240,100%,50%,0.16) 0px, transparent 50%),
  radial-gradient(at 32% 9%, hsla(240,99%,75%,0.17) 0px, transparent 50%),
  radial-gradient(at 96% 10%, hsla(189,63%,61%,0.05) 0px, transparent 50%),
  radial-gradient(at 4% 3%, hsla(239,70%,77%,0) 0px, transparent 50%),
  radial-gradient(at 50% 70%, hsla(233,73%,73%,0.2) 0px, transparent 50%);
  background-size: cover;
  font-family: Rajdhani;
}

.other {
  padding: 24px;
  padding-top: 20px;
  width: 200px;
  height: 200px;
  border: 1px solid #368cff;
  margin: 10px;
  border-radius: 25px;
  background-color:hsla(88,0%,0%,1);
  background-image:
  radial-gradient(at 86% 84%, hsla(237,84%,62%,0.2) 0px, transparent 50%),
  radial-gradient(at 59% 82%, hsla(240,75%,60%,0.2) 0px, transparent 50%),
  radial-gradient(at 39% 30%, hsla(240,100%,50%,0.16) 0px, transparent 50%),
  radial-gradient(at 32% 9%, hsla(240,99%,75%,0.17) 0px, transparent 50%),
  radial-gradient(at 96% 10%, hsla(189,63%,61%,0.05) 0px, transparent 50%),
  radial-gradient(at 4% 3%, hsla(239,70%,77%,0) 0px, transparent 50%),
  radial-gradient(at 50% 70%, hsla(233,73%,73%,0.2) 0px, transparent 50%);
  background-size: cover;
  font-family: Rajdhani;
}

.deworkImg {
  margin: 24px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  padding: 10px;
}

.otherImg {
  margin: 24px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  padding: 10px;
}

.dework:hover {
    box-shadow: inset 2px 2px 17px 0px rgba(0, 64, 255, 0.75);
    -webkit-box-shadow: inset 2px 2px 17px 0px rgba(0, 64, 255, 0.75);
    -moz-box-shadow: inset 2px 2px 17px 0px rgba(0, 64, 255, 0.75);

    background-color:hsla(88,0%,0%,1);
    background-image:
    radial-gradient(at 86% 84%, hsla(237,84%,62%,0.2) 0px, transparent 50%),
    radial-gradient(at 59% 82%, hsla(240,75%,60%,0.2) 0px, transparent 50%),
    radial-gradient(at 39% 30%, hsla(240,100%,50%,0.16) 0px, transparent 50%),
    radial-gradient(at 32% 9%, hsla(240,99%,75%,0.17) 0px, transparent 50%),
    radial-gradient(at 96% 10%, hsla(189,63%,61%,0.05) 0px, transparent 50%),
    radial-gradient(at 4% 3%, hsla(239,70%,77%,0) 0px, transparent 50%),
    radial-gradient(at 50% 70%, hsla(233,73%,73%,0.2) 0px, transparent 50%);
}

.other:hover {
  box-shadow: inset 2px 2px 17px 0px rgba(0, 64, 255, 0.75);
  -webkit-box-shadow: inset 2px 2px 17px 0px rgba(0, 64, 255, 0.75);
  -moz-box-shadow: inset 2px 2px 17px 0px rgba(0, 64, 255, 0.75);

  background-color:hsla(88,0%,0%,1);
  background-image:
  radial-gradient(at 86% 84%, hsla(237,84%,62%,0.2) 0px, transparent 50%),
  radial-gradient(at 59% 82%, hsla(240,75%,60%,0.2) 0px, transparent 50%),
  radial-gradient(at 39% 30%, hsla(240,100%,50%,0.16) 0px, transparent 50%),
  radial-gradient(at 32% 9%, hsla(240,99%,75%,0.17) 0px, transparent 50%),
  radial-gradient(at 96% 10%, hsla(189,63%,61%,0.05) 0px, transparent 50%),
  radial-gradient(at 4% 3%, hsla(239,70%,77%,0) 0px, transparent 50%),
  radial-gradient(at 50% 70%, hsla(233,73%,73%,0.2) 0px, transparent 50%);
}

.chat {
  width: 200px;
  position: absolute;
  right: 24px;
  bottom: 10px;
}

.textGradient {
  background: #FFFFFF;
  background: linear-gradient(to right, #001eff 0%, #00bbff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
  
}

.mailIcon {
  position: absolute;
  left: 10;
}

.mail {
  position: absolute;
  left: 30;
}



.images-row{
  padding: 10px 0;
}

.images-col {
  display: flex;
  flex-direction: column;
}

.blg-image {
  height: 10%;
}

.partners-image {
  width: 3rem;
}

.copywrite-col{
  text-align: center;
}

.copywrite-col p{
  font-size: 0.8rem;
}

.footer-title {
  display: flex;
  align-items: center;
}

.footer-title img {
  width: 16rem;
  
}

.footer-titles{
  color: #368cff;
  font-weight: 700;
}

.footer-socials{
  font-size: 1.8rem;
  color: #FFFFFF;
  margin-right: 1rem;
  transition: 0.2s;
  
}

.footer-socials:hover {
  color: #368cff;
}

.email {
  color: #FFFFFF;
  text-decoration: none;
}

.apply-col {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 2rem !important;
}

.partners-col {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  
}

.partners-title {
  font-size: 2rem;
  font-weight: 700;
  margin-top: 1rem;
  margin-bottom: 1rem;
  
}

.socials-col {
  justify-content: center;
  display: flex;
  margin-top: 1rem !important;
  
}

.contacts-col {
  display: flex;
  flex-direction: column;
}

.mobileIcon {
  position: absolute;
  bottom: 6;
  right: 280;
}

.mobileFooter {
  margin-top: 50px;
}

.apply-btn {
  width: 75px;
  height: 33px;
  font-size: 1em;
  border: 1px solid #368cff;
  border-radius: 10px;
  position: absolute;
  left: 24px;
  bottom: 20px;
}

@media only screen and (max-width: 600px) {
  .mobileFooter {
    font-size: 0.8em;
  }
  .mobileIcon {
    position: absolute;
    bottom: 6;
    right: 0;
    width: 100%;
  }

  .chat {
    width: 100px;
    position: absolute;
    bottom: 45px;
    right: 10px;
  }

  .apply-btn {
    width: 50px;
    height: 25px;
    font-size: .75em;
    border-radius: 7px;
    position: absolute;
    left: 24px;
    bottom: 70px;
  }

  .copy {
    font-size: 0.7em;
  }

  .dework {
    height: 100px;
    border-radius: 15px;
    padding: auto;
  }

  .other {
    height: 100px;
    border-radius: 15px;
    padding: auto;
  }

  .deworkImg {
    margin: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
    padding: 5px;
    padding-top: 10px;
  }
  
  .otherImg {
    margin: auto;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
    padding: 5px;
    padding-top: 10px;
  }

}